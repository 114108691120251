<template>
    <div>
        <v-dialog v-model="dialog_period_selection" no-click-animation persistent scrollable transition="dialog-bottom-transition" max-width="290">
          <v-card>
            <v-card-title noshadow nopadding>
                <v-subheader full-width nopadding>{{title}}</v-subheader>
            </v-card-title>

            <!-- <v-divider></v-divider> -->

            <v-card-text dialog-card-content nopadding>
                <v-layout wrap>

                    <v-form data-vv-scope="fields" full-width>

                        <v-flex xs12 sm12 md12 form-item @click="openDatePicker('start', startDate)">
                            <v-text-field  
                                readonly 
                                :value="fn_getStartDate()" 
                                label="Start Date" 
                                placeholder=" " 
                                append-icon="arrow_drop_down"
                                v-validate="'required'" 
                                name="startDate"
                                data-vv-name="startDate" 
                                :error-messages="errors.collect('fields.startDate')"
                                required>
                            </v-text-field>

                        </v-flex>

                        <v-flex xs12 sm12 md12 form-item @click="openDatePicker('end', endDate)">
                            <v-text-field  
                                readonly 
                                :value="fn_getEndDate()"
                                label="End Date" 
                                placeholder=" " 
                                append-icon="arrow_drop_down"
                                v-validate="'required'" 
                                name="endDate"
                                data-vv-name="endDate" 
                                :error-messages="errors.collect('fields.endDate')"
                                required>
                            </v-text-field>

                        </v-flex>
                    </v-form>

                </v-layout>

            </v-card-text>

            <!-- <v-divider></v-divider> -->

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="fn_closeDialog" v-if="!hideCancel">close</v-btn>
              <v-btn color="blue darken-1" text @click="fn_action">Done</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default{
    props:['title', 'closeDialog', 'openDatePicker', 'startDate', 'endDate', 'actionMethod', 'hideCancel'],
    data(){ 
        return{
            dialog_period_selection: false
        }
    },
    methods:{
        openDialogWeb: function () {
            this.dialog_period_selection = true;
        },
        closeDialogWeb: function () {
            this.dialog_period_selection = false;
        },
        fn_closeDialog: function(){
            this.closeDialog ? this.closeDialog() : this.dialog_period_selection = false;
        },
        fn_getStartDate: function(){
            if(this.startDate)
                return this.$moment(this.startDate).format('DD-MMM-YYYY');
            else
                return null
        },
        fn_getEndDate: function(){
            if(this.endDate)
                return this.$moment(this.endDate).format('DD-MMM-YYYY');
            else
                return null
        },
        fn_action: function(){
            if(this.actionMethod){
                this.fn_closeDialog();
                this.actionMethod();
            }
            else{
                this.fn_closeDialog();
            }
        }
    }
}
</script>