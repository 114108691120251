<template>
    <div full-height>
        <v-toolbar flat fixed>
            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
            <v-toolbar-title class="ml-5">Add Phone Number</v-toolbar-title>
        </v-toolbar>

        <div class="hastoolbar hasfooter" :class="{'desktop-view':$root.platform()=='desktop'}">
            <v-form class="form-element">

                <v-layout align-center nomargin padding-top-ten row wrap white-background>

                    <v-flex xs12 sm12 md12 form-item>
                       <v-text-field  
                            v-model="Form.PhoneNumber" 
                            label="Enter Phone Number" 
                            type="tel"
                            placeholder=" "
                            clearable
                            prefix="+91"
                            v-validate="'required|numeric|min:10|max:10'"
                            :maxlength="10"
                            data-vv-name="phoneNumber"  
                            data-vv-as="phone number"  
                            :error-messages="errors.collect('phoneNumber')"
                            required>
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 sm12 md12 form-item>
                       <v-text-field  
                            v-model="Form.Code"
                            :maxlength="6"
                            clearable
                            hide-details
                            type="number"
                            label="Enter Code"
                            placeholder=" "
                            v-validate="'required|min:6'"
                            data-vv-name="code" 
                            data-vv-as="code" 
                            required>
                        </v-text-field>
                    </v-flex>

                </v-layout>

            </v-form>

            <!-- <v-footer style="position:fixed;bottom:0;width:100%">
                <v-layout row wrap>
                    <v-flex xs12 sm12 md12 form-item>
                        <v-btn color="primary" block @click="fn_checkValidations">Save</v-btn>
                    </v-flex>  
                </v-layout>
            </v-footer> -->

            <v-footer nopadding style="position:fixed;bottom:0;width:100%">
                <v-layout row wrap>
                    <v-flex xs12 sm12 md12 height48>
                        <v-btn color="primary" block full-height noshadow no-round-borders @click="fn_checkValidations">
                            Save
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-footer>
        </div>  

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios';

    export default{
        name:'form_testingPhoneNumber',
        data(){
            return{
                Form:{"PhoneNumber":null, "Code":null},
                loadingObj:{
                  loading:false,
                  message:"Please wait..."
                }
            }
        },
        methods: {

            fn_checkValidations: function(){
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        let self = this;
                        this.$root.fn_showLoading('Please wait...', this.loadingObj);
                        this.$root.fn_getAuthToken(function(token){
                            if(token=='error'){
                              self.$root.fn_hideLoading(self.loadingObj);
                              self.$root.fn_showToastMsg("Something went wrong. Try again!");
                              return;
                            }
                            self.fn_save(token);
                        })
                    }
                });
            },

            fn_save: function(token){

                var headersObj = {
                headers: { 
                      'Authorization': 'bearer '+token,
                    }
                };

                let postObj = {};
                postObj.PhoneNumber = '91'+this.Form.PhoneNumber;
                postObj.Code = this.Form.Code;

                console.log(JSON.stringify(postObj));

                axios.post(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/testingphonenumbers/create', postObj, headersObj, {timeout:30000})
                .then(response => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    console.log(response);
                    this.$root.fn_showToastMsg("Phone Number added!");
                    this.fn_goBack();
                }).catch(e => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    console.log(JSON.stringify(e));
                })
                
            },
           
            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
        }
    }
</script>