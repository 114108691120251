<template>
    <div full-height>
        <v-toolbar flat fixed>

            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title style="line-height:20px" class="ml-5">
                App Usage Day Wise Report (Action On)
            </v-toolbar-title>

            <!-- <v-spacer></v-spacer> -->

            

        </v-toolbar>

        <div class="hastoolbar hasfooter" full-height>
            <v-subheader style="height:40px">

            <v-menu offset-y content-class="menu-max-height">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark text v-bind="attrs" v-on="on" class="datepickerbtn"><v-icon>date_range</v-icon>&nbsp;Period<span v-show="dateFrom && dateTo">:&nbsp;</span> {{dateFrom | moment("DD/MM/YYYY")}}<span v-show="dateFrom && dateTo">&nbsp;To&nbsp;</span>{{dateTo | moment("DD/MM/YYYY")}}</v-btn>
                </template>
                <v-list>
                  <v-list-item @click="fn_getLatestData('Today')">
                    <v-list-item-title>Today</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Yesterday')">
                    <v-list-item-title>Yesterday</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 3 days')">
                    <v-list-item-title>Last 3 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 7 days')">
                    <v-list-item-title>Last 7 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 15 days')">
                    <v-list-item-title>Last 15 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 30 days')">
                    <v-list-item-title>Last 30 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Lifetime')">
                    <v-list-item-title>Lifetime</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Lifetime-1')">
                    <v-list-item-title>Lifetime -1</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Lifetime-4')">
                    <v-list-item-title>Lifetime -4</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Lifetime-8')">
                    <v-list-item-title>Lifetime -8</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_openDialogPeriod">
                    <v-list-item-title>Custom date range</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- <v-btn outlined rounded @click="fn_clearDateFilter" v-if="dateFrom && dateTo" dark text class="clearfilterbtn">Clear Filter <v-icon class="pl-1" size="20">close</v-icon></v-btn> -->

              <v-menu offset-y content-class="menu-max-height">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark text v-bind="attrs" v-on="on" class="datepickerbtn"><v-icon>date_range</v-icon>&nbsp;Created Date<span v-show="BussinessCreatedEDate && BussinessCreatedSDate">:&nbsp;</span> {{BussinessCreatedSDate | moment("DD/MM/YYYY")}}<span v-show="BussinessCreatedEDate && BussinessCreatedSDate">&nbsp;To&nbsp;</span>{{BussinessCreatedEDate | moment("DD/MM/YYYY")}}</v-btn>
                </template>
                <v-list>
                  <v-list-item @click="fn_getLatestDataCreated('Today')">
                    <v-list-item-title>Today</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Yesterday')">
                    <v-list-item-title>Yesterday</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Last 3 days')">
                    <v-list-item-title>Last 3 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Last 7 days')">
                    <v-list-item-title>Last 7 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Last 15 days')">
                    <v-list-item-title>Last 15 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Last 30 days')">
                    <v-list-item-title>Last 30 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Lifetime')">
                    <v-list-item-title>Lifetime</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Lifetime-1')">
                    <v-list-item-title>Lifetime -1</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Lifetime-4')">
                    <v-list-item-title>Lifetime -4</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Lifetime-8')">
                    <v-list-item-title>Lifetime -8</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_openDialogPeriodCreated">
                    <v-list-item-title>Custom date range</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- <v-btn outlined rounded @click="fn_clearDateCreatedFilter" v-if="BussinessCreatedEDate && BussinessCreatedSDate" dark text class="clearfilterbtn">Clear Filter <v-icon class="pl-1" size="20">close</v-icon></v-btn> -->

            
            <span v-if="reportData">Total Mobile Usage: {{ reportData ? TotalSum : 0 }}</span>
           
            <v-spacer></v-spacer>

            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on"><v-icon color="primary">filter_list</v-icon></v-btn>
                              </template>
                              <v-list>
                                <v-list-item @click="showRegistrationTypeMenu">
                                  <v-list-item-title>Registration Type</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="showGstinfilledTypeMenu">
                                  <v-list-item-title>Is GST Filled</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="showAddressFilledMenu">
                                  <v-list-item-title>Is Address Filled</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="showPoweredByRemovedMenu">
                                  <v-list-item-title>Is Powered By Removed</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>

                            <v-menu offset-y :position-x="x" :position-y="y" absolute v-if="$root.platform()=='desktop'" v-model="registrationTypeMenu">
                              <v-list>
                                <v-list-item v-for="(item, index) in RegistrationType" :key="index" @click="fn_changeStatus(item.status)">
                                  <v-list-item-title>{{item.name}}</v-list-item-title>
                                  <v-list-item-action>
                                    <v-icon v-if="item.status==selectedRegistrationType">radio_button_checked</v-icon>
                                    <v-icon v-else>radio_button_unchecked</v-icon>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list>
                            </v-menu>

                            <v-menu offset-y :position-x="x" :position-y="y" absolute v-if="$root.platform()=='desktop'" v-model="gstinfilledTypeMenu">
                              <v-list>
                                <v-list-item v-for="(item, index) in GSTINFilledType" :key="index" @click="fn_changeGstinfilledType(item.value)">
                                  <v-list-item-title>{{item.name}}</v-list-item-title>
                                  <v-list-item-action>
                                    <v-icon v-if="item.value==selectedGstinfilledType">radio_button_checked</v-icon>
                                    <v-icon v-else>radio_button_unchecked</v-icon>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list>
                            </v-menu>

                            <v-menu offset-y :position-x="x" :position-y="y" absolute v-if="$root.platform()=='desktop'" v-model="addressFilledTypeMenu">
                              <v-list>
                                <v-list-item v-for="(item, index) in AddressFilledType" :key="index" @click="fn_changeAddressFilledType(item.value)">
                                  <v-list-item-title>{{item.name}}</v-list-item-title>
                                  <v-list-item-action>
                                    <v-icon v-if="item.value==selectedAddressFilledType">radio_button_checked</v-icon>
                                    <v-icon v-else>radio_button_unchecked</v-icon>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list>
                            </v-menu>

                            <v-menu offset-y :position-x="x" :position-y="y" absolute v-if="$root.platform()=='desktop'" v-model="poweredByRemovedTypeMenu">
                              <v-list>
                                <v-list-item v-for="(item, index) in PoweredByRemovedType" :key="index" @click="fn_changePoweredByRemovedType(item.value)">
                                  <v-list-item-title>{{item.name}}</v-list-item-title>
                                  <v-list-item-action>
                                    <v-icon v-if="item.value==selectedPoweredByRemovedType">radio_button_checked</v-icon>
                                    <v-icon v-else>radio_button_unchecked</v-icon>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                        </v-subheader>
                        <div class="mb-1" v-if="selectedRegistrationType || selectedGstinfilledType != null || selectedAddressFilledType != null || selectedPoweredByRemovedType != null">
                            <span class="filtered_box" v-if="selectedRegistrationType">{{getSelectedRegistrationType()}}</span>
                            <span class="filtered_box" v-if="selectedGstinfilledType != null">{{ selectedGstinfilledType == true ? " GST Info Filled: Yes " : selectedGstinfilledType == false ? " GST Info Filled: No " : "All"}}</span>
                            <span class="filtered_box" v-if="selectedAddressFilledType != null">{{ selectedAddressFilledType == true ? " Address Filled: Yes " : selectedAddressFilledType == false ? " Address Filled: No " : "All" }}</span>
                            <span class="filtered_box" v-if="selectedPoweredByRemovedType != null">{{ selectedPoweredByRemovedType == true ? " Powered By Removed: Yes " : selectedPoweredByRemovedType == false ? " Powered By Removed: No " : "All" }}</span>
                            <span @click="fn_clearFilter()" style="margin-right: 16px; margin-right: 16px; margin-left:8px; cursor: pointer; border: 1px solid black; border-radius: 20px; padding: 4px;" v-if="selectedRegistrationType || selectedGstinfilledType != null || selectedAddressFilledType != null || selectedPoweredByRemovedType != null">
                                <span>Clear All Filters</span><v-icon class="ml-2" size="18">close</v-icon>
                            </span>
                        </div>
            <template v-if="reportData">

                <template>
                    <div class="table-wrapper" style="width:100%;font-size:10px">
                        <table style="width:100%">
                            
                            <thead>
                                <tr class="primarybackground" style="color:#fff">
                                    <td class="pa-1">S.No</td>
                                    <!-- <td class="pa-1">Business Name</td> -->
                                    <td class="pa-1">Date</td>
                                    <td class="pa-1">Mobile Usage Count</td>
                                    <td class="pa-1">Excluding Same Day Created Count</td>
                                    <!-- <td class="pa-1">Desktop Usage Count</td> -->
                                    
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(item, index) in reportData.data.Details" :key="index">
                                    <td>{{ (index + 1) }}</td>
                                    <!-- <td><a @click="fn_viewDetails(item.BusinessID)">{{item.BusinessName}}</a></td> -->
                                    <td>{{item.Date | moment("DD/MM/YYYY")}}</td>
                                    <td>{{item.MobileUsageCount}}</td>
                                    <td>{{item.ExcludingSameDayCreatedCount}}</td>
                                </tr>
                            </tbody>

                            <!-- <tfoot>
                                <tr grey-back>
                                    <th class="pa-1 left-align">Total</th>
                                    <th class="pa-1 left-align">{{reportData.data.Total}}</th>
                                    <th class="pa-1 left-align">{{subTotlalofRegistered}}</th>
                                    <th class="pa-1 left-align">{{subTotlalofUnregistered}}</th>
                                    <th class="pa-1 left-align">{{subTotlalofComposite}}</th>
                                    <th class="pa-1 left-align">{{subTotlalofComposite}}</th>
                                </tr>
                            </tfoot> -->
                        </table>
                    </div>

                    <div class="pa-4" center-align style="font-size:10px" v-if="!reportData.data.Details.length">
                        No record found!
                    </div>
                </template>
            </template>

            <template v-else>
                <div class="pt-4 pl-4">
                    <v-btn style="border-radius: 0px; background-color: rgb(75, 119, 190) !important;" color="primary" @click="fn_getLatestData('Last 7 days')">
                        Generate Report
                    </v-btn>
                </div>
            </template>
            

        </div>

        <dialog-period-selection ref="ref_dialogCustomPeriod" title="Select Period" :openDatePicker="fn_openDatePicker" :startDate="selectedStartDate" :endDate="selectedEndDate" :actionMethod="fn_updateCustomDates"></dialog-period-selection>

        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

        <dialog-loading :loading="loading"></dialog-loading>

        <v-dialog ref="ref_monthpicker" no-click-animation v-model="monthPicker" :return-value.sync="selectedMonth" persistent lazy full-width width="290px" transition="slide-y-transition">
            <v-date-picker v-model="selectedMonth" scrollable type="month">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="monthPicker = false">Cancel</v-btn>
                <v-btn text color="primary" @click="fn_saveMonth(selectedMonth)">OK</v-btn>
            </v-date-picker>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios';

import dialogPeriodSelection from '../../tickets/dialogs/dialog_period_selection'
import dialogDatepicker from '../../tickets/dialogs/dialog_datepicker'
import dialogLoading from '../../../dialogs/dialog_loading.vue'

    export default{
        name:'report_appusage',
        data(){
            return{
                reportData:null,
                selectedStartDate:this.$root.minDateSelection,
                selectedEndDate:this.$root.maxDateSelection,
                dateFrom:this.$root.minDateSelection,
                dateTo:this.$root.maxDateSelection,
                dateType:null,
                selectedDate:null,
                dialog_datepicker:false,

                loadingObj:{
                    loading:false,
                    message:"Please wait..."    
                },

                loading:false,

                // months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                selectedMonth:new Date().toISOString().substring(0, 7),
                monthPicker:false,
                BussinessCreatedEDate: null,
                BussinessCreatedSDate: null,
                selectionTypeValue: null,
                RegistrationType:[
                    {"name":"All", "status":null},
                    {"name":"Registered", "status":1},
                    {"name":"Composite", "status":2},
                    {"name":"Unregistered", "status":3}
                ],
                GSTINFilledType:[
                    {"name":"All", "value":null},
                    {"name":"Yes", "value":true},
                    {"name":"No", "value":false}
                ],
                AddressFilledType:[
                    {"name":"All", "value":null},
                    {"name":"Yes", "value":true},
                    {"name":"No", "value":false}
                ],
                PoweredByRemovedType:[
                    {"name":"All", "value":null},
                    {"name":"Yes", "value":true},
                    {"name":"No", "value":false}
                ],
                statusMenu:false,
                typeMenu:false,
                registrationTypeMenu: false,
                gstinfilledTypeMenu: false,
                addressFilledTypeMenu: false,
                poweredByRemovedTypeMenu:  false,
                selectedRegistrationType:null,
                selectedGstinfilledType: null,
                selectedAddressFilledType: null,
                selectedPoweredByRemovedType: null,
                TotalSum: 0,
                x:null,
                y:null,
            }
        },
        components: {
            'dialog-period-selection':dialogPeriodSelection,
            'dialog-datepicker':dialogDatepicker,
            'dialog-loading':dialogLoading
        },
        methods:{

            getSelectedRegistrationType: function(){
                for(let i=0; i<this.RegistrationType.length; i++){
                    if(this.RegistrationType[i].status == this.selectedRegistrationType){
                        return this.RegistrationType[i].name;
                    }
                }
            },

            fn_clearFilter: function(){
                this.selectedRegistrationType =null;
                this.selectedGstinfilledType = null;
                this.selectedAddressFilledType = null;
                this.selectedPoweredByRemovedType = null;
                this.fn_getToken();
            },

            showRegistrationTypeMenu (e) {
                e.preventDefault()
                this.gstinfilledTypeMenu = false;
                this.addressFilledTypeMenu = false;
                this.poweredByRemovedTypeMenu = false;
                this.x = window.screen.width - 40;
                this.y = 146
                this.$nextTick(() => {
                    this.registrationTypeMenu = true;
                })
              },

            showGstinfilledTypeMenu (e) {
                e.preventDefault();
                this.registrationTypeMenu = false;
                this.addressFilledTypeMenu = false;
                this.poweredByRemovedTypeMenu = false;
                this.x = window.screen.width - 40;
                this.y = 146
                this.$nextTick(() => {
                    this.gstinfilledTypeMenu = true;
                })
              },

            showAddressFilledMenu (e) {
                e.preventDefault();
                this.registrationTypeMenu = false;
                this.gstinfilledTypeMenu = false;
                this.poweredByRemovedTypeMenu = false;
                this.x = window.screen.width - 40;
                this.y = 146
                this.$nextTick(() => {
                    this.addressFilledTypeMenu = true;
                })
              },

            showPoweredByRemovedMenu (e) {
                e.preventDefault();
                this.registrationTypeMenu = false;
                this.addressFilledTypeMenu = false;
                this.gstinfilledTypeMenu = false;
                this.x = window.screen.width - 40;
                this.y = 146
                this.$nextTick(() => {
                    this.poweredByRemovedTypeMenu = true;
                    
                })
              },

            fn_openDialogPeriod: function(){
                this.selectedStartDate = this.dateFrom;
                this.selectedEndDate = this.dateTo;
                this.selectionTypeValue = "NormalDialog";
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = true;
            },

            fn_openDialogPeriodCreated: function(){
                this.selectedStartDate = this.BussinessCreatedSDate;
                this.selectedEndDate = this.BussinessCreatedEDate;
                this.selectionTypeValue = "CreatedDialog";
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = true;
            },

            fn_openDatePicker: function (type, date) {
                let self = this;
                this.dateType = type;
                let dateObj = date ? new Date(date) : new Date();
                this.$refs['ref_dialogDatePicker'].selectedDate = dateObj.getFullYear()+'-'+("0" + (dateObj.getMonth()+1)).slice(-2)+'-'+("0" + dateObj.getDate()).slice(-2);
                setTimeout(function () {
                    self.dialog_datepicker = true;
                })
            },

            fn_updateDate: function (value) {
                if(this.dateType=="start"){
                    this.selectedStartDate = value;
                }
                else if(this.dateType=="end"){
                    this.selectedEndDate = value;
                }

                this.dialog_datepicker = false;
            },

            fn_updateCustomDates: function(type){
                if(this.selectionTypeValue == 'NormalDialog'){
                    this.dateFrom = this.selectedStartDate;
                    this.dateTo = this.selectedEndDate;
                }
                else{
                    this.BussinessCreatedSDate = this.selectedStartDate;
                    this.BussinessCreatedEDate = this.selectedEndDate;
                }
               
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = false;
                this.fn_getToken(type);
            },

            fn_saveMonth: function (value) {
                this.$refs.ref_monthpicker.save(value);
                let date = new Date(value);
                this.selectedStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
                this.selectedEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                this.fn_updateCustomDates('month')
            },

            fn_getToken: function(type){
                let self = this;
                this.loading = true;
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){
                    if(token == 'error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        this.loading = false;
                        self.$root.fn_showToastMsg("Something went wrong. Try again!!");
                        return;
                    }

                    self.fn_getReport(token, type);
                });
            },

            fn_getReport: function(token, type){
                var headersObj = {
                    headers: { 
                      'Authorization': 'bearer '+token,
                    }
                };

                let postJson = {
                    "DateFrom":this.dateFrom, 
                    "DateTo":this.dateTo, 
                    "BussinessCreatedEDate": this.BussinessCreatedEDate,
                    "BussinessCreatedSDate": this.BussinessCreatedSDate,
                    "RegistrationType": this.selectedRegistrationType,
                    "IsGSTINFilled": this.selectedGstinfilledType,
                    "IsAddressFilled": this.selectedAddressFilledType,
                    "IsPoweredByRemoved": this.selectedPoweredByRemovedType,
                    "IsDriveSetup": null

                }

                if(this.dateFrom){
                    postJson.DateFrom = this.$moment(postJson.DateFrom).startOf('day');   
                    postJson.DateFrom = this.fn_getLocalDateFormat(postJson.DateFrom); 
                }

                if(this.dateTo){
                    postJson.DateTo = this.$moment(postJson.DateTo).endOf('day');
                    postJson.DateTo = this.fn_getLocalDateFormat(postJson.DateTo);
                }

                if(this.BussinessCreatedEDate){
                    postJson.BussinessCreatedEDate = this.$moment(postJson.BussinessCreatedEDate).startOf('day');   
                    postJson.BussinessCreatedEDate = this.fn_getLocalDateFormat(postJson.BussinessCreatedEDate); 
                }

                if(this.BussinessCreatedSDate){
                    postJson.BussinessCreatedSDate = this.$moment(postJson.BussinessCreatedSDate).startOf('day');   
                    postJson.BussinessCreatedSDate = this.fn_getLocalDateFormat(postJson.BussinessCreatedSDate); 
                }

                console.log(JSON.stringify(postJson));
                this.TotalSum = 0;
                axios.post(process.env.VUE_APP_BASE_API_URL + "/api/supportteam/reports/appusage/daywise", postJson, headersObj, {timeout:30000})
                .then(response => {
                    console.log(JSON.stringify(response));
                    this.reportData = response;
                    
                    if(this.reportData.data && this.reportData.data.Details){
                        this.reportData.data.Details.forEach(item => {
                            this.TotalSum += item.MobileUsageCount;
                        });
                    }

                    this.loading = false;
                    this.$root.fn_hideLoading(this.loadingObj);
                    type ? this.dateType = type : ''
                }).catch(e => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    this.loading = false;
                    console.log(JSON.stringify(e));
                })
            },

            fn_goBack: function () {
                this.$router.go(-1);
            },

            fn_getLatestData: function(type, selectiontype){
              var currentDate = new Date();
              if(type != 'Today' && type != 'Lifetime' && type != 'Lifetime-4' && type != 'Lifetime-8')
                currentDate.setDate(currentDate.getDate() - 1);
              if(type == 'Lifetime-4')
                currentDate.setDate(currentDate.getDate() - 4);
              else if(type == 'Lifetime-8')
                currentDate.setDate(currentDate.getDate() - 8);
              var year = currentDate.getFullYear();
              var month = String(currentDate.getMonth() + 1).padStart(2, '0');
              var day = String(currentDate.getDate()).padStart(2, '0');
              var formattedStartDate = `${year}-${month}-${day}`;
              this.dateTo = formattedStartDate;

              var startDate = new Date();
              if(type == 'Yesterday')
                startDate.setDate(startDate.getDate() - 1);
              else if(type == 'Last 3 days')
                startDate.setDate(startDate.getDate() - 3);
              else if(type == 'Last 7 days')
                startDate.setDate(startDate.getDate() - 7);
              else if(type == 'Last 15 days')
                startDate.setDate(startDate.getDate() - 15);
              else if(type == 'Last 30 days')
                startDate.setDate(startDate.getDate() - 30);
              var endDateYear = startDate.getFullYear();
              var endDateMonth = String(startDate.getMonth() + 1).padStart(2, '0');
              var endDateDay = String(startDate.getDate()).padStart(2, '0');
              var formattedEndDate = `${endDateYear}-${endDateMonth}-${endDateDay}`;
              console.log('Start Date : ' + formattedStartDate);
              console.log('End Date : ' + formattedEndDate);
                
              this.dateFrom = formattedEndDate;
              if(type == 'Lifetime' | type == 'Lifetime-1' || type == 'Lifetime-4' || type == 'Lifetime-8')
                    this.dateFrom = "2023-1-1";
              
              if(selectiontype != 'selectdateonly')
                this.fn_getToken();

            },

            fn_getLatestDataCreated: function(type, selectiontype){
              var currentDate = new Date();
              if(type != 'Today' && type != 'Lifetime' && type != 'Lifetime-4' && type != 'Lifetime-8')
                currentDate.setDate(currentDate.getDate() - 1);
              if(type == 'Lifetime-4')
                currentDate.setDate(currentDate.getDate() - 4);
              else if(type == 'Lifetime-8')
                currentDate.setDate(currentDate.getDate() - 8);
              var year = currentDate.getFullYear();
              var month = String(currentDate.getMonth() + 1).padStart(2, '0');
              var day = String(currentDate.getDate()).padStart(2, '0');
              var formattedStartDate = `${year}-${month}-${day}`;
              this.BussinessCreatedEDate = formattedStartDate;
              

              var startDate = new Date();
              if(type == 'Yesterday')
                startDate.setDate(startDate.getDate() - 1);
              else if(type == 'Last 3 days')
                startDate.setDate(startDate.getDate() - 3);
              else if(type == 'Last 7 days')
                startDate.setDate(startDate.getDate() - 7);
              else if(type == 'Last 15 days')
                startDate.setDate(startDate.getDate() - 15);
              else if(type == 'Last 30 days')
                startDate.setDate(startDate.getDate() - 30);
              var endDateYear = startDate.getFullYear();
              var endDateMonth = String(startDate.getMonth() + 1).padStart(2, '0');
              var endDateDay = String(startDate.getDate()).padStart(2, '0');
              var formattedEndDate = `${endDateYear}-${endDateMonth}-${endDateDay}`;
              this.BussinessCreatedSDate = formattedEndDate;
              if(type == 'Lifetime' || type == 'Lifetime-1' || type == 'Lifetime-4' || type == 'Lifetime-8')
                    this.BussinessCreatedSDate = "2023-1-1";
              
              if(selectiontype != 'selectdateonly')
                this.fn_getToken();

            },

            fn_clearDateFilter: function(){
              this.BussinessCreatedEDate = null;
              this.BussinessCreatedSDate = null;
              this.fn_getToken();
            },

            fn_clearDateCreatedFilter: function(){
                this.dateFrom = null;
                this.dateTo = null;
                this.fn_getToken();
            },

            fn_changeStatus: function(status){
                this.selectedRegistrationType = status;
                this.fn_getToken();
            },

            fn_changeGstinfilledType: function(requestType){
                this.selectedGstinfilledType = requestType;
                this.fn_getToken();
            },

            fn_changeAddressFilledType: function(requestType){
                this.selectedAddressFilledType = requestType;
                this.fn_getToken();
            },

            fn_changePoweredByRemovedType: function(requestType){
                this.selectedPoweredByRemovedType = requestType;
                this.fn_getToken();
            },

        },
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
            // this.fn_getUsers();
            this.fn_getLatestData('Last 7 days', 'selectdateonly');
            this.fn_getLatestDataCreated('Yesterday', 'selectdateonly');
         
        },
        // beforeRouteEnter (to, from, next) {
        //     next(vm => {
        //         vm.routerPreviousPath = from.name;
               
        //             localStorage.removeItem('filters');
        //     })
        // },
        beforeRouteLeave(to, from, next){
            if(this.filtersheet){
                this.filtersheet = false;
                next(false);
            }
            else if(this.dialog_datepicker){
                this.dialog_datepicker = false;
                next(false);
            }
            else if(this.$refs['ref_dialogCustomPeriod'].dialog_period_selection){
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = false;
                next(false);
            }
            else{
                next();
            }
        }
    }
</script>

<style>
    .table-wrapper table tbody tr:nth-child(even){
        background: #f0f0f0 !important;
    }
    .table-wrapper table thead tr th,
    .table-wrapper table tbody tr td{
        padding:4px;
    }
    .filtered_box{
        border-radius: 12px;
        padding: 5px;
        border: 1px solid black;
        margin-left: 5px;
        margin-right: 5px;
    }
</style>