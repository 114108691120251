<template>
    <div>
        <v-dialog v-model="dialog_addtags" content-class="businessview_dialog" :hide-overlay="true" scrollable persistent max-width="50%" no-click-animation transition="slide-x-transition">
            <v-card>
                <v-toolbar flat class="main_toolbar">
                    <v-toolbar-side-icon @click="closeDialogWeb"><v-icon color="primary">close</v-icon></v-toolbar-side-icon>
                    <v-toolbar-title class="ml-5">Add Tags</v-toolbar-title>
                </v-toolbar>
                <div class="hastoolbar hasfooter">
                    <template>
                        <template>
                            <v-form class="form-element">
                                <v-layout align-center nomargin padding-top-ten row wrap white-background>
                                    <v-flex xs12 sm12 md12 form-item>
                                        <v-select
                                            multiple
                                            v-model="Form.TagIDs"
                                            label="Select Tags" 
                                            :items="tagList"
                                            item-text="Title"
                                            item-value="TagID"
                                            placeholder=" ">
                                        </v-select>
                                    </v-flex>
                                </v-layout>
                            </v-form>

                            <v-footer nopadding style="position:fixed;bottom:0;width:48%; right: 14px;">
                                <v-layout row wrap>
                                    <v-flex xs12 sm12 md12 height48>
                                        <v-btn color="primary" block full-height noshadow no-round-borders @click="fn_checkValidations">
                                            Save
                                        </v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-footer>

                        </template>
                    </template>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="loadingdialog" :hide-overlay="true" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>     

<script>

import axios from 'axios';

export default{
    props:['type', 'fn_updateTags'],
    data(){ 
        return{
            reportData:null,
            dialog_addtags: false,
            loadingdialog: false,
            loadingObj:{
                loading:false,
                message:"Please wait..."    
            },
            displayData:null,
            TotalSum: 0,
            Average: 0,
            subTotlal: 0,
            loading: false,
            tagList: [],
            Form:{"TagIDs": null},
            businessID: null 
        }
    },
    methods: {
        openDialogWeb: function () {    
            this.dialog_addtags = true;
        },
        closeDialogWeb: function () {
            this.dialog_addtags = false;
            var wrp = document.getElementsByTagName('html');
            for (var i = 0; i < wrp.length; i++) {
                wrp[i].style.height = "auto";
                wrp[i].style.overflowY = "auto";
            }
        },

        fn_getToken: function(businessid, tags){
            let self = this;
            this.Form = {"TagIDs": null};
            if(tags)
                this.Form.TagIDs = tags;
            this.businessID = businessid;
            this.loadingdialog = true;
            this.$root.fn_showLoading('Please wait...', this.loadingObj);
            this.$root.fn_getAuthToken(function(token){
                if(token == 'error'){
                    self.$root.fn_hideLoading(self.loadingObj);
                    self.loadingdialog = false;
                    return;
                }

                self.fn_getTags(token,);
            });
        },

        fn_getTags: function(token){
            var headersObj = {
            headers: { 
                    'Authorization': 'bearer '+token,
                }
            };

            this.subTotlal = 0;
            this.tagList = [];
            axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/tags/getlist?status=1', headersObj, {timeout:30000})
            .then(response => {
                this.$root.fn_hideLoading(this.loadingObj);
                this.tagList = response.data;
                this.loadingdialog = false;
                this.dialog_addtags = true;

                var wrp = document.getElementsByTagName('html');
                for (var i = 0; i < wrp.length; i++) {
                    wrp[i].style.height = "100vh";
                    wrp[i].style.overflowY = "hidden";
                }
                console.log(response);
                console.log(JSON.stringify(response.data));
            }).catch(e => {
                this.loadingdialog = false;
                this.$root.fn_hideLoading(this.loadingObj);
                this.$root.fn_showToastMsg("Something went wrong. Try again!");
                this.snackbarRetry = true;
                console.log(JSON.stringify(e));
            })
        },

        fn_checkValidations: function(){
            this.$validator.validateAll().then((result) => {
                if (result) {
                    let self = this;
                    this.$root.fn_showLoading('Please wait...', this.loadingObj);
                    this.$root.fn_getAuthToken(function(token){
                        if(token=='error'){
                            self.$root.fn_hideLoading(self.loadingObj);
                            self.$root.fn_showToastMsg("Something went wrong. Try again!");
                            return;
                        }
                        self.fn_save(token);
                    })
                }
            });
        },

        fn_save: function(token){

            var headersObj = {
            headers: { 
                    'Authorization': 'bearer '+token,
                }
            };

            let postObj = {};
            postObj.BusinessID = this.businessID;
            postObj.TagIDs = this.Form.TagIDs;

            console.log(JSON.stringify(postObj));

            axios.post(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/businesstags/update', postObj, headersObj, {timeout:30000})
            .then(response => {
                this.$root.fn_hideLoading(this.loadingObj);
                console.log(response);
                var tags = [];
                tags = this.tagList.filter(tag => this.Form.TagIDs.includes(tag.TagID)).map(tag => tag.Title);
                this.fn_updateTags(tags, this.Form.TagIDs);
                this.$root.fn_showToastMsg("Tagded successfully!");
                this.dialog_addtags = false;
            }).catch(e => {
                this.$root.fn_hideLoading(this.loadingObj);
                this.$root.fn_showToastMsg("Something went wrong. Try again!");
                console.log(JSON.stringify(e));
            })
            
        },

    }
}
</script>

<style>
.businessview_fullscreendialog{
    position: absolute;
    right: 0px;
    left: auto;
    max-width: 100% !important;
    width: calc(100% - 256px);
    max-height: 100% !important;
    margin: 0px;
    height: calc(100% - 56px);
    top: 56px;
    box-shadow: none !important;
}
</style>