import 'material-design-icons-iconfont/dist/material-design-icons.css'

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';


import VueRouter from 'vue-router'
import VeeValidate from 'vee-validate';

import './actioninterface.js';
import {globalMixin} from './global_mixin'
import './firebase.js'
// import * as firebase from 'firebase/app';
// import "firebase/auth";
// import "firebase/storage"

var auth = require('./firebase.js').fireConfig.auth
// import { auth } from './firebase.js'

// Disable console log in production
if (process.env.NODE_ENV === 'production') {
    console.log = function () {};
}

Vue.use(VueRouter);
Vue.use(VeeValidate);
Vue.use(require('vue-moment'));

Vue.use(vuetify, {
  iconfont: 'md'
});


// var firebaseConfig = {
//   apiKey: "AIzaSyCv6EAAU8rDE_K1lfCmgX7VDb7snwsIUx4",
//   authDomain: "bizopsapp.firebaseapp.com",
//   databaseURL: "https://bizopsapp.firebaseio.com",
//   projectId: "bizopsapp",
//   storageBucket: "bizopsapp.appspot.com",
//   messagingSenderId: "81292557995",
//   appId: "1:81292557995:android:252d55026b5aca0366c1ee",
// };

// Initialize Firebase
// firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false;

// import unauthorized from '@/components/unauthorized'

import defaultPage from '@/components/support_user/default'

// General User
import userTickets from '@/components/general_user/tickets/items'
import userTicketForm from '@/components/general_user/tickets/form'
import userTicketDisplay from '@/components/general_user/tickets/display'
import userTicketMessageForm from '@/components/general_user/tickets/message_form'

// Support User

import supportUserContainer from '@/components/support_user/container'
import supportLogin from '@/components/support_user/login/login'
import supportOtpVerification from '@/components/support_user/login/otp_verification'

import supportItems from '@/components/support_user/support_items'
import supportGetBusiness from '@/components/support_user/tickets/get_business'
import supportTickets from '@/components/support_user/tickets/items'
import supportTicketMessageTypes from '@/components/support_user/tickets/message/message_types'
import supportTicketMessages from '@/components/support_user/tickets/message/messages'
import supportTicketForm from '@/components/support_user/tickets/form'
import supportTicketDisplay from '@/components/support_user/tickets/display'
import supportTicketMessageForm from '@/components/support_user/tickets/message_form'
import supportTicketUpdateMessageForm from '@/components/support_user/tickets/update_message_form'
import supportTicketMessageSignature from '@/components/support_user/tickets/signature_form'
import supportTicketFeedbacks from '@/components/support_user/tickets/items_feedback'
import supportBackupRestore from '@/components/support_user/tickets/backup_restore'

import appFeedbacks from '@/components/support_user/tickets/app_feedback/items'
import appFeedbackDisplay from '@/components/support_user/tickets/app_feedback/display'

import supportPendingNotifications from '@/components/support_user/tickets/notification/items'

// update support user
import supportUsers from '@/components/support_user/user_configs/items'
import supportUsersForm from '@/components/support_user/user_configs/form'

// business
import businessItems from '@/components/support_user/business/items'
import businessDisplay from '@/components/support_user/business/display'

// testing phone number
import testingPhoneNumberItems from '@/components/support_user/testing_phone_numbers/items'
import testingPhoneNumberForm from '@/components/support_user/testing_phone_numbers/form'

// message templates
import messageTemplateItems from '@/components/support_user/message_templates/items'
import messageTemplateForm from '@/components/support_user/message_templates/form'

// tags
import tagItems from '@/components/support_user/tags/items'
import tagForm from '@/components/support_user/tags/form'

// reports
import reportList from '@/components/support_user/reports/reportlist'
import supportTicketReport from '@/components/support_user/reports/templates/support_tickets'
import ticektProgressReport from '@/components/support_user/reports/templates/ticket_progress'
import userProgressReport from '@/components/support_user/reports/templates/user_progress'
import businessRegisterReport from '@/components/support_user/reports/templates/business_register'
import appUsageReport from '@/components/support_user/reports/templates/app_usage'
import appUsageDayWiseReport from '@/components/support_user/reports/templates/app_usage_daywise'
import retentionReport from '@/components/support_user/reports/templates/retention'
import appUsageReportTagWise from '@/components/support_user/reports/templates/app_usagetagwise'

import workflowItems from '@/components/support_user/workflow_items'

import userLanding from '@/components/user_landing'

const router = new VueRouter({
    // base:'/registration/',
    routes:[
        { path: '/', redirect: '/support/supportteam/default'},

        { path: '/support/userlanding', name:'userLanding', component:userLanding},
        // { path: '/unauthorized', component:unauthorized, name:'unauthorized'},

        { path: '/support/supportteam/default', name:'defaultPage', component:defaultPage},

        // General User
        { path: '/support/appsupport/tickets/items', name:'userTickets', component:userTickets},
        { path: '/support/appsupport/tickets/new', name:'userTicketForm', component:userTicketForm},
        { path: '/support/appsupport/tickets/:ticketid', name:'userTicketDisplay', component:userTicketDisplay},
        { path: '/support/appsupport/tickets/:ticketid/message', name:'userTicketMessageForm', component:userTicketMessageForm},

        // Support User
        { path: '/support/supportteam/login', name:'supportLogin', component:supportLogin},
        { path: '/support/supportteam/verifyotp', name:'supportOtpVerification', component:supportOtpVerification},

        { path: '/support/supportteam', name:'supportUserContainer', component:supportUserContainer,

          children: [
            { path: '/support/supportteam/items', name:'supportItems', component:supportItems},
            { path: '/support/supportteam/tickets/getbusiness', name:'supportGetBusiness', component:supportGetBusiness},
            { path: '/support/supportteam/tickets/items', name:'supportTickets', component:supportTickets},
            { path: '/support/supportteam/tickets/message/types', name:'supportTicketMessageTypes', component:supportTicketMessageTypes},
            // { path: '/support/supportteam/tickets/message/items', name:'supportTicketMessages', component:supportTicketMessages},
            { path: '/support/supportteam/tickets/message/user_message/items', name:'userTicketMessages', component:supportTicketMessages},
            { path: '/support/supportteam/tickets/message/support_message/items', name:'supportTicketMessages', component:supportTicketMessages},
            { path: '/support/supportteam/tickets/message/draft_message/items', name:'draftTicketMessages', component:supportTicketMessages},

            { path: '/support/supportteam/tickets/new', name:'supportTicketForm', component:supportTicketForm},
            { path: '/support/supportteam/tickets/:ticketid', name:'supportTicketDisplay', component:supportTicketDisplay},
            // new message
            { path: '/support/supportteam/tickets/:ticketid/message', name:'supportTicketMessageForm', component:supportTicketMessageForm},
            // edit message
            { path: '/support/supportteam/tickets/:ticketid/message/:messageid', name:'supportTicketUpdateMessageForm', component:supportTicketUpdateMessageForm},
            { path: '/support/supportteam/tickets/message/signature/edit', name:'supportTicketMessageSignature', component:supportTicketMessageSignature},
            { path: '/support/supportteam/tickets/feedback/items', name:'supportTicketFeedbacks', component:supportTicketFeedbacks},

            { path: '/support/supportteam/tickets/appfeedback/items', name:'appFeedbacks', component:appFeedbacks},
            { path: '/support/supportteam/tickets/appfeedback/items/:feedbackId', name:'appFeedbackDisplay', component:appFeedbackDisplay},

            { path: '/support/supportteam/tickets/notification/items', name:'supportPendingNotifications', component:supportPendingNotifications},

            { path: '/support/supportteam/backup_restore', name:'supportBackupRestore', component:supportBackupRestore},

            // support user
            { path: '/support/supportteam/users', name:'supportUsers', component:supportUsers},
            { path: '/support/supportteam/new', name:'supportUsersForm', component:supportUsersForm},
            { path: '/support/supportteam/:supportUserId/edit', name:'supportUserEdit', component:supportUsersForm},

            // business
            { path: '/support/supportteam/business/items', name:'businessItems', component:businessItems},
            { path: '/support/supportteam/business/item/:businessid', name:'businessDisplay', component:businessDisplay},

            // testing phone number
            { path: '/support/supportteam/testingphonenumbers/items', name:'testingPhoneNumberItems', component:testingPhoneNumberItems},
            { path: '/support/supportteam/testingphonenumbers/items/new', name:'testingPhoneNumberForm', component:testingPhoneNumberForm},

            // tags
            { path: '/support/supportteam/tag/items', name:'tagItems', component:tagItems},
            { path: '/support/supportteam/tag/items/new', name:'tagForm', component:tagForm},

            // message templates
            { path: '/support/supportteam/messagetemplate/items', name:'messageTemplateItems', component:messageTemplateItems},
            { path: '/support/supportteam/messagetemplate/items/new', name:'messageTemplateForm', component:messageTemplateForm},

            // reports
            { path: '/support/supportteam/reports/items', name:'reportList', component:reportList},
            { path: '/support/supportteam/reports/items/ticket', name:'supportTicketReport', component:supportTicketReport},
            { path: '/support/supportteam/reports/items/ticketprogress', name:'ticektProgressReport', component:ticektProgressReport},
            { path: '/support/supportteam/reports/items/userprogress', name:'userProgressReport', component:userProgressReport},
            { path: '/support/supportteam/reports/items/businessregister', name:'businessRegisterReport', component:businessRegisterReport},
            { path: '/support/supportteam/reports/items/appusage', name:'appUsageReport', component:appUsageReport},
            { path: '/support/supportteam/reports/items/appusagedaywise', name:'appUsageDayWiseReport', component:appUsageDayWiseReport},
            { path: '/support/supportteam/reports/items/retention', name:'retentionReport', component:retentionReport},
            { path: '/support/supportteam/reports/items/appusagedaywisetagwise', name:'appUsageTagWiseReport', component:appUsageReportTagWise},


            { path: '/support/supportteam/workflow/items', name:'workflowItems', component:workflowItems},
          ]

        },
    ],
    mode: 'history'
});

Vue.prototype.guidWeb = function(){
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

Vue.prototype.fn_getLocalDateFormat = function(dateObj){
  let now = dateObj ? new Date(dateObj) : new Date();
  let year = now.getFullYear();
  let month = ("0" + (now.getMonth()+1)).slice(-2);
  let date = ("0" + now.getDate()).slice(-2);
  let time = now.toTimeString().substring(0, now.toTimeString().indexOf(' '));
  return year + '-' + month + '-' + date + 'T' + time + '.' + now.getMilliseconds();
}

Vue.prototype.signInWithCustomToken = function(customToken, callback){
  // firebase.auth().signInWithCustomToken(customToken).then(function(response){
  auth.signInWithCustomToken(customToken).then(function(response){
    console.log("Success");
    console.log(response);
    callback(response);
  }).catch(function(error) {
    // Handle Errors here.
    // var errorCode = error.code;
    // var errorMessage = error.message;
    console.log(JSON.stringify(error));
    // ...
    callback("error");
  });
}

Vue.prototype.getFirebaseToken = function(callback){
  // console.log(firebase);
  // const user = firebase.auth().currentUser;
  const user = auth.currentUser;
  if (user) {
    user.getIdToken(true).then(function(idToken) {
      console.log(idToken)
      callback(idToken); // Nothing happens. No errors and the function not continues
    }).catch(function(error) {
      console.log(error+'--'); // Nothing
      callback('error');
    });
  } else {
    // No user is signed in.
    callback("error");
  }
}

Vue.prototype.getUser = function(callback){
  // console.log(firebase);
  // const user = firebase.auth().currentUser;
  const user = auth.currentUser;
  callback(user);
}

Vue.prototype.signOut = function(callback){
  // firebase.auth().signOut().then(function() {
  auth.signOut().then(function() {
    // Sign-out successful.
    callback();
  }).catch(function(error) {
    // An error happened.
    console.log(error);
    callback('error');
  });
  // const user = firebase.auth().currentUser;
  // if (user) {
  //   user.signOut().then(function() {
  //      callback();
  //   }).catch(function(error) {
  //       console.log(error+'--'); // Nothing
  //       callback('error');
  //   });
  // } else {
  //   callback("error");
  // }
}

// Vue.prototype.fn_deleteFile = function(url, callback){
//   let storage = firebase.storage();
//   var httpsReference = storage.refFromURL(url);
//   // Delete the file
//   httpsReference.delete().then(function() {
//     callback()
//   }).catch(function(error) {
//     console.log(error);
//     // Uh-oh, an error occurred!
//   });
// }

// var uploadTask = null;

// Vue.prototype.fn_cancelDesktopUpload = function(){
//   if(uploadTask){
//     alert('in');
//     uploadTask.cancel().then(function() {
//       // Updated metadata for 'images/forest.jpg' is returned in the Promise
//       alert('cancel done');
//     })
//     .catch(function(error) {
//       // Uh-oh, an error occurred!
//       console.log(error);
//       alert('failed');
//     });
//   }
// }

// Vue.prototype.fn_upload = function(file, fn_setStatus, tfId, mfId, callback,){

//     // console.log(firebase.auth().currentUser);
//     let storageRef = firebase.storage().ref();
//     // console.log(fileEvent.target.files[0]);
//     // File or Blob named mountains.jpg
//     // let file = fileEvent.target.files[0];

//     let ticketFolderId = tfId;
//     let messageFolderId = mfId;
//     // Create the file metadata
//     // var metadata = {
//     //   contentType: 'image/jpeg'
//     // };


//     // Upload file and metadata to the object 'images/mountains.jpg'
//     uploadTask = storageRef.child('tickets/'+ticketFolderId+'/'+messageFolderId+'/' + file.name).put(file);

//     // Listen for state changes, errors, and completion of the upload.
//     uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
//       function(snapshot) {
//         // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
//         var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
//         console.log('Upload is ' + progress + '% done');
//         // setting up buffer size
//         fn_setStatus(progress)
//         switch (snapshot.state) {
//           case firebase.storage.TaskState.PAUSED: // or 'paused'
//             console.log('Upload is paused');
//             break;
//           case firebase.storage.TaskState.RUNNING: // or 'running'
//             console.log('Upload is running');
//             break;
//         }
//       }, function(error) {

//       // A full list of error codes is available at
//       // https://firebase.google.com/docs/storage/web/handle-errors
//       switch (error.code) {
//         case 'storage/unauthorized':
//           alert('storage/unauthorized');
//           // User doesn't have permission to access the object
//           break;

//         case 'storage/canceled':
//           alert('storage/canceled');
//           callback('cancelled');
//           // User canceled the upload
//           break;

//         case 'storage/unknown':
//           alert('storage/unknown');
//           // Unknown error occurred, inspect error.serverResponse
//           break;
//       }
//     }, function() {
//       // Upload completed successfully, now we can get the download URL
//       uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
//         console.log('File available at', downloadURL);
//         callback(downloadURL);
//       });
//     });
//   }

// Vue.prototype.fn_checkUserState = function(callback){
//   // callback(false);
//   // let  user = firebase.auth().currentUser;
//   // callback(user);
//   firebase.auth().onAuthStateChanged(function(user) {
//     if (user) {
//       console.log("User signed in.");
//       callback(true);
//       // User is signed in.
//     } else {
//       // No user is signed in.
//       console.log("User signed out.");
//       callback(false);
//     }
//   });
// }

// Vue.prototype.fn_getLastSignIn = function(callback){
//   let user = firebase.auth().currentUser;
//   if(user){
//     let signInDate = user.metadata.lastSignInTime; 
//     callback(signInDate); 
//   }
//   else{
//     callback();
//   }
// }

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('../../service-worker.js').then(registration => {
      console.log('SW registered: ', registration);

    }).catch(registrationError => {
      console.log(registrationError);
    });

    if (navigator.serviceWorker.controller) {
      console.log('This page is currently controlled by:', navigator.serviceWorker.controller);
    }

    navigator.serviceWorker.oncontrollerchange = function() {
      console.log('This page is now controlled by:', navigator.serviceWorker.controller);
      console.log('Install complete. Triggering update');
      if(Vue.prototype.displayMode=='standalone'){
        fn_onUpdateFound();
      }
    };
  });
}

function fn_onUpdateFound(){
  var r = confirm("A new update is ready. Do you want to update now?");
  if(r==true){
    window.location.reload();
  }
  else{
    console.log("refresh cancel");
  }
}

document.title = "BizOps App Support";

// Vue.prototype.deferredPrompt = null;

// window.addEventListener('beforeinstallprompt', (e) => {
//   alert('in');
//   // Prevent the mini-infobar from appearing on mobile
//   e.preventDefault();
//   // Stash the event so it can be triggered later.
//   Vue.prototype.deferredPrompt = e;
//   // Update UI notify the user they can install the PWA
//   // intallBtn.style.display = 'none';

// });

// Vue.prototype.fn_openInstallPrompt = function(){
//   alert('click');
//   // console.log(e);
//   // Hide the app provided install promotion
//   // intallBtn.style.display = 'none';
//   // Show the install prompt
//   Vue.prototype.deferredPrompt.prompt();
//   // Wait for the user to respond to the prompt
//   Vue.prototype.deferredPrompt.userChoice.then((choiceResult) => {
//     if (choiceResult.outcome === 'accepted') {
//       console.log('User accepted the install prompt');
//     } else {
//       console.log('User dismissed the install prompt');
//       // intallBtn.style.display = 'block';
//     }
//   }); 
// }

// window.addEventListener('appinstalled', (e) => {
//     console.log(e);
//     alert('success');
//   // Log install to analytics
//   console.log('INSTALL: Success');
// });
Vue.prototype.displayMode = null;

window.addEventListener('DOMContentLoaded', () => {

  if (navigator.standalone) {
    Vue.prototype.displayMode = 'standalone-ios';
  }
  if (window.matchMedia('(display-mode: standalone)').matches) {
    Vue.prototype.displayMode = 'standalone';
  }
  else{
    Vue.prototype.displayMode = 'browser tab';
  }

//   if(!Vue.prototype.displayMode || Vue.prototype.displayMode=='browser tab'){
//     Vue.prototype.displayMode = "browser tab";
//   }
  // Log launch display mode to analytics
  console.log('DISPLAY_MODE_LAUNCH:', Vue.prototype.displayMode);
});

new Vue({
  vuetify,
  router,
  render: h => h(App),
  mixins: [globalMixin]
}).$mount('#app')