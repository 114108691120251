<template>
    <div>
        <v-toolbar flat fixed>

            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title class="ml-5">Messages</v-toolbar-title>

        </v-toolbar>

        <div class="hastoolbar">

            <v-list nopadding>

                <v-list-item @click="fn_getUserMessages(1, false)">
                    <v-list-item-action>
                      <v-icon color="primary">person_outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>User Messages</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_getSupportMessages(1, true)">
                    <v-list-item-action>
                      <v-icon color="primary">help_outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Support Messages</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_getDraftMessages(2, true)">
                    <v-list-item-action>
                      <v-icon color="primary">drafts</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Draft Messages</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

            </v-list>

        </div>

    </div>
</template>

<script>
    export default{
        name:'types_message',
        methods:{

            fn_getUserMessages: function(status, fromSupport){
                // status for publish or draft
                this.$router.push({path:"/support/supportteam/tickets/message/user_message/items", query:{"status":status, "fromSupport":fromSupport, "businessid":this.$route.query.businessid}});
            },

            fn_getSupportMessages: function(status, fromSupport){
                // status for publish or draft
                this.$router.push({path:"/support/supportteam/tickets/message/support_message/items", query:{"status":status, "fromSupport":fromSupport, "businessid":this.$route.query.businessid}});
            },

            fn_getDraftMessages: function(status, fromSupport){
                // status for publish or draft
                this.$router.push({path:"/support/supportteam/tickets/message/draft_message/items", query:{"status":status, "fromSupport":fromSupport, "businessid":this.$route.query.businessid}});
            },

            fn_goBack: function(){
                this.$router.go(-1);
            },
        },
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
        }
    }
</script>