<template>
    <div full-height>
        <v-toolbar flat extended fixed>

            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title class="ml-5">Support Ticket Report</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-text-field slot="extension" nopadding hide-details elevation-10  flat solo placeholder="Search..." v-model.lazy="searchValue" @input="fn_filterReport" clearable></v-text-field>

            <v-btn color="primary" icon @click="fn_exportExcel">
                <v-icon>vertical_align_bottom</v-icon>
            </v-btn>

            <v-btn color="primary" icon @click="filtersheet = true">
                <v-icon>filter_list</v-icon>
            </v-btn>

        </v-toolbar>

        <div class="hastoolbar hasExtension" full-height>

            <!-- <v-subheader style="height:40px" @click="fn_openDialogPeriod">
                Period: {{dateFrom | moment("DD/MM/YYYY")}} To {{dateTo | moment("DD/MM/YYYY")}}
                <v-spacer></v-spacer>
                <v-icon>date_range</v-icon>
            </v-subheader> -->

            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark text v-bind="attrs" v-on="on" class="datepickerbtn"><v-icon>date_range</v-icon>&nbsp;Period<span v-show="dateFrom && dateTo">:&nbsp;</span> {{dateFrom | moment("DD/MM/YYYY")}}<span v-show="dateFrom && dateTo">&nbsp;To&nbsp;</span>{{dateTo | moment("DD/MM/YYYY")}}</v-btn>
                </template>
                <v-list>
                  <v-list-item @click="fn_getLatestData('Today')">
                    <v-list-item-title>Today</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Yesterday')">
                    <v-list-item-title>Yesterday</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 3 days')">
                    <v-list-item-title>Last 3 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 7 days')">
                    <v-list-item-title>Last 7 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 15 days')">
                    <v-list-item-title>Last 15 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 30 days')">
                    <v-list-item-title>Last 30 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_openDialogPeriod">
                    <v-list-item-title>Custom date range</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn outlined rounded @click="fn_clearDateFilter" v-if="dateFrom && dateTo" dark text class="clearfilterbtn">Clear Filter <v-icon class="pl-1" size="20">close</v-icon></v-btn>

            <div class="table-wrapper" style="width:100%;overflow-x:scroll;font-size:10px">
                <table border="0" cellspacing="0" cellpadding="4" v-if="reportData.count">
                    <thead>
                        <tr primarybackground style="color:#fff">
                            <th style="min-width: 80px" v-for="(header, index) in reportData.headers" :key="index" :class="[header.type == 'number' ? 'right-align': 'left-align']">{{header.text}}</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(item, index) in reportData.items" :key="index" style="border-bottom:1px #f0f0f0 solid">
                            <td right-align>{{item.TicketNumber}}</td>
                            <td>{{item.CreatedOn | moment("DD MMM, YYYY h:mm a")}}</td>
                            <td>{{item.Business.BusinessProfileNumber}}</td>
                            <td>{{item.Business.BusinessName}}</td>
                            <td>{{item.Business.State}}</td>
                            <td>{{$root.fn_requestType(item.RequestType)}}</td>
                            <td>{{item.Title}}</td>
                            <td>{{$root.fn_ticketStatus(item.Status)}}</td>
                            <td>{{item.IsNew}}</td>
                            <td>{{item.ModifiedOn | moment("DD MMM, YYYY h:mm a")}}</td>
                            <td>{{item.ModifiedByUser.Name ? item.ModifiedByUser.Name : item.ModifiedByUser.PhoneNumber}}</td>
                            <!-- <td>{{item.ModifiedByUser.PhoneNumber}}</td> -->
                            <td>{{item.FirstReminderDate | moment("DD MMM, YYYY h:mm a")}}</td>
                            <td>{{item.SecondReminderDate | moment("DD MMM, YYYY h:mm a")}}</td>
                            <td>{{item.FeedbackType === true ? 'Satisfied' : item.FeedbackType === false ? 'Not Satisfied' : ''}}</td>
                            <td>{{item.FeedbackComments}}</td>
                            <td>{{item.ClosedOn | moment("DD MMM, YYYY h:mm a")}}</td>
                        </tr>
                    </tbody>
                </table>

                <!-- <div class="pa-3" center-align v-if="reportData.count > reportData.items.length">
                    <v-btn text @click="getToken">Load More</v-btn>
                </div> -->
                
            </div>

            <div class="pa-4" center-align style="font-size:10px">

                <div><v-progress-circular indeterminate color="grey" width="2" size="24" v-if="loading"></v-progress-circular></div>

                <div>
                    <v-btn :loading="loading" :disabled="loading" text @click="fn_getToken" v-if="reportData.count > reportData.items.length">Load More
                        <template v-slot:loader><span>Loading...</span></template>
                    </v-btn>
                </div>

                <div center-align class="pa-5" v-if="!loading && reportData.count <= reportData.items.length">
                    {{!reportData.count ? 'No record found!' : 'No more tickets to load!'}}
                </div>
            </div>

        </div>

        <dialog-period-selection ref="ref_dialogCustomPeriod" title="Select Period" :openDatePicker="fn_openDatePicker" :startDate="selectedStartDate" :endDate="selectedEndDate" :actionMethod="fn_updateCustomDates"></dialog-period-selection>

        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

        <v-bottom-sheet hide-overlay v-model="filtersheet">
            <v-subheader style="background:#fff">
                Filter Report<v-spacer></v-spacer>
                <v-btn icon @click="filtersheet = false">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-subheader>
            <v-divider></v-divider>
            <div class="pa-3" style="background:#fff">
                <v-select
                  v-model="requestType"
                  :items="requestTypes"
                  item-text="name"
                  item-value="value"
                  label="Request Type"
                  placeholder=" "
                  return-object
                  @change="fn_filterReport"
                  clearable
                ></v-select>

                <v-select
                  v-model="ticketStatus"
                  :items="ticketStatuses"
                  item-text="name"
                  item-value="status"
                  label="Ticket Status"
                  placeholder=" "
                  return-object
                  @change="fn_filterReport"
                  clearable
                ></v-select>

                <v-select
                  v-model="isNew"
                  :items="ticketPriorities"
                  item-text="name"
                  item-value="value"
                  label="Is New"
                  placeholder=" "
                  return-object
                  @change="fn_filterReport"
                  clearable
                ></v-select>

                <v-select
                  v-model="state"
                  :items="states"
                  item-text="Title"
                  item-value="Title"
                  label="State"
                  placeholder=" "
                  return-object
                  @change="fn_filterReport"
                  clearable
                ></v-select>

            </div>
        </v-bottom-sheet>
    </div>
</template>

<script>
import axios from 'axios';

import dialogPeriodSelection from '../../tickets/dialogs/dialog_period_selection'
import dialogDatepicker from '../../tickets/dialogs/dialog_datepicker'

    export default{
        data(){
            return{
                reportData:{
                    headers:[
                        {"text":"Ticket Number", "value":"TicketNumber", "type":"number"},
                        {"text":"Created On", "value":"CreatedOn", "type":"string"}, 
                        {"text":"Phone Number", "value":"BusinessProfileNumber", "type":"string"}, 
                        {"text":"Business Name", "value":"BusinessName", "type":"string"}, 
                        {"text":"State", "value":"State", "type":"string"}, 
                        {"text":"Issue Type", "value":"RequestType", "type":"string"}, 
                        {"text":"Ticket Title", "value":"Title", "type":"string"}, 
                        {"text":"Ticket Status", "value":"Status", "type":"string"}, 
                        {"text":"Is New", "value":"IsNew", "type":"string"}, 
                        {"text":"Modified On", "value":"ModifiedOn", "type":"string"}, 
                        {"text":"Modified By", "value":"ModifiedBy", "type":"string"}, 
                        // {"text":"Modified By Phone Number", "value":"PhoneNumber", "type":"string"}, 
                        {"text":"First Reminder Date", "value":"FirstReminderDate", "type":"string"}, 
                        {"text":"Second Reminder Date", "value":"SecondReminderDate", "type":"string"}, 
                        {"text":"Feedback Type", "value":"FeedbackType", "type":"string"}, 
                        {"text":"Feedback Comments", "value":"FeedbackComments", "type":"string"}, 
                        {"text":"Closed On", "value":"ClosedOn", "type":"string"}
                    ],
                    items:[],
                    count:null
                },
                offset:0,
                toolbarTitle:null,
                loading:false,
                searchValue:'',
                selectedStartDate:this.$root.minDateSelection,
                selectedEndDate:this.$root.maxDateSelection,
                dateFrom:this.$root.minDateSelection,
                dateTo:this.$root.maxDateSelection,
                dateType:null,
                selectedDate:null,

                requestType:null,//issueType
                ticketStatus:null,
                businessState:null,
                isNew:null,
                state:null,

                dialog_datepicker:false,
                filtersheet:false,

                requestTypes:[{"value":1, "name":"General"},{"value":2, "name":"Login Issue"},{"value":3, "name":"Registration Issue"},{"value":4, "name":"Data Issue"}],
                ticketStatuses:[
                    {"name":"Open", "status":1},
                    {"name":"Waiting for Response", "status":2},
                    {"name":"Closed", "status":3},
                ],
                ticketPriorities:[
                    {"name":"Yes", "value":true},
                    {"name":"No", "value":false},
                ],

                states:[],

                loadingObj:{
                    loading:false,
                    message:"Please wait..."    
                },
            }
        },
        components: {
            'dialog-period-selection':dialogPeriodSelection,
            'dialog-datepicker':dialogDatepicker
        },
        methods:{

            fn_openDialogPeriod: function(){
                this.selectedStartDate = this.dateFrom;
                this.selectedEndDate = this.dateTo;
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = true;
            },

            fn_openDatePicker: function (type, date) {
                let self = this;
                this.dateType = type;
                let dateObj = date ? new Date(date) : new Date();
                this.$refs['ref_dialogDatePicker'].selectedDate = dateObj.getFullYear()+'-'+("0" + (dateObj.getMonth()+1)).slice(-2)+'-'+("0" + dateObj.getDate()).slice(-2);
                setTimeout(function () {
                    self.dialog_datepicker = true;
                })
            },

            fn_updateDate: function (value) {
                if(this.dateType=="start"){
                    this.selectedStartDate = value;
                }
                else if(this.dateType=="end"){
                    this.selectedEndDate = value;
                }

                this.dialog_datepicker = false;
            },

            fn_updateCustomDates: function(){
                this.dateFrom = this.selectedStartDate;
                this.dateTo = this.selectedEndDate;
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = false;
                this.fn_filterReport();
                // this.fn_getToken();
            },

            fn_filterReport: function(){
                this.reportData.items = [];
                this.offset = 0;
                this.reportData.count = null;
                this.fn_getToken();
            },

            fn_getToken: function(){
                let self = this;
                // this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.loading = true;
                this.$root.fn_getAuthToken(function(token){
                    if(token == 'error'){
                        // self.$root.fn_hideLoading(self.loadingObj);
                        self.loading = false;
                        self.$root.fn_showToastMsg("Something went wrong. Try again!!");
                        return;
                    }

                    self.fn_getReport(token);
                });
            },

            fn_getReport: function(token){
                var headersObj = {
                    headers: { 
                      'Authorization': 'bearer '+token,
                    }
                };

                let postJson = {
                    "Keywords": this.searchValue, 
                    "BusinessID": null, 
                    "RequestType" : this.requestType ? this.requestType.value : null, 
                    "Status": this.ticketStatus ? this.ticketStatus.status : null, 
                    "TicketNumber":null, 
                    "DateFrom":this.dateFrom, 
                    "DateTo":this.dateTo, 
                    "State":this.state ? this.state.Title : null,
                    "IsNew":this.isNew ? this.isNew.value : null
                }

                if(this.dateFrom){
                    postJson.DateFrom = this.$moment(postJson.DateFrom).startOf('day');
                    postJson.DateFrom = this.fn_getLocalDateFormat(postJson.DateFrom);
                }

                if(this.dateTo){
                    postJson.DateTo = this.$moment(postJson.DateTo).endOf('day');
                    postJson.DateTo = this.fn_getLocalDateFormat(postJson.DateTo);
                }
                
                

                console.log(JSON.stringify(postJson));


                axios.post(process.env.VUE_APP_BASE_API_URL + "/api/supportteam/reports/tickets/getlist?offset="+this.offset+"&limit=10", postJson, headersObj, {timeout:30000})
                .then(response => {
                    console.log(JSON.stringify(response));

                    this.reportData.count = response.data.ItemCount;``

                    if (this.reportData.count > this.reportData.items.length) {
                        for(let i=0; i<response.data.Tickets.length; i++){
                            this.reportData.items.push(response.data.Tickets[i]);
                        }
                    }

                    this.offset = this.reportData.items.length;
                    // this.$root.fn_hideLoading(this.loadingObj);
                    this.loading = false;
                }).catch(e => {
                    // this.$root.fn_hideLoading(this.loadingObj);
                    this.loading = false;
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    console.log(JSON.stringify(e));
                })
            },



            fn_exportExcel: function() {
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                let self = this;
                this.$root.fn_getAuthToken(function(token){

                    if(token=='error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        return;
                    }

                    let postJson = {
                        "Keywords": self.searchValue, 
                        "BusinessID": null, 
                        "RequestType" : self.requestType ? self.requestType.value : null, 
                        "Status": self.ticketStatus ? self.ticketStatus.status : null, 
                        "TicketNumber":null, 
                        "DateFrom":self.dateFrom, 
                        "DateTo":self.dateTo,
                        "State":self.state ? self.state.Title : null,
                        "IsNew":self.isNew ? self.isNew.value : null
                    }

                    postJson.DateFrom = self.$moment(postJson.DateFrom).startOf('day');
                    postJson.DateTo = self.$moment(postJson.DateTo).endOf('day');

                    postJson.DateFrom = self.fn_getLocalDateFormat(postJson.DateFrom);
                    postJson.DateTo = self.fn_getLocalDateFormat(postJson.DateTo);

                    console.log(JSON.stringify(postJson));

                    var headersObj = {
                        headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                    let requestKey = self.guidWeb();
                    axios.post(process.env.VUE_APP_BASE_API_URL+"/api/supportteam/reports/tickets/ExportRequest?requestkey="+requestKey, postJson, headersObj, {timeout:30000})
                    .then(response => {
                        self.$root.fn_hideLoading(self.loadingObj);
                        console.log(JSON.stringify(response));
                        // let exportpath = process.env.VUE_APP_BASE_API_URL+"/api/Export/"+response.data;
                        // let exportpath = process.env.VUE_APP_BASE_API_URL+"/api/supportteam/Export/"+response.data+"?requestkey="+requestKey;
                        let exportpath = process.env.VUE_APP_BASE_API_URL+"/api/supportteam/Export/"+response.data+"?requestkey="+requestKey;

                        if(self.$root.platform()=='desktop'){
                            window.open(exportpath);
                        }
                        else{
                            self.fn_downloadExcel(exportpath);
                        }
                    }).catch(e => {
                        self.$root.fn_hideLoading(self.loadingObj);
                        console.log(e);
                    })
                })
            },

            fn_downloadExcel: function(path){
                let self = this;
                this.getData('OpenBrowser', path, 
                    {   
                        onSuccess: function(response){
                            console.log(response);
                        }, 
                        onError: function(error){
                            self.$root.fn_showToastMsg("Something went wrong. Try again!");
                            console.log(error);
                        }
                    }
                );
            },

            fn_getStates: function(){

                this.$root.fn_showLoading('Getting States...', this.loadingObj);

                let self = this;
                this.$root.fn_getAuthToken(function(token){

                    if(token == 'error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        self.$root.fn_showToastMsg("Something went wrong. Try again!!");
                        return;
                    }

                    var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                    axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/reports/states/getlist', headersObj, {timeout:30000})
                    .then(response => {
                        console.log(response);
                        JSON.stringify(response);
                        self.states = response.data;
                        self.fn_getReport(token);
                        self.$root.fn_hideLoading(self.loadingObj);
                    }).catch(e => {
                        console.log(e);
                        self.$root.fn_showToastMsg("Something went wrong. Try again!");
                        self.$root.fn_hideLoading(self.loadingObj);
                    })
                })
            },

            fn_goBack: function () {
                this.$router.go(-1);
            },

            fn_getLatestData: function(type){
              var currentDate = new Date();
              var year = currentDate.getFullYear();
              var month = String(currentDate.getMonth() + 1).padStart(2, '0');
              var day = String(currentDate.getDate()).padStart(2, '0');
              var formattedStartDate = `${year}-${month}-${day}`;
              this.dateTo = formattedStartDate;


              var startDate = new Date();
              if(type == 'Yesterday')
                startDate.setDate(startDate.getDate() - 1);
              else if(type == 'Last 3 days')
                startDate.setDate(startDate.getDate() - 3);
              else if(type == 'Last 7 days')
                startDate.setDate(startDate.getDate() - 7);
              else if(type == 'Last 15 days')
                startDate.setDate(startDate.getDate() - 15);
              else if(type == 'Last 30 days')
                startDate.setDate(startDate.getDate() - 30);
              var endDateYear = startDate.getFullYear();
              var endDateMonth = String(startDate.getMonth() + 1).padStart(2, '0');
              var endDateDay = String(startDate.getDate()).padStart(2, '0');
              var formattedEndDate = `${endDateYear}-${endDateMonth}-${endDateDay}`;
              console.log('Start Date : ' + formattedStartDate);
              console.log('End Date : ' + formattedEndDate);
              this.dateFrom = formattedEndDate;
              this.fn_filterReport();

            },

            fn_clearDateFilter: function(){
              this.dateFrom = null;
              this.dateTo = null;
              this.fn_filterReport();
            }

        },
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
            this.fn_getStates();
        },
        beforeRouteLeave(to, from, next){
            if(this.filtersheet){
                this.filtersheet = false;
                next(false);
            }
            else if(this.dialog_datepicker){
                this.dialog_datepicker = false;
                next(false);
            }
            else if(this.$refs['ref_dialogCustomPeriod'].dialog_period_selection){
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = false;
                next(false);
            }
            else{
                next();
            }
        }
    }
</script>

<style>
    .table-wrapper table tbody tr:nth-child(even){
        background: #f0f0f0 !important;
    }
    .table-wrapper table thead tr th,
    .table-wrapper table tbody tr td{
        padding:4px;
    }
</style>