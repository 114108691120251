<template>
    <div full-height>
        <v-toolbar flat fixed>

            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title style="line-height:20px" class="ml-5">
                App Usage Report
            </v-toolbar-title>

            <!-- <v-spacer></v-spacer> -->

            

        </v-toolbar>

        <div class="hastoolbar hasfooter" full-height>
            <v-subheader style="height:40px; border-bottom: 1px #ddd solid !important;">

            <v-menu offset-y content-class="menu-max-height">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark text v-bind="attrs" v-on="on" class="datepickerbtn"><v-icon>date_range</v-icon>&nbsp;Period<span v-show="dateFrom && dateTo">:&nbsp;</span> {{dateFrom | moment("DD/MM/YYYY")}}<span v-show="dateFrom && dateTo">&nbsp;To&nbsp;</span>{{dateTo | moment("DD/MM/YYYY")}}</v-btn>
                </template>
                <v-list>
                  <v-list-item @click="fn_getLatestData('Today')">
                    <v-list-item-title>Today</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Yesterday')">
                    <v-list-item-title>Yesterday</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 3 days')">
                    <v-list-item-title>Last 3 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 7 days')">
                    <v-list-item-title>Last 7 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 15 days')">
                    <v-list-item-title>Last 15 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 30 days')">
                    <v-list-item-title>Last 30 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Lifetime')">
                    <v-list-item-title>Lifetime</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Lifetime-1')">
                    <v-list-item-title>Lifetime -1</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Lifetime-2')">
                    <v-list-item-title>Lifetime -2</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Lifetime-4')">
                    <v-list-item-title>Lifetime -4</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Lifetime-8')">
                    <v-list-item-title>Lifetime -8</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_openDialogPeriod">
                    <v-list-item-title>Custom date range</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- <v-btn outlined rounded @click="fn_clearDateFilter" v-if="dateFrom && dateTo" dark text class="clearfilterbtn">Clear Filter <v-icon class="pl-1" size="20">close</v-icon></v-btn> -->

              <v-menu offset-y content-class="menu-max-height">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark text v-bind="attrs" v-on="on" class="datepickerbtn"><v-icon>date_range</v-icon>&nbsp;Created Date<span v-show="BussinessCreatedEDate && BussinessCreatedSDate">:&nbsp;</span> {{BussinessCreatedSDate | moment("DD/MM/YYYY")}}<span v-show="BussinessCreatedEDate && BussinessCreatedSDate">&nbsp;To&nbsp;</span>{{BussinessCreatedEDate | moment("DD/MM/YYYY")}}</v-btn>
                </template>
                <v-list>
                  <v-list-item @click="fn_getLatestDataCreated('Today')">
                    <v-list-item-title>Today</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Yesterday')">
                    <v-list-item-title>Yesterday</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Last 3 days')">
                    <v-list-item-title>Last 3 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Last 7 days')">
                    <v-list-item-title>Last 7 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Last 15 days')">
                    <v-list-item-title>Last 15 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Last 30 days')">
                    <v-list-item-title>Last 30 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Lifetime')">
                    <v-list-item-title>Lifetime</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Lifetime-1')">
                    <v-list-item-title>Lifetime -1</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Lifetime-2')">
                    <v-list-item-title>Lifetime -2</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Lifetime-4')">
                    <v-list-item-title>Lifetime -4</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestDataCreated('Lifetime-8')">
                    <v-list-item-title>Lifetime -8</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_openDialogPeriodCreated">
                    <v-list-item-title>Custom date range</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <!-- <v-btn outlined rounded @click="fn_clearDateCreatedFilter" v-if="BussinessCreatedEDate && BussinessCreatedSDate" dark text class="clearfilterbtn">Clear Filter <v-icon class="pl-1" size="20">close</v-icon></v-btn> -->

            
            <span v-if="reportData">Total Business Count: {{ filteredBusinesses ? filteredBusinesses.length : 0 }}</span>
           
            <v-spacer></v-spacer>

                        </v-subheader>

                        <v-subheader class="mt-4 pl-2 pr-2" style="height:40px; position: relative; z-index: 1;">
                          <v-select
                            class="filter_selection pl-2"
                              v-model="selectedRegistrationType"
                              :items="RegistrationType"
                              item-text="name"
                              item-value="status"
                              placeholder=" "
                              label="Registration Type"
                            ></v-select>
                            <v-select
                            class="filter_selection"
                              v-model="selectedGstinfilledType"
                              :items="GSTINFilledType"
                              item-text="name"
                              item-value="value"
                              placeholder=" "
                              label="Is GST Filled"
                            ></v-select>
                            <v-select
                            class="filter_selection"
                              v-model="selectedAddressFilledType"
                              :items="AddressFilledType"
                              item-text="name"
                              item-value="value"
                              placeholder=" "
                              label="Is Address Filled"
                            ></v-select>
                            <v-select
                            class="filter_selection"
                              v-model="selectedPoweredByRemovedType"
                              :items="PoweredByRemovedType"
                              item-text="name"
                              item-value="value"
                              placeholder=" "
                              label="Is Powered By Removed"
                            ></v-select>
                            <v-select
                            class="filter_selection"
                              v-model="selectedDriveSetupType"
                              :items="DriveSetupType"
                              item-text="name"
                              item-value="value"
                              placeholder=" "
                              label="Is Drive Setup"
                            ></v-select>
                            <v-select
                            class="filter_selection"
                              v-model="selectedTags"
                              :items="TagList"
                              item-text="Title"
                              item-value="Title"
                              placeholder=" "
                              label="Tagged"
                            ></v-select>
                            <v-select
                            class="filter_selection pr-2"
                              v-model="notSelectedTags"
                              :items="TagList"
                              item-text="Title"
                              item-value="Title"
                              placeholder=" "
                              label="Not Tagged"
                            ></v-select>
                        </v-subheader>

            <template v-if="reportData">

                <template>

                    <div class="table-wrapper" style="width:100%;font-size:10px; position: relative; z-index: 8;">
                        <table style="width:100%">
                            
                            <thead>
                                <tr class="primarybackground" style="color:#fff">
                                    <td class="pa-1">S.No</td>
                                    <td class="pa-1">Action</td>
                                    <td class="pa-1" style="cursor: pointer;" @click="sortBy('BusinessName')">Business Name</td>
                                    <td class="pa-1" style="cursor: pointer;" @click="sortBy('CreatedOn')">Created On</td>
                                    <td class="pa-1" style="cursor: pointer;" @click="sortBy('MobileUsageCount')">Mobile Usage Count</td>
                                    <td class="pa-1">Registration Type</td>
                                    <td class="pa-1">First Transaction Created</td>
                                    <td class="pa-1">First Invoice Created</td>
                                    <td class="pa-1">Data Drive</td>
                                    <!-- <td class="pa-1">Desktop Usage Count</td> -->
                                    
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(item, index) in sortedBusinesses" :key="index">
                                    <td>{{ (index + 1) }}</td>
                                    <td>
                                        <v-menu>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on"><v-icon size="18">more_vert</v-icon></v-btn>
                                            </template>
                                            <v-list nopadding>
                                                <v-list-item @click="fn_sendMessage(item.PhoneNumber)">
                                                    <v-list-item-title>Send Message</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </td>
                                    <td><a @click="fn_viewDetails(item.BusinessID)">{{item.BusinessName}}</a></td>
                                    <td>{{item.CreatedOn | moment("DD/MM/YYYY")}}</td>
                                    <td>{{item.MobileUsageCount}}</td>
                                    <td>{{getSelectedRegistrationType(item.RegistrationType)}}</td>
                                    <td>{{item.FirstTransactionCreated}}</td>
                                    <td>{{item.FirstInvoiceCreated}}</td>
                                    <td>{{item.DataDrive}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="pa-4" center-align style="font-size:10px" v-if="!reportData.data.Details.length">
                        No record found!
                    </div>
                </template>
            </template>

            <template v-else>
                <div class="pt-4 pl-4">
                    <v-btn style="border-radius: 0px; background-color: rgb(75, 119, 190) !important;" color="primary" @click="fn_getLatestData('Today')">
                        Generate Report
                    </v-btn>
                </div>
            </template>
            

        </div>

        <dialog-period-selection ref="ref_dialogCustomPeriod" title="Select Period" :openDatePicker="fn_openDatePicker" :startDate="selectedStartDate" :endDate="selectedEndDate" :actionMethod="fn_updateCustomDates"></dialog-period-selection>

        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

        <dialog-business-details ref="ref_dialogBusinessDetails" type="business" :showBusinessMetrics="true" :updateData="fn_getToken"></dialog-business-details>

        <dialog-loading :loading="loading"></dialog-loading>

        <v-dialog ref="ref_monthpicker" no-click-animation v-model="monthPicker" :return-value.sync="selectedMonth" persistent lazy full-width width="290px" transition="slide-y-transition">
            <v-date-picker v-model="selectedMonth" scrollable type="month">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="monthPicker = false">Cancel</v-btn>
                <v-btn text color="primary" @click="fn_saveMonth(selectedMonth)">OK</v-btn>
            </v-date-picker>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios';

import dialogPeriodSelection from '../../tickets/dialogs/dialog_period_selection'
import dialogDatepicker from '../../tickets/dialogs/dialog_datepicker'
import dialogLoading from '../../../dialogs/dialog_loading.vue'
import dialogBusinessDetails from '../../tickets/dialogs/dialog_viewbusinessdetailsfullscreen';

    export default{
        name:'report_appusage',
        data(){
            return{
                reportData:null,
                selectedStartDate:this.$root.minDateSelection,
                selectedEndDate:this.$root.maxDateSelection,
                dateFrom:this.$root.minDateSelection,
                dateTo:this.$root.maxDateSelection,
                dateType:null,
                selectedDate:null,
                dialog_datepicker:false,

                loadingObj:{
                    loading:false,
                    message:"Please wait..."    
                },

                loading:false,
                selectedMonth:new Date().toISOString().substring(0, 7),
                monthPicker:false,
                BussinessCreatedEDate: null,
                BussinessCreatedSDate: null,
                selectionTypeValue: null,
                RegistrationType:[
                    {"name":"All", "status":null},
                    {"name":"Registered", "status":1},
                    {"name":"Composite", "status":2},
                    {"name":"Unregistered", "status":3}
                ],
                GSTINFilledType:[
                    {"name":"All", "value":null},
                    {"name":"Yes", "value":true},
                    {"name":"No", "value":false}
                ],
                AddressFilledType:[
                    {"name":"All", "value":null},
                    {"name":"Yes", "value":true},
                    {"name":"No", "value":false}
                ],
                PoweredByRemovedType:[
                    {"name":"All", "value":null},
                    {"name":"Yes", "value":true},
                    {"name":"No", "value":false}
                ],
                DriveSetupType:[
                    {"name":"All", "value":null},
                    {"name":"Yes", "value":true},
                    {"name":"No", "value":false}
                ],
                TagList: [],
                selectedRegistrationType:null,
                selectedGstinfilledType: null,
                selectedAddressFilledType: null,
                selectedPoweredByRemovedType: null,
                selectedDriveSetupType: null,
                selectedTags: 'All',
                notSelectedTags: 'All',
                x:null,
                y:null,
                ex4: null,
                businesses: [],
                filteredBusinesses: [],
                sortColumn: null,
                sortDirection: 'asc',
            }
        },
        components: {
            'dialog-period-selection':dialogPeriodSelection,
            'dialog-datepicker':dialogDatepicker,
            'dialog-loading':dialogLoading,
            'dialog-business-details':dialogBusinessDetails
        },
        methods:{

            getSelectedRegistrationType: function(value){
                for(let i=0; i<this.RegistrationType.length; i++){
                    if(this.RegistrationType[i].status == value){
                        return this.RegistrationType[i].name;
                    }
                }
            },

            fn_getTagName: function(value){
              for(let i=0; i<this.TagList.length; i++){
                    if(this.TagList[i].Title == value){
                        return this.TagList[i].Title;
                    }
                }
            },

            fn_sendMessage: function(mobilenumber){
                console.log(mobilenumber);
                var message = "Hi,%0aKeep%20your%20data%20safe%20%26%20secure%20on%20your%20Google%20drive.%20*Setup%20auto%20backup%20now!*%0a%0aWatch%20help%20video%20on%20how%20to%20setup:%0ahttps://youtu.be/rpbq7VpGU34%0a%0aThanks,%0aBizOps%20App%20Team";
                var link = "https://wa.me/" + mobilenumber + "?text="+ message;
                window.open(link, '_blank');
            },

            fn_clearFilter: function(){
                this.selectedRegistrationType =null;
                this.selectedGstinfilledType = null;
                this.selectedAddressFilledType = null;
                this.selectedPoweredByRemovedType = null;
                this.selectedDriveSetupType = null;
                this.selectedTags = [];
                this.fn_getToken();
            },

            fn_viewDetails: function(businessId){
                this.$refs['ref_dialogBusinessDetails'].fn_getToken(businessId);
                //this.$router.push({path:"/support/supportteam/business/item/"+businessId});
            },

            fn_openDialogPeriod: function(){
                this.selectedStartDate = this.dateFrom;
                this.selectedEndDate = this.dateTo;
                this.selectionTypeValue = "NormalDialog";
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = true;
            },

            fn_openDialogPeriodCreated: function(){
                this.selectedStartDate = this.BussinessCreatedSDate;
                this.selectedEndDate = this.BussinessCreatedEDate;
                this.selectionTypeValue = "CreatedDialog";
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = true;
            },

            fn_openDatePicker: function (type, date) {
                let self = this;
                this.dateType = type;
                let dateObj = date ? new Date(date) : new Date();
                this.$refs['ref_dialogDatePicker'].selectedDate = dateObj.getFullYear()+'-'+("0" + (dateObj.getMonth()+1)).slice(-2)+'-'+("0" + dateObj.getDate()).slice(-2);
                setTimeout(function () {
                    self.dialog_datepicker = true;
                })
            },

            fn_updateDate: function (value) {
                if(this.dateType=="start"){
                    this.selectedStartDate = value;
                }
                else if(this.dateType=="end"){
                    this.selectedEndDate = value;
                }

                this.dialog_datepicker = false;
            },

            fn_updateCustomDates: function(type){
                if(this.selectionTypeValue == 'NormalDialog'){
                    this.dateFrom = this.selectedStartDate;
                    this.dateTo = this.selectedEndDate;
                }
                else{
                    this.BussinessCreatedSDate = this.selectedStartDate;
                    this.BussinessCreatedEDate = this.selectedEndDate;
                }
               
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = false;
                this.fn_getToken(type);
            },

            fn_saveMonth: function (value) {
                this.$refs.ref_monthpicker.save(value);
                let date = new Date(value);
                this.selectedStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
                this.selectedEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                this.fn_updateCustomDates('month')
            },

            fn_getToken: function(type){
                let self = this;
                this.loading = true;
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){
                    if(token == 'error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        this.loading = false;
                        self.$root.fn_showToastMsg("Something went wrong. Try again!!");
                        return;
                    }

                    self.fn_getReport(token, type);
                });
            },

            fn_getReport: function(token, type){
                var headersObj = {
                    headers: { 
                      'Authorization': 'bearer '+token,
                    }
                };

                let postJson = {
                    "DateFrom":this.dateFrom, 
                    "DateTo":this.dateTo, 
                    "BussinessCreatedEDate": this.BussinessCreatedEDate,
                    "BussinessCreatedSDate": this.BussinessCreatedSDate,
                    "RegistrationType": null,
                    "IsGSTINFilled": null,
                    "IsAddressFilled": null,
                    "IsPoweredByRemoved": null,
                    "IsDriveSetup": null,
                    "Tags": null

                }

                if(this.dateFrom){
                    postJson.DateFrom = this.$moment(postJson.DateFrom).startOf('day');   
                    postJson.DateFrom = this.fn_getLocalDateFormat(postJson.DateFrom); 
                }

                if(this.dateTo){
                    postJson.DateTo = this.$moment(postJson.DateTo).endOf('day');
                    postJson.DateTo = this.fn_getLocalDateFormat(postJson.DateTo);
                }

                if(this.BussinessCreatedEDate){
                    postJson.BussinessCreatedEDate = this.$moment(postJson.BussinessCreatedEDate).startOf('day');   
                    postJson.BussinessCreatedEDate = this.fn_getLocalDateFormat(postJson.BussinessCreatedEDate); 
                }

                if(this.BussinessCreatedSDate){
                    postJson.BussinessCreatedSDate = this.$moment(postJson.BussinessCreatedSDate).startOf('day');   
                    postJson.BussinessCreatedSDate = this.fn_getLocalDateFormat(postJson.BussinessCreatedSDate); 
                }

                console.log(JSON.stringify(postJson));

                axios.post(process.env.VUE_APP_BASE_API_URL + "/api/supportteam/reports/appusage", postJson, headersObj, {timeout:30000})
                .then(response => {
                    console.log(JSON.stringify(response));
                    this.reportData = response;
                    //this.businesses = this.reportData.data.Details;
                    this.filteredBusinesses = this.reportData.data.Details;
                    this.filterBusinesses();
                    
                    this.loading = false;
                    this.$root.fn_hideLoading(this.loadingObj);
                    type ? this.dateType = type : ''
                }).catch(e => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    this.loading = false;
                    console.log(JSON.stringify(e));
                })
            },

            fn_goBack: function () {
                this.$router.go(-1);
            },

            fn_getLatestData: function(type, selectiontype){
                var currentDate = new Date();
                if(type != 'Today' && type != 'Lifetime' && type != 'Lifetime-2' && type != 'Lifetime-4' && type != 'Lifetime-8')
                    currentDate.setDate(currentDate.getDate() - 1);
                if(type == 'Lifetime-2')
                  currentDate.setDate(currentDate.getDate() - 2);
                else if(type == 'Lifetime-4')
                  currentDate.setDate(currentDate.getDate() - 4);
                else if(type == 'Lifetime-8')
                  currentDate.setDate(currentDate.getDate() - 8);
                var year = currentDate.getFullYear();
                var month = String(currentDate.getMonth() + 1).padStart(2, '0');
                var day = String(currentDate.getDate()).padStart(2, '0');
                var formattedStartDate = `${year}-${month}-${day}`;
                this.dateTo = formattedStartDate;
              

                var startDate = new Date();
                if(type == 'Yesterday')
                    startDate.setDate(startDate.getDate() - 1);
                else if(type == 'Last 3 days')
                    startDate.setDate(startDate.getDate() - 3);
                else if(type == 'Last 7 days')
                    startDate.setDate(startDate.getDate() - 7);
                else if(type == 'Last 15 days')
                    startDate.setDate(startDate.getDate() - 15);
                else if(type == 'Last 30 days')
                    startDate.setDate(startDate.getDate() - 30);
                var endDateYear = startDate.getFullYear();
                var endDateMonth = String(startDate.getMonth() + 1).padStart(2, '0');
                var endDateDay = String(startDate.getDate()).padStart(2, '0');
                var formattedEndDate = `${endDateYear}-${endDateMonth}-${endDateDay}`;
                    
                this.dateFrom = formattedEndDate;

                if(type == 'Lifetime' || type == 'Lifetime-1' || type == 'Lifetime-2' || type == 'Lifetime-4' || type == 'Lifetime-8')
                    this.dateFrom = "2023-1-1";
                
                if(selectiontype != 'selectdateonly')
                    this.fn_getToken();

            },

            fn_getLatestDataCreated: function(type, selectiontype){
                var currentDate = new Date();
                if(type != 'Today' && type != 'Lifetime' && type != 'Lifetime-2' && type != 'Lifetime-4' && type != 'Lifetime-8')
                    currentDate.setDate(currentDate.getDate() - 1);
                if(type == 'Lifetime-2')
                  currentDate.setDate(currentDate.getDate() - 2);
                else if(type == 'Lifetime-4')
                  currentDate.setDate(currentDate.getDate() - 4);
                else if(type == 'Lifetime-8')
                  currentDate.setDate(currentDate.getDate() - 8);
                var year = currentDate.getFullYear();
                var month = String(currentDate.getMonth() + 1).padStart(2, '0');
                var day = String(currentDate.getDate()).padStart(2, '0');
                var formattedStartDate = `${year}-${month}-${day}`;
                this.BussinessCreatedEDate = formattedStartDate;
                

                var startDate = new Date();
                if(type == 'Yesterday')
                    startDate.setDate(startDate.getDate() - 1);
                else if(type == 'Last 3 days')
                    startDate.setDate(startDate.getDate() - 3);
                else if(type == 'Last 7 days')
                    startDate.setDate(startDate.getDate() - 7);
                else if(type == 'Last 15 days')
                    startDate.setDate(startDate.getDate() - 15);
                else if(type == 'Last 30 days')
                    startDate.setDate(startDate.getDate() - 30);
                var endDateYear = startDate.getFullYear();
                var endDateMonth = String(startDate.getMonth() + 1).padStart(2, '0');
                var endDateDay = String(startDate.getDate()).padStart(2, '0');
                var formattedEndDate = `${endDateYear}-${endDateMonth}-${endDateDay}`;

                this.BussinessCreatedSDate = formattedEndDate;

                if(type == 'Lifetime' || type == 'Lifetime-1' || type == 'Lifetime-2' || type == 'Lifetime-4' || type == 'Lifetime-8')
                    this.BussinessCreatedSDate = "2023-1-1";
                
                if(selectiontype != 'selectdateonly')
                    this.fn_getToken();
            },

            fn_clearDateFilter: function(){
              this.BussinessCreatedEDate = null;
              this.BussinessCreatedSDate = null;
              this.fn_getToken();
            },

            fn_clearDateCreatedFilter: function(){
                this.dateFrom = null;
                this.dateTo = null;
                this.fn_getToken();
            },

            fn_changeStatus: function(status){
                this.selectedRegistrationType = status;
                this.fn_getToken();
            },

            fn_changeGstinfilledType: function(requestType){
                this.selectedGstinfilledType = requestType;
                this.fn_getToken();
            },

            fn_changeAddressFilledType: function(requestType){
                this.selectedAddressFilledType = requestType;
                this.fn_getToken();
            },

            fn_changePoweredByRemovedType: function(requestType){
                this.selectedPoweredByRemovedType = requestType;
                this.fn_getToken();
            },

            fn_changeDriveSetupType: function(requestType){
                this.selectedDriveSetupType = requestType;
                this.fn_getToken();
            },

            fn_changeTags: function(){
                //this.selectedTags = requestType;
                this.fn_getToken();
            },

            fn_getTagToken: function(){
                let self = this;
                this.loading = true;
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){
                    if(token == 'error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        this.loading = false;
                        self.$root.fn_showToastMsg("Something went wrong. Try again!!");
                        return;
                    }

                    self.fn_getTags(token);
                });
            },

            fn_getTags: function(token){
                let self = this;

                var headersObj = {
                headers: { 
                        'Authorization': 'bearer '+token,
                    }
                };

                axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/tags/getlist?status=1', headersObj, {timeout:30000})
                .then(response => { 
                    console.log(JSON.stringify(response.data));
                    self.TagList = response.data;
                    self.TagList.unshift({ 'Status': 1, 'TagID': null, 'Title': 'All' })
                    self.loading = false;
                    this.$root.fn_hideLoading(this.loadingObj);
                    //self.$root.fn_hideLoading(self.loadingObj);
                }).catch(e => {
                    console.log(e);
                    self.snackbarRetry = true;
                    self.$root.fn_hideLoading(self.loadingObj);
                })
                
            },

            filterBusinesses() {
              this.filteredBusinesses = this.reportData.data.Details.filter(business => {
                return (
                  (this.selectedRegistrationType === null || business.RegistrationType === this.selectedRegistrationType) &&
                  (this.selectedGstinfilledType === null || business.GSTINFilled === this.selectedGstinfilledType) &&
                  (this.selectedAddressFilledType === null || business.AddressFilled === this.selectedAddressFilledType) &&
                  (this.selectedPoweredByRemovedType === null || business.PoweredByRemoved === this.selectedPoweredByRemovedType) &&
                  (this.selectedDriveSetupType === null || business.IsDriveSetup === this.selectedDriveSetupType) &&
                  (this.selectedTags === null || this.selectedTags == 'All' || business.Tags.includes(this.selectedTags)) &&
                  (this.notSelectedTags === null || this.notSelectedTags == 'All' || !business.Tags.includes(this.notSelectedTags))
                );
              });
            },

            sortBy(column) {
              if (this.sortColumn === column) {
                this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
              } else {
                this.sortColumn = column;
                this.sortDirection = 'asc';
              }
            },
            getColumnValue(item, column) {
              switch (column) {
                case 'BusinessName':
                  return item.BusinessName;
                case 'CreatedOn':
                  return item.CreatedOn;
                case 'MobileUsageCount':
                  return parseInt(item.MobileUsageCount);
                default:
                  return '';
              }
            }

        },
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
            // this.fn_getUsers();
            this.fn_getLatestData('Today', 'selectdateonly');
            this.fn_getLatestDataCreated('Today', 'selectdateonly');
            this.fn_getTagToken();
         
        },
        beforeRouteLeave(to, from, next){
            if(this.filtersheet){
                this.filtersheet = false;
                next(false);
            }
            else if(this.dialog_datepicker){
                this.dialog_datepicker = false;
                next(false);
            }
            else if(this.$refs['ref_dialogCustomPeriod'].dialog_period_selection){
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = false;
                next(false);
            }
            else{
                next();
            }
        },

        watch: {
          selectedRegistrationType: 'filterBusinesses',
          selectedGstinfilledType: 'filterBusinesses',
          selectedAddressFilledType: 'filterBusinesses',
          selectedPoweredByRemovedType: 'filterBusinesses',
          selectedDriveSetupType: 'filterBusinesses',
          selectedTags: 'filterBusinesses',
          notSelectedTags: 'filterBusinesses',
        },

        computed: {
          sortedBusinesses() {
            if (!this.sortColumn) {
              return this.filteredBusinesses;
            }

            return this.filteredBusinesses.slice().sort((a, b) => {
              const aValue = this.getColumnValue(a, this.sortColumn);
              const bValue = this.getColumnValue(b, this.sortColumn);

              if(this.sortColumn != 'MobileUsageCount'){
                if (this.sortDirection === 'asc') {
                return aValue.localeCompare(bValue);
                } else {
                  return bValue.localeCompare(aValue);
                }
              }
              else{
                if (this.sortDirection === 'asc') {
                  return aValue - bValue;
                } else {
                  return bValue - aValue;
                }
              }
              
            });
          }
        },
    }
</script>

<style>
    .table-wrapper table tbody tr:nth-child(even){
        background: #f0f0f0 !important;
    }
    .table-wrapper table thead tr th,
    .table-wrapper table tbody tr td{
        padding:4px;
    }
    .filtered_box{
        border-radius: 20px !important;
        padding: 5px;
        border: 1px solid black;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
    }
    .menu-max-height{
      max-height: 450px;
    }

    .tagmenu{
      min-width: 250px !important;
      padding-left: 10px;
      padding-bottom: 16px;
      background-color: white;
    }

</style>