<template>
    <div full-height>
        <v-toolbar flat fixed>
            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
            <v-toolbar-title class="ml-5">{{toolbarTitle}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on"><v-icon color="primary">attachment</v-icon></v-btn>
              </template>
              <v-list nopadding>
                <v-list-item @click="fn_selectFile('file')">
                  <v-list-item-title>Attach File</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="fn_selectFile('db', 1)" :disabled="$root.platform()=='desktop'">
                  <v-list-item-title>Attach Database</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="fn_selectFile('db', 2)">
                  <v-list-item-title>Attach Database From Device</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
        </v-toolbar>

        <div class="hastoolbar hasfooter" :class="{'hasQuillToolbar':editMessage, 'desktop-view':$root.platform()=='desktop'}">
            <v-form class="form-element">

                <v-expansion-panels flat class="mb-3" v-if="attachments && attachments.length">
                  <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="expand_more" style="padding:8px 16px;min-height:48px;">Attachments ({{attachments.length}})</v-expansion-panel-header>
                    <v-divider></v-divider>
                    <v-expansion-panel-content>
                      <div v-for="(item, index) in attachments" :key="index">
                        <v-chip class="mr-2 mt-2 mb-2 pr-1" color="primary" label text-color="white">
                          <span style="overflow:hidden;text-overflow:ellipsis">{{item.fileName}}</span> 
                          <v-icon v-ripple class="ml-3" @click="fn_deleteAttachment(item.filePath, index)">close</v-icon>
                        </v-chip>  
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <input type="file" id="filePicker" style="display:none" @change="fn_validateFile"/>

                <v-layout align-center nomargin padding-top-ten row wrap white-background>

                    <v-flex xs12 sm12 md12 form-item v-if="$route.query.status">
                        <v-select :items="ticketStatuses" label="Ticket Status" v-model="Form.StatusObj" return-object item-text="name" item-value="status"></v-select>
                    </v-flex>

                     <v-flex xs12 sm12 md12 form-item class="pb-4" :class="{'blur':!editMessage}">
                        <label style="color: rgba(0, 0, 0, 0.6);font-size: 12px;">Message</label>
                        
                        <quill-editor class="mt-1"
                          ref="myTextEditor"
                          v-model="Form.Message"
                          :options="editorOption"
                          placeholder=""
                          @blur="onEditorBlur($event)"
                          @focus="onEditorFocus($event)"
                          @ready="onEditorReady($event)">
                          <div id="toolbar" slot="toolbar">
                              <!-- Add a bold button -->
                              <button class="ql-bold">Bold</button>
                              <button class="ql-italic">Italic</button>
                              <button class="ql-underline">ql-underline</button>
                              <button class="ql-strike">Italic</button>
                              <!-- Add ordered/bullet list -->
                              <button class="ql-list" value="ordered">ql-list</button>
                              <button class="ql-list" value="bullet">ql-list</button>
                              <button class="ql-link">ql-link</button>

                              
                          </div>
                      </quill-editor>

                        <v-divider dark-opacity-divider></v-divider>
                    </v-flex>
                   
                </v-layout>

            </v-form>

            <v-footer nopadding style="position:fixed;bottom:0;" :style="{'width':$root.platform()=='desktop' ? 'calc(100% - 256px)' : '100%'}">
                <v-layout row wrap>

                    <v-flex height48 xs6 sm6 md6>
                        <v-btn style="background:#eee" full-height nomargin no-round-borders noshadow block @click="fn_checkValidations(2)">
                          Post as Draft
                        </v-btn>
                    </v-flex>

                    <v-flex height48 xs6 sm6 md6>
                        <v-btn nomargin no-round-borders full-height noshadow color="primary" block @click="fn_checkValidations(1)">
                          Update
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-footer>
        </div>  

        <dialog-progress ref="ref_dialogProgress" :status="buffer_size" :progressMessage="progressMessage" :abortRequest="fn_cancelUpload" :cancelInit="cancelInit"></dialog-progress>

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <dialog-permission ref="ref_dialogPermission"></dialog-permission>

        <dialog-confirmation ref="ref_dialogConfirmation" header="Attach Database" confirmationMessage="Desktop connection will break. Would you like to continue?" :actionMethod="fn_getLocalKeyValue" okBtnText="Yes" cancelBtnText="No"></dialog-confirmation>
    </div>
</template>

<script>
import axios from 'axios'
import {supportMixin} from './support_mixin'

import dialogProgress from '../../dialogs/dialog_progress'

import dialogConfirmation from '../../dialogs/dialog_confirmation'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import { quillEditor } from 'vue-quill-editor'
import Quill from "quill"; // Importing Quill library

    export default{
        name:'form_message',
        mixins:[supportMixin],
        data(){
            return{
              toolbarTitle:"Add Message",
                Form:{Message:"", Attachments:null, StatusObj:null, TicketFolderID:null, TicketMessageFolderID:null},
                ticketStatuses:[
                    {"name":"Open", "status":1},
                    {"name":"Waiting for Response", "status":2},
                    {"name":"Closed", "status":3},
                    // {"name":"Pending", "status":4},
                ],
                attachments:[],
                editMessage:true,
                editorOption: {placeholder:"",modules: {toolbar: '#toolbar'}},
                buffer_size:0,
                progressMessage:null,
                loadingObj:{
                    loading:false,
                    message:"Please wait..."    
                }
            }
        },
        components:{
            quillEditor,
            'dialog-progress':dialogProgress,
            'dialog-confirmation':dialogConfirmation
        },
        methods: {

            onEditorBlur(editor) {
              this.editMessage = false;
              console.log('editor blur!', editor);
            },
            onEditorFocus(editor) {
                this.editMessage = true;
                console.log('editor focus!', editor)
            },
            onEditorReady(editor) {
                console.log('editor ready!', editor);

                const quill = this.$refs.myTextEditor.quill;
                console.log(quill);
                const Clipboard = Quill.import("modules/clipboard");
                console.log('Quill Clipboard Registered');

                class CustomClipboard extends Clipboard {
                  onPaste(e) {
                    const range = this.quill.getSelection();
                    const text = e.clipboardData.getData("text/plain");

                    if (this.handleYouTubeLink(text, range)) {
                      e.preventDefault();
                      console.log('Quill CustomClipboard Registered');
                      return;
                    }

                    super.onPaste(e);
                  }

                  handleYouTubeLink(text, range) {
                    const regExp = /(?:https?:\/\/)?(?:www\.)?(youtube\.com\/|youtu\.be\/|facebook\.com\/|twitter\.com\/|google\.com\/|instagram.com\.com\/)(\S+)/; //eslint-disable-line
            
                    // Check if pasted content is a link from supported websites
                    console.log(match);
                    const match = text.match(regExp);
                    if (match) {
                      console.log('Quill Clipboard is matched');
                      // Extract the URL
                      const url = match[0];

                      // Modify the pasted content to create a proper hyperlink
                      this.quill.deleteText(range.index, text.length);
                      this.quill.insertText(range.index, text, {
                        link: url
                      });
                      return true;
                    }
                    return false;
                  }
                }

                Quill.register("modules/clipboard", CustomClipboard);

            },

            fn_checkValidations: function(messageStatus){
                if(!this.Form.Message){
                  this.$root.fn_showToastMsg("Please add message!");
                }
                else{
                   let self = this;
                    this.$root.fn_showLoading('Please wait...', this.loadingObj);
                    this.$root.fn_getAuthToken(function(token){

                      if(token=='error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        self.$root.fn_showToastMsg("Something went wrong. Try again!");
                        return;
                      }

                      self.fn_postMessage(token, messageStatus);
                    })
                }
            },

            fn_postMessage: function(token, messageStatus){

              let postObj = {};
              postObj.Message = this.Form.Message;
              postObj.Attachments = this.attachments.length ? JSON.stringify(this.attachments) : null;
              postObj.TicketMessageFolderID = this.Form.TicketMessageFolderID;
              postObj.FromDeviceType = this.$root.platform() == 'desktop' ? 3 : 1;

              let toastMsg = null;
              let api = null;

              // if(this.$route.query.status){
              //   // status for update ticket status pending/inprocess/resolved
              //   postObj.Status = this.Form.StatusObj.status;
              //   toastMsg = "Status Updated!";
              //   api = process.env.VUE_APP_BASE_API_URL + "/api/supportteam/tickets/"+this.$route.params.ticketid+"/updatestatus"
              // }
              // else{
              //   // status for message publish/draft
              //   toastMsg = "Message added!";
              //   api = process.env.VUE_APP_BASE_API_URL + "/api/supportteam/tickets/"+this.$route.params.ticketid+"/postmessage"
              // }

              postObj.Status = messageStatus;
              postObj.TicketStatus = null;
              if(typeof(this.Form.StatusObj) == 'object' && this.Form.StatusObj.status){
                postObj.TicketStatus = this.Form.StatusObj.status;
              }
              else{
                postObj.TicketStatus = this.Form.StatusObj;
              }

              if(messageStatus == 2){
                postObj.TicketStatus = Number(this.$route.query.status);
              }
              // postObj.TicketStatus = messageStatus == 1 ? this.Form.StatusObj : null;
              toastMsg = "Message added!";
              api = process.env.VUE_APP_BASE_API_URL + "/api/supportteam/tickets/"+this.$route.params.ticketid+"/postmessage";

              console.log(JSON.stringify(postObj));

              var headersObj = {
              headers: {
                    'Authorization': 'bearer '+token,
                  }
              };

              axios.post(api, postObj, headersObj, {timeout:30000})
              .then(response => {
                  this.$root.fn_hideLoading(this.loadingObj);
                  console.log(response);
                  this.fn_goBack();
                  this.$root.fn_showToastMsg(toastMsg);
              }).catch(e => {
                  this.$root.fn_hideLoading(this.loadingObj);
                  this.$root.fn_showToastMsg("Something went wrong. Try again!");
                  console.log(e);
              })
                
            },

            // fn_init: function(){
            //   let self = this;
            //   this.fn_getSignature(function(response){
            //     self.Form.Message = response.data.SignatureText;
            //     self.editMessage = true;
            //   })
            // },
           
            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        
        mounted: function(){
          window.js_globals.fn_onBackPress = this.fn_goBack;
          window.js_globals.fn_setStatus = this.fn_setStatus;
          this.Form.TicketFolderID = this.$route.query.ticketFolderId;
          if(this.$route.query.status){
            // this.toolbarTitle = "Update Status";
            this.Form.StatusObj = this.$route.query.status;
          }

          let self = this;
          this.fn_generateGuid(function(guid){
            self.Form.TicketMessageFolderID = guid;
          });
          // else{
          //   this.toolbarTitle = "Add Message";
          // }
          // this.fn_init();
        },
        
        beforeRouteLeave(to, from, next){
            if(this.$refs['ref_dialogProgress'].dialog_progress){
                this.$root.fn_showToastMsg("File uploading in progress!");
                next(false);
            }
            else if(this.$refs['ref_dialogPermission'].dialog_permission){
              this.$refs['ref_dialogPermission'].dialog_permission = false;
              next(false);
            }
            else if(this.$refs['ref_dialogConfirmation'].dialog_confimation){
              this.$refs['ref_dialogConfirmation'].closeDialogWeb();
              next(false);
            }
            else{
                next();
            }
        }
    }
</script>