<template>
    <div>
        <v-toolbar flat fixed>

            <v-toolbar-side-icon @click="fn_goBack" v-if="$root.platform()=='android'"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title :class="{'ml-5':$root.platform()=='android'}">User Support</v-toolbar-title>

        </v-toolbar>

        <div class="hastoolbar">

            <v-list nopadding>

                <v-list-item @click="fn_itemClick">
                    <v-list-item-action>
                      <v-icon color="primary">label_important</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Support Tickets</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_getBusiness">
                    <v-list-item-action>
                      <v-icon color="primary">control_point</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Create Support Ticket</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_routeTo('/support/supportteam/tickets/feedback/items')">
                    <v-list-item-action>
                      <v-icon color="primary">feedback</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>User Feedbacks</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_routeTo('/support/supportteam/tickets/appfeedback/items')">
                    <v-list-item-action>
                      <v-icon color="primary">feedback</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>App Feedbacks</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider v-if="$root.platform()=='android'"></v-divider>

                <v-list-item @click="fn_backRestore" v-if="$root.platform()=='android'">
                    <v-list-item-action>
                      <v-icon color="primary">autorenew</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Backup/Restore</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_supportUsers">
                    <v-list-item-action>
                      <v-icon color="primary">portrait</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Support Users</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_routeTo('/support/supportteam/business/items')">
                    <v-list-item-action>
                      <v-icon color="primary">business</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Business List</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_routeTo('/support/supportteam/testingphonenumbers/items')">
                    <v-list-item-action>
                      <v-icon color="primary">smartphone</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Testing Phone Numbers</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_messageTypes">
                    <v-list-item-action>
                      <v-icon color="primary">message</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Messages</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

               <!--  <v-divider></v-divider>

                <v-list-item to="/support/supportteam/tickets/message/signature/edit">
                    <v-list-item-action>
                      <v-icon color="primary">add</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Message Signature</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item> -->

                <v-divider></v-divider>

                <v-list-item @click="fn_notificationList">
                    <v-list-item-action>
                      <v-icon color="primary">notifications_none</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Pending Notifications {{notificationCount ? '('+notificationCount+')' : ''}}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_reports">
                    <v-list-item-action>
                      <v-icon color="primary">dashboard</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Reports</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_routeTo('/support/supportteam/workflow/items')">
                    <v-list-item-action>
                      <v-icon color="primary">settings</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Workflow</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

            </v-list>

        </div>

    </div>
</template>

<script>
import axios from 'axios';

    export default{
        name:'options_support',
        data(){
            return{
                notificationCount:0
            }
        },
        methods:{
            fn_routeTo: function(routePath){
              this.$router.push({path:routePath});
            },

            fn_reports: function(){
              this.$router.push({path:"/support/supportteam/reports/items", query:{"businessid":this.$route.query.businessid}});
            },

            fn_itemClick: function(){
                this.$router.push({path:"/support/supportteam/tickets/items", query:{"businessid":this.$route.query.businessid}})
            },

            fn_messageTypes: function(){
                this.$router.push({path:"/support/supportteam/tickets/message/types", query:{"businessid":this.$route.query.businessid}});
            },

            fn_getBusiness: function(){
                this.$router.push({path:"/support/supportteam/tickets/getbusiness", query:{"businessid":this.$route.query.businessid}})
            },

            fn_backRestore: function(){
                this.$router.push({path:"/support/supportteam/backup_restore", query:{"businessid":this.$route.query.businessid}})
            },

            fn_supportUsers: function(){
                this.$router.push({path:"/support/supportteam/users"});
            },

            fn_notificationList: function(){
                this.$router.push({path:"/support/supportteam/tickets/notification/items"});
            },

            fn_getNotificationCount: function(){
                this.loadingCounts = true;

                let self = this;
                this.$root.fn_getAuthToken(function(token){

                  var headersObj = {
                  headers: { 
                        'Authorization': 'bearer '+token,
                      }
                  };

                  axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/SupportTicketNotifications/getcount', headersObj, {timeout:30000})
                  .then(response => { 
                      console.log(JSON.stringify(response.data));
                      self.notificationCount = response.data.Count;
                      self.loadingCounts = false;
                  }).catch(e => {
                      self.$root.fn_showToastMsg("Failed to get pending notification's count!");
                      self.loadingCounts = false;
                      console.log(e);
                  })
                })
                  
            },

            // fn_testApi: function(){
            //   // this.dataLoading = true;
            //   let self = this;
            //   this.$root.fn_getAuthToken(function(token){
            //      var headersObj = {
            //         headers: {
            //             'Authorization': 'bearer ' + token
            //         }
            //     };
            //     //, headersObj
            //     axios.get('https://devbizopsappapi.azurewebsites.net/api/business/'+self.$route.query.businessid+'/djtestdevices', headersObj)
            //     .then(response => {
            //         console.log(response);
            //     });
            //   })
             
            // },

            fn_goBack: function(){
                if(this.$root.isRestoreDb){
                    this.$root.fn_closeSupportAndReopenDB();
                }
                else{
                    this.exitApp();
                }
            },


        },
        mounted: function(){
            /*global AndroidRoutingTest*/
            AndroidRoutingTest.onPageLoadCompleted('1', JSON.stringify({}));
            this.hideLoading();
            this.fn_getNotificationCount();
            window.js_globals.fn_onBackPress = this.fn_goBack;
        }
    }
</script>