<template>
    <div full-height>
        <v-toolbar flat fixed>

            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title class="ml-5">Support Ticket <!-- <span v-if="displayData">({{displayData.TicketNumber}})</span> --></v-toolbar-title>

        </v-toolbar>

        <div class="hastoolbar hasfooter grey-back" full-height v-if="displayData">

            <v-card flat class="mx-auto">

                 <v-list three-line subheader full-width>

                    <v-list-item style="padding-right:0">
                        <v-list-item-content>
                          <v-list-item-title>{{displayData.Title}}</v-list-item-title>
                          <v-list-item-subtitle>Ticket No: <span class="primary-color">#{{displayData.TicketNumber}}</span></v-list-item-subtitle>
                          <v-list-item-subtitle class="primary-color" @click="sheet=true">View Details</v-list-item-subtitle>
                          <!-- <v-list-item-subtitle>
                              {{displayData.RequestType==1 ? 'Login Issue' : displayData.RequestType==2 ? 'Registration Issue' : 'Data Issue'}}
                          </v-list-item-subtitle> -->
                          <!-- <v-list-item-subtitle>{{displayData.CreatedOn | moment("DD MMM, YYYY h:mm a")}}</v-list-item-subtitle> -->
                        </v-list-item-content>

                        <v-list-item-action>
                            <div :class="$root.fn_statusBackgroundClass(displayData.Status)" style="color: #fff;padding: 2px 8px;border-top-left-radius: 4px;border-bottom-left-radius: 4px;font-size: 0.875rem;">
                                <!-- {{displayData.Status == 1 ? 'Pending' : displayData.Status == 2 ? 'In Process' : 'Resolved'}} -->
                                {{$root.fn_ticketStatus(displayData.Status)}}
                            </div>
                        </v-list-item-action>
                    </v-list-item>

                </v-list>

            </v-card>

           <!--  <v-card flat class="mx-auto mt-3">
                <v-list two-line subheader full-width>
                    <v-subheader white-background>Business Details</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{displayData.Business.BusinessName}}</v-list-item-title>
                          <v-list-item-subtitle>{{displayData.Business.State}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card> -->

            <div class="mt-3 conversation">
                <!-- <v-subheader white-background>Conversation</v-subheader>
                <v-divider></v-divider> -->
                <div v-for="(conversation, index) in displayData.SupportTicketConversations" :key="index" :class="{'margin-top-ten':index!=0}">
                    <v-list two-line subheader full-width>
                        <v-list-item avatar :id="'item_'+(index+1)">
                            <v-list-item-avatar :class="{'grey-back':!conversation.FromSupport}">
                                <div v-if="!conversation.FromSupport">
                                    <img style="width:100%" :src="$root.currentBusinessLogo" v-if="$root.currentBusinessLogo" class="logo-img"/>
                                    <span v-else>{{abbreviatedBusinessName}}</span>
                                </div>
                                
                                <v-img style="width:100%" src="../../../assets/BizOpslogo_Updated.svg" v-else></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{!conversation.FromSupport ? displayData.Business.BusinessName : 'BizOps App Support Team'}}</v-list-item-title>
                                <v-list-item-subtitle>
                                    {{conversation.CreatedOn | moment("DD MMM, YYYY h:mm a")}}
                                    <template v-if="conversation.FeedbackType!=null">
                                        | <span class="primary-color">Feedback</span>
                                    </template>
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action v-if="conversation.ConversationType==1">
                                <v-icon :color="conversation.FeedbackType ? 'green' : 'red'">{{conversation.FeedbackType ? 'thumb_up' : 'thumb_down'}}</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>

                    <!-- <v-card flat class="mx-auto" :class="{'my-message':!conversation.FromSupport}"> -->
                    <v-card flat class="mx-auto message-card">

                        <v-card-text style="padding-top:0">
                            <div v-html="conversation.Message"></div>
                            <!-- <div><span v-if="conversation.FeedbackType != null" class="primary-color"><strong>Feedback</strong> | </span>{{conversation.CreatedOn | moment("DD MMM, YYYY h:mm a")}}</div> -->
                            <!-- <div><span v-if="conversation.FeedbackType != null" class="primary-color">Feedback</span></div> -->
                            <div class="primary-color" v-ripple @click="fn_openDialogAttachments(conversation.Attachments)" v-if="conversation.Attachments">
                                <v-icon>attachment</v-icon>&nbsp;<u>View Attachments</u>
                            </div>
                        </v-card-text>

                    </v-card>

                    <!-- <v-divider v-if="displayData.SupportTicketConversations.length-1 != index"></v-divider> -->
                </div>  

                <!-- <v-btn color="primary" dark fixed bottom right fab @click="fn_newMessage" v-if="!displayData.IsFeedback">
                    <v-icon>{{displayData.Status == 3 ? 'feedback' : 'add_comment'}}</v-icon>
                </v-btn> -->
            </div>  

            <v-bottom-sheet v-model="sheet">
              <v-sheet>
                <v-card flat class="mx-auto">

                    <v-subheader white-background style="border-bottom:1px #ddd solid">Details <v-spacer></v-spacer> <v-btn icon @click="sheet=false"><v-icon>close</v-icon></v-btn> </v-subheader>
                    <v-card-text style="padding:0px;margin:0">

                        <v-layout ma-0 row wrap class="pt-3 pb-3 pl-3 pr-3">
                            <v-flex xs6 sm6 md6 pl-3>Title</v-flex> 
                            <v-flex xs6 sm6 md6 pr-3 right-align>{{displayData.Title}}</v-flex>

                            <v-flex xs6 sm6 md6 pl-3>Ticket No.</v-flex> 
                            <v-flex xs6 sm6 md6 pr-3 right-align>#{{displayData.TicketNumber}}</v-flex>

                            <v-flex xs6 sm6 md6 pl-3>Issue Type</v-flex> 
                            <v-flex xs6 sm6 md6 pr-3 right-align>{{$root.fn_requestType(displayData.RequestType)}}</v-flex>

                            <v-flex xs6 sm6 md6 pl-3>Created Date</v-flex> 
                            <v-flex xs6 sm6 md6 pr-3 right-align>{{displayData.CreatedOn | moment("DD MMM, YYYY h:mm a")}}</v-flex>
                        </v-layout>
                    </v-card-text>

                </v-card>
              </v-sheet>
            </v-bottom-sheet>  

            <v-footer style="position:fixed;bottom:0;width:100%;padding:0" v-if="!displayData.IsFeedback">
                <v-layout ma-0 row wrap>
                    <v-flex xs12 sm12 md12 style="height:48px;">
                        <v-btn style="height:100%" color="primary" block @click="fn_newMessage">
                            {{displayData.Status == 3 ? 'Submit Feedback' : 'Add Message'}}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-footer>  

           <!--  <v-footer style="position:fixed;bottom:0;width:100%; padding-top:0;padding-bottom:0">
                <v-layout row wrap>
                    <v-list full-width nopadding>
                        <v-list-item>
                            <v-list-item-action>
                                <v-icon color="primary">add_comment</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title class="primary-color">Add Message</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon class="primary-color">chevron_right</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-layout>
            </v-footer> -->
            
        </div>

        <div class="hastoolbar" v-else>
            <v-progress-linear indeterminate :height="4" color="primary"></v-progress-linear>
        </div>

        <dialog-attachments ref="ref_dialogAttachments" :attachments="attachedFiles" :downloadAttachment="fn_downloadAttachment"></dialog-attachments>

        <v-snackbar v-model="snackbar" :timeout="0">
            <v-btn icon color="white" @click="snackbar=false" nomargin>
              <v-icon>close</v-icon>
            </v-btn>
            Download Complete
            <v-btn text color="primary" small @click="fn_openFile" class="mr-1">Open</v-btn>
        </v-snackbar>

        <v-snackbar v-model="snackbar_restore" :timeout="0">
            <v-btn icon color="white" @click="snackbar_restore=false" nomargin>
              <v-icon>close</v-icon>
            </v-btn>
            Download Complete!
            <v-btn text color="primary" small @click="fn_restoreDatabase" class="mr-1">Restore</v-btn>
        </v-snackbar>

        <v-snackbar v-model="$root.snackbarMessageObj.value" right top>
          {{$root.snackbarMessageObj.message}}
          <template v-slot:action="{ attrs }">
            <v-btn icon color="white" v-bind="attrs" text @click.native="$root.snackbarMessageObj.value=false">
                <v-icon>close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>

        <dialog-progress ref="ref_dialogProgress" :status="buffer_size" :progressMessage="progressMessage" :abortRequest="fn_cancelDownload" :cancelInit="cancelInit"></dialog-progress>

        <snackbar-retry :value="snackbarRetry" message="Failed to load ticket!" :action="fn_getToken"></snackbar-retry>

        <dialog-permission ref="ref_dialogPermission"></dialog-permission>
    </div>
</template>

<script>
import axios from 'axios';
import dialogAttachments from './dialogs/dialog_attachments'
import dialogProgress from '../../dialogs/dialog_progress'
import dialogPermission from '../../dialogs/dialog_permission';

import snackbarRetry from '../../dialogs/snackbar_retry'

    export default{
        name:'user_ticket_display',
        data(){
            return{
                displayData:null,
                attachedFiles:null,
                snackbar: false,
                snackbar_restore:false,
                currentFileObj:null,
                buffer_size:0,
                progressMessage:null,
                cancelInit:false,
                snackbarRetry:false,
                abbreviatedBusinessName:null,
                sheet:false
            }
        },
        components:{
            'dialog-attachments':dialogAttachments,
            'dialog-progress':dialogProgress,
            'snackbar-retry': snackbarRetry,
            'dialog-permission': dialogPermission,
        },
        methods:{

            fn_openFile: function(){
                this.snackbar = false;
                this.openDownloadedFile()
            },

            fn_openDialogAttachments: function(attachments){
                this.attachedFiles = attachments;
                this.$refs['ref_dialogAttachments'].openDialogWeb();
            },
            
            fn_newMessage: function(){
                let feedbackVal = this.displayData.Status == 3 ? 1 : 0;
                // let feedbackVal = 1;
                this.$router.push({path:"/support/appsupport/tickets/"+this.displayData.SupportTicketID+"/message", query:{"businessid":this.$route.query.businessid, "isfeedback":feedbackVal, "ticketFolderId":this.displayData.TicketFolderID}});
            },
            
            fn_getToken: function(){
                let self = this;
                this.snackbarRetry = false;

                this.showLoading('Please wait...');
                this.$root.fn_getAuthToken(function(token){
                    if(token == 'error'){
                        self.hideLoading();
                        self.snackbarRetry = true;
                        return;
                    }

                    self.fn_getTicket(token);
                });
            },

            fn_getTicket: function(token){
                var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                // axios.get(process.env.VUE_APP_BASE_API_URL + '/api/business/'+this.$route.query.businessid+'/User/tickets/'+this.$route.params.ticketid, headersObj, {timeout:30000})
                axios.get(process.env.VUE_APP_BASE_API_URL + '/api/business/'+this.$route.query.businessid+'/Support/tickets/'+this.$route.params.ticketid, headersObj, {timeout:30000})
                .then(response => {
                    this.hideLoading();
                    console.log(response);
                    console.log(JSON.stringify(response.data));
                    this.displayData = response.data;
                    if(this.displayData.SupportTicketConversations){
                        for(let i=0; i<this.displayData.SupportTicketConversations.length; i++){
                            if(this.displayData.SupportTicketConversations[i].Attachments){
                                this.displayData.SupportTicketConversations[i].Attachments = JSON.parse(this.displayData.SupportTicketConversations[i].Attachments)
                            }
                        }
                    }

                    let words = this.displayData.Business.BusinessName.split(' ');

                    if(words.length > 1){
                        this.abbreviatedBusinessName = words[0][0].toUpperCase() + words[1][0].toUpperCase();    
                    }
                    else{
                        this.abbreviatedBusinessName = words[0][0].toUpperCase();
                    }

                    // this.abbreviatedBusinessName = this.displayData.Business.BusinessName[0].toUpperCase() + this.displayData.Business.BusinessName[1].toUpperCase();


                    let self = this;
                    setTimeout(function(){
                        self.$vuetify.goTo('#item_'+self.displayData.SupportTicketConversations.length, self.scrollOptions);
                    },100);
                    
                }).catch(e => {
                    this.hideLoading();
                    // this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    this.snackbarRetry = true;
                    console.log('some');
                    console.log(e);
                })
            },

            fn_openDialogProgress: function(){
                this.$refs['ref_dialogProgress'].openDialogWeb();
            },

            fn_closeDialogProgress: function(){
                this.$refs['ref_dialogProgress'].closeDialogWeb();
            },

            fn_setStatus: function(value){
                console.log(value);
                this.buffer_size = value;
            },

            fn_downloadAttachment: function(fielObj){
                if(!navigator.onLine){
                    this.$root.fn_showToastMsg('Internet not available.');
                    return;
                }

                if(this.$root.platform() == 'iframe'){
                    this.fn_downloadAttachmentIframe(fielObj);
                }
                else{
                    let self = this;
                    this.$root.fn_checkStoragePermission(function(){
                        self.currentFileObj = fielObj;
                        fielObj.fileType == 1 ? self.fn_downloadFile() : self.fn_downloadDatabase();
                    })
                }
            },

            fn_downloadAttachmentIframe: function(fielObj){
                if(!navigator.onLine){
                    this.$root.fn_showToastMsg("Internet not available!");
                    return;
                }

                if(fielObj.fileType==1){
                    this.$refs['ref_dialogAttachments'].closeDialogWeb();
                    this.progressMessage = "Downloading...";
                    this.fn_openDialogProgress();
                    let self = this;

                    this.xhrDownloadRequest = new XMLHttpRequest();
                        this.xhrDownloadRequest.responseType = 'blob';
                        this.xhrDownloadRequest.onload = function(event) {
                        console.log(event);
                        var downloadUrl = URL.createObjectURL(self.xhrDownloadRequest.response);
                        var a = document.createElement("a");
                        a.href = downloadUrl;
                        a.download = fielObj.fileName;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();

                        self.xhrDownloadRequest = null;
                        setTimeout(function(){
                            self.fn_closeDialogProgress();
                        },1000);
                        };

                        this.xhrDownloadRequest.onprogress = function(event) {
                        console.log(`Downloaded ${event.loaded} of ${event.total} bytes`);
                        let percent = (event.loaded/event.total)*100
                        console.log(percent);
                        self.fn_setStatus(percent);
                    }

                    this.xhrDownloadRequest.onabort = function(){
                        self.cancelInit = false;
                        self.xhrDownloadRequest = null;
                        self.fn_closeDialogProgress();
                        self.$root.fn_showToastMsg("Downloading cancelled!");
                    }

                    this.xhrDownloadRequest.open('GET', fielObj.filePath);
                    this.xhrDownloadRequest.send();
                }
                else{
                    // this.$root.fn_showToastMsg("Data restore action is not supported in Desktop version!");
                    alert("Data restore action is not supported in Desktop version!");
                }
            },

            fn_downloadFile: function(){
                let self = this;
                this.progressMessage = "Downloading...";
                this.fn_openDialogProgress();

                let postObj = {};
                postObj.fileName = this.currentFileObj.fileName;
                // postObj.filePath = this.currentFileObj.filePath;
                // postObj.filePath = "/tickets/"+this.displayData.BusinessID+"/"+this.displayData.TicketFolderID+"/"+this.displayData.TicketMessageFolderID+"/"+this.currentFileObj.fileName;
                postObj.filePath = "/tickets/"+this.displayData.BusinessID+"/"+this.displayData.TicketFolderID+"/"+this.currentFileObj.messageFolderId+"/"+this.currentFileObj.fileName;

                this.postData("DownloadFirebaseFile", postObj, 
                  {
                      onSuccess: function(response){
                          console.log(response);
                          self.snackbar = true;
                          self.$refs['ref_dialogAttachments'].dialog_attachments = false;
                          self.fn_closeDialogProgress();
                      },
                      onError: function(error){
                          console.log(error);
                          self.fn_closeDialogProgress();
                          self.$root.fn_showToastMsg("Something went wrong. Try again!");
                      }
                  }
                );
            },

            fn_downloadDatabase: function(){
                this.progressMessage = "Downloading...";
                this.fn_openDialogProgress();

                let filterObject = {};
                filterObject.fileName = this.currentFileObj.fileName;
                // filterObject.filePath = this.currentFileObj.filePath;
                filterObject.filePath = "/tickets/"+this.displayData.BusinessID+"/"+this.currentFileObj.ticketFolderId+"/"+this.currentFileObj.messageFolderId+"/"+this.currentFileObj.fileName;

                let self = this;
                this.getFilterData('DownloadFirebaseDatabaseFile', null, filterObject, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.fn_closeDialogProgress();
                            self.snackbar_restore = true;
                        }, 
                        onError: function(error){
                            console.log(error);
                            self.fn_closeDialogProgress();
                            self.$root.fn_showToastMsg("Something went wrong. Try again!");
                        }
                    }
                );
            },

            fn_cancelDownload: function(){
                let self = this;
                this.progressMessage = "Cancel Download...";
                this.cancelInit = true;
                this.getData('CancelDownload', null, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.cancelInit = false;
                            self.$root.fn_showToastMsg("Downloading cancelled!");
                            self.fn_closeDialogProgress();
                        }, 
                        onError: function(error){
                            self.cancelInit = false;
                            self.fn_closeDialogProgress();
                            self.$root.fn_showToastMsg("Failed to cancel downloading!");
                            console.log(error);
                        }
                    }
                );
            },

            fn_restoreDatabase: function(){
                let self = this;
                this.snackbar_restore = false;
                this.showLoading('Restore in Process...');

                let postObj = {};
                postObj.businessId = this.$route.query.businessid;
                postObj.fileName = this.currentFileObj.fileName;
                postObj.overwriteBusiness = true;

                this.postData("OverwriteRestoreBusiness", postObj, 
                  {
                      onSuccess: function(response){
                            console.log(response);
                            self.fn_reopenDB();
                            self.hideLoading();
                            self.$refs['ref_dialogAttachments'].dialog_attachments = false;
                            self.$root.isRestoreDb = true;
                            AndroidRoutingTest.dbRestored('true');
                      },
                      onError: function(error){
                          console.log(error);
                          if(error=="Database not compatible"){
                            self.$root.fn_showToastMsg("This database is not compatible!");  
                          }
                          else{
                            self.$root.fn_showToastMsg("Something went wrong. Try again!");  
                          }
                          self.hideLoading();
                      }
                  }
                );
            },  

            fn_reopenDB: function(){
                this.getData('ReopenDataBase', this.$route.query.businessid, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                        }, 
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
            },

            fn_backToMainApp: function(){
                this.getData('StartMainApp', null, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                        }, 
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
            },

            fn_goBack: function(){
                if(this.$route.query.notificationType || this.$route.query.unread){
                    this.fn_backToMainApp();
                }
                else{
                    this.$router.go(-1);
                }
            },

            fn_openPermissionDialog: function(permissionType){
                this.$root.permissionType = permissionType;
                this.$refs['ref_dialogPermission'].dialog_permission = true;
            },
        },
        mounted: function(){
            if(this.$route.query.notificationType || this.$route.query.unread){
                /*global AndroidRoutingTest*/
                AndroidRoutingTest.onPageLoadCompleted('1', JSON.stringify({}));
                this.hideLoading();
            }
            this.fn_getToken();
            window.js_globals.fn_onBackPress = this.fn_goBack;
            window.js_globals.fn_setStatus = this.fn_setStatus;
            window.js_globals.fn_openPermissionDialog = this.fn_openPermissionDialog;
        },
        beforeRouteLeave(to, from, next){
            if(this.$refs['ref_dialogAttachments'].dialog_attachments || this.$refs['ref_dialogPermission'].dialog_permission || this.snackbar || this.snackbar_restore || this.sheet){
                this.$refs['ref_dialogAttachments'].dialog_attachments = false;
                this.$refs['ref_dialogPermission'].dialog_permission = false;
                this.snackbar = false;
                this.snackbar_restore = false;
                this.sheet = false;
                next(false);
            }
            else{
                next();
            }
        }
    }
</script>