<template>
    <div>
        <v-dialog v-model="dialog_confirmation" persistent max-width="290" transition="slide-y-transition">
           <v-card>
                <v-card-title class="title noshadow">
                    {{header}}
                    <!-- <v-icon v-if="icon" :class="[iconClass ? iconClass : '']">{{icon}}</v-icon> -->
                    <!-- <span :class="[icon ? 'ml-4' : '']">{{header}}</span> -->
                </v-card-title>
                <v-card-text class="pt-0" v-html="confirmationMessage"></v-card-text>
                <v-divider></v-divider>
                <v-card-actions style="padding:6px 0px;">
                    <v-layout row wrap>
                        <v-flex xs6 sm6 md6 center-align style="border-right:1px #ddd solid">
                            <v-btn small font-heavy capitalize dark-opacity-color text @click="closeDialogWeb">No</v-btn>
                        </v-flex>
                        <v-flex xs6 sm6 md6 center-align>
                            <v-btn small font-heavy capitalize class="primary-color" text @click="actionMethod('deletedraft')">Yes</v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>     

<script>

export default{
    props:['header', 'confirmationMessage', 'actionMethod'],
    data(){ 
        return{
            dialog_confirmation: false
        }
    },
    methods: {
        openDialogWeb: function () {    
            this.dialog_confirmation = true;
        },
        closeDialogWeb: function () {
            this.dialog_confirmation = false;
        },
    }
}
</script>