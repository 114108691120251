<template>
    <div full-height>
        <v-toolbar flat fixed>

            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title style="line-height:20px" class="ml-5">
                {{ $route.query.reporttype == 'daywise' ? 'Business Register Day Wise Report' : 'Business Register Month Wise Report' }}
                <!-- <div font12>{{selectedUserId ? fn_getSelectedUserName() : 'Select User'}}&nbsp;<v-icon>arrow_drop_down</v-icon></div> -->
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn outlined capitalize small @click="monthPicker=true" v-if="$route.query.reporttype == 'monthwise'">
                {{ dateType != 'month' ? 'Select Month' : selectedStartDate | moment("MMM, YYYY") }}
                <v-icon>arrow_drop_down</v-icon>
            </v-btn>

            <!-- <v-spacer></v-spacer>

            <v-btn color="primary" icon @click="filtersheet = true">
                <v-icon>filter_list</v-icon>
            </v-btn> -->

        </v-toolbar>

        <div class="hastoolbar hasfooter" full-height>

            <v-subheader v-if="$route.query.reporttype == 'monthwise'" style="height:40px" @click="fn_openDialogPeriod">
                Period: {{dateFrom | moment("DD/MM/YYYY")}} To {{dateTo | moment("DD/MM/YYYY")}}
                <v-spacer></v-spacer>
                <v-icon>date_range</v-icon>
            </v-subheader>

            <v-menu offset-y v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark text v-bind="attrs" v-on="on" class="datepickerbtn"><v-icon>date_range</v-icon>&nbsp;Period<span v-show="dateFrom && dateTo">:&nbsp;</span> {{dateFrom | moment("DD/MM/YYYY")}}<span v-show="dateFrom && dateTo">&nbsp;To&nbsp;</span>{{dateTo | moment("DD/MM/YYYY")}}</v-btn>
                </template>
                <v-list>
                  <v-list-item @click="fn_getLatestData('Today')">
                    <v-list-item-title>Today</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Yesterday')">
                    <v-list-item-title>Yesterday</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 3 days')">
                    <v-list-item-title>Last 3 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 7 days')">
                    <v-list-item-title>Last 7 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 15 days')">
                    <v-list-item-title>Last 15 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 30 days')">
                    <v-list-item-title>Last 30 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_openDialogPeriod">
                    <v-list-item-title>Custom date range</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn outlined rounded @click="fn_clearDateFilter" v-if="dateFrom && dateTo && $route.query.reporttype == 'daywise'" dark text class="clearfilterbtn">Clear Filter <v-icon class="pl-1" size="20">close</v-icon></v-btn>

            <v-divider></v-divider>

            <template v-if="reportData">

                <template v-if="$route.query.reporttype == 'daywise'">
                    <div class="table-wrapper" style="width:100%;font-size:10px">
                        <table style="width:100%">
                            
                            <thead>
                                <tr class="primarybackground" style="color:#fff">
                                    <td class="pa-1">Date</td>
                                    <td class="pa-1">Total Registration</td>
                                    <td class="pa-1">Registered</td>
                                    <td class="pa-1">Unregistered</td>
                                    <td class="pa-1">Composite</td>
                                    <td class="pa-1">DeActive</td>
                                    <td class="pa-1">First Transaction Created</td>
                                    <td class="pa-1">First Invoice Created</td>
                                    
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(item, index) in reportData.data.DaywiseDetails" :key="index">
                                    <td>{{item.Date | moment("DD MMM, YYYY")}}</td>
                                    <td>{{item.Count}}</td>
                                    <td>{{item.Registered}}</td>
                                    <td>{{item.Unregistered}}</td>
                                    <td>{{item.Composite}}</td>
                                    <td>{{item.DeActive}}</td>
                                    <td>{{item.FirstTransactionCreatedCount}}</td>
                                    <td>{{item.FirstInvoiceCreatedCount}}</td>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr grey-back>
                                    <th class="pa-1 left-align">Total</th>
                                    <th class="pa-1 left-align">{{reportData.data.Total}}</th>
                                    <th class="pa-1 left-align">{{subTotlalofRegistered}}</th>
                                    <th class="pa-1 left-align">{{subTotlalofUnregistered}}</th>
                                    <th class="pa-1 left-align">{{subTotlalofComposite}}</th>
                                    <th class="pa-1 left-align">{{subTotlalofDeActive}}</th>
                                    <th class="pa-1 left-align">{{subTotlalofFirstTransactionCreatedCount}}</th>
                                    <th class="pa-1 left-align">{{subTotlalofFirstInvoiceCreatedCount}}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                    <div class="pa-4" center-align style="font-size:10px" v-if="!reportData.data.DaywiseDetails.length">
                        No record found!
                    </div>
                </template>

                <template v-if="$route.query.reporttype == 'monthwise'">
                    <div class="table-wrapper" style="width:100%;font-size:10px">
                        <table style="width:100%">
                            
                            <thead>
                                <tr class="primarybackground" style="color:#fff">
                                    <td class="pa-1">Month</td>
                                    <td class="pa-1">Registration Count</td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(item, index) in reportData.data.MonthwiseDetails" :key="index">
                                    <td>{{item.MonthName}}, {{ item.Year }}</td>
                                    <td>{{item.Count}}</td>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr grey-back>
                                    <th class="pa-1 left-align">Total</th>
                                    <th class="pa-1 left-align">{{reportData.data.Total}}</th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                    <div class="pa-4" center-align style="font-size:10px" v-if="!reportData.data.MonthwiseDetails.length">
                        No record found!
                    </div>
                </template>
            </template>
            

        </div>

        <dialog-period-selection ref="ref_dialogCustomPeriod" title="Select Period" :openDatePicker="fn_openDatePicker" :startDate="selectedStartDate" :endDate="selectedEndDate" :actionMethod="fn_updateCustomDates"></dialog-period-selection>

        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

        <!-- <v-bottom-sheet hide-overlay v-model="filtersheet">
            <v-subheader style="background:#fff">
                Filter Report<v-spacer></v-spacer>
                <v-btn icon @click="filtersheet = false">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-subheader>
            <v-divider></v-divider>
            <div class="pa-3" style="background:#fff">

                <v-select
                  v-model="ticketStatus"
                  :items="ticketStatuses"
                  item-text="name"
                  item-value="status"
                  label="Ticket Status"
                  placeholder=" "
                  return-object
                  @change="fn_filterReport"
                  clearable
                ></v-select>

            </div>
        </v-bottom-sheet>

        <v-dialog v-model="dialog_users" scrollable persistent max-width="280px" no-click-animation transition="slide-y-transition">
            <v-card v-if="userData">
                <v-card-title>Select User</v-card-title>
                <v-card-text nopadding>
                    <v-list>
                        <v-list-item v-for="(item, index) in userData.data" :key="index" @click="fn_setUser(item.UserID)">
                            <v-list-item-title>{{item.Name}}</v-list-item-title>
                            <v-list-item-action>
                              <v-icon v-if="item.UserID == selectedUserId">radio_button_checked</v-icon>
                              <v-icon v-else>radio_button_unchecked</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small font-heavy capitalize class="primary-color" text @click="dialog_users = false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
            
        </v-dialog> -->

        <dialog-loading :loading="loading"></dialog-loading>

        <v-dialog ref="ref_monthpicker" no-click-animation v-model="monthPicker" :return-value.sync="selectedMonth" persistent lazy full-width width="290px" transition="slide-y-transition">
            <v-date-picker v-model="selectedMonth" scrollable type="month">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="monthPicker = false">Cancel</v-btn>
                <v-btn text color="primary" @click="fn_saveMonth(selectedMonth)">OK</v-btn>
            </v-date-picker>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios';

import dialogPeriodSelection from '../../tickets/dialogs/dialog_period_selection'
import dialogDatepicker from '../../tickets/dialogs/dialog_datepicker'
import dialogLoading from '../../../dialogs/dialog_loading.vue'

    export default{
        data(){
            return{
                reportData:null,
                selectedStartDate:this.$root.minDateSelection,
                selectedEndDate:this.$root.maxDateSelection,
                dateFrom:this.$root.minDateSelection,
                dateTo:this.$root.maxDateSelection,
                dateType:null,
                selectedDate:null,
                dialog_datepicker:false,

                loadingObj:{
                    loading:false,
                    message:"Please wait..."    
                },

                loading:false,

                // months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                selectedMonth:new Date().toISOString().substring(0, 7),
                monthPicker:false,
                subTotlalofRegistered: 0,
                subTotlalofUnregistered: 0,
                subTotlalofComposite: 0,
                subTotlalofDeActive: 0,
                subTotlalofFirstTransactionCreatedCount: 0,
                subTotlalofFirstInvoiceCreatedCount: 0
            }
        },
        components: {
            'dialog-period-selection':dialogPeriodSelection,
            'dialog-datepicker':dialogDatepicker,
            'dialog-loading':dialogLoading
        },
        methods:{

            fn_openDialogPeriod: function(){
                this.selectedStartDate = this.dateFrom;
                this.selectedEndDate = this.dateTo;
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = true;
            },

            fn_openDatePicker: function (type, date) {
                let self = this;
                this.dateType = type;
                let dateObj = date ? new Date(date) : new Date();
                this.$refs['ref_dialogDatePicker'].selectedDate = dateObj.getFullYear()+'-'+("0" + (dateObj.getMonth()+1)).slice(-2)+'-'+("0" + dateObj.getDate()).slice(-2);
                setTimeout(function () {
                    self.dialog_datepicker = true;
                })
            },

            fn_updateDate: function (value) {
                if(this.dateType=="start"){
                    this.selectedStartDate = value;
                }
                else if(this.dateType=="end"){
                    this.selectedEndDate = value;
                }

                this.dialog_datepicker = false;
            },

            fn_updateCustomDates: function(type){
                this.dateFrom = this.selectedStartDate;
                this.dateTo = this.selectedEndDate;
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = false;
                this.fn_getToken(type);
            },

            fn_saveMonth: function (value) {
                this.$refs.ref_monthpicker.save(value);
                let date = new Date(value);
                this.selectedStartDate = new Date(date.getFullYear(), date.getMonth(), 1);
                this.selectedEndDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                this.fn_updateCustomDates('month')
            },

            fn_getToken: function(type){
                let self = this;
                this.loading = true;
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){
                    if(token == 'error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        this.loading = false;
                        self.$root.fn_showToastMsg("Something went wrong. Try again!!");
                        return;
                    }

                    self.fn_getReport(token, type);
                });
            },

            fn_getReport: function(token, type){
                var headersObj = {
                    headers: { 
                      'Authorization': 'bearer '+token,
                    }
                };

                let postJson = {
                    "DateFrom":this.dateFrom, 
                    "DateTo":this.dateTo, 
                }

                if(this.dateFrom){
                    postJson.DateFrom = this.$moment(postJson.DateFrom).startOf('day');   
                    postJson.DateFrom = this.fn_getLocalDateFormat(postJson.DateFrom); 
                }

                if(this.dateTo){
                    postJson.DateTo = this.$moment(postJson.DateTo).endOf('day');
                    postJson.DateTo = this.fn_getLocalDateFormat(postJson.DateTo);
                }

                console.log(JSON.stringify(postJson));

                this.subTotlalofRegistered = 0;
                this.subTotlalofUnregistered = 0;
                this.subTotlalofComposite = 0;
                this.subTotlalofDeActive = 0;
                this.subTotlalofFirstTransactionCreatedCount = 0;
                this.subTotlalofFirstInvoiceCreatedCount = 0;

                axios.post(process.env.VUE_APP_BASE_API_URL + "/api/supportteam/reports/businessregistration/"+this.$route.query.reporttype, postJson, headersObj, {timeout:30000})
                .then(response => {
                    console.log(JSON.stringify(response));
                    this.reportData = response;
                    if(this.reportData.data && this.reportData.data.DaywiseDetails){
                        this.reportData.data.DaywiseDetails.forEach(item => {
                            this.subTotlalofRegistered += item.Registered;
                            this.subTotlalofUnregistered += item.Unregistered;
                            this.subTotlalofComposite += item.Composite;
                            this.subTotlalofDeActive += item.DeActive;
                            this.subTotlalofFirstTransactionCreatedCount += item.FirstTransactionCreatedCount;
                            this.subTotlalofFirstInvoiceCreatedCount += item.FirstInvoiceCreatedCount;
                        });
                    }
                    
                    this.loading = false;
                    this.$root.fn_hideLoading(this.loadingObj);
                    type ? this.dateType = type : ''
                }).catch(e => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    this.loading = false;
                    console.log(JSON.stringify(e));
                })
            },

            // fn_getUsers: function(){

            //     this.$root.fn_showLoading('Getting Users...', this.loadingObj);

            //     let self = this;
            //     this.$root.fn_getAuthToken(function(token){

            //         if(token == 'error'){
            //             self.$root.fn_hideLoading(self.loadingObj);
            //             self.$root.fn_showToastMsg("Something went wrong. Try again!!");
            //             return;
            //         }

            //         var headersObj = {
            //         headers: { 
            //               'Authorization': 'bearer '+token,
            //             }
            //         };

            //         axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/reports/users/getlist', headersObj, {timeout:30000})
            //         .then(response => {
            //             console.log(response);
            //             JSON.stringify(response);
            //             self.userData = response;
            //             self.$root.fn_hideLoading(self.loadingObj);
            //             self.dialog_users = true;

            //             // if (self.user.count > self.user.items.length) {
            //             //     for(let i=0; i<response.data.Users.length; i++){
            //             //         self.user.items.push(response.data.Users[i]);
            //             //     }
            //             //     $state.loaded();
            //             // } else {
            //             //     $state.complete();
            //             // }

            //             // self.offset = self.user.items.length;

            //         }).catch(e => {
            //             console.log(e);
            //             self.$root.fn_showToastMsg("Something went wrong. Try again!");
            //             self.$root.fn_hideLoading(self.loadingObj);
            //         })
            //     })
            // },

            // fn_getSelectedUserName: function(){
            //     for(let i in this.userData.data){
            //         if(this.userData.data[i].UserID == this.selectedUserId){
            //             return this.userData.data[i].Name;
            //         }
            //     }
            // },

            fn_goBack: function () {
                this.$router.go(-1);
            },

            fn_getLatestData: function(type){
              var currentDate = new Date();
              var year = currentDate.getFullYear();
              var month = String(currentDate.getMonth() + 1).padStart(2, '0');
              var day = String(currentDate.getDate()).padStart(2, '0');
              var formattedStartDate = `${year}-${month}-${day}`;
              this.dateTo = formattedStartDate;


              var startDate = new Date();
              if(type == 'Yesterday')
                startDate.setDate(startDate.getDate() - 1);
              else if(type == 'Last 3 days')
                startDate.setDate(startDate.getDate() - 3);
              else if(type == 'Last 7 days')
                startDate.setDate(startDate.getDate() - 7);
              else if(type == 'Last 15 days')
                startDate.setDate(startDate.getDate() - 15);
              else if(type == 'Last 30 days')
                startDate.setDate(startDate.getDate() - 30);
              var endDateYear = startDate.getFullYear();
              var endDateMonth = String(startDate.getMonth() + 1).padStart(2, '0');
              var endDateDay = String(startDate.getDate()).padStart(2, '0');
              var formattedEndDate = `${endDateYear}-${endDateMonth}-${endDateDay}`;
              console.log('Start Date : ' + formattedStartDate);
              console.log('End Date : ' + formattedEndDate);
              this.dateFrom = formattedEndDate;
              this.fn_getToken();

            },

            fn_clearDateFilter: function(){
              this.dateFrom = null;
              this.dateTo = null;
              this.fn_getToken();
            }

        },
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
            // this.fn_getUsers();
            if(this.$route.query.reporttype == 'daywise')
                this.fn_getLatestData('Last 7 days')
            else
                this.fn_getToken();
        },
        beforeRouteLeave(to, from, next){
            if(this.filtersheet){
                this.filtersheet = false;
                next(false);
            }
            else if(this.dialog_datepicker){
                this.dialog_datepicker = false;
                next(false);
            }
            else if(this.$refs['ref_dialogCustomPeriod'].dialog_period_selection){
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = false;
                next(false);
            }
            else{
                next();
            }
        }
    }
</script>

<style>
    .table-wrapper table tbody tr:nth-child(even){
        background: #f0f0f0 !important;
    }
    .table-wrapper table thead tr th,
    .table-wrapper table tbody tr td{
        padding:4px;
    }
</style>