<template>
    <div full-height>
        <v-toolbar flat fixed v-if="$root.platform()=='android'">
            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
            <v-toolbar-title class="ml-5">Create Ticket</v-toolbar-title>
        </v-toolbar>

        <div class="hasfooter" :class="[$root.platform()=='android' ? 'hastoolbar' : 'pt-3 desktop-view']">
            <v-form class="form-element">

                <v-layout align-center nomargin padding-top-ten row wrap white-background>
                    <v-flex xs12 sm12 md12 form-item>
                       <v-text-field  
                            v-model="Form.PhoneNumber" 
                            label="Enter User Phone Number" 
                            type="tel"
                            placeholder=" "
                            clearable
                            prefix="+91"
                            v-validate="'required|numeric|min:10|max:10'"
                            data-vv-name="phoneNumber"  
                            :maxlength="10"
                            required>
                        </v-text-field>
                    </v-flex>
                </v-layout>

            </v-form>

            <v-footer nopadding style="position:fixed;bottom:0;width:100%">
                <v-layout row wrap>
                    <v-flex xs12 sm12 md12 height48>
                        <v-btn color="primary" block full-height noshadow no-round-borders @click="fn_checkValidations">Next</v-btn>
                    </v-flex>
                </v-layout>
            </v-footer>
        </div>  

        <dialog-business-list ref="ref_dialogBusinessList" :businessList="businessList" :newTicket="fn_newTicket"></dialog-business-list>

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios';

import dialogBusinessList from './dialogs/dialog_business_list'

    export default{
        name:'get_business',
        data(){
            return{
                Form:{"PhoneNumber":null},
                businessList:null,
                loadingObj:{
                    loading:false,
                    message:"Please wait..."    
                }
            }
        },
        components:{
            'dialog-business-list':dialogBusinessList
        },
        methods: {

            fn_newTicket: function(userBusinessId){
                this.$refs['ref_dialogBusinessList'].closeDialogWeb();
                this.$router.push({path:"/support/supportteam/tickets/new", query:{"businessid":this.$route.query.businessid, "userbusinessid":userBusinessId}});
            },

            fn_checkValidations: function(){
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        // alert('in'); 
                        let self = this;
                        // this.showLoading('Please wait...');
                        this.$root.fn_showLoading('Please wait...', this.loadingObj);
                        this.$root.fn_getAuthToken(function(token){
                            if(token=='error'){
                              self.$root.fn_hideLoading(self.loadingObj);
                              self.$root.fn_showToastMsg("Something went wrong. Try again!");
                              return;
                            }
                            self.fn_getBusiness(token);
                        })
                    }
                    else{
                        this.$root.fn_showToastMsg("Phone Number not valid!");
                    }
                });
            },

            fn_getBusiness: function(token){

                var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                let postObj = {};
                postObj.PhoneNumber = '91' + this.Form.PhoneNumber;

                axios.post(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/getbusinessids', postObj, headersObj, {timeout:30000})
                .then(response => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    console.log(response);
                    this.businessList = response.data.BusinessList;
                    if(this.businessList.length){
                        this.$refs['ref_dialogBusinessList'].openDialogWeb();    
                    }
                    else{
                        this.$root.fn_showToastMsg("No Business registered on this Phone Number!");
                    }
                    
                }).catch(e => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    console.log(e);
                })
                
            },
           
            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
        },
        beforeRouteLeave(to, from, next){
            if(this.$refs['ref_dialogBusinessList'].dialog_business){
                this.$refs['ref_dialogBusinessList'].dialog_business = false;
                next(false);
            }
            else{
                next();
            }
        }
    }
</script>