<template>
    <div full-height>
        <v-toolbar flat fixed class="pr-0">

            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title class="ml-5">Support Ticket</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn style="border-radius:0; background-color: #4b77be !important; border-color: #4b77be !important;" class="mt-3 mb-3 height48" color="primary" @click="fn_getUserConversationIds" v-if="displayData && !displayData.IsSupportRead">Mark as Read</v-btn>
            <!-- <v-btn style="border-radius:0" class="mt-3 mb-3 height48" color="primary" @click="fn_getUserConversationIds" v-if="displayData && !displayData.IsSupportRead">Mark as UnRead</v-btn> -->

            <!-- <v-btn text icon v-bind="attrs" v-on="on"><v-icon color="primary">more_vert</v-icon></v-btn> -->
            <!--<v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text icon v-bind="attrs" v-on="on"><v-icon color="primary">more_vert</v-icon></v-btn>
              </template>
              <v-list nopadding>
                <v-list-item @click="fn_newMessage(1)">
                  <v-list-item-title>Update Status</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->

        </v-toolbar>

        <div class="hastoolbar hasfooter grey-back" full-height v-if="displayData">

            <v-alert :value="true" type="warning" v-if="!displayData.Business.Status">Business related to this ticket has been deactivated</v-alert>

            <v-card flat class="mx-auto">
                 <v-list three-line subheader full-width>
                    <v-list-item style="padding-right:0">
                        <v-list-item-content>
                          <v-list-item-title>{{displayData.Title}}</v-list-item-title>
                          <v-list-item-subtitle>Ticket No: <span class="primary-color">#{{displayData.TicketNumber}}</span></v-list-item-subtitle>
                          <v-list-item-subtitle class="primary-color" @click="sheet=true">View Details</v-list-item-subtitle>
                          <!-- <v-list-item-subtitle>
                              {{displayData.RequestType==1 ? 'Login Issue' : displayData.RequestType==2 ? 'Registration Issue' : 'Data Issue'}}
                          </v-list-item-subtitle> -->
                          <!-- <v-list-item-subtitle>Created On: {{displayData.CreatedOn | moment("DD MMM, YYYY h:mm a")}}</v-list-item-subtitle>
                          <v-list-item-subtitle>By: {{displayData.CreatedByUser.PhoneNumber}}</v-list-item-subtitle> -->
                        </v-list-item-content>
                        <v-list-item-action>
                            <div :class="$root.fn_statusBackgroundClass(displayData.Status)" style="color: #fff;padding: 2px 8px;border-top-left-radius: 4px;border-bottom-left-radius: 4px;font-size: 0.875rem;">
                                <!-- {{displayData.Status == 1 ? 'Pending' : displayData.Status == 2 ? 'In Process' : 'Resolved'}} -->
                                {{$root.fn_ticketStatus(displayData.Status)}}
                            </div>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card>

            <!-- <v-card flat class="mx-auto mt-3">
                <v-list  three-line subheader full-width>
                    <v-subheader white-background>Business Details</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{displayData.Business.BusinessName}}</v-list-item-title>
                          <v-list-item-subtitle>{{displayData.Business.State}}</v-list-item-subtitle>
                          <v-list-item-subtitle>{{displayData.Business.BusinessProfileNumber}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card> -->

            <div class="mt-3 conversation">
                <!-- <v-subheader white-background>Conversation</v-subheader>
                <v-divider></v-divider> -->
                <div v-for="(conversation, index) in displayData.SupportTicketConversations" :key="index" class="user-support-conversations" :class="{'margin-top-ten':index!=0, 'highlight':!conversation.FromSupport && !displayData.IsSupportRead && !conversation.IsSupportRead && conversation.FeedbackType==null}" :id="'message_'+conversation.SupportTicketConversationID">

                    <v-list two-line subheader full-width>
                        <v-list-item avatar :id="'item_'+(index+1)">
                            <v-list-item-avatar :class="{'grey-back':!conversation.FromSupport}">
                                <div v-if="!conversation.FromSupport">
                                    <img style="width:100%" :src="$root.currentBusinessLogo" v-if="$root.currentBusinessLogo" class="logo-img"/>
                                    <span v-else>{{abbreviatedBusinessName}}</span>
                                </div>
                                
                                <v-img style="width:100%" src="../../../assets/BizOpslogo_Updated.svg" v-else></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <a v-if="!conversation.FromSupport" @click="fn_openDialogBusinessDetails(displayData.BusinessID)">{{!conversation.FromSupport ? displayData.Business.BusinessName : 'BizOps App Support Team'}}</a>
                                    <span v-else>BizOps App Support Team</span>
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{conversation.CreatedOn | moment("DD MMM, YYYY h:mm a")}}
                                    <template v-if="conversation.Status == 2">
                                        | <span class="primary-color">Draft</span>
                                    </template>

                                    <template v-if="conversation.FeedbackType!=null">
                                        | <span class="primary-color">Feedback</span>
                                    </template>
                                </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-menu v-if="conversation.Status==2">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on"><v-icon>more_horiz</v-icon></v-btn>
                                  </template>
                                  <v-list nopadding>
                                    <v-list-item @click="fn_editDraftMessage(conversation)">
                                      <v-list-item-title>Update Message</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="fn_openDialogConfirmation(conversation.SupportTicketConversationID, index)">
                                      <v-list-item-title>Delete</v-list-item-title>
                                    </v-list-item>
                                  </v-list>
                                </v-menu>

                                <div v-if="conversation.FeedbackType!=null"><v-icon :color="conversation.FeedbackType ? 'green' : 'red'">{{conversation.FeedbackType ? 'thumb_up' : 'thumb_down'}}</v-icon></div>

                                <!-- <span class="primary-color">{{item.Status == 2 ? 'Draft' : ''}}</span> -->
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>

                    <!-- <v-card flat class="mx-auto" :id="'message_'+conversation.SupportTicketConversationID" :class="{'my-message':conversation.FromSupport}"> -->
                    <v-card flat class="mx-auto">

                        <v-card-text class="message-text">
                            <div v-html="conversation.Message"></div>
                            <!-- <div><span v-if="conversation.FeedbackType != null" class="primary-color"><strong>Feedback</strong> | </span>{{conversation.CreatedOn | moment("DD MMM, YYYY h:mm a")}}</div>
                            <div v-if="!conversation.FromSupport">By Customer</div> -->
                            <!-- <div><span v-if="conversation.FeedbackType != null" class="primary-color"><strong>Feedback</strong> | </span></div> -->
                            <div class="primary-color" v-ripple @click="fn_openDialogAttachments(conversation.Attachments)" v-if="conversation.Attachments">
                                <span class="link-item">
                                    <v-icon>attachment</v-icon>&nbsp;<u>View Attachments</u>
                                </span>
                            </div>
                        </v-card-text>

                    </v-card>

                    <!-- <v-divider v-if="displayData.SupportTicketConversations.length-1 != index"></v-divider> -->
                </div>

                <!-- <v-btn style="border-radius:0" text class="mt-3 mb-3 height48" color="primary" block @click="fn_getUserConversationIds" v-if="!displayData.IsSupportRead">
                    Mark as Read
                </v-btn> -->
            </div>

            <v-bottom-sheet v-model="sheet">
              <v-sheet>
                <v-card flat class="mx-auto">

                    <v-subheader white-background style="border-bottom:1px #ddd solid">Details <v-spacer></v-spacer> <v-btn icon @click="sheet=false"><v-icon>close</v-icon></v-btn> </v-subheader>
                    <v-card-text style="padding:0px;margin:0">

                        <v-layout row wrap class="pt-3 pb-3 pl-3 pr-3">
                            <v-flex xs6 sm6 md6 pl-3>Title</v-flex> 
                            <v-flex xs6 sm6 md6 pr-3 right-align>{{displayData.Title}}</v-flex>

                            <v-flex xs6 sm6 md6 pl-3>Business ID</v-flex> 
                            <v-flex xs6 sm6 md6 pr-3 right-align>{{displayData.BusinessID}}</v-flex>

                            <v-flex xs6 sm6 md6 pl-3>Ticket No.</v-flex> 
                            <v-flex xs6 sm6 md6 pr-3 right-align>#{{ displayData.TicketNumber }}</v-flex>

                            <v-flex xs6 sm6 md6 pl-3>Issue Type</v-flex> 
                            <v-flex xs6 sm6 md6 pr-3 right-align>{{$root.fn_requestType(displayData.RequestType)}}</v-flex>

                            <v-flex xs6 sm6 md6 pl-3>Created Date</v-flex> 
                            <v-flex xs6 sm6 md6 pr-3 right-align>{{displayData.CreatedOn | moment("DD MMM, YYYY h:mm a")}}</v-flex>
                        </v-layout>
                    </v-card-text>

                </v-card>
              </v-sheet>
            </v-bottom-sheet>

            <v-footer style="position:fixed;bottom:0;padding:0" :style="{'width': $root.platform()=='desktop' ? 'calc(100% - 256px)' : '100%'}">
                <v-layout row wrap>
                    <v-flex xs12 sm12 md12 style="height:48px;">
                        <v-btn style="height:100%" color="primary" block @click="fn_newMessage(1)">Add Message</v-btn>
                    </v-flex>
                </v-layout>
            </v-footer>    

            
        </div>

        <dialog-attachments ref="ref_dialogAttachments" :attachments="attachedFiles" :downloadAttachment="fn_downloadAttachment"></dialog-attachments>

        <dialog-restore-types ref="ref_dialogRestoreTypes" :restore="fn_restore"></dialog-restore-types>
        <dialog-business-details ref="ref_dialogBusinessDetails" type="ticket"></dialog-business-details>

        <v-snackbar v-model="snackbar" :timeout="0">
            <v-btn icon color="white" @click="snackbar=false" nomargin>
              <v-icon>close</v-icon>
            </v-btn>
            Download Complete
            <v-btn text color="primary" small @click="fn_openFile" class="mr-1">Open</v-btn>
        </v-snackbar>

        <v-snackbar v-model="snackbar_restore" :timeout="0">
            <v-btn icon color="white" @click="snackbar_restore=false" nomargin>
              <v-icon>close</v-icon>
            </v-btn>
            Download Complete!
            <v-btn text color="primary" small @click="fn_openDialogRestoreType" class="mr-1">Restore</v-btn>
        </v-snackbar>

        <dialog-progress ref="ref_dialogProgress" :status="buffer_size" :progressMessage="progressMessage" :abortRequest="fn_cancelDownload" :cancelInit="cancelInit"></dialog-progress>

        <snackbar-retry :value="snackbarRetry" message="Failed to load ticket!" :action="fn_getToken"></snackbar-retry>

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <dialog-permission ref="ref_dialogPermission"></dialog-permission>
        <dialog-confirmation ref="ref_dialogConfirmation" header="Delete draft message?" :actionMethod="fn_getToken"></dialog-confirmation>
    </div>
</template>

<script>
import axios from 'axios';
import dialogAttachments from './dialogs/dialog_attachments'
import dialogRestoreTypes from './dialogs/dialog_restore_type'
import dialogProgress from '../../dialogs/dialog_progress'
import snackbarRetry from '../../dialogs/snackbar_retry'
import dialogPermission from '../../dialogs/dialog_permission';
import dialogConfirmation from './dialogs/dialog_confirmation';
import dialogBusinessDetails from './dialogs/dialog_viewbusinessdetails';

    export default{
        name:'display_ticket',
        data(){
            return{
                displayData:null,
                attachedFiles:null,
                snackbar: false,
                snackbar_restore:false,
                currentFileObj:null,
                scrollOptions: {"duration":300, "offset":56, "easing":"linear"},
                buffer_size:0,
                progressMessage:null,
                cancelInit:false,
                snackbarRetry:false,
                loadingObj:{
                    loading:false,
                    message:"Please wait..."    
                },
                isTemporaryBusiness:false,
                currentBusinessDetails:null,
                abbreviatedBusinessName:null,
                sheet:false,
                selectedMessageObj:null
            }
        },
        components:{
            'dialog-attachments':dialogAttachments,
            'dialog-restore-types':dialogRestoreTypes,
            'dialog-progress':dialogProgress,
            'snackbar-retry': snackbarRetry,
            'dialog-permission': dialogPermission,
            'dialog-confirmation': dialogConfirmation,
            'dialog-business-details': dialogBusinessDetails
        },
        methods:{

            fn_editDraftMessage: function(item){
                this.$router.push({path:"/support/supportteam/tickets/"+item.SupportTicketID+"/message/"+item.SupportTicketConversationID, query:{businessid:this.$route.query.businessid, "userbusinessid":this.displayData.BusinessID, "ticketFolderId":item.TicketFolderID}});
            },

            fn_openFile: function(){
                this.snackbar = false;
                this.openDownloadedFile()
            },

            fn_openDialogAttachments: function(attachments){
                this.attachedFiles = attachments;
                this.$refs['ref_dialogAttachments'].openDialogWeb();
            },

            fn_openDialogConfirmation: function(messageId, index){
                this.selectedMessageObj = {};
                this.selectedMessageObj.messageId = messageId;
                this.selectedMessageObj.messageIndex = index;
                this.$refs['ref_dialogConfirmation'].openDialogWeb();
            },
            
            fn_newMessage: function(updateStatus){
                let status = updateStatus ? this.displayData.Status : 0;
                this.$router.push({path:"/support/supportteam/tickets/"+this.displayData.SupportTicketID+"/message", query:{status:status,businessid:this.$route.query.businessid, "userbusinessid":this.displayData.BusinessID, "ticketFolderId":this.displayData.TicketFolderID}});
            },
            
            fn_getToken: function(type){

                this.$refs['ref_dialogConfirmation'].closeDialogWeb();

                let self = this;
                this.snackbarRetry = false;
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){
                    if(token == 'error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        self.snackbarRetry = true;
                        return;
                    }

                    if(type=='deletedraft'){
                        self.fn_deleteDraftMessage(token)
                    }
                    else{
                        type == 'cloneDatabase' ? self.fn_cloneDatabase(token) : self.fn_getTicket(token);
                    }

                });
            },

            fn_deleteDraftMessage: function(token){
                var headersObj = {
                headers: { 
                      'Authorization': 'bearer '+token,
                    }
                };

                axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/tickets/'+this.$route.params.ticketid+'/Messages/'+this.selectedMessageObj.messageId+'/delete', headersObj, {timeout:30000})
                .then(response => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    console.log(response);
                    this.displayData.SupportTicketConversations.splice(this.selectedMessageObj.messageIndex, 1);
                    this.$root.fn_showToastMsg("Draft message deleted!");
                }).catch(e => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    console.log(JSON.stringify(e));
                })
            },

            fn_getTicket: function(token){
                var headersObj = {
                headers: { 
                      'Authorization': 'bearer '+token,
                    }
                };

                axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/tickets/'+this.$route.params.ticketid, headersObj, {timeout:30000})
                .then(response => {

                    this.$root.fn_hideLoading(this.loadingObj);
                    console.log(response);
                    console.log(JSON.stringify(response.data));
                    this.displayData = response.data;
                    if(this.displayData.SupportTicketConversations){
                        for(let i=0; i<this.displayData.SupportTicketConversations.length; i++){
                            if(this.displayData.SupportTicketConversations[i].Attachments){
                                this.displayData.SupportTicketConversations[i].Attachments = JSON.parse(this.displayData.SupportTicketConversations[i].Attachments)
                            }
                        }
                    }

                    let words = this.displayData.Business.BusinessName.split(' ');

                    if(words.length > 1){
                        this.abbreviatedBusinessName = words[0][0].toUpperCase() + words[1][0].toUpperCase();    
                    }
                    else{
                        this.abbreviatedBusinessName = words[0][0].toUpperCase();
                    }

                    // this.abbreviatedBusinessName = this.displayData.Business.BusinessName[0].toUpperCase() + this.displayData.Business.BusinessName[1].toUpperCase();

                    let self = this;
                    setTimeout(function(){
                        if(self.$route.query.messageid){
                            self.$vuetify.goTo('#message_'+self.$route.query.messageid, self.scrollOptions);
                            let element = document.getElementById('message_'+self.$route.query.messageid);
                            element.classList.add("highlight");
                            // self.$vuetify.goTo('.highlight', self.scrollOptions);
                        }
                        else if(self.$route.query.notificationType && self.$route.query.notificationType==2){
                            self.$vuetify.goTo('.user-support-conversations', self.scrollOptions);
                        }
                        else{
                            self.$vuetify.goTo('#item_'+self.displayData.SupportTicketConversations.length, self.scrollOptions);
                        }
                    },300);
                    
                }).catch(e => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    this.snackbarRetry = true;
                    console.log(JSON.stringify(e));
                })
            },

            fn_getUserConversationIds: function(){

                let conversationIds = [];
                for(let i=0; i<this.displayData.SupportTicketConversations.length; i++){
                    if(!this.displayData.SupportTicketConversations[i].FromSupport){
                        conversationIds.push(this.displayData.SupportTicketConversations[i].SupportTicketConversationID);
                    }
                }

                let self = this;
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){
                    if(token == 'error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        self.$root.fn_showToastMsg("Something went wrong. Try again!!");
                        return;
                    }

                    self.fn_markConversationAsRead(token, conversationIds);
                });

            },

            fn_markConversationAsRead: function(token, conversationIds){

                var headersObj = {
                headers: { 
                      'Authorization': 'bearer '+token,
                    }
                };

                // let postArr = conversationIds;

                // console.log(JSON.stringify(this.Form));

                // axios.post(process.env.VUE_APP_BASE_API_URL + '/api/support/tickets/create?businessid='+this.$route.query.userbusinessid, this.Form, headersObj, {timeout:30000})
                console.log(conversationIds);
                let postObj = {"ConversationIDs":conversationIds};

                axios.post(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/tickets/'+this.displayData.SupportTicketID+'/MarkAsSupportRead', postObj, headersObj, {timeout:30000})
                .then(response => { 
                    console.log(response);
                    this.displayData.IsSupportRead = !this.displayData.IsSupportRead;
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Ticket marked as read!");
                }).catch(e => {
                    console.log(e);
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                })
                
            },

            fn_openDialogProgress: function(){
                this.$refs['ref_dialogProgress'].openDialogWeb();
            },

            fn_closeDialogProgress: function(){
                this.$refs['ref_dialogProgress'].closeDialogWeb();
            },

            fn_setStatus: function(value){
                console.log(value);
                this.buffer_size = value;
            },

            fn_downloadAttachment: function(fielObj){
                if(!navigator.onLine){
                    this.$root.fn_showToastMsg("Internet not available!");
                    return;
                }

                if(this.$root.platform()=='desktop'){
                    if(fielObj.fileType==1){
                        this.$refs['ref_dialogAttachments'].closeDialogWeb();
                        this.progressMessage = "Downloading...";
                        this.fn_openDialogProgress();
                        let self = this;

                        this.xhrDownloadRequest = new XMLHttpRequest();
                          this.xhrDownloadRequest.responseType = 'blob';
                          this.xhrDownloadRequest.onload = function(event) {
                            console.log(event);
                            var downloadUrl = URL.createObjectURL(self.xhrDownloadRequest.response);
                            var a = document.createElement("a");
                            a.href = downloadUrl;
                            a.download = fielObj.fileName;
                            document.body.appendChild(a);
                            a.click();
                            a.remove();

                            self.xhrDownloadRequest = null;
                            setTimeout(function(){
                                self.fn_closeDialogProgress();
                            },1000);
                          };

                          this.xhrDownloadRequest.onprogress = function(event) {
                            console.log(`Downloaded ${event.loaded} of ${event.total} bytes`);
                            let percent = (event.loaded/event.total)*100
                            console.log(percent);
                            self.fn_setStatus(percent);
                        }

                        this.xhrDownloadRequest.onabort = function(){
                            self.cancelInit = false;
                            self.xhrDownloadRequest = null;
                            self.fn_closeDialogProgress();
                            self.$root.fn_showToastMsg("Downloading cancelled!");
                        }

                        this.xhrDownloadRequest.open('GET', fielObj.filePath);
                        this.xhrDownloadRequest.send();
                    }
                    else{
                        this.$root.fn_showToastMsg("Data restore action is not supported in Desktop version!");
                    }
                }
                else{
                    let self = this;
                    this.$root.fn_checkStoragePermission(function(){
                        self.currentFileObj = fielObj;
                        if(fielObj.fileType == 1){
                            self.fn_downloadFile();
                        }
                        else if(fielObj.fileType == 2){
                            self.fn_downloadDatabase();
                        }
                        else if(fielObj.fileType == 3){
                            self.fn_downloadLogFile();
                        }
                        // fielObj.fileType == 1 ? self.fn_downloadFile() : self.fn_downloadDatabase();
                    })
                }
            },

            fn_downloadFile: function(){

                console.log(this.currentFileObj);

                let self = this;
                this.progressMessage = "Downloading...";
                this.fn_openDialogProgress();

                let postObj = {};
                postObj.fileName = this.currentFileObj.fileName;
                // postObj.filePath = this.currentFileObj.filePath;
                // postObj.filePath = "/tickets/"+this.displayData.BusinessID+"/"+this.displayData.TicketFolderID+"/"+this.displayData.TicketMessageFolderID+"/"+this.currentFileObj.fileName;
                postObj.filePath = "/tickets/"+this.displayData.BusinessID+"/"+this.displayData.TicketFolderID+"/"+this.currentFileObj.messageFolderId+"/"+this.currentFileObj.fileName;

                this.postData("DownloadFirebaseFile", postObj, 
                  {
                      onSuccess: function(response){
                          console.log(response);
                          self.snackbar = true;
                          self.$refs['ref_dialogAttachments'].dialog_attachments = false;
                          self.fn_closeDialogProgress();
                      },
                      onError: function(error){
                          console.log(error);
                          self.fn_closeDialogProgress();
                          self.$root.fn_showToastMsg("Something went wrong. Try again!");
                      }
                  }
                );
            },

            fn_cancelDownload: function(){
                let self = this;
                this.progressMessage = "Cancel Download...";
                this.cancelInit = true;
                if(this.$root.platform()=='desktop'){
                    this.xhrDownloadRequest.abort();
                }
                else{
                    this.getData('CancelDownload', null, {
                            onSuccess: function(response){
                                console.log(response);
                                self.cancelInit = false;
                                self.$root.fn_showToastMsg("Downloading cancelled!");
                                self.fn_closeDialogProgress();
                            }, 
                            onError: function(error){
                                self.cancelInit = false;
                                self.fn_closeDialogProgress();
                                self.$root.fn_showToastMsg("Failed to cancel downloading!");
                                console.log(error);
                            }
                        }
                    );
                }
            },

            fn_downloadDatabase: function(){
                this.progressMessage = "Downloading...";
                this.fn_openDialogProgress();

                let filterObject = {};
                filterObject.fileName = this.currentFileObj.fileName;
                // filterObject.filePath = this.currentFileObj.filePath;
                filterObject.filePath = "/tickets/"+this.displayData.BusinessID+"/"+this.currentFileObj.ticketFolderId+"/"+this.currentFileObj.messageFolderId+"/"+this.currentFileObj.fileName;

                let self = this;
                this.getFilterData('DownloadFirebaseDatabaseFile', null, filterObject, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.snackbar_restore = true;
                            self.fn_closeDialogProgress();
                        }, 
                        onError: function(error){
                            self.fn_closeDialogProgress();
                            self.$root.fn_showToastMsg("Something went wrong. Try again!");
                            console.log(error);
                        }
                    }
                );
            },

            fn_downloadLogFile: function(){
                this.progressMessage = "Downloading...";
                this.fn_openDialogProgress();

                let filterObject = {};
                filterObject.fileName = this.currentFileObj.fileName;
                filterObject.filePath = "/tickets/"+this.displayData.BusinessID+"/"+this.currentFileObj.ticketFolderId+"/"+this.currentFileObj.messageFolderId+"/"+this.currentFileObj.fileName;

                let self = this;
                this.getFilterData('DownloadFirebaseLogFile', null, filterObject, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            // self.snackbar_restore = true;
                            self.fn_restoreLogFile();
                        }, 
                        onError: function(error){
                            self.fn_closeDialogProgress();
                            self.$root.fn_showToastMsg("Something went wrong. Try again!");
                            console.log(error);
                        }
                    }
                );
            },

            fn_restoreLogFile: function(){
                let self = this;

                let postObj = {};
                postObj.businessId = this.$route.query.businessid;
                postObj.fileName = this.currentFileObj.fileName;

                console.log(JSON.stringify(postObj));
                this.postData("RestoreLogFile", postObj, 
                  {
                      onSuccess: function(response){
                            console.log(response);
                            self.$root.fn_showToastMsg("Log file downloaded");
                            //   self.hideLoading();
                            self.fn_closeDialogProgress();
                      },
                      onError: function(error){
                          console.log(error);
                          self.$root.fn_showToastMsg("Something went wrong. Try again!");
                          self.fn_closeDialogProgress();
                      }
                  }
                );
            },

            fn_openDialogRestoreType: function(){
                this.snackbar_restore = false;
                this.fn_checkTemporaryBusiness();
            },

            fn_checkTemporaryBusiness: function(){
                let self = this;
                this.getData('CheckTemporaryBusiness', this.$route.query.businessid, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.isTemporaryBusiness = JSON.parse(response);
                            self.$refs['ref_dialogAttachments'].dialog_attachments = false;
                            self.$refs['ref_dialogRestoreTypes'].openDialogWeb();
                        }, 
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
            },

            fn_restore: function(type){
                this.$refs['ref_dialogRestoreTypes'].closeDialogWeb();
                type == 1 ? this.fn_getCurrentBusinessDetails(true) : this.fn_restoreDatabase();
                // this.fn_toggleDataShare(type);
            },

            fn_toggleDataShare: function(){
                let filterObj = {};
                if(this.$route.query.notificationType && this.currentBusinessDetails && this.currentBusinessDetails.BusinessID){
                    filterObj.businessId = this.currentBusinessDetails.BusinessID;
                }
                else{
                    filterObj.businessId = this.$route.query.businessid;
                }
                // filterObj.businessId = this.$root.CurrentBusiness.BusinessID;
                filterObj.IsTemporary = this.isTemporaryBusiness ? true : false;

                let toggleValue = false;
                // let self = this;
                this.getFilterData("WatchForegroundService", JSON.stringify(toggleValue), filterObj,
                    {
                        onSuccess: function(response){
                            console.log(response);
                            // type == 1 ? self.fn_getCurrentBusinessDetails(true) : self.fn_restoreDatabase();
                        }, 
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
            },

            fn_getCurrentBusinessDetails: function(restoreTemp){
                let self = this;
                this.getData('CurrentBusinessDetail', null, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.currentBusinessDetails = JSON.parse(response);
                            /*global AndroidRoutingTest*/
                            AndroidRoutingTest.onPageLoadCompleted('1', JSON.stringify({}));
                            self.hideLoading();

                            restoreTemp ? self.fn_restoreTemp() : '';
                        }, 
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
            },

            fn_restoreTemp: function(){
                let self = this;

                let postObj = {};
                // if(this.$route.query.notificationType && this.currentBusinessDetails && this.currentBusinessDetails.activeBusinessId){
                //     postObj.businessId = this.currentBusinessDetails.activeBusinessId;
                // }
                // else{
                //     postObj.businessId = this.$route.query.businessid;
                // }
                postObj.businessId = this.currentBusinessDetails.activeBusinessId;
                
                postObj.fileName = this.currentFileObj.fileName;
                postObj.overwriteBusiness = false;

                console.log(JSON.stringify(postObj));
                this.showLoading("Please wait...");
                this.postData("TemporaryRestoreBusiness", postObj, 
                  {
                      onSuccess: function(response){
                          console.log(response);
                          self.$root.isRestoreDb = true;
                          AndroidRoutingTest.dbRestored('true');
                          self.fn_reopenDB(response, false);
                          self.hideLoading();
                      },
                      onError: function(error){
                          console.log(error);
                          self.$root.fn_showToastMsg("Something went wrong. Try again!");
                          self.fn_reopenDB(null, false);
                          self.hideLoading();
                      }
                  }
                );
            },

            fn_restoreDatabase: function(){
                let self = this;
                this.showLoading('Restore in Process...');

                let postObj = {};
                // postObj.businessId = this.$route.query.businessid;
                if(this.$route.query.notificationType && this.currentBusinessDetails && this.currentBusinessDetails.BusinessID){
                    postObj.businessId = this.currentBusinessDetails.BusinessID;
                }
                else{
                    postObj.businessId = this.$route.query.businessid;
                }
                
                postObj.fileName = this.currentFileObj.fileName;
                postObj.overwriteBusiness = true;

                this.postData("OverwriteRestoreBusiness", postObj, 
                  {
                      onSuccess: function(response){
                            console.log(response);
                            self.$root.isRestoreDb = true;
                            AndroidRoutingTest.dbRestored('true');
                            self.fn_reopenDB(null, true);
                            self.hideLoading();
                      },
                      onError: function(error){
                          console.log(error);
                          self.$root.fn_showToastMsg("Something went wrong. Try again!");
                          self.fn_reopenDB(null, false);
                          self.hideLoading();
                      }
                  }
                );
            }, 

            fn_reopenDB: function(businessid, doBackup){
                let self = this;
                let bid = null;
                if(businessid){
                    this.$root.temporaryBusinessId = businessid;
                    bid = businessid;
                }
                else{
                    this.$root.temporaryBusinessId = null;
                    bid = this.$route.query.businessid;
                }
                this.getData('ReopenDataBase', bid, 
                    {
                        onSuccess: function(response){

                            console.log(response);
                            if(businessid){
                                self.$root.fn_showToastMsg("Data restore complete!");    
                            }
                            
                            if(doBackup){
                                self.fn_backupAfterOverwrite();
                            }

                            self.fn_toggleDataShare();
                        }, 
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
            },

            fn_backupAfterOverwrite: function(){
                let self = this;
                this.showLoading("Backup in process...");
                this.getData('BackupAfterOverwrite', this.$route.query.businessid, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.hideLoading();
                        }, 
                        onError: function(error){
                            console.log(error);
                            self.$root.fn_showToastMsg("Data backup failed after overwrite business!");
                            self.hideLoading();
                        }
                    }
                );
            },

            fn_backToMainApp: function(){
                this.getData('StartMainApp', null, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                        }, 
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
            },

            fn_goBack: function(){
                if(this.$route.query && this.$route.query.notificationType){
                    this.fn_backToMainApp();
                }
                else{
                    this.$router.go(-1);
                }
            },

            fn_openPermissionDialog: function(permissionType){
                this.$root.permissionType = permissionType;
                this.$refs['ref_dialogPermission'].dialog_permission = true;
            },

            fn_openDialogBusinessDetails: function(businessid){
                this.$refs['ref_dialogBusinessDetails'].fn_getToken(businessid);
            },

        },
        mounted: function(){
            if(this.$route.query.notificationType && typeof(AndroidRoutingTest) != "undefined"){
                this.fn_getCurrentBusinessDetails();
            }
            this.fn_getToken();
            window.js_globals.fn_onBackPress = this.fn_goBack;
            window.js_globals.fn_setStatus = this.fn_setStatus;
            window.js_globals.fn_openPermissionDialog = this.fn_openPermissionDialog;
        },
        beforeRouteLeave(to, from, next){
            if(this.$refs['ref_dialogAttachments'].dialog_attachments || this.$refs['ref_dialogRestoreTypes'].dialog_restore_type || this.$refs['ref_dialogPermission'].dialog_permission || this.$refs['ref_dialogConfirmation'].dialog_confirmation || this.snackbar || this.snackbar_restore){
                this.$refs['ref_dialogAttachments'].dialog_attachments = false;
                this.$refs['ref_dialogRestoreTypes'].dialog_restore_type = false;
                this.$refs['ref_dialogPermission'].dialog_permission = false;
                this.$refs['ref_dialogConfirmation'].dialog_confirmation = false;
                this.snackbar = false;
                this.snackbar_restore = false;
                next(false);
            }
            else{
                next();
            }
        }
    }
</script>
<style>

.v-toolbar__content{
    padding-right: 0px !important;
}

</style>