<template>
    <div>
        <v-toolbar flat fixed>
            <v-toolbar-side-icon @click="fn_goBack" v-if="$root.platform()=='android'"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
            <v-toolbar-title :class="{'ml-5':$root.platform()=='android'}">Tags</v-toolbar-title>
            <v-select
                class="filter_selection"
                  v-model="status"
                  :items="statusList"
                  item-text="title"
                  item-value="value"
                  placeholder=" "
                  @change="fn_getToken"
                ></v-select>
            <v-spacer></v-spacer>
            <v-btn class="mr-3" color="primary" icon @click="fn_new">New</v-btn>
        </v-toolbar>

        <div class="hastoolbar">

            <!-- <template v-if="items">
                <template>
                    <div class="table-wrapper" style="width:100%;font-size:10px">
                        <table style="width:100%">
                            
                            <thead>
                                <tr class="primarybackground" style="color:#fff">
                                    <td class="pa-1" style="width: 70px;">Action</td>
                                    <td class="pa-1">Name</td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(item, index) in items" :key="index">
                                    <td>{{ (index + 1) }}</td>
                                    <td>{{item.Title}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="pa-4" center-align style="font-size:10px" v-if="!items.length">
                        No record found!
                    </div>
                </template>
            </template> -->

            <v-list two-line subheader full-width v-if="items && items.length">

                <div v-for="(item, index) in items" :key="index">
                    <v-list-item style="padding-right:0">
                        <v-list-item-content>
                          <v-list-item-title>{{item.Title}}</v-list-item-title>
                          <v-list-item-subtitle>Status: {{item.Status == 1 ? 'Active' : item.Status == 2 ? 'Draft': 'In-Active'}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action style="display: inline-block;">
                            <!-- <v-btn icon @click="fn_deletePhoneNumber(item.TestingPhoneNumberID, index)">
                                <v-icon>delete_outline</v-icon>
                            </v-btn> -->
                            <v-btn icon @click="fn_editTag(item.TagID)">
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if="items.length-1 != index"></v-divider>
                </div>

            </v-list>

            <div class="pa-3" grey-color center-align v-if="!items.length">
                No tag added yet!
            </div>
        </div>

        <snackbar-retry :value="snackbarRetry" message="Failed to load tags!" :action="fn_getTags"></snackbar-retry>

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <dialog-loading :loading="loading"></dialog-loading>

    </div>
</template>

<script>
import axios from 'axios';
import snackbarRetry from '../../dialogs/snackbar_retry'
import dialogLoading from '../../dialogs/dialog_loading.vue'

    export default{
        name:'item_tag',
        data(){
            return{
                items:[],
                snackbarRetry:false,
                loadingObj:{
                  loading:false,
                  message:"Please wait..."    
                },
                status: 0,
                statusList: [
                    {title: 'All', value: 0},
                    {title: 'Active', value: 1},
                    {title: 'In-Active', value: -1},
                    {title: 'Draft', value: 2},
                ],
                loading:false,
            }
        },
        components: {
            'snackbar-retry': snackbarRetry,
            'dialog-loading':dialogLoading,
        },
        methods:{

            fn_new: function(){
                this.$router.push({path:"/support/supportteam/tag/items/new"});
            },

            fn_getToken: function(){
                let self = this;
                this.loading = true;
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){
                    if(token == 'error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        this.loading = false;
                        self.$root.fn_showToastMsg("Something went wrong. Try again!!");
                        return;
                    }

                    self.fn_getTags(token);
                });
            },

            fn_editTag: function(tagid){
                this.$router.push({path:"/support/supportteam/tag/items/new", query:{"tagid":tagid}});
            },


            fn_getTags: function(token){
                let self = this;

                var headersObj = {
                headers: { 
                        'Authorization': 'bearer '+token,
                    }
                };

                axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/tags/getlist?status=' + this.status, headersObj, {timeout:30000})
                .then(response => { 
                    console.log(JSON.stringify(response.data));
                    self.items = response.data;
                    self.loading = false;
                    this.$root.fn_hideLoading(this.loadingObj);
                    //self.$root.fn_hideLoading(self.loadingObj);
                }).catch(e => {
                    console.log(e);
                    self.snackbarRetry = true;
                    self.$root.fn_hideLoading(self.loadingObj);
                })
                
            },

            fn_deletePhoneNumber: function(testingPhoneNumberId, index){
                let self = this;
                this.$root.fn_showLoading('Deleting tag...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){

                    var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                    axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/testingphonenumbers/'+testingPhoneNumberId+'/delete', headersObj, {timeout:30000})
                    .then(response => {
                        console.log(response);
                        self.$root.fn_showToastMsg("Tag deleted!");
                        self.items.splice(index, 1);
                        self.$root.fn_hideLoading(self.loadingObj);
                    }).catch(e => {
                        console.log(e);
                        self.$root.fn_showToastMsg("Something went wrong. Try again!");
                        self.$root.fn_hideLoading(self.loadingObj);
                        self.snackbarRetry = true;
                    })
                })
            },

            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
            this.fn_getToken();
        }
    }
</script>
<style>
.filter_selection{
    max-width: 250px;
    padding-left: 16px;
    padding-top: 16px !important;
}
</style>