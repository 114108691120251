<template>
	<div>
		<!-- <v-snackbar :value="value" :timeout="0">
          {{fn_getMessage()}}
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click.native="action">{{btntext ? btntext : 'retry'}}</v-btn>
        </v-snackbar> -->

        <v-snackbar :value="value" :timeout="0">
            {{fn_getMessage()}}
            <template v-slot:action="{ attrs }">
                <v-btn text color="primary" @click.native="action" v-bind="attrs">{{btntext ? btntext : 'retry'}}</v-btn>
            </template>
        </v-snackbar>
	</div>
</template>

<script>
	export default{
		props:['value', 'message', 'action', 'btntext'],
		methods:{
			fn_getMessage: function(){
				if(!navigator.onLine){
					return "Internet not available!";
				}
				else{
					return this.message;
				}
			}
		}
	}
</script>