<template>
    <div full-height>
        <v-toolbar flat fixed>
            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
            <v-toolbar-title class="ml-5">Verify OTP</v-toolbar-title>
        </v-toolbar>

        <div class="hastoolbar">

            <v-card noshadow>
                <v-card-text>

                    <v-form class="form-element">

                            <v-layout align-center row wrap style="max-width:400px;margin:auto">

                                <p class="title grey-color" style="line-height:28px !important">
                                    We have sent an OTP on this number: <span dark-opacity-color><strong>{{'+91'+$route.query.mobile_number}}</strong></span>
                                </p>


                                <v-flex xs12 sm12 md12 nopadding class="mt-3">
                                   <v-text-field  
                                        v-model="Form.Code"
                                        :maxlength="6"
                                        clearable
                                        hide-details
                                        type="tel"
                                        label="Enter OTP"
                                        placeholder=" "
                                        v-validate="'required|min:6'"
                                        data-vv-name="otp" 
                                        data-vv-as="otp" 
                                        required>
                                            
                                    </v-text-field>
                                </v-flex>


                                <v-flex xs12 sm12 md12 margin-top-ten right-align nopadding>
                                    <v-btn color="primary" nomargin @click="fn_checkValidation">Confirm</v-btn>
                                </v-flex>

                            </v-layout>

                    </v-form>
                </v-card-text>
            </v-card>

        </div>
        
        <v-footer bottom-fixed-footer form-item v-if="timeLeft>0" style="height:32px">
          Resend OTP in: {{timeLeft}} seconds
        </v-footer>

        <v-snackbar :timeout="0" v-model="snackbarSendOtp">Resend OTP?
            <v-btn text color="primary" @click.native="fn_resendOtp">Okay</v-btn>
        </v-snackbar>

        <v-snackbar v-model="snackbarToast">{{toastMsg}}
            <v-btn text color="primary" @click="snackbarToast=false">Okay</v-btn>
        </v-snackbar>

        <v-dialog v-model="loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

import axios from 'axios'
var otpTimer = null;

    export default{
        name:'otp',
        data(){
            return{
                Form:{"Code":null},
                loading:false,
                timeLeft:null,
                timeOut:false,
                snackbarSendOtp:false,
                snackbarToast:false,
                toastMsg:"Something went wrong!",
                requestId:null,
                requestKey:null
            }
        },
        methods:{

            // fn_signOut: function(){
            //     this.$root.signOut(function(){
            //         alert("signOut");
            //     })
            // },

            fn_testToken: function(){
                let self = this;
                 this.$root.getFirebaseToken(function(token){
                        if(token=="error"){
                            self.snackbarToast = true
                            self.toastMsg = "Something went wrong!"
                            self.loading = false;
                            return;
                        }

                        console.log(token);

                    })
             },

            fn_checkValidation: function(){
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.fn_verifyOtp(); 
                    }
                    else{
                        this.snackbarToast = true
                        this.toastMsg = "Invalid Otp!"
                    }
                });
            },

            fn_verifyOtp: function(){
                let postObj = {};
                // postObj.RequestID = this.$route.query.request_id;
                postObj.RequestID = this.requestId;
                postObj.OTP = this.Form.Code;
                postObj.Device = {};
                postObj.Device.DeviceName = navigator.platform;
                postObj.Device.DeviceType = 3;
                postObj.Device.FCMID = null;

                postObj.RequestKey = this.requestKey;

                console.log(JSON.stringify(postObj));
                this.loading = true;
                axios.post(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/login/VerifyOTP', postObj, {timeout:60000})
                .then(response => { 
                    console.log(response);
                    this.fn_getCustomToken(response.data.Token);
                }).catch(e => {
                    this.loading = false;
                    this.snackbarToast = true;
                    this.toastMsg = "Something went wrong!"
                    console.log(e);
                })
            },

            fn_getCustomToken(customToken){
                let self = this;
                this.$root.signInWithCustomToken(customToken, function(response){
                    if(response=='error'){
                        self.snackbarToast = true;
                        self.toastMsg = "Something went wrong!"
                        self.loading = false;
                        return
                    }
                    self.getFirebaseToken(function(token){
                        if(token=="error"){
                            self.snackbarToast = true;
                            self.toastMsg = "Something went wrong!"
                            self.loading = false;
                            return;
                        }

                        self.fn_login(token)
                    })
                })
            },

            fn_login: function(token){
                  var headersObj = {
                  headers: { 
                        'Authorization': 'bearer '+token
                      }
                  };

                  axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/login', headersObj, {timeout:30000})
                  .then(response => { 
                      console.log(JSON.stringify(response.data));
                      this.fn_setCookie("user", response.data.Name, 30);
                  }).catch(e => {
                      this.loading = false;
                      this.snackbarToast = true;
                      this.toastMsg = "Something went wrong!"
                      console.log(e);
                  })
            },

            fn_setCookie: function(cname, cvalue, exdays) {
                var d = new Date();
                d.setTime(d.getTime() + (exdays*24*60*60*1000));
                var expires = "expires=" + d.toGMTString();
                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";

                this.loading = false;
                this.snackbarToast = true
                this.toastMsg = "Login Successful!";
                localStorage.setItem("userProfile", JSON.stringify({"Name":cvalue}));
                this.$router.replace({path:"/support/supportteam/tickets/items"});
            },

            fn_setTimer: function(){
                let self = this;
                this.timeLeft = 90;
                this.timeOut = false;
                otpTimer = setInterval(function(){
                  self.timeLeft -= 1;
                  if(self.timeLeft <= 0){
                    self.snackbarSendOtp = true;
                    clearInterval(otpTimer);
                    self.timeOut = true;
                  }
                }, 1000);
            },

            fn_resendOtp: function(){

                let postObj = {};
                postObj.RequestID = this.requestId;
                postObj.RequestKey = this.guidWeb();
                console.log(JSON.stringify(postObj));
                this.loading = true;
                this.snackbarSendOtp = false;
                axios.post(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/login/ResendOTP', postObj, {timeout:30000})
                .then(response => { 
                    console.log(response);
                    this.requestId = response.data.RequestID;
                    this.requestKey = postObj.RequestKey;
                    this.loading = false;
                    this.snackbarToast = true;
                    this.toastMsg = "Otp Sent!"
                    this.fn_setTimer();
                }).catch(e => {
                    this.loading = false;
                    this.snackbarToast = true
                    this.toastMsg = "Something went wrong!"
                    this.snackbarSendOtp = true;
                    console.log(e);
                })
            },

            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        mounted: function(){
            this.fn_setTimer();
            console.log(this.$route);
            this.requestId = this.$route.query.request_id;
            this.requestKey = this.$route.query.request_key;
            window.js_globals.fn_onBackPress = this.fn_goBack;
            let formElement = document.getElementsByClassName("form-element")[0];
            formElement.addEventListener("submit", function(e){
                e.preventDefault();
            });
        }
    }
</script>