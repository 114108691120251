<template>
    <div>
        <v-dialog v-model="dialog_progress" persistent max-width="290">
            <v-card class="pt-5">
                <v-card-text>
                   {{progressMessage}}
                   <v-progress-linear class="mt-3" indeterminate color="primary" v-if="status==0 || status==100 || cancelInit"></v-progress-linear>
                   <v-progress-linear class="mt-3" :value="status" color="primary" height="4" v-else></v-progress-linear>
                   <span>{{Math.round(status)}}%</span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small color="blue darken-1" text @click="abortRequest">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>     

<script>

export default{
    props:['status', 'progressMessage', 'abortRequest', 'cancelInit'],
    data(){ 
        return{
            dialog_progress: false,
        }
    },
    methods: {
        openDialogWeb: function () {    
            this.dialog_progress = true;
        },
        closeDialogWeb: function () {
            this.dialog_progress = false;
        },
    }
}
</script>