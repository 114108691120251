<template>
    <div>
        <v-toolbar flat fixed>
            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
            <v-toolbar-title class="ml-5">Pending Notifications <span v-if="notification.count">({{notification.count}})</span></v-toolbar-title>
        </v-toolbar>

        <div class="hastoolbar">

            <v-list three-line subheader full-width>

                <div v-for="(item, index) in notification.items" :key="index">
                    <v-list-item>
                        <v-list-item-action>
                            <v-btn color="error" icon @click="fn_archiveNotification(index, item.SupportTicketNotificationID)"><v-icon>remove_circle_outline</v-icon></v-btn>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>{{item.TicketTitle}}</v-list-item-title>
                          <v-list-item-subtitle>{{item.MessageType == 1 ? "New ticket created" : "New message added"}}</v-list-item-subtitle>
                          <v-list-item-subtitle>{{item.Business.BusinessName}}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-btn color="primary" outlined small @click="fn_sendNotification(index, item.SupportTicketNotificationID)">send</v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if="notification.items.length-1 != index"></v-divider>
                </div>

                <infinite-loading spinner="spiral" :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading>
            </v-list>


        </div>

        <snackbar-retry :value="snackbarRetry" message="Failed to load notifications!" :action="fn_searchItem"></snackbar-retry>

    </div>
</template>

<script>
import axios from 'axios';

import InfiniteLoading from 'vue-infinite-loading';
import snackbarRetry from '../../../dialogs/snackbar_retry'

    export default{
        name:'items_notifications',
        data(){
            return{
                notification:{"items":[], "count":null},
                offset:0,
                infiniteId: +new Date(),
                snackbarRetry:false
            }
        },
        components: {
            InfiniteLoading,
            'snackbar-retry': snackbarRetry
        },
        methods:{

            infiniteHandler($state) {

                let jsonData = { "Keywords":"", "BusinessID": null, "MessageType": null, "TicketNumber":null, "FromUserID":null, "DateFrom" : null, "DateTo":null }

                console.log(JSON.stringify(jsonData));

                let self = this;
                this.$root.fn_getAuthToken(function(token){

                    if(token=='error'){
                        self.snackbarRetry = true;
                        $state.complete();
                        return;
                    }

                    var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                    axios.post(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/SupportTicketNotifications/getlist?offset='+self.offset+'&limit=10', jsonData, headersObj, {timeout:30000})
                    .then(response => {
                        console.log(response);
                        JSON.stringify(response);
                        self.notification.count = response.data.ItemCount;

                        if (self.notification.count > self.notification.items.length) {
                            for(let i=0; i<response.data.Notifications.length; i++){
                                self.notification.items.push(response.data.Notifications[i]);
                            }
                            $state.loaded();
                        } else {
                            $state.complete();
                        }

                        self.offset = self.notification.items.length;

                    }).catch(e => {
                        console.log(e);
                        self.snackbarRetry = true;
                        self.$root.fn_showToastMsg("Something went wrong. Try again!");
                        $state.complete();
                    })
                })
                
            },

            fn_searchItem:  function() {
              this.snackbarRetry = false;
              this.offset = 0;
              this.notification.items = [];
              this.notification.count = 0;
              this.infiniteId += 1;
            },

            fn_archiveNotification: function(index, notificationId){

                let self = this;
                this.showLoading("Archive...");
                this.$root.fn_getAuthToken(function(token){

                    var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };
                    
                    axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/SupportTicketNotifications/'+notificationId+'/archive', headersObj, {timeout:30000})
                    .then(response => {
                        console.log(JSON.stringify(response));
                        self.notification.items.splice(index, 1);
                        self.notification.count -= 1;
                        self.$root.fn_showToastMsg("Notification Archived!");
                        self.hideLoading();
                    }).catch(e => {
                        self.hideLoading();
                        self.$root.fn_showToastMsg("Something went wrong. Try again!");
                        console.log(e);
                    })

                })
                
            },

            fn_sendNotification: function(index, notificationId){

                let self = this;
                this.showLoading("Sending notification...");
                this.$root.fn_getAuthToken(function(token){

                    var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };
                    
                    axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/SupportTicketNotifications/sendnotification/'+notificationId, headersObj, {timeout:30000})
                    .then(response => {
                        console.log(JSON.stringify(response));
                        self.notification.items.splice(index, 1);
                        self.notification.count -= 1;
                        self.$root.fn_showToastMsg("Notification Sent!");
                        self.hideLoading();
                    }).catch(e => {
                        self.hideLoading();
                        self.$root.fn_showToastMsg("Something went wrong. Try again!");
                        console.log(e);
                    })

                })
                
            },

            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
        }
    }
</script>