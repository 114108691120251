<template>
    <div>
        <v-dialog v-model="dialog_businessmetrics" content-class="businessview_dialog" :hide-overlay="true" scrollable persistent max-width="50%" no-click-animation transition="slide-x-transition">
            <v-card>
                <v-toolbar flat class="main_toolbar">
                    <v-toolbar-side-icon @click="closeDialogWeb"><v-icon color="primary">close</v-icon></v-toolbar-side-icon>
                    <v-toolbar-title class="ml-5">Usage</v-toolbar-title>
                </v-toolbar>
                <div class="hastoolbar hasfooter">
                    <template v-if="reportData">
                        <template>
                            <div class="table-wrapper" style="width:100%;font-size:10px">
                                <table style="width:100%">
                                    
                                    <thead>
                                        <tr class="primarybackground" style="color:#fff">
                                            <td class="pa-1">S.No</td>
                                            <!-- <td class="pa-1">Business Name</td> -->
                                            <td class="pa-1">Date & Time</td>
                                            <!-- <td class="pa-1">Mobile Usage Count</td> -->
                                            <!-- <td class="pa-1">Desktop Usage Count</td> -->
                                            
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr v-for="(item, index) in reportData.data" :key="index">
                                            <td>{{ (index + 1) }}</td>
                                            <!-- <td><a @click="fn_viewDetails(item.BusinessID)">{{item.BusinessName}}</a></td> -->
                                            <td>{{item.ActionDate | moment("DD/MM/YYYY h:mm a")}}</td>
                                            <!-- <td>{{item.MobileUsageCount}}</td> -->
                                        </tr>
                                    </tbody>

                                </table>
                            </div>

                            <div class="pa-4" center-align style="font-size:10px" v-if="!reportData.data.length">
                                No record found!
                            </div>
                        </template>
                    </template>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="loadingdialog" :hide-overlay="true" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>     

<script>

import axios from 'axios';

export default{
    props:['attachments', 'downloadAttachment', 'type', 'hideOverlay'],
    data(){ 
        return{
            reportData:null,
            dialog_businessmetrics: false,
            loadingdialog: false,
            loadingObj:{
                loading:false,
                message:"Please wait..."    
            },
            displayData:null,
            TotalSum: 0,
            Average: 0,
            subTotlal: 0
        }
    },
    methods: {
        openDialogWeb: function () {    
            this.dialog_businessmetrics = true;
        },
        closeDialogWeb: function () {
            this.dialog_businessmetrics = false;
            //this.fn_hideOverlay();
            var wrp = document.getElementsByTagName('html');
            for (var i = 0; i < wrp.length; i++) {
                wrp[i].style.height = "auto";
                wrp[i].style.overflowY = "auto";
            }
        },

        fn_getToken: function(businessid){
                let self = this;
                this.loadingdialog = true;
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){
                    if(token == 'error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        self.loadingdialog = false;
                        return;
                    }

                    self.fn_getBusiness(token, businessid);
                });
            },

            fn_getBusiness: function(token, businessid){
                var headersObj = {
                headers: { 
                      'Authorization': 'bearer '+token,
                    }
                };

                this.subTotlal = 0;
                axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/reports/appusage/'+businessid + '/detailed', headersObj, {timeout:30000})
                .then(response => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.reportData = response;
                    this.loadingdialog = false;
                    this.dialog_businessmetrics = true;

                    var wrp = document.getElementsByTagName('html');
                    for (var i = 0; i < wrp.length; i++) {
                        wrp[i].style.height = "100vh";
                        wrp[i].style.overflowY = "hidden";
                    }
                    console.log(response);
                    console.log(JSON.stringify(response.data));
                }).catch(e => {
                    this.loadingdialog = false;
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    this.snackbarRetry = true;
                    console.log(JSON.stringify(e));
                })
            },

    }
}
</script>

<style>
.businessview_fullscreendialog{
    position: absolute;
    right: 0px;
    left: auto;
    max-width: 100% !important;
    width: calc(100% - 256px);
    max-height: 100% !important;
    margin: 0px;
    height: calc(100% - 56px);
    top: 56px;
    box-shadow: none !important;
}
.businessview_dialog{
    background: white;
}
</style>