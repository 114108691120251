<template>
    <div full-height>
        <v-toolbar flat fixed>

            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title class="ml-5">Ticket Progress Report</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn color="primary" icon @click="filtersheet = true">
                <v-icon>filter_list</v-icon>
            </v-btn>

        </v-toolbar>

        <div class="hastoolbar hasBigfooter" full-height>

            <!-- <v-subheader style="height:40px" @click="fn_openDialogPeriod">
                Period: {{dateFrom | moment("DD/MM/YYYY")}} To {{dateTo | moment("DD/MM/YYYY")}}
                <v-spacer></v-spacer>
                <v-icon>date_range</v-icon>
            </v-subheader> -->

            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark text v-bind="attrs" v-on="on" class="datepickerbtn"><v-icon>date_range</v-icon>&nbsp;Period<span v-show="dateFrom && dateTo">:&nbsp;</span> {{dateFrom | moment("DD/MM/YYYY")}}<span v-show="dateFrom && dateTo">&nbsp;To&nbsp;</span>{{dateTo | moment("DD/MM/YYYY")}}</v-btn>
                </template>
                <v-list>
                  <v-list-item @click="fn_getLatestData('Today')">
                    <v-list-item-title>Today</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Yesterday')">
                    <v-list-item-title>Yesterday</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 3 days')">
                    <v-list-item-title>Last 3 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 7 days')">
                    <v-list-item-title>Last 7 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 15 days')">
                    <v-list-item-title>Last 15 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 30 days')">
                    <v-list-item-title>Last 30 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_openDialogPeriod">
                    <v-list-item-title>Custom date range</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn outlined rounded @click="fn_clearDateFilter" v-if="dateFrom && dateTo" dark text class="clearfilterbtn">Clear Filter <v-icon class="pl-1" size="20">close</v-icon></v-btn>

            <v-divider></v-divider>

           <!--  <v-list nopadding two-line subheader full-width>

                <div v-for="(group, index) in reportData.Groups" :key="index" :class="{'mt-2':index!=0}">
                    <v-subheader>{{group.Status == 1 ? 'Open' :group.Status == 2 ? 'Waiting for Reponse' : 'Closed'}}</v-subheader>
                    <v-divider></v-divider>
                    <div v-for="(item, itemIndex) in group.Items" :key="itemIndex">
                        <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>Ticket Count: {{item.Count}}</v-list-item-title>
                              <v-list-item-subtitle>{{item.Date | moment("DD MMM, YYYY")}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="group.Items.length-1 != itemIndex"></v-divider>    
                    </div>
                    
                </div>

            </v-list> -->
            <template v-if="reportData">
                <div class="table-wrapper" style="width:100%;font-size:10px" v-for="(group, index) in reportData.data.Groups" :key="index">
                    <!-- <span :key="index">{{group.Status}}</span> -->
                    <!-- <v-subheader style="height:32px">{{group.Status == 1 ? 'Open' : group.Status == 2 ? 'Waiting for Reponse' : 'Closed'}}</v-subheader> -->
                    <table style="width:100%" :class="{'mt-2':index!=0}">
                        <caption class="left-align pa-1">{{group.Status == 1 ? 'Open' : group.Status == 2 ? 'Waiting for Reponse' : 'Closed'}}</caption>
                        <thead>
                            <tr class="primarybackground" style="color:#fff">
                                <td class="pa-1">Date</td>
                                <td class="pa-1">Ticket Count</td>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(item, itemIndex) in group.Items" :key="itemIndex">
                                <td>{{item.Date | moment("DD MMM, YYYY")}}</td>
                                <td>{{item.Count}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="pa-4" center-align style="font-size:10px" v-if="!reportData.data.Groups.length">
                    No record found!
                </div>
            </template>

            <v-footer v-if="reportData" style="position: fixed;width: calc(100% - 256px);bottom: 0;background: #fff;border-top: 1px #ddd solid;font-size:10px">
                <v-layout wrap v-for="(item, index) in reportData.data.Total" :key="index" full-width>
                    <!-- <v-flex xs6 sm6 md6>Status</v-flex> -->
                    <v-flex xs6 sm6 md6>{{item.Status == 1 ? 'Open' : item.Status == 2 ? 'Waiting for Reponse' : 'Closed'}}</v-flex>

                    <!-- <v-flex xs6 sm6 md6>Ticket Count</v-flex> -->
                    <v-flex xs6 sm6 md6 right-align>{{item.Count}}</v-flex>
                </v-layout>
            </v-footer>
            

        </div>

        <dialog-period-selection ref="ref_dialogCustomPeriod" title="Select Period" :openDatePicker="fn_openDatePicker" :startDate="selectedStartDate" :endDate="selectedEndDate" :actionMethod="fn_updateCustomDates"></dialog-period-selection>

        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

        <v-bottom-sheet hide-overlay v-model="filtersheet">
            <v-subheader style="background:#fff">
                Filter Report<v-spacer></v-spacer>
                <v-btn icon @click="filtersheet = false">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-subheader>
            <v-divider></v-divider>
            <div class="pa-3" style="background:#fff">

                <v-select
                  v-model="ticketStatus"
                  :items="ticketStatuses"
                  item-text="name"
                  item-value="status"
                  label="Ticket Status"
                  placeholder=" "
                  return-object
                  @change="fn_filterReport"
                  clearable
                ></v-select>

            </div>
        </v-bottom-sheet>
    </div>
</template>

<script>
import axios from 'axios';

import dialogPeriodSelection from '../../tickets/dialogs/dialog_period_selection'
import dialogDatepicker from '../../tickets/dialogs/dialog_datepicker'

    export default{
        data(){
            return{
                reportData:null,
                selectedStartDate:this.$root.minDateSelection,
                selectedEndDate:this.$root.maxDateSelection,
                dateFrom:this.$root.minDateSelection,
                dateTo:this.$root.maxDateSelection,
                dateType:null,
                selectedDate:null,
                dialog_datepicker:false,
                filtersheet:false,

                ticketStatus:null,
                ticketStatuses:[
                    {"name":"Open", "status":1},
                    {"name":"Waiting for Response", "status":2},
                    {"name":"Closed", "status":3},
                ],

                loadingObj:{
                    loading:false,
                    message:"Please wait..."    
                },
            }
        },
        components: {
            'dialog-period-selection':dialogPeriodSelection,
            'dialog-datepicker':dialogDatepicker
        },
        methods:{

            fn_openDialogPeriod: function(){
                this.selectedStartDate = this.dateFrom;
                this.selectedEndDate = this.dateTo;
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = true;
            },

            fn_openDatePicker: function (type, date) {
                let self = this;
                this.dateType = type;
                let dateObj = date ? new Date(date) : new Date();
                this.$refs['ref_dialogDatePicker'].selectedDate = dateObj.getFullYear()+'-'+("0" + (dateObj.getMonth()+1)).slice(-2)+'-'+("0" + dateObj.getDate()).slice(-2);
                setTimeout(function () {
                    self.dialog_datepicker = true;
                })
            },

            fn_updateDate: function (value) {
                if(this.dateType=="start"){
                    this.selectedStartDate = value;
                }
                else if(this.dateType=="end"){
                    this.selectedEndDate = value;
                }

                this.dialog_datepicker = false;
            },

            fn_updateCustomDates: function(){
                this.dateFrom = this.selectedStartDate;
                this.dateTo = this.selectedEndDate;
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = false;
                this.fn_getToken();
            },

            fn_filterReport: function(){
                this.reportData = null;
                this.fn_getToken();
            },

            fn_getToken: function(){
                let self = this;
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){
                    if(token == 'error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        self.$root.fn_showToastMsg("Something went wrong. Try again!!");
                        return;
                    }

                    self.fn_getReport(token);
                });
            },

            fn_getReport: function(token){
                var headersObj = {
                    headers: { 
                      'Authorization': 'bearer '+token,
                    }
                };

                let postJson = {
                    "UserID":null,
                    "DateFrom":this.dateFrom, 
                    "DateTo":this.dateTo, 
                    "TicketStatus":this.ticketStatus ? this.ticketStatus.status : null
                }

                if(this.dateFrom){
                    postJson.DateFrom = this.$moment(postJson.DateFrom).startOf('day');
                    postJson.DateFrom = this.fn_getLocalDateFormat(postJson.DateFrom);
                }
                
                if(this.dateTo){
                    postJson.DateTo = this.$moment(postJson.DateTo).endOf('day');
                    postJson.DateTo = this.fn_getLocalDateFormat(postJson.DateTo);
                }
                

                console.log(JSON.stringify(postJson));


                axios.post(process.env.VUE_APP_BASE_API_URL + "/api/supportteam/reports/users/statuswise", postJson, headersObj, {timeout:30000})
                .then(response => {
                    console.log(JSON.stringify(response));
                    this.reportData = response;
                    this.$root.fn_hideLoading(this.loadingObj);
                }).catch(e => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    console.log(JSON.stringify(e));
                })
            },


            fn_goBack: function () {
                this.$router.go(-1);
            },

            fn_getLatestData: function(type){
              var currentDate = new Date();
              var year = currentDate.getFullYear();
              var month = String(currentDate.getMonth() + 1).padStart(2, '0');
              var day = String(currentDate.getDate()).padStart(2, '0');
              var formattedStartDate = `${year}-${month}-${day}`;
              this.dateTo = formattedStartDate;


              var startDate = new Date();
              if(type == 'Yesterday')
                startDate.setDate(startDate.getDate() - 1);
              else if(type == 'Last 3 days')
                startDate.setDate(startDate.getDate() - 3);
              else if(type == 'Last 7 days')
                startDate.setDate(startDate.getDate() - 7);
              else if(type == 'Last 15 days')
                startDate.setDate(startDate.getDate() - 15);
              else if(type == 'Last 30 days')
                startDate.setDate(startDate.getDate() - 30);
              var endDateYear = startDate.getFullYear();
              var endDateMonth = String(startDate.getMonth() + 1).padStart(2, '0');
              var endDateDay = String(startDate.getDate()).padStart(2, '0');
              var formattedEndDate = `${endDateYear}-${endDateMonth}-${endDateDay}`;
              console.log('Start Date : ' + formattedStartDate);
              console.log('End Date : ' + formattedEndDate);
              this.dateFrom = formattedEndDate;
              this.fn_getToken();

            },

            fn_clearDateFilter: function(){
              this.dateFrom = null;
              this.dateTo = null;
              this.fn_getToken();
            }
        },
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
            
            this.fn_getToken();
        },
        beforeRouteLeave(to, from, next){
            if(this.filtersheet){
                this.filtersheet = false;
                next(false);
            }
            else if(this.dialog_datepicker){
                this.dialog_datepicker = false;
                next(false);
            }
            else if(this.$refs['ref_dialogCustomPeriod'].dialog_period_selection){
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = false;
                next(false);
            }
            else{
                next();
            }
        }
    }
</script>

<style>
    .table-wrapper table tbody tr:nth-child(even){
        background: #f0f0f0 !important;
    }
    .table-wrapper table thead tr th,
    .table-wrapper table tbody tr td{
        padding:4px;
    }
</style>