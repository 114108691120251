<template>
    <div full-height>
        <v-toolbar flat fixed>
            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
            <v-toolbar-title class="ml-5">Update Message</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on"><v-icon color="primary">attachment</v-icon></v-btn>
              </template>
              <v-list nopadding>
                <v-list-item @click="fn_selectFile('file')">
                  <v-list-item-title>Attach File</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="fn_selectFile('db', 1)" :disabled="$root.platform()=='desktop'">
                  <v-list-item-title>Attach Database</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="fn_selectFile('db', 2)">
                  <v-list-item-title>Attach Database From Device</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
        </v-toolbar>

        <div class="hastoolbar hasfooter" :class="{'hasQuillToolbar':editMessage, 'desktop-view':$root.platform()=='desktop'}">
            <v-form class="form-element">

                <v-expansion-panels flat class="mb-3" v-if="attachments && attachments.length">
                  <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="expand_more" style="padding:8px 16px;min-height:48px;">Attachments ({{attachments.length}})</v-expansion-panel-header>
                    <v-divider></v-divider>
                    <v-expansion-panel-content>
                      <div v-for="(item, index) in attachments" :key="index">
                        <v-chip class="mr-2 mt-2 mb-2 pr-1" color="primary" label text-color="white">
                          <span style="overflow:hidden;text-overflow:ellipsis">{{item.fileName}}</span> 
                          <v-icon v-ripple class="ml-3" @click="fn_deleteAttachment(item.filePath, index, 'update')">close</v-icon>
                        </v-chip>  
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <input type="file" id="filePicker" style="display:none" @change="fn_validateFile"/>

                <v-layout align-center nomargin padding-top-ten row wrap white-background>

                    <v-flex xs12 sm12 md12 form-item>
                        <v-select :items="ticketStatuses" label="Ticket Status" v-model="statusObj" return-object item-text="name" item-value="status"></v-select>
                    </v-flex>

                     <v-flex xs12 sm12 md12 form-item class="pb-4" :class="{'blur':!editMessage}">
                        <label style="color: rgba(0, 0, 0, 0.6);font-size: 12px;">Message</label>
                        
                        <quill-editor class="mt-1"
                          ref="myTextEditor"
                          v-model="Form.Message"
                          :options="editorOption"
                          placeholder=""
                          @blur="onEditorBlur($event)"
                          @focus="onEditorFocus($event)"
                          @ready="onEditorReady($event)">
                          <div id="toolbar" slot="toolbar">
                              <!-- Add a bold button -->
                              <button class="ql-bold">Bold</button>
                              <button class="ql-italic">Italic</button>
                              <button class="ql-underline">ql-underline</button>
                              <button class="ql-strike">Italic</button>
                              <!-- Add ordered/bullet list -->
                              <button class="ql-list" value="ordered">ql-list</button>
                              <button class="ql-list" value="bullet">ql-list</button>
                              <button class="ql-link">ql-link</button>

                              
                          </div>
                      </quill-editor>

                        <v-divider dark-opacity-divider></v-divider>
                    </v-flex>
                   
                </v-layout>

            </v-form>

            <v-footer nopadding style="position:fixed;bottom:0;width:100%">
                <v-layout row wrap>

                    <v-flex height48 xs6 sm6 md6>
                        <v-btn style="background:#eee" nomargin no-round-borders full-height noshadow block @click="fn_checkValidations(2)">
                          Post as Draft
                        </v-btn>
                    </v-flex>

                    <v-flex height48 xs6 sm6 md6>
                        <v-btn nomargin no-round-borders full-height noshadow color="primary" block @click="fn_checkValidations(1)">
                          Post Message
                        </v-btn>
                    </v-flex>

                </v-layout>
            </v-footer>
        </div>  

        <dialog-progress ref="ref_dialogProgress" :status="buffer_size" :progressMessage="progressMessage" :abortRequest="fn_cancelUpload" :cancelInit="cancelInit"></dialog-progress>

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <dialog-permission ref="ref_dialogPermission"></dialog-permission>
    </div>
</template>

<script>
import axios from 'axios'
import {supportMixin} from './support_mixin'
import dialogProgress from '../../dialogs/dialog_progress'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import { quillEditor } from 'vue-quill-editor'

    export default{
        name:'form_updateMessage',
        mixins:[supportMixin],
        data(){
            return{
              Form:{Message:null, Attachments:null, Status:null, TicketFolderID:null, TicketMessageFolderID:null},
              ticketStatuses:[
                  {"name":"Open", "status":1},
                  {"name":"Waiting for Response", "status":2},
                  {"name":"Closed", "status":3},
                  // {"name":"Pending", "status":4},
              ],
              statusObj:null,
              attachments:[],
              editMessage:false,
              editorOption: {placeholder:"",modules: {toolbar: '#toolbar'}},
              deletedFiles:[],
              buffer_size:0,
              progressMessage:null,
              loadingObj:{
                  loading:false,
                  message:"Please wait..."    
              }
            }
        },
        components:{
            quillEditor,
            'dialog-progress':dialogProgress
        },
        methods: {

            onEditorBlur(editor) {
              this.editMessage = false;
              console.log('editor blur!', editor);
            },
            onEditorFocus(editor) {
                this.editMessage = true;
                console.log('editor focus!', editor)
            },
            onEditorReady(editor) {
                console.log('editor ready!', editor)
            },

            fn_getToken: function(){
              let self = this;
              this.$root.fn_showLoading('Please wait...', this.loadingObj);
              this.$root.fn_getAuthToken(function(token){
                if(token=='error'){
                  self.$root.fn_hideLoading(self.loadingObj);
                  self.$root.fn_showToastMsg("Something went wrong. Try again!");
                  return;
                }
                self.fn_getMessage(token);
              })
            },

            fn_getMessage: function(token){
              var headersObj = {
              headers: { 
                    'Authorization': 'bearer '+token,
                  }
              };

              axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/tickets/'+this.$route.params.ticketid+'/Messages/'+this.$route.params.messageid, headersObj, {timeout:30000})
              .then(response => {
                  this.$root.fn_hideLoading(this.loadingObj);
                  console.log(response);
                  console.log(JSON.stringify(response.data));
                  this.messageData = response.data;
                  this.Form.Message = this.messageData.Message;
                  this.attachments = this.messageData.Attachments ? JSON.parse(this.messageData.Attachments) : [];
                  this.Form.TicketMessageFolderID = this.messageData.TicketMessageFolderID;
                  this.statusObj = this.messageData.TicketStatus;
                  this.editMessage = true;
              }).catch(e => {
                  this.$root.fn_hideLoading(this.loadingObj);
                  this.$root.fn_showToastMsg("Something went wrong. Try again!");
                  console.log(e);
              })
            },

            fn_checkValidations: function(messageStatus){
              if(!this.Form.Message){
                this.$root.fn_showToastMsg("Please add message!");
              }
              else{
                let self = this;
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){
                  if(token=='error'){
                    self.$root.fn_hideLoading(self.loadingObj);
                    self.$root.fn_showToastMsg("Something went wrong. Try again!");
                    return;
                  }
                  self.fn_updateMessage(token, messageStatus);
                })
              }
            },

            fn_updateMessage: function(token, messageStatus){

              let postObj = {};
              postObj.Message = this.Form.Message;
              postObj.Attachments = this.attachments.length ? JSON.stringify(this.attachments) : null;
              postObj.Status = messageStatus;
              postObj.TicketMessageFolderID = this.Form.TicketMessageFolderID;
              postObj.TicketStatus = null;
              if(typeof(this.statusObj) == 'object' && this.statusObj.status){
                postObj.TicketStatus = this.statusObj.status;
              }
              else{
                postObj.TicketStatus = this.statusObj;
              }

              postObj.FromDeviceType = this.$root.platform() == 'desktop' ? 3 : 1;
              // postObj.TicketStatus = messageStatus == 1 ? this.statusObj.status : null;

              console.log(JSON.stringify(postObj));

              var headersObj = {
              headers: { 
                    'Authorization': 'bearer '+token,
                  }
              };

              axios.post(process.env.VUE_APP_BASE_API_URL + "/api/supportteam/tickets/"+this.$route.params.ticketid+"/"+this.$route.params.messageid+"/updateconversation", postObj, headersObj, {timeout:30000})
              .then(response => {
                  console.log(response);
                  if(this.deletedFiles.length){
                    this.fn_deleteAttachmentsFromFB();
                  }
                  else{
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Message updated!");
                    this.fn_goBack(); 
                  }
              }).catch(e => {
                  this.$root.fn_hideLoading(this.loadingObj);
                  this.$root.fn_showToastMsg("Something went wrong. Try again!");
                  console.log(e);
              })
                
            },

            fn_deleteAttachmentsFromFB: function(){
              let self = this;
              let filterObj = {"firebaseFileUrls":this.deletedFiles};

              this.getFilterData('DeleteAllFirebaseFile', null, filterObj, 
                  {
                      onSuccess: function(response){
                          console.log(response);
                          self.$root.fn_hideLoading(self.loadingObj);
                          self.$root.fn_showToastMsg("Message updated!");
                          self.fn_goBack();
                      }, 
                      onError: function(error){
                          console.log(error);
                          self.$root.fn_hideLoading(self.loadingObj);
                          self.$root.fn_showToastMsg("Message updated!");
                          self.fn_goBack();
                      }
                  }
              );
            },
           
            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        
        mounted: function(){
          window.js_globals.fn_onBackPress = this.fn_goBack;
          window.js_globals.fn_setStatus = this.fn_setStatus;
          this.Form.TicketFolderID = this.$route.query.ticketFolderId;
          this.fn_getToken();
        },
        beforeRouteLeave(to, from, next){
            if(this.$refs['ref_dialogProgress'].dialog_progress){
                this.$root.fn_showToastMsg("File uploading in progress!");
                next(false);
            }
            else if(this.$refs['ref_dialogPermission'].dialog_permission){
              this.$refs['ref_dialogPermission'].dialog_permission = false;
              next(false);
            }
            else{
                next();
            }
        }
    }
</script>