<template>
    <div full-height>
        <v-toolbar flat fixed v-if="$root.platform()=='android'">
            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
            <v-toolbar-title class="ml-5">Message Signature</v-toolbar-title>
        </v-toolbar>

        <div class="hasfooter hasQuillToolbar" :class="[$root.platform()=='android' ? 'hastoolbar' : 'desktop-view']">
            <v-form class="form-element">

                <v-layout align-center nomargin row wrap white-background>

                     <v-flex xs12 sm12 md12 form-item class="pb-4">
                        <label style="color: rgba(0, 0, 0, 0.6);font-size: 12px;">Signature Text</label>
                        
                        <quill-editor class="mt-1"
                          ref="myTextEditor"
                          v-model="Form.SignatureText"
                          :options="editorOption"
                          placeholder=""
                          @blur="onEditorBlur($event)"
                          @focus="onEditorFocus($event)"
                          @ready="onEditorReady($event)">
                          <div id="toolbar" slot="toolbar">
                              <!-- Add a bold button -->
                              <button class="ql-bold">Bold</button>
                              <button class="ql-italic">Italic</button>
                              <button class="ql-underline">ql-underline</button>
                              <button class="ql-strike">Italic</button>
                              <!-- Add ordered/bullet list -->
                              <button class="ql-list" value="ordered">ql-list</button>
                              <button class="ql-list" value="bullet">ql-list</button>
                              <button class="ql-link">ql-link</button>

                              
                          </div>
                      </quill-editor>

                        <v-divider dark-opacity-divider></v-divider>
                    </v-flex>
                   
                </v-layout>

            </v-form>

            <v-footer style="position:fixed;bottom:0;width:100%">
                <v-layout row wrap>
                    <v-flex xs12 sm12 md12 form-item>
                        <v-btn color="primary" block @click="fn_checkValidations">
                          Update Signature
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-footer>
        </div>  

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios'
import {supportMixin} from './support_mixin'
// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import { quillEditor } from 'vue-quill-editor'

    export default{
        name:'form_signature',
        mixins:[supportMixin],
        data(){
            return{
              toolbarTitle:"Message",
                Form:{SignatureText:null},
                editorOption: {placeholder:"",modules: {toolbar: '#toolbar'}},
                loadingObj:{
                    loading:false,
                    message:"Please wait..."    
                }
            }
        },
        components:{
            quillEditor,
        },
        methods: {

            onEditorBlur(editor) {
              console.log('editor blur!', editor);
            },
            onEditorFocus(editor) {
                console.log('editor focus!', editor)
            },
            onEditorReady(editor) {
                console.log('editor ready!', editor)
            },

            fn_init: function(){
              let self = this;
              this.fn_getSignature(function(response){
                self.Form.SignatureText = response.data.SignatureText;
              })
            },

            fn_checkValidations: function(){
              if(!this.Form.SignatureText){
                this.$root.fn_showToastMsg("Please add signature text!");
              }
              else{
                let self = this;
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){
                  if(token=='error'){
                    self.$root.fn_hideLoading(self.loadingObj);
                    self.$root.fn_showToastMsg("Something went wrong. Try again!");
                    return;
                  }
                  self.fn_postSignature(token);
                })
              }
            },

            fn_postSignature: function(token){

              var headersObj = {
              headers: { 
                    'Authorization': 'bearer '+token,
                  }
              };

              console.log(JSON.stringify(this.Form));

              axios.post(process.env.VUE_APP_BASE_API_URL + "/api/supportteam/SupportSignatures/postsignature", this.Form, headersObj, {timeout:30000})
              .then(response => {
                  this.$root.fn_hideLoading(this.loadingObj);
                  console.log(response);
                  this.$root.fn_showToastMsg("Signature updated!");
                  this.fn_goBack();
              }).catch(e => {
                  this.$root.fn_hideLoading(this.loadingObj);
                  this.$root.fn_showToastMsg("Something went wrong. Try again!");
                  console.log(e);
              })
                
            },
           
            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        
        mounted: function(){
          window.js_globals.fn_onBackPress = this.fn_goBack;
          this.fn_init();
        }
    }
</script>