<template>
    <div>
        <v-toolbar flat extended fixed>

            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title class="ml-5">My Tickets</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn color="primary" text @click="fn_newTicket">New</v-btn>

            <v-text-field slot="extension" nopadding hide-details elevation-10  flat solo placeholder="Search..." v-model="searchValue" @input="fn_searchItem" clearable></v-text-field>

            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text icon v-bind="attrs" v-on="on"><v-icon color="primary">filter_list</v-icon></v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in ticketStatuses" :key="index" @click="fn_changeStatus(item.status)">
                  <v-list-item-title>{{item.name}}</v-list-item-title>
                  <v-list-item-action>
                    <v-icon v-if="item.status==selectedStatus">radio_button_checked</v-icon>
                    <v-icon v-else>radio_button_unchecked</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>

        </v-toolbar>

        <div class="hastoolbar hasExtension">

            <v-list three-line subheader full-width>

                <v-subheader v-if="myTickets.count">{{getSelectedStatus()}} ({{myTickets.count ? myTickets.count : 0}})</v-subheader>

                <v-divider v-if="myTickets.count"></v-divider>

                <div v-for="(ticket, index) in myTickets.Tickets" :key="index">
                    <v-list-item :class="{'unread':!ticket.IsRead}" style="padding-right:0;height:auto">
                        <v-list-item-content v-ripple @click="fn_viewTicket(ticket.SupportTicketID)">
                          <v-list-item-title class="mb-1">{{ticket.Title}}</v-list-item-title>
                          <v-list-item-subtitle class="mb-1">
                            Ticket No. {{ticket.TicketNumber}}
                            </v-list-item-subtitle>
                          <v-list-item-subtitle class="mb-1">Last Modified: {{ticket.ModifiedOn | moment("DD/MM/YYYY h:mm a")}}</v-list-item-subtitle>
                          <v-list-item-subtitle>
                              <div :class="$root.fn_statusBackgroundClass(ticket.Status)" style="color: #fff;padding: 2px 8px;border-radius: 4px;font-size: 0.875rem;display:inline;line-height:1.5">
                                {{$root.fn_ticketStatus(ticket.Status)}}
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action style="align-self: center !important;">
                            <v-btn icon @click="fn_addFeedback(ticket.SupportTicketID)" v-if="ticket.Status===3 && !ticket.IsFeedback">
                                <v-icon color="primary">feedback</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if="myTickets.Tickets.length-1 != index"></v-divider>
                </div>

                <infinite-loading spinner="spiral" :identifier="infiniteId" @infinite="infiniteHandler" v-if="viewLoaded">
                      <div slot="no-results" font12 light-opacity-color class="pa-3"></div>
                      <div slot="no-more"></div>
                </infinite-loading>
            </v-list>

            <!-- <transition name="fade" mode="out-in"> -->
                <v-card noshadow v-if="!loading && showPlaceHolder && !myTickets.count" style="height: calc(100vh - 162px);display: flex;align-items: center;">
                    <v-card-text @click="fn_newTicket">

                        <div center-align>
                            <div v-ripple style="width: 68px;height: 68px;border-radius: 50%;background: #F0F0F0;margin: auto;padding: 12px;position:relative">
                                <i aria-hidden="true" class="v-icon material-icons" style="position: absolute;top: 0;right: 0px;background: #4b77be !important;border-radius: 50%;color: #fff !important;font-size: 20px;">add</i>
                                <v-icon style="font-size:42px">toll</v-icon>
                            </div><br>

                            <p class="title light-opacity-color" style="line-height:28px !important">Add New Ticket</p>
                        </div>

                    </v-card-text>
                </v-card>
            <!-- </transition> -->

        </div>

        <snackbar-retry :value="snackbarRetry" message="Failed to load tickets!" :action="fn_searchItem"></snackbar-retry>

        <v-snackbar v-model="$root.snackbarMessageObj.value" right top>
          {{$root.snackbarMessageObj.message}}
          <template v-slot:action="{ attrs }">
            <v-btn icon color="white" v-bind="attrs" text @click.native="$root.snackbarMessageObj.value=false">
                <v-icon>close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
    </div>
</template>

<script>
import axios from 'axios';

import InfiniteLoading from 'vue-infinite-loading';

import snackbarRetry from '../../dialogs/snackbar_retry'

    export default{
        name: 'user_ticket_items',
        data(){
            return{
                myTickets:{"Tickets":[], "count":null},
                offset:0,
                infiniteId: +new Date(),
                searchValue:'',
                viewLoaded:false,
                // ticketStatuses:[
                //     {"name":"All", "status":null},
                //     {"name":"Pending", "status":1},
                //     {"name":"In Process", "status":2},
                //     {"name":"Resolved", "status":3}
                // ],
                ticketStatuses:[
                    {"name":"All", "status":null},
                    {"name":"Open", "status":1},
                    {"name":"Waiting for Response", "status":2},
                    // {"name":"Pending", "status":4},
                    {"name":"Closed", "status":3}
                ],
                selectedStatus:null,
                snackbarRetry:false,
                fromView:null,
                showPlaceHolder:false,
                loading:false
            }
        },
        components: {
            InfiniteLoading,
            'snackbar-retry': snackbarRetry
        },
        methods:{

            fn_addFeedback: function(supportTicketID){
                this.$router.push({path:"/support/appsupport/tickets/"+supportTicketID+"/message", query:{businessid:this.$route.query.businessid, isfeedback:1}});
            },

            getSelectedStatus: function(){
                for(let i=0; i<this.ticketStatuses.length; i++){
                    if(this.ticketStatuses[i].status == this.selectedStatus){
                        return this.ticketStatuses[i].name;
                    }
                }
            },

            fn_newTicket: function(){
                this.$router.push({path:"/support/appsupport/tickets/new", query:{businessid:this.$route.query.businessid}});
            },

            fn_viewTicket: function(ticketId){
                this.$router.push({path:"/support/appsupport/tickets/"+ticketId, query:{businessid:this.$route.query.businessid}});
            },

            fn_changeStatus: function(status){
                this.selectedStatus = status;
                this.fn_searchItem();
            },

            infiniteHandler($state) {

                this.loading = true;

                let jsonData = {"Keywords": this.searchValue,"RequestType" : null,"Status": this.selectedStatus,"TicketNumber":null};

                let self = this;

                // alert('ok');

                this.$root.fn_getAuthToken(function(token){

                    // alert(token);

                    if(token=='error'){
                        self.snackbarRetry = true;
                        $state.complete();
                        self.loading = false;
                        return;
                    }

                    var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                    // axios.post(process.env.VUE_APP_BASE_API_URL + '/api/business/'+self.$route.query.businessid+'/User/tickets/getmylist?offset='+self.offset+'&limit=10', jsonData, headersObj, {timeout:30000})
                    axios.post(process.env.VUE_APP_BASE_API_URL + '/api/business/'+self.$route.query.businessid+'/Support/tickets/getmylist?offset='+self.offset+'&limit=10', jsonData, headersObj, {timeout:30000})
                    .then(response => {
                        console.log(response);
                        JSON.stringify(response);
                        self.myTickets.count = response.data.ItemCount;

                        if (self.myTickets.count > self.myTickets.Tickets.length) {
                            for(let i=0; i<response.data.Tickets.length; i++){
                                self.myTickets.Tickets.push(response.data.Tickets[i]);
                            }
                            $state.loaded();
                        } else {
                            $state.complete();
                        }

                        self.offset = self.myTickets.Tickets.length;

                        setTimeout(function(){
                            self.showPlaceHolder = true;
                            self.loading = false;
                        },300);

                    }).catch(e => {
                        console.log(e);
                        // self.$root.fn_showToastMsg("Something went wrong. Try again!");
                        self.snackbarRetry = true;
                        self.loading = false;
                        $state.complete();
                    })
                })
                
            },

            fn_searchItem:  function() {
                this.snackbarRetry = false;
                this.offset = 0;
                this.myTickets.Tickets = [];
                this.myTickets.count = 0;
                this.infiniteId += 1;
            },

            fn_goBack: function(){
                if(this.$root.platform() == 'iframe'){
                    window.parent.postMessage({
                        type: 'closeSupportApp'
                    }, "*");
                }
                else if(this.$root.isRestoreDb){
                    this.$root.fn_closeSupportAndReopenDB();
                }
                else{
                    this.exitApp();
                }
            },
        },
        // mounted: function(){
        //     /*global AndroidRoutingTest*/
        //     alert('in');
        //     AndroidRoutingTest.onPageLoadCompleted('1', JSON.stringify({}));
        //     window.js_globals.fn_onBackPress = this.fn_goBack;
        //     let self = this;
        //     setTimeout(function(){
        //         self.hideLoading();
        //         self.viewLoaded = true;
        //     },100);

        //     // getting current business logo
        //     this.$root.fn_getCurrentBusinessLogo(this.$route.query.businessid);
        // },
        activated: function(){
            /*global AndroidRoutingTest*/
            if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
                AndroidRoutingTest.onPageLoadCompleted('1', JSON.stringify({}));
            }

            // if(this.$root.platform() == 'iframe'){
            //     alert(this.$route.query.token);
            //     this.$root.iframeToken = this.$route.query.token;
            // }

            window.js_globals.fn_onBackPress = this.fn_goBack;
            let self = this;
            setTimeout(function(){
                self.hideLoading();
                self.viewLoaded = true;
                if(self.fromView=='userTicketForm' || localStorage.getItem("refresh_items")){
                    localStorage.removeItem("refresh_items");
                    self.fn_searchItem();
                }
            },100);

            // getting current business logo
            if(this.$root.platform() == 'iframe'){
                window.parent.postMessage({
                    type: 'getBusinessLogo'
                }, "*");
            }
            else{
                this.$root.fn_getCurrentBusinessLogo(this.$route.query.businessid);
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {

                vm.fromView = from.name;

            })
        },
    }
</script>