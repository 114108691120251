<template>
    <div>
        <v-dialog v-model="loading" persistent max-width="290">
          <v-card>
            <v-card-text>
              <div class="pt-2 pb-1">Please wait...</div>
              <!-- <v-progress-linear v-model="buffer_value" color="amber" height="25"></v-progress-linear> -->
              <v-progress-linear indeterminate></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
    </div>
</template>     

<script>

export default{
    props:['loading']
    // data(){  
    //  return{
    //      loading: false,
    //  }
    // }
}
</script>