<template>
    <div>
        <v-toolbar flat extended fixed v-if="$root.platform()=='android'">

            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title class="ml-5">Support Tickets</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-text-field slot="extension" nopadding hide-details elevation-10  flat solo placeholder="Search..." v-model.lazy="searchValue" @input="fn_searchItem" clearable></v-text-field>

            <v-btn color="primary" text @click="fn_exportExcel">
                <v-icon>vertical_align_bottom</v-icon>
            </v-btn>

             <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on"><v-icon color="primary">filter_list</v-icon></v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in ticketStatuses" :key="index" @click="fn_changeStatus(item.status)">
                  <v-list-item-title>{{item.name}}</v-list-item-title>
                  <v-list-item-action>
                    <v-icon v-if="item.status==selectedStatus">radio_button_checked</v-icon>
                    <v-icon v-else>radio_button_unchecked</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on"><v-icon color="primary">more_vert</v-icon></v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in ticketRequestTypes" :key="index" @click="fn_changeRequestType(item.requestType)">
                  <v-list-item-title>{{item.name}}</v-list-item-title>
                  <v-list-item-action>
                    <v-icon v-if="item.requestType==selectedRequestType">radio_button_checked</v-icon>
                    <v-icon v-else>radio_button_unchecked</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>

        </v-toolbar>

        <div :class="{'hastoolbar hasExtension': $root.platform()=='android'}">

            <v-text-field class="desktop-searchbar" nopadding hide-details elevation-10  flat solo placeholder="Search..." v-model.lazy="searchValue" @input="fn_searchItemDebounce" clearable v-if="$root.platform()=='desktop'"></v-text-field>

            <v-divider v-if="$root.platform()=='desktop'"></v-divider>

            <v-list three-line subheader full-width>

                <!-- <v-subheader v-if="myTickets.count || $root.platform()=='desktop'" class="pr-2"> -->
                <v-subheader style="height:40px">

                    <span @click="fn_openDialogPeriod">
                        <v-icon>date_range</v-icon>&nbsp;Period: {{dateFrom | moment("DD/MM/YYYY")}} To {{dateTo | moment("DD/MM/YYYY")}}
                    </span>

                    <v-spacer></v-spacer>

                    <span @click="fn_clearFilter()" style="margin-right: 16px; cursor: pointer; border: 1px solid black; border-radius: 20px; padding: 4px;" v-if="selectedStatus || selectedRequestType">
                        <span>Clear All Filters</span><v-icon class="ml-2" size="18">close</v-icon>
                    </span>

                    <span v-if="myTickets.count || $root.platform()=='desktop'">
                        {{getSelectedStatus()}} <span v-if="selectedRequestType">, {{getSelectedRequestType()}}&nbsp;</span> ({{myTickets.count ? myTickets.count : 0}})    
                    </span>


                    <template v-if="$root.platform()=='desktop'">
                        <v-btn color="primary" text @click="fn_exportExcel">
                            <v-icon>vertical_align_bottom</v-icon>
                        </v-btn>

                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on"><v-icon color="primary">filter_list</v-icon></v-btn>
                              </template>
                              <v-list>
                                <v-list-item @click="showStatusMenu">
                                  <v-list-item-title>Filter by status</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="showTypeMenu">
                                  <v-list-item-title>Filter by type</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>

                            <v-menu offset-y :position-x="x" :position-y="y" absolute v-if="$root.platform()=='desktop'" v-model="statusMenu">
                              <v-list>
                                <v-list-item v-for="(item, index) in ticketStatuses" :key="index" @click="fn_changeStatus(item.status)">
                                  <v-list-item-title>{{item.name}}</v-list-item-title>
                                  <v-list-item-action>
                                    <v-icon v-if="item.status==selectedStatus">radio_button_checked</v-icon>
                                    <v-icon v-else>radio_button_unchecked</v-icon>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list>
                            </v-menu>

                            <v-menu offset-y :position-x="x" :position-y="y" absolute v-if="$root.platform()=='desktop'" v-model="typeMenu">
                              <v-list>
                                <v-list-item v-for="(item, index) in ticketRequestTypes" :key="index" @click="fn_changeRequestType(item.requestType)">
                                  <v-list-item-title>{{item.name}}</v-list-item-title>
                                  <v-list-item-action>
                                    <v-icon v-if="item.requestType==selectedRequestType">radio_button_checked</v-icon>
                                    <v-icon v-else>radio_button_unchecked</v-icon>
                                  </v-list-item-action>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                    </template>

                    
                </v-subheader>

                <v-divider></v-divider>

                <div v-for="(ticket, index) in myTickets.Tickets" :key="index" :class="{'highlight':!ticket.IsSupportRead}">
                    <v-list-item @click="fn_viewTicket(ticket.SupportTicketID)" style="padding-right:0">
                        <v-list-item-content>
                          <v-list-item-title class="mb-1">{{ticket.Title}}</v-list-item-title>
                          <v-list-item-subtitle class="mb-1">Ticket No. {{ticket.TicketNumber}}</v-list-item-subtitle>
                          <v-list-item-subtitle class="mb-1">Last Modified: {{ticket.ModifiedOn | moment("DD/MM/YYYY h:mm a")}}</v-list-item-subtitle>
                          <v-list-item-subtitle>

                            

                            <div :class="$root.fn_statusBackgroundClass(ticket.Status)" style="color: #fff;padding: 2px 8px;border-radius: 4px;font-size: 0.875rem;display:inline;line-height:1.5">
                                {{$root.fn_ticketStatus(ticket.Status)}}
                            </div>

                            
                          </v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action v-if="ticket.IsNew">
                            <div style="background:orange;color:#fff;padding: 2px 8px;border-top-left-radius: 4px;border-bottom-left-radius: 4px;font-size: 0.875rem;display:inline;line-height:1.5">
                                New
                            </div>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if="myTickets.Tickets.length-1 != index"></v-divider>
                </div>

                <infinite-loading spinner="spiral" :identifier="infiniteId" @infinite="infiniteHandler">
                    <div slot="no-results" font12 light-opacity-color class="pa-3">There are no tickets!</div>
                    <div slot="no-more"></div>
                </infinite-loading>
            </v-list>

        </div>

        <snackbar-retry :value="snackbarRetry" message="Failed to load tickets!" :action="fn_searchItem"></snackbar-retry>

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <dialog-period-selection ref="ref_dialogCustomPeriod" title="Select Period" :openDatePicker="fn_openDatePicker" :startDate="selectedStartDate" :endDate="selectedEndDate" :actionMethod="fn_updateCustomDates"></dialog-period-selection>

        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

    </div>
</template>

<script>
import axios from 'axios';
import debounce from 'lodash/debounce';

import InfiniteLoading from 'vue-infinite-loading';
import snackbarRetry from '../../dialogs/snackbar_retry'

import dialogPeriodSelection from './dialogs/dialog_period_selection'
import dialogDatepicker from './dialogs/dialog_datepicker'

    export default{
        name:'support_ticket_items',
        data(){
            return{
                myTickets:{"Tickets":[], "count":null},
                offset:0,
                infiniteId: +new Date(),
                searchValue:'',
                viewLoaded:false,
                ticketStatuses:[
                    {"name":"All", "status":null},
                    {"name":"Open", "status":1},
                    {"name":"Waiting for Response", "status":2},
                    {"name":"Closed", "status":3},
                    // {"name":"Pending", "status":4}
                ],
                selectedStatus:1,
                ticketRequestTypes:[
                    {"name":"All", "requestType":null},
                    {"name":"General", "requestType":1},
                    {"name":"Login Issue", "requestType":2},
                    {"name":"Registration Issue", "requestType":3},
                    {"name":"Data Issue", "requestType":4},
                ],
                selectedRequestType:null,
                snackbarRetry:false,
                loadingObj:{
                    loading:false,
                    message:"Please wait..."    
                },
                statusMenu:false,
                typeMenu:false,
                x:null,
                y:null,
                dialog_datepicker:false,
                selectedDate:null,
                dateType:null,
                selectedStartDate:this.$root.minDateSelection,
                selectedEndDate:this.$root.maxDateSelection,
                dateFrom:this.$root.minDateSelection,
                dateTo:this.$root.maxDateSelection,
                routerPreviousPath: null
            }
        },
        components: {
            InfiniteLoading,
            'snackbar-retry': snackbarRetry,
            'dialog-period-selection':dialogPeriodSelection,
            'dialog-datepicker':dialogDatepicker
        },
        methods:{

            fn_openDialogPeriod: function(){
                this.selectedStartDate = this.dateFrom;
                this.selectedEndDate = this.dateTo;
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = true;
            },

            fn_openDatePicker: function (type, date) {
                let self = this;
                this.dateType = type;
                let dateObj = date ? new Date(date) : new Date();
                this.$refs['ref_dialogDatePicker'].selectedDate = dateObj.getFullYear()+'-'+("0" + (dateObj.getMonth()+1)).slice(-2)+'-'+("0" + dateObj.getDate()).slice(-2);
                setTimeout(function () {
                    self.dialog_datepicker = true;
                })
            },

            fn_updateDate: function (value) {
                // if(this.dateType=="end" && new Date(value).getTime() < new Date(this.$root.CurrentBusiness.OpeningDate).getTime()){
                //     this.showToast("Not Applicable");
                //     return;
                // }

                if(this.dateType=="start"){
                    this.selectedStartDate = value;
                }
                else if(this.dateType=="end"){
                    this.selectedEndDate = value;
                }

                this.dialog_datepicker = false;

                // this.fn_getDashboardData();
            },

            fn_updateCustomDates: function(){
                this.dateFrom = this.selectedStartDate;
                this.dateTo = this.selectedEndDate;
                // this.fn_closePeriodDialog();
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = false;

                // let filterObj = {};
                // filterObj.dateFrom = this.selectedStartDate;
                // filterObj.dateTo = this.selectedEndDate;

                let filterObj = {}
                filterObj.dateFrom = this.selectedStartDate;
                filterObj.dateTo = this.selectedEndDate;

                if(localStorage.getItem('filters')){
                    filterObj = JSON.parse(localStorage.getItem('filters'));
                    filterObj.dateFrom = this.selectedStartDate;
                    filterObj.dateTo = this.selectedEndDate;
                }

                localStorage.setItem('filters', JSON.stringify(filterObj));

                this.fn_searchItem();
            },

            showStatusMenu (e) {
                e.preventDefault()
                this.showMenu = false
                this.x = window.screen.width - 40;
                this.y = 146
                this.$nextTick(() => {
                  this.statusMenu = true;
                })
              },

            showTypeMenu (e) {
                e.preventDefault()
                this.showMenu = false
                this.x = window.screen.width - 40;
                this.y = 146
                this.$nextTick(() => {
                  this.typeMenu = true;
                })
              },

            getSelectedStatus: function(){
                for(let i=0; i<this.ticketStatuses.length; i++){
                    if(this.ticketStatuses[i].status == this.selectedStatus){
                        return this.ticketStatuses[i].name;
                    }
                }
            },

            getSelectedRequestType: function(){
                for(let i=0; i<this.ticketRequestTypes.length; i++){
                    if(this.ticketRequestTypes[i].requestType == this.selectedRequestType){
                        return this.ticketRequestTypes[i].name;
                    }
                }
            },

            fn_newTicket: function(){
                this.$router.push({path:"/support/supportteam/items/getbusiness"});
            },

            fn_viewTicket: function(ticketId){
                this.$router.push({path:"/support/supportteam/tickets/"+ticketId, query:{"businessid":this.$route.query.businessid}});
            },

            fn_changeStatus: function(status){
                let filterObj = {};
                filterObj.selectedStatus = status;

                if(localStorage.getItem('filters')){
                    let filterData = JSON.parse(localStorage.getItem('filters'));
                    filterData.selectedRequestType != undefined ? filterObj.selectedRequestType = filterData.selectedRequestType : '';
                    if(filterData.selectedRequestType !== undefined)
                        filterObj.selectedRequestType = filterData.selectedRequestType;
                    // filterData.dateFrom != undefined ? filterObj.dateFrom = filterData.dateFrom : '';
                    // filterData.dateTo != undefined ? filterObj.dateTo = filterData.dateTo : '';
                }

                filterObj.dateFrom = this.dateFrom;
                filterObj.dateTo = this.dateTo;

                localStorage.setItem('filters', JSON.stringify(filterObj));
                this.selectedStatus = status;
                this.fn_searchItem();
            },

            fn_changeRequestType: function(requestType){
                let filterObj = {};
                filterObj.selectedRequestType = requestType;

                if(localStorage.getItem('filters')){
                    let filterData = JSON.parse(localStorage.getItem('filters'));
                    filterData.selectedStatus != undefined ? filterObj.selectedStatus = filterData.selectedStatus : '';
                    if(filterData.selectedStatus !== undefined)
                        filterObj.selectedStatus = filterData.selectedStatus;
                    // filterData.dateFrom != undefined ? filterObj.dateFrom = filterData.dateFrom : '';
                    // filterData.dateTo != undefined ? filterObj.dateTo = filterData.dateTo : '';
                }

                filterObj.dateFrom = this.dateFrom;
                filterObj.dateTo = this.dateTo;

                localStorage.setItem('filters', JSON.stringify(filterObj));
                this.selectedRequestType = requestType;
                this.fn_searchItem();
            },

            fn_clearFilter: function(){
                let filterObj = {};
                filterObj.selectedRequestType = null;
                filterObj.selectedStatus = null;
                filterObj.dateFrom = this.dateFrom;
                filterObj.dateTo = this.dateTo;

                localStorage.setItem('filters', JSON.stringify(filterObj));
                this.selectedStatus = null;
                this.selectedRequestType = null;
                this.fn_searchItem();
            },

            infiniteHandler($state) {

                let jsonData = {
                    "Keywords": this.searchValue, 
                    "BusinessID" : null, 
                    "RequestType" : this.selectedRequestType,
                    "Status": this.selectedStatus,
                    "TicketNumber":null, 
                    "DateFrom":this.dateFrom, 
                    "DateTo":this.dateTo
                };

                if(localStorage.getItem('filters')){
                    let filterData = JSON.parse(localStorage.getItem('filters'));

                    if(filterData.selectedRequestType || this.routerPreviousPath == 'supportTicketDisplay'){
                        jsonData.RequestType = filterData.selectedRequestType === undefined ? null : filterData.selectedRequestType;
                        this.selectedRequestType = jsonData.RequestType;
                    }

                    if(filterData.selectedStatus || this.routerPreviousPath == 'supportTicketDisplay'){
                        jsonData.Status = filterData.selectedStatus === undefined ? 1 : filterData.selectedStatus;
                        this.selectedStatus = jsonData.Status;
                    }
                    
                    if(filterData.dateFrom){
                        jsonData.DateFrom = filterData.dateFrom;
                        this.dateFrom = filterData.dateFrom;
                    }
                    
                    if(filterData.dateTo){
                        jsonData.DateTo = filterData.dateTo;
                        this.dateTo = filterData.dateTo;
                    }
                }

                jsonData.DateFrom = this.$moment(jsonData.DateFrom).startOf('day');
                jsonData.DateTo = this.$moment(jsonData.DateTo).endOf('day');

                jsonData.DateFrom = this.fn_getLocalDateFormat(jsonData.DateFrom);
                jsonData.DateTo = this.fn_getLocalDateFormat(jsonData.DateTo);

                console.log(JSON.stringify(jsonData));

                let self = this;
                this.$root.fn_getAuthToken(function(token){

                    if(token=='error'){
                        self.snackbarRetry = true;
                        $state.complete();
                        return;
                    }

                    var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                    axios.post(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/tickets/getlist?offset='+self.offset+'&limit=10', jsonData, headersObj, {timeout:30000})
                    .then(response => {
                        console.log(response);
                        JSON.stringify(response);
                        self.myTickets.count = response.data.ItemCount;

                        if (self.myTickets.count > self.myTickets.Tickets.length) {
                            for(let i=0; i<response.data.Tickets.length; i++){
                                self.myTickets.Tickets.push(response.data.Tickets[i]);
                            }
                            $state.loaded();
                        } else {
                            $state.complete();
                        }

                        self.offset = self.myTickets.Tickets.length;

                    }).catch(e => {
                        console.log(e);
                        self.snackbarRetry = true;
                        self.$root.fn_showToastMsg("Something went wrong. Try again!");
                        $state.complete();
                    })
                })
                
            },

            fn_searchItem:  function() {
              this.snackbarRetry = false;
              this.offset = 0;
              this.myTickets.Tickets = [];
              this.myTickets.count = 0;
              this.infiniteId += 1;
            },

            fn_searchItemDebounce: debounce(function() {
                this.snackbarRetry = false;
                this.offset = 0;
                this.myTickets.Tickets = [];
                this.myTickets.count = 0;
                this.infiniteId += 1;
            }, 1000),


            fn_exportExcel: function() {
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                let self = this;
                this.$root.fn_getAuthToken(function(token){

                    if(token=='error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        return;
                    }

                    let jsonData = {"Keywords":"", "BusinessID":null, "RequestType":self.selectedRequestType, "Status":self.selectedStatus,"TicketNumber":null,"DateFrom":null,"DateTo":null}

                    jsonData.DateFrom = self.$moment(self.dateFrom).startOf('day');
                    jsonData.DateTo = self.$moment(self.dateTo).endOf('day');

                    jsonData.DateFrom = self.fn_getLocalDateFormat(jsonData.DateFrom);
                    jsonData.DateTo = self.fn_getLocalDateFormat(jsonData.DateTo);

                    var headersObj = {
                        headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                    let requestKey = self.guidWeb();
                    // axios.post(process.env.VUE_APP_BASE_API_URL+"/api/supportteam/tickets/ExportRequest", jsonData, headersObj, {timeout:30000})
                    axios.post(process.env.VUE_APP_BASE_API_URL+"/api/supportteam/tickets/ExportRequest?requestkey="+requestKey, jsonData, headersObj, {timeout:30000})
                    .then(response => {
                        self.$root.fn_hideLoading(self.loadingObj);
                        console.log(JSON.stringify(response));
                        // let exportpath = process.env.VUE_APP_BASE_API_URL+"/api/Export/"+response.data;
                        let exportpath = process.env.VUE_APP_BASE_API_URL+"/api/supportteam/Export/"+response.data+"?requestkey="+requestKey;

                        if(self.$root.platform()=='desktop'){
                            window.open(exportpath);
                        }
                        else{
                            self.fn_downloadExcel(exportpath);
                        }
                    }).catch(e => {
                        self.$root.fn_hideLoading(self.loadingObj);
                        console.log(e);
                    })
                })
            },

            fn_downloadExcel: function(path){
                let self = this;
                this.getData('OpenBrowser', path, 
                    {   
                        onSuccess: function(response){
                            console.log(response);
                        }, 
                        onError: function(error){
                            self.$root.fn_showToastMsg("Something went wrong. Try again!");
                            console.log(error);
                        }
                    }
                );
            },

            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
            /*global AndroidRoutingTest*/
            AndroidRoutingTest.onPageLoadCompleted('1', JSON.stringify({}));
            this.hideLoading();
        },

        activated() {
            console.log('Component activated');
        },
        deactivated() {
            console.log('Component deactivated');
        },

        // activated: function(){
        //     window.js_globals.fn_onBackPress = this.fn_goBack;
        // },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.routerPreviousPath = from.name;
                if(from.name == 'supportItems' || from.name == 'defaultPage'){
                    console.log(vm);
                    localStorage.removeItem('filters');
                }
            })
        },
        beforeRouteLeave (to, from , next) {
            if(this.dialog_datepicker){
                this.dialog_datepicker = false;
                next(false);
            }
            else if(this.$refs['ref_dialogCustomPeriod'].dialog_period_selection){
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = false;
                next(false);
            }
            else{
                next();
            }
        },
    }
</script>