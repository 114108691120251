<template>
    <div>
        <v-dialog v-model="dialog_error_logs" scrollable persistent no-click-animation transition="slide-y-transition">
            <v-card>
                <v-card-title>Select File</v-card-title>
                <v-divider></v-divider>
                <v-card-text nopadding>
                    <v-list two-line>
                        <v-list-item v-for="(item, index) in logFileObj.logFiles" :key="index" @click="uploadLogs(item.fileName)">
                            <v-list-item-content>
                                <v-list-item-title>{{item.fileName}}</v-list-item-title>
                                <v-list-item-subtitle>From: {{item.fileTime | moment("DD/MM/YYYY")}} to {{fn_getCloseFileTime(item.fileTime) | moment("DD/MM/YYYY")}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small color="blue darken-1" text @click="closeDialogWeb">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>     

<script>

export default{
    props:['logFileObj', 'uploadLogs'],
    data(){ 
        return{
            dialog_error_logs: false,
        }
    },
    methods: {
        openDialogWeb: function () {    
            this.dialog_error_logs = true;
        },
        closeDialogWeb: function () {
            this.dialog_error_logs = false;
        },
        fn_getCloseFileTime: function(fileStartTime){
            let startTime = new Date(fileStartTime);
            let closeDate = new Date(startTime.setDate(startTime.getDate() + 7));
            if(closeDate < new Date()){
                return closeDate;
            }
            else{
                return new Date();
            }
        }
    }
}
</script>