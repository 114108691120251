<template>
    <div>
        <div v-if="displayMode=='browser tab'">
            <v-card noshadow align-center>
                <v-card-text>
                        <v-layout row wrap style="margin:auto">

                            <p class="title grey-color">
                                Kindly install app to access <strong>Bizops Support App</strong> from browser address bar.
                            </p>

                        </v-layout>
                </v-card-text>
            </v-card>
        </div> 
        <v-dialog v-model="loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    export default{
        name:'default',
        data(){
            return{
                loading: true
            }
        },
        methods:{

            fn_checkCookie: function(){
                var user = this.fn_getCookie("user");
                localStorage.setItem("userProfile", JSON.stringify({"Name":user}));
                if (user != "") {
                    console.log("Already signed in!");
                    // this.fn_init();
                    let self = this;
                    this.getUser(function(user){
                        console.log(user);
                        setTimeout(function(){
                            self.$router.replace({path:"/support/supportteam/tickets/items"});
                        },5000);
                    })
                } 
                else{
                    let self = this;
                    this.$root.signOut(function(status){
                        if(status == 'error'){
                            self.loading = false;
                            return;
                        }
                        self.loading = false;
                        setTimeout(function(){
                            self.$router.replace({path:"/support/supportteam/login"});
                        },300);
                    })
                }
            },

            fn_getCookie: function(cname) {
                var name = cname + "=";
                var decodedCookie = decodeURIComponent(document.cookie);
                var ca = decodedCookie.split(';');
                for(var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            }
        },
        mounted: function(){
            if(this.$root.platform()=='desktop'){
                let self = this;
                if(process.env.NODE_ENV === 'development'){
                    setTimeout(function(){
                        self.fn_checkCookie();
                    },300);
                }
                else{
                    let waitFormDomContentLoad = setInterval(function(){
                        if(self.displayMode){
                            if(self.displayMode=="standalone"){
                                self.fn_checkCookie();
                            }
                            else{
                                self.loading = false;
                            }
                            clearInterval(waitFormDomContentLoad);
                        }
                    },500);
                }
                
                // let self = this;
                // setTimeout(function(){
                //     self.fn_checkCookie();
                // },300);
            }
        }
    }
</script>