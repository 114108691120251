<template>
    <div full-height>
        <v-toolbar flat fixed>
            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
            <v-toolbar-title class="ml-5">New Ticket</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" :disabled="loading"><v-icon color="primary">attachment</v-icon></v-btn>
              </template>
              <v-list nopadding>
                <v-list-item @click="fn_selectFileViaDesktop('file')">
                  <v-list-item-title>Attach File</v-list-item-title>
                </v-list-item>

                <template v-if="$root.platform()!='iframe'">
                    <v-list-item @click="fn_checkStorage('db')">
                        <v-list-item-title>Attach Database</v-list-item-title>
                    </v-list-item>
                    
                    <v-list-item @click="fn_checkStorage('logs')">
                        <v-list-item-title>Attach Error Logs</v-list-item-title>
                    </v-list-item>
                </template>
                
                
              </v-list>
            </v-menu>
        </v-toolbar>

        <!-- <div class="hastoolbar hasfooter" :class="{'hasQuillToolbar':editMessage}"> -->
        <div class="hastoolbar hasfooter hasQuillToolbar">

            <input type="file" id="filePicker" style="display:none" @change="fn_validateFile"/>

            <v-form class="form-element">

                <v-expansion-panels flat class="mb-3" v-if="attachments && attachments.length">
                  <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="expand_more" style="padding:8px 16px;min-height:48px;">Attachments ({{attachments.length}})</v-expansion-panel-header>
                    <v-divider></v-divider>
                    <v-expansion-panel-content>
                      <div v-for="(item, index) in attachments" :key="index">
                        <v-chip class="mr-2 mt-2 mb-2 pr-1" color="primary" label text-color="white">
                          <span style="overflow:hidden;text-overflow:ellipsis">{{item.fileName}}</span> 
                          <v-icon v-ripple class="ml-3" @click="fn_deleteAttachment(item.filePath, index)">close</v-icon>
                        </v-chip>  
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-layout align-center nomargin padding-top-ten row wrap white-background>
                    <v-flex xs12 sm12 md12 form-item>
                       <!-- <v-text-field  
                            v-model="Form.Title" 
                            label="Title" 
                            placeholder=" "
                            v-validate="'required'"
                            data-vv-name="title"  
                            :error-messages="errors.collect('title')"
                            required>
                        </v-text-field> -->
                        <v-textarea 
                          v-validate="'required|max:250'"
                          data-vv-name="title" 
                          :error-messages="errors.collect('title')" 
                          rows="1" 
                          v-model="Form.Title"
                          placeholder=" " 
                          label="Title" 
                          :counter="250"
                          :disabled="loading">
                        </v-textarea>
                    </v-flex>

                    <v-flex xs12 sm12 md12 form-item>
                        <v-select :disabled="loading" :items="requestTypes" label="Request Type" placeholder="" v-model="requestTypeObj" return-object item-text="name" item-value="value"></v-select>
                    </v-flex>

                    <!-- <v-flex xs12 sm12 md12 form-item class="pb-4" :class="{'blur':!editMessage}"> -->
                    <v-flex xs12 sm12 md12 form-item class="pb-4">
                        <label style="color: rgba(0, 0, 0, 0.6);font-size: 12px;">Message</label>
                        
                        <quill-editor class="mt-1"
                            :disabled="loading"
                          ref="myTextEditor"
                          v-model="Form.Message"
                          :options="editorOption"
                          placeholder=""
                          @blur="onEditorBlur($event)"
                          @focus="onEditorFocus($event)"
                          @ready="onEditorReady($event)">
                          <div id="toolbar" slot="toolbar">
                              <!-- Add a bold button -->
                              <button :disabled="!editMessage" :class="{'disabled-richtext-toolbar-button':!editMessage}" class="ql-bold">Bold</button>
                              <button :disabled="!editMessage" :class="{'disabled-richtext-toolbar-button':!editMessage}" class="ql-italic">Italic</button>
                              <button :disabled="!editMessage" :class="{'disabled-richtext-toolbar-button':!editMessage}" class="ql-underline">ql-underline</button>
                              <button :disabled="!editMessage" :class="{'disabled-richtext-toolbar-button':!editMessage}" class="ql-strike">Italic</button>
                              <!-- Add ordered/bullet list -->
                              <button :disabled="!editMessage" :class="{'disabled-richtext-toolbar-button':!editMessage}" class="ql-list" value="ordered">ql-list</button>
                              <button :disabled="!editMessage" :class="{'disabled-richtext-toolbar-button':!editMessage}" class="ql-list" value="bullet">ql-list</button>
                              <button :disabled="!editMessage" :class="{'disabled-richtext-toolbar-button':!editMessage}" class="ql-link">ql-link</button>
                              
                          </div>
                      </quill-editor>

                        <v-divider dark-opacity-divider></v-divider>
                    </v-flex>

                </v-layout>

            </v-form>

            <v-footer style="position:fixed;bottom:0;width:100%;padding:0">
                <v-layout ma-0 row wrap>
                    <v-flex xs12 sm12 md12 style="height:48px;">
                        <v-btn :disabled="loading" style="height:100%" color="primary" block @click="fn_checkValidations">Create Ticket</v-btn>
                    </v-flex>
                </v-layout>
            </v-footer>
        </div>  

        <dialog-progress ref="ref_dialogProgress" :status="buffer_size" :progressMessage="progressMessage" :abortRequest="fn_cancelUpload" :cancelInit="cancelInit"></dialog-progress>

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">{{loadingObj.message ? loadingObj.message : 'Please wait...'}}</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <dialog-permission ref="ref_dialogPermission"></dialog-permission>
        <dialog-error-logs ref="ref_dialogErrorLogs" :logFileObj="errorLogFileObj" :uploadLogs="fn_getlogEncKey"></dialog-error-logs>

        <dialog-confirmation ref="ref_dialogConfirmation" header="Attach Database" confirmationMessage="Desktop connection will break. Would you like to continue?" :actionMethod="fn_getLocalKeyValue" okBtnText="Yes" cancelBtnText="No"></dialog-confirmation>

        <v-snackbar v-model="$root.snackbarMessageObj.value" right top>
          {{$root.snackbarMessageObj.message}}
          <template v-slot:action="{ attrs }">
            <v-btn icon color="white" v-bind="attrs" text @click.native="$root.snackbarMessageObj.value=false">
                <v-icon>close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
    </div>
</template>

<script>
import dialogProgress from '../../dialogs/dialog_progress'
import dialogErrorLogs from './dialogs/dialog_error_logs'

import dialogConfirmation from '../../dialogs/dialog_confirmation'

import axios from 'axios'
import {userMixin} from './user_mixin'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import { quillEditor } from 'vue-quill-editor'

    export default{
        mixins:[userMixin],
        name:'user_ticket_form',
        data(){
            return{
                Form:{Title:null, Message:'', Attachments:null, RequestType:null, TicketFolderID:null, TicketMessageFolderID:null},
                requestTypes:[{"value":1, "name":"General"},{"value":2, "name":"Login Issue"},{"value":3, "name":"Registration Issue"},{"value":4, "name":"Data Issue"}],
                requestTypeObj:{"value":1, "name":"General"},
                attachments:[],
                editMessage:false,
                editorOption: {placeholder:"",modules: {toolbar: '#toolbar'}},
                buffer_size:0,
                progressMessage:null,
                loading:false,
                loadingObj:{
                    loading:false,
                    message:"Please wait..."    
                },
                errorLogFileObj:null
            }
        },
        components:{
            quillEditor,
            'dialog-progress':dialogProgress,
            'dialog-error-logs':dialogErrorLogs,
            'dialog-confirmation':dialogConfirmation
        },
        methods: {

          onEditorBlur(editor) {
            this.editMessage = false;
            console.log('editor blur!', editor);
          },
          onEditorFocus(editor) {
              this.editMessage = true;
              // let self = this;
              // setTimeout(function () {
              //   self.$vuetify.goTo(editor.container, self.scrollOptions);
              // },300);
              console.log('editor focus!', editor)
          },
          onEditorReady(editor) {
              console.log('editor ready!', editor)
          },

            fn_checkValidations: function(){
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        if(!this.Form.Message){
                          this.$root.fn_showToastMsg("Please add message!");
                        }
                        else{
                            let self = this;
                            this.loading = true;
                            this.showLoading('Please wait...');
                            this.$root.fn_getAuthToken(function(token){
                                if(token == 'error'){
                                    self.loading = false;
                                    self.hideLoading();
                                    return;
                                }

                                self.fn_createTicket(token);
                            })
                        }
                    }
                    else{
                        this.$vuetify.goTo('.error--text', this.scrollOptions);
                    }
                });
            },

            fn_createTicket: function(token){

                var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                this.Form.Attachments = this.attachments.length ? JSON.stringify(this.attachments) : null;
                this.Form.RequestType = this.requestTypeObj.value;
                this.Form.FromDeviceType = this.$root.platform() == 'desktop' ? 3 : 1;

                console.log(JSON.stringify(this.Form));

                // axios.post(process.env.VUE_APP_BASE_API_URL + '/api/business/'+this.$route.query.businessid+'/User/tickets/create', this.Form, headersObj, {timeout:30000})
                axios.post(process.env.VUE_APP_BASE_API_URL + '/api/business/'+this.$route.query.businessid+'/Support/tickets/create', this.Form, headersObj, {timeout:30000})
                .then(response => {
                    this.hideLoading();
                    console.log(response);
                    this.$root.fn_showToastMsg('Ticket created!');
                    localStorage.removeItem('user_ticket_filters');
                    this.fn_goBack();
                }).catch(e => {
                    this.loading = false;
                    this.hideLoading();
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    console.log(e);
                })
                
            },
           
            fn_goBack: function(){
              this.$router.go(-1);
              // if(loadItems){
              //   this.$router.replace({"path":"/support/appsupport/tickets/items", query:{"businessid":this.$route.query.businessid}, params:{"loadItems":1}});
              // }
              // else{
              //   this.$router.go(-1);
              // }
            }
        },
        
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
            window.js_globals.fn_setStatus = this.fn_setStatus;
            let self = this;
            this.fn_generateGuid(function(guid){
              self.Form.TicketFolderID = guid;
            });
        },
        beforeRouteLeave(to, from, next){
            if(this.$refs['ref_dialogProgress'].dialog_progress){
                this.$root.fn_showToastMsg("File uploading in progress!");
                next(false);
            }
            else if(this.$refs['ref_dialogPermission'].dialog_permission){
              this.$refs['ref_dialogPermission'].dialog_permission = false;
              next(false);
            }
            else if(this.$refs['ref_dialogConfirmation'].dialog_confimation){
              this.$refs['ref_dialogConfirmation'].closeDialogWeb();
              next(false);
            }
            else{
                next();
            }
        }
    }
</script>