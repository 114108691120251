<template>
    <div full-height class="height100">
        <v-toolbar flat fixed v-if="$root.platform()=='desktop'">
            <v-img max-height="24" max-width="24" src="../../assets/BizOpslogo_Updated.svg" class="mr-4"></v-img>
            <v-toolbar-title>BizOps App Support</v-toolbar-title>
            <v-spacer></v-spacer>
            <span class="primary-color mr-4" style="font-size:0.875rem" v-if="userProfile">{{userProfile.Name}}</span>
            <span class="primary-color">|</span>
            <v-btn color="error" text @click="fn_signOut">Sign Out <v-icon right>exit_to_app</v-icon></v-btn>
        </v-toolbar>

        <div full-height class="height100" :class="{'hastoolbar':$root.platform()=='desktop'}">
            <div v-if="$root.platform()=='desktop'">
                <v-navigation-drawer absolute permanent style="position:fixed;top:56px;">
                    <v-list nav dense>
                        <v-list-item-group v-model="item" color="primary">
                          <template v-for="(group, groupIndex) in itemGroups">
                              <v-subheader :key="'group'+groupIndex" v-if="group.header">{{group.header}}</v-subheader>
                              <v-list-item  v-for="(item, itemIndex) in group.items" :key="'item'+itemIndex+groupIndex" @click="fn_routeTo(item)" :class="{'pl-4':group.header, 'isactive_list': $route.path == item.path}">
                                <v-list-item-icon>
                                  <v-icon v-text="item.icon"></v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                  <v-list-item-title v-text="item.text"></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                          </template>
                        </v-list-item-group>
                      </v-list>      
                </v-navigation-drawer>
            </div>  

            <div class="height100" full-height :class="{'content-container':$root.platform()=='desktop'}">
              <!-- <keep-alive include="support_ticket_items"> -->
              <!-- <keep-alive :include="/support_ticket_items|message_items/"> -->
              <!-- <keep-alive :include="['support_ticket_items', 'message_items']"> -->
                <router-view :key="$route.fullPath"></router-view>
              <!-- </keep-alive> -->
            </div>
        </div>

        <!-- <v-snackbar v-model="$root.snackbarMessageObj.value" right top color="info">
          {{$root.snackbarMessageObj.message}}
          <v-spacer></v-spacer>
          <v-btn icon color="white" text @click.native="$root.snackbarMessageObj.value=false">
              <v-icon>close</v-icon>
          </v-btn>
        </v-snackbar> -->

        <v-snackbar v-model="$root.snackbarMessageObj.value" right top>
          {{$root.snackbarMessageObj.message}}
          <template v-slot:action="{ attrs }">
            <v-btn icon color="white" v-bind="attrs" text @click.native="$root.snackbarMessageObj.value=false">
                <v-icon>close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
  export default {
    name:'support_container',
    data: () => ({
      item: null,
      itemGroups:[
        {"header":null, items:[{ text: 'Support Tickets', icon: 'label_important', path:"/support/supportteam/tickets/items", isActive:true}]},
        {"header":null, items:[{ text: 'Create Support Ticket', icon: 'control_point', path:"/support/supportteam/tickets/getbusiness", isActive:false}]},
        {"header":null, items:[{ text: 'User Feedbacks', icon: 'feedback', path:"/support/supportteam/tickets/feedback/items", isActive:false}]},
        {"header":null, items:[{ text: 'App Feedbacks', icon: 'feedback', path:"/support/supportteam/tickets/appfeedback/items", isActive:false}]},
        {"header":null, items:[{ text: 'Support Users', icon: 'portrait', path:"/support/supportteam/users", isActive:false }]},
        {"header":null, items:[{ text: 'Business List', icon: 'business', path:"/support/supportteam/business/items", isActive:false }]},
        {"header":null, items:[{ text: 'Testing Phone Numbers', icon: 'smartphone', path:"/support/supportteam/testingphonenumbers/items", isActive:false }]},
        {"header":null, items:[{ text: 'Reports', icon: 'dashboard', path:"/support/supportteam/reports/items", isActive:false }]},
        {"header":null, items:[{ text: 'Tags', icon: 'mdi-tag-outline', path:"/support/supportteam/tag/items", isActive:false }]},
        //{"header":null, items:[{ text: 'Message Templates', icon: 'mdi-newspaper-variant-outline', path:"/support/supportteam/messagetemplate/items", isActive:false }]},
        //{"header":null, items:[{ text: 'Workflow', icon: 'settings', path:"/support/supportteam/workflow/items", isActive:false }]},
        {"header":"Messages", items:[
            { text: 'User', icon: 'person_outline', path:"/support/supportteam/tickets/message/user_message/items", isActive:false, query:{"status":1, "fromSupport":false}},
            { text: 'Support', icon: 'help_outline', path:"/support/supportteam/tickets/message/support_message/items", isActive:false , query:{"status":1, "fromSupport":true} },
            { text: 'Support Drafts', icon: 'drafts', path:"/support/supportteam/tickets/message/draft_message/items", isActive:false , query:{"status":2, "fromSupport":true} }
            // { text: 'Signature', icon: 'edit', path:"/support/supportteam/tickets/message/signature/edit", isActive:false }
        ]}
      ],
      // snackbarMessageObj:{"value":false, "messag":""},
      loadingObj:{
          loading:false,
          message:"Please wait..."    
      },
      userProfile:null
    }),
    methods:{
        fn_routeTo: function(item){
          this.$router.push({path:item.path, query:item.query});
        },

        fn_signOut: function(){

          this.$root.fn_showLoading('Please wait...', this.loadingObj);
          // deleting cookie
          // var cookies = document.cookie.split(";");
          // document.cookie = "test=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          document.cookie = "user='';expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/"

          let self = this;
          this.signOut(function(){
            self.$router.replace({path:"/support/supportteam/default"});
            self.$root.fn_hideLoading(self.loadingObj);
          })
        },
        // fn_showToastMsg: function(message){
        //     if(this.$root.platform()=='android'){
        //         this.showToast(message);
        //     }
        //     else{
        //         this.$root.snackbarMessageObj.value = true;
        //         if(!navigator.onLine){
        //           this.$root.snackbarMessageObj.message = "Internet not available!";  
        //         }
        //         else{
        //           this.$root.snackbarMessageObj.message = message;
        //         }
        //     }
        // }
    },
    mounted: function(){
      this.userProfile = JSON.parse(localStorage.getItem("userProfile"));
    },

    activated() {
            console.log('Component activated');
        },
        deactivated() {
            console.log('Component deactivated');
        },
  }
</script>

<style>

.v-list-item--active::before{
  opacity: 0 !important;
}

.isactive_list{
  color: #1976d2 !important;
  caret-color : #1976d2 !important
}

.isactive_list::before{
  opacity: 0.12 !important;
}

</style>