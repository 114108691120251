<template>
    <div>
        <v-toolbar flat fixed>
            <v-toolbar-side-icon @click="fn_goBack" v-if="$root.platform()=='android'"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
            <v-toolbar-title :class="{'ml-5':$root.platform()=='android'}">Testing Phone Numbers</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" icon @click="fn_new">New</v-btn>
        </v-toolbar>

        <div class="hastoolbar">

            <v-list two-line subheader full-width v-if="items && items.length">

                <div v-for="(item, index) in items" :key="index">
                    <v-list-item style="padding-right:0">
                        <v-list-item-content>
                          <v-list-item-title>{{item.PhoneNumber}}</v-list-item-title>
                          <v-list-item-subtitle>Added On: {{item.CreatedOn | moment("DD/MM/YYYY h:mm a")}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon @click="fn_deletePhoneNumber(item.TestingPhoneNumberID, index)">
                                <v-icon>delete_outline</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if="items.length-1 != index"></v-divider>
                </div>

            </v-list>

            <div class="pa-3" grey-color center-align v-if="!items.length">
                No phone number added yet!
            </div>
        </div>

        <snackbar-retry :value="snackbarRetry" message="Failed to load testing phone numbers!" :action="fn_getPhoneNumbers"></snackbar-retry>

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import axios from 'axios';
import snackbarRetry from '../../dialogs/snackbar_retry'

    export default{
        name:'item_testingPhoneNumber',
        data(){
            return{
                items:[],
                snackbarRetry:false,
                loadingObj:{
                  loading:false,
                  message:"Please wait..."    
                }
            }
        },
        components: {
            'snackbar-retry': snackbarRetry
        },
        methods:{

            fn_new: function(){
                this.$router.push({path:"/support/supportteam/testingphonenumbers/items/new"});
            },

            fn_getPhoneNumbers: function(){
                let self = this;
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){

                    if(token=='error'){
                        self.snackbarRetry = true;
                        return;
                    }

                    var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                    axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/testingphonenumbers', headersObj, {timeout:30000})
                    .then(response => { 
                        console.log(JSON.stringify(response.data));
                        self.items = response.data;
                        self.$root.fn_hideLoading(self.loadingObj);
                    }).catch(e => {
                        console.log(e);
                        self.snackbarRetry = true;
                        self.$root.fn_hideLoading(self.loadingObj);
                    })
                })
            },

            fn_deletePhoneNumber: function(testingPhoneNumberId, index){
                let self = this;
                this.$root.fn_showLoading('Deleting phone number...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){

                    var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                    axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/testingphonenumbers/'+testingPhoneNumberId+'/delete', headersObj, {timeout:30000})
                    .then(response => {
                        console.log(response);
                        self.$root.fn_showToastMsg("Phone Number deleted!");
                        self.items.splice(index, 1);
                        self.$root.fn_hideLoading(self.loadingObj);
                    }).catch(e => {
                        console.log(e);
                        self.$root.fn_showToastMsg("Something went wrong. Try again!");
                        self.$root.fn_hideLoading(self.loadingObj);
                        self.snackbarRetry = true;
                    })
                })
            },

            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
            this.fn_getPhoneNumbers();
        }
    }
</script>