import dialogPermission from '../../dialogs/dialog_permission';
import axios from 'axios';
var storageFn = require('../../../firebase.js').fireConfig.storageFn
var storageObj = require('../../../firebase.js').fireConfig.storageObj

export const userMixin =  {
  components:{
    'dialog-permission': dialogPermission,
  },
  data(){
    return{
      cancelInit:false
    }
  },
	methods:{
    fn_generateGuid: function(callback){
      this.showLoading('Please wait...');
      let self = this;
      this.$root.fn_getAuthToken(function(token){
        if(token == 'error'){
          self.hideLoading();
          return;
        }

        var headersObj = {
        headers: { 
            'Authorization': 'bearer '+token,
          }
        };

        axios.get(process.env.VUE_APP_BASE_API_URL + '/api/business/'+self.$route.query.businessid+'/Support/generateguid', headersObj, {timeout:30000})
        .then(response => {
            console.log(response);
            self.hideLoading();
            callback(response.data.GUID);
        }).catch(e => {
            // this.showToast("Something went wrong. Try again!");
            console.log(e);
            self.hideLoading();
            callback(null);
        })
      })
        
    },

    fn_openDialogProgress: function(){
      this.$refs['ref_dialogProgress'].openDialogWeb();
    },

    fn_closeDialogProgress: function(){
      this.$refs['ref_dialogProgress'].closeDialogWeb();
    },

    fn_setStatus: function(value){
      console.log(value);
      this.buffer_size = value;
    },

    fn_cancelUpload: function(){
        let self = this;
        this.progressMessage = "Cancel Upload...";
        this.cancelInit = true;
        this.getData('CancelUpload', null, 
            {
                onSuccess: function(response){
                    console.log(response);
                    self.$root.fn_showToastMsg("Uploading cancelled!");
                    self.cancelInit = false;
                    self.fn_closeDialogProgress();
                }, 
                onError: function(error){
                    self.cancelInit = false;
                    self.fn_closeDialogProgress();
                    self.$root.fn_showToastMsg("Failed to cancel uploading!");
                    console.log(error);
                }
            }
        );
    },

    fn_checkStorage: function(type){
      if(this.attachments.length==5){
        this.$root.fn_showToastMsg("Max file limit reached!");
        return;
      }

      let self = this;
      this.$root.fn_checkStoragePermission(function(){
        if(!self.Form.TicketMessageFolderID){
          self.fn_generateGuid(function(guid){
            self.Form.TicketMessageFolderID = guid;
            if(type == 'logs'){
                self.fn_getLogFiles();
            }
            else if(type == 'file'){
                self.fn_selectFile(type);
            }
            else{
                // self.fn_getLocalKeyValue();
                // self.$refs['ref_dialogConfirmation'].openDialogWeb();
                self.$root.fn_checkConnectionStatus(response => {
                  if(JSON.parse(response)){
                    self.$refs['ref_dialogConfirmation'].openDialogWeb();
                  }
                  else{
                    self.fn_getLocalKeyValue();
                  }
                });
            }
            // type == 'file' ? self.fn_selectFile(type) : self.fn_getLocalKeyValue();
          });
        }
        else{
            if(type == 'logs'){
                self.fn_getLogFiles();
            }
            else if(type == 'file'){
                self.fn_selectFile(type);
            }
            else{
                // self.fn_getLocalKeyValue();
                self.$root.fn_checkConnectionStatus(response => {
                  if(JSON.parse(response)){
                    self.$refs['ref_dialogConfirmation'].openDialogWeb();
                  }
                  else{
                    self.fn_getLocalKeyValue();
                  }
                });
            }
        //   type == 'file' ? self.fn_selectFile(type) : self.fn_getLocalKeyValue();
        }
      })

    },

    fn_getLogFiles : function(){
        let self = this;
        this.getData('GetLogFiles', null, 
        {
            onSuccess: function(response){
                console.log(response);
                self.errorLogFileObj = JSON.parse(response);
                if(self.errorLogFileObj && self.errorLogFileObj.logFiles){
                    if(self.errorLogFileObj.logFiles.length == 1){
                        self.fn_getlogEncKey(self.errorLogFileObj.logFiles[0].fileName);
                    }
                    else{
                        self.$refs['ref_dialogErrorLogs'].openDialogWeb();
                    }
                }
            }, 
            onError: function(error){
                console.log(error);
            }
        });
    },

    fn_getlogEncKey : function(fileName){
        let self = this;
        this.getData('LogEncryptionKey', this.$route.query.businessid, 
        {
            onSuccess: function(response){
                console.log(response);
                let keyValueObj = JSON.parse(response);
                self.$refs['ref_dialogErrorLogs'].closeDialogWeb();
                self.fn_uploadErrorLogs(keyValueObj, fileName);
            }, 
            onError: function(error){
                console.log(error);
                self.$root.fn_showToastMsg("Something went wrong. Try again!");
            }
        }
    )},

    fn_uploadErrorLogs: function(keyValueObj, fileName){
        var self = this;
        let postObj = {
          "ticketFolderId":this.Form.TicketFolderID,
          "messageFolderId":this.Form.TicketMessageFolderID,
          "businessId":this.$route.query.businessid,
          "KeyName":keyValueObj.KeyName,
          "KeyValue":keyValueObj.KeyValue,
          "fileName":fileName,
        };

        self.progressMessage = "Uploading...";
        self.fn_openDialogProgress();
        this.postData("UploadLogToFirebase", postObj, 
            {
                onSuccess: function(response){
                    console.log(response);
                    if(!self.cancelInit){
                      let fileObj = JSON.parse(response);
                      self.attachments.push(fileObj);
                    }
                    else{
                      self.$root.fn_showToastMsg("Uploading cancelled!");
                    }
                    self.cancelInit = false;
                    self.fn_closeDialogProgress();
                },
                onError: function(error){
                    console.log(error);
                    self.$root.fn_showToastMsg("Something went wrong. Try again!");
                    self.cancelInit = false;
                    self.fn_closeDialogProgress();
                }
            }
        )},

		// fn_checkStorage: function(type){
  //         if(this.attachments.length==5){
  //           this.showToast('Max file limit reached!');
  //           return;
  //         }

  //         let self = this;
  //         this.$root.fn_checkStoragePermission(function(){
  //           // type == 'file' ? self.fn_selectFile() : self.fn_selectDatabase();
  //           type == 'file' ? self.fn_selectFile() : self.fn_getLocalKeyValue();
  //         })
  //       },

        fn_selectFile: function(type){
          var self = this;
          let postObj = {
            "fileExtentions":['JPG','JPEG','PNG','GIF','MP4', 'MPEG', 'AVI','MOV','FLV','PDF','DOC','DOCX','TEXT','XLSX','CSV','MP3','WMA','AAC','WAV'],
            "isSupportUser":false,
            "ticketFolderId":this.Form.TicketFolderID, 
            "messageFolderId":this.Form.TicketMessageFolderID, 
            "businessId":this.$route.query.businessid
          };

          if(type == 'logs'){
            postObj.fileExtentions.push('AES');
          }

          self.progressMessage = "Uploading...";
          // self.fn_openDialogProgress();
          setTimeout(function(){
            self.fn_openDialogProgress();
          },300)
          this.postData("SelectLocalFile", postObj, 
              {
                  onSuccess: function(response){
                      console.log(response);
                      if(!self.cancelInit){
                        let fileObj = JSON.parse(response);
                        // self.Form.TicketFolderId = fileObj.ticketFolderId;
                        // self.Form.TicketMessageFolderID = fileObj.messageFolderId;
                        self.attachments.push(fileObj);
                      }
                      else{
                        self.$root.fn_showToastMsg("Uploading cancelled!");
                      }
                      self.cancelInit = false;
                      self.fn_closeDialogProgress();
                  },
                  onError: function(error){
                      console.log(error);
                      if(error == 'file not valid'){
                        self.$root.fn_showToastMsg("Invalid file!");
                      }
                      else if(typeof(error) == 'string' && error.toLowerCase() == "request cancel"){
                        console.log('request cancel');
                      }
                      else{
                        self.$root.fn_showToastMsg("Something went wrong. Try again!");
                      }
                      // else if(error != "request cancel"){
                      //   self.$root.fn_showToastMsg("Something went wrong. Try again!");
                      // }
                      self.cancelInit = false
                      self.fn_closeDialogProgress();
                  }
              }
          );
        },

        fn_selectFileViaDesktop: function(type){
            if(this.$root.platform()=='iframe'){
                document.getElementById('filePicker').click();  
            }
            else{
                this.fn_checkStorage(type);
            }
        },

        fn_validateFile: function(event){
            if(this.attachments.length==5){
            //   alert("Max file limit reached!");
                this.$root.fn_showToastMsg("Max file limit reached");
                return;
            }
    
            let extentions = ['JPG','JPEG','PNG','GIF','MP4', 'MPEG', 'AVI','MOV','FLV','PDF','DOC','DOCX','TEXT','XLSX','CSV','MP3','WMA','AAC','WAV','BIZOPS'];
            console.log(event.target.files[0]);
            let file = event.target.files[0];
            let fileExt = file.name.substr(file.name.lastIndexOf('.'));
            let extIndex = extentions.findIndex(ext => '.'+ext.toUpperCase()==fileExt.toUpperCase());
            if(extIndex == -1){
            //   alert("File not supported!");
                this.$root.fn_showToastMsg("File not supported");
                return;
            }
    
            // this.fileName = file.name;
            // file.name = file.name.substr(0, file.name.lastIndexOf('.')) + "@" + new Date().getTime() + fileExt;
            let fileName = file.name.substr(0, file.name.lastIndexOf('.')) + "@" + new Date().getTime() + fileExt;
    
            this.progressMessage = "Uploading...";
            this.fn_openDialogProgress();
    
            let self = this;
            // !this.Form.TicketFolderID ? this.Form.TicketFolderID = this.guidWeb() : '';
            // !this.Form.TicketMessageFolderID ? this.Form.TicketMessageFolderID = this.guidWeb() : '';
    
            let fileType = fileExt == '.bizops' ? 2 : 1;
            this.fn_uploadViaDesktop(file, fileName, function(downloadUrl){
              let fileObj = {"fileExt":fileExt, "fileType":fileType, "fileName":fileName, "filePath":downloadUrl, "ticketFolderId":self.Form.TicketFolderId, "messageFolderId":self.Form.TicketMessageFolderID};
              if(!self.cancelInit){
                self.attachments.push(fileObj);
                self.fn_closeDialogProgress();
              }
              else{
                self.$root.fn_showToastMsg("Uploading cancelled!");
                self.fn_closeDialogProgress();
                self.cancelInit = false;
              }
    
              self.$root.fn_hideLoading(self.loadingObj);
              document.getElementById('filePicker').value = "";
            })
        },

        fn_uploadViaDesktop: function(file, fileName, callback){

            // let storageRef = firebase.storage().ref();
            let storageRef = storageFn.ref();
            let self = this;
    
            let path = 'tickets/'+ this.$route.query.businessid + '/' + this.Form.TicketFolderID+'/'+this.Form.TicketMessageFolderID+'/' + fileName
            // 'tickets/'+ this.$route.query.businessid + '/' + this.Form.TicketFolderID+'/'+this.Form.TicketMessageFolderID+'/' + fileName
            // Upload file and metadata to the object 'images/mountains.jpg'
            this.uploadTask = storageRef.child(path).put(file);
    
            // Listen for state changes, errors, and completion of the upload.
            this.uploadTask.on(storageObj.TaskEvent.STATE_CHANGED, // or 'state_changed'
              function(snapshot) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                // setting up buffer size
                self.fn_setStatus(progress)
                switch (snapshot.state) {
                  case storageObj.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                  case storageObj.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                }
              }, function(error) {
    
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case 'storage/unauthorized':
                  console.log('storage/unauthorized');
                  // User doesn't have permission to access the object
                  break;
    
                case 'storage/canceled':
                  callback('cancelled');
                  // User canceled the upload
                  break;
    
                case 'storage/unknown':
                  console.log('storage/unknown');
                  // Unknown error occurred, inspect error.serverResponse
                  break;
              }
            }, function() {
              // Upload completed successfully, now we can get the download URL
              self.uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                console.log('File available at', downloadURL);
                callback(downloadURL);
              });
            });
        },

        fn_getLocalKeyValue : function(){
          this.$refs['ref_dialogConfirmation'].closeDialogWeb();
          let self = this;
          this.getData('GetLocalKeyValue', this.$route.query.businessid, 
              {
                  onSuccess: function(response){
                      console.log(response);
                      let keyValueObj = JSON.parse(response);
                      self.fn_selectDatabase(keyValueObj);
                  }, 
                  onError: function(error){
                      console.log(error);
                      self.$root.fn_showToastMsg("Something went wrong. Try again!");
                  }
              }
          );
        },

        fn_selectDatabase: function(keyValueObj){
          var self = this;
          let postObj = {
            "ticketFolderId":this.Form.TicketFolderID,
            "messageFolderId":this.Form.TicketMessageFolderID,
            "businessId":this.$route.query.businessid,
            "KeyName":keyValueObj.KeyName,
            "KeyValue":keyValueObj.KeyValue
          };

          self.progressMessage = "Uploading...";
          self.fn_openDialogProgress();
          this.postData("UploadDbToFirebase", postObj, 
              {
                  onSuccess: function(response){
                      console.log(response);
                      if(!self.cancelInit){
                        let fileObj = JSON.parse(response);
                        // self.Form.TicketFolderId = fileObj.ticketFolderId;
                        // self.Form.TicketMessageFolderID = fileObj.messageFolderId;
                        self.attachments.push(fileObj);
                      }
                      else{
                        self.$root.fn_showToastMsg("Uploading cancelled!");
                      }
                      self.cancelInit = false;
                      self.$root.isRestoreDb = true;
                      /* global AndroidRoutingTest */
                      AndroidRoutingTest.dbRestored('false');
                      self.fn_reopenDB();
                      self.fn_closeDialogProgress();
                  },
                  onError: function(error){
                      console.log(error);
                      self.$root.fn_showToastMsg("Something went wrong. Try again!");
                      self.cancelInit = false;
                      self.$root.isRestoreDb = true;
                      AndroidRoutingTest.dbRestored('false');
                      self.fn_reopenDB();
                      self.fn_closeDialogProgress();
                  }
              }
          );
        },

        fn_reopenDB: function(){
            this.getData('ReopenDataBase', this.$route.query.businessid, 
                {
                    onSuccess: function(response){
                        console.log(response);
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_deleteAttachment: function(filePath, index){
        //   this.showLoading('Deleting File...');
            let self = this;
            if(this.$root.platform()=='android'){
                this.showLoading('Deleting File...');
                this.postData("deleteFirebaseFile", filePath, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        self.attachments.splice(index, 1);
                        self.hideLoading();
                    },
                    onError: function(error){
                        console.log(error);
                        self.$root.fn_showToastMsg("Something went wrong. Try again!");
                        self.hideLoading();
                    }
                });
            }
            else{
                this.$root.fn_showLoading('Deleting File...', this.loadingObj);
                this.fn_deleteFile(filePath, index);
            }
          
        },

        fn_deleteFile: function(filePath, index){
            let self = this;
            var fileRef = storageFn.refFromURL(filePath);
            // Delete the file
            fileRef.delete().then(function() {
              self.attachments.splice(index, 1);
              self.$root.fn_hideLoading(self.loadingObj);
            }).catch(function(error) {
              console.log(error);
              self.$root.fn_hideLoading(self.loadingObj);
              self.$root.fn_showToastMsg("Something went wrong. Try again!");
            });
        },

        fn_openDialogQuill: function(){
            this.$refs['ref_dialogQuill'].openDialogWeb();
        },

        fn_setMessage: function(message){
            this.Form.Message = message;
        },

        fn_openPermissionDialog: function(permissionType){
            this.$root.permissionType = permissionType;
            this.$refs['ref_dialogPermission'].dialog_permission = true;
        },
	},
  mounted: function(){
    window.js_globals.fn_openPermissionDialog = this.fn_openPermissionDialog;
  }
}