<template>
    <div>
        <v-toolbar flat extended fixed v-if="$root.platform()=='android'">

            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title class="ml-5">Business List</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-text-field slot="extension" nopadding hide-details elevation-10  flat solo placeholder="Search..." v-model.lazy="searchValue" @input="fn_searchItem" clearable></v-text-field>

             <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on"><v-icon color="primary">filter_list</v-icon></v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="fn_changeStatus(null)">
                      <v-list-item-title>All</v-list-item-title>
                      <v-list-item-action>
                        <v-icon v-if="selectedStatus==null">radio_button_checked</v-icon>
                        <v-icon v-else>radio_button_unchecked</v-icon>
                      </v-list-item-action>
                    </v-list-item>

                    <v-list-item @click="fn_changeStatus(1)">
                      <v-list-item-title>Active</v-list-item-title>
                      <v-list-item-action>
                        <v-icon v-if="selectedStatus==1">radio_button_checked</v-icon>
                        <v-icon v-else>radio_button_unchecked</v-icon>
                      </v-list-item-action>
                    </v-list-item>

                    <v-list-item @click="fn_changeStatus(-1)">
                      <v-list-item-title>De-Active</v-list-item-title>
                      <v-list-item-action>
                        <v-icon v-if="selectedStatus==-1">radio_button_checked</v-icon>
                        <v-icon v-else>radio_button_unchecked</v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-menu>

        </v-toolbar>

        <div :class="{'hastoolbar hasExtension': $root.platform()=='android'}">

            <v-text-field class="desktop-searchbar" nopadding hide-details elevation-10  flat solo placeholder="Search..." v-model.lazy="searchValue" @input="fn_searchItemDebounce" clearable v-if="$root.platform()=='desktop'"></v-text-field>

            <v-list three-line subheader full-width>

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark text v-bind="attrs" v-on="on" class="datepickerbtn"><v-icon>date_range</v-icon>&nbsp;Period<span v-show="dateFrom && dateTo">:&nbsp;</span> {{dateFrom | moment("DD/MM/YYYY")}}<span v-show="dateFrom && dateTo">&nbsp;To&nbsp;</span>{{dateTo | moment("DD/MM/YYYY")}}</v-btn>
                  <!-- <v-subheader color="primary" dark v-bind="attrs" v-on="on" style="height:40px; cursor: pointer;" full-width>
                    <v-icon>date_range</v-icon>&nbsp;Period: {{dateFrom | moment("DD/MM/YYYY")}} To {{dateTo | moment("DD/MM/YYYY")}}
                  </v-subheader> -->
                </template>
                <v-list>
                  <v-list-item @click="fn_getLatestData('Today')">
                    <v-list-item-title>Today</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Yesterday')">
                    <v-list-item-title>Yesterday</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 3 days')">
                    <v-list-item-title>Last 3 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 7 days')">
                    <v-list-item-title>Last 7 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 15 days')">
                    <v-list-item-title>Last 15 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_getLatestData('Last 30 days')">
                    <v-list-item-title>Last 30 days</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="fn_openDialogPeriod">
                    <v-list-item-title>Custom date range</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn outlined rounded @click="fn_clearDateFilter" v-if="dateFrom && dateTo" dark text class="clearfilterbtn">Clear Filter<v-icon class="pl-1" size="20">close</v-icon></v-btn>
              <v-btn outlined rounded @click="fn_pastePhoneNumber" dark text class="clearfilterbtn ml-4">Paste Phone Number<v-icon class="pl-1" size="20">mdi-content-paste</v-icon></v-btn>

                <v-divider></v-divider>
                
                <v-subheader style="position: sticky;top:56px;z-index: 1;background:#fff">
                    ({{businessData.count}}) Businesses
                    <v-spacer></v-spacer>

                    <v-btn outlined rounded @click="fn_clearFilter" v-if="selectedStatus" dark text class="clearfilterbtn">Clear Filter <v-icon class="pl-1" size="20">close</v-icon></v-btn>

                    <v-menu offset-y v-if="$root.platform()=='desktop'">
                      <template v-slot:activator="{ on, attrs }">
                         <v-btn small text capitalize color="primary" v-bind="attrs" v-on="on">{{ selectedStatus == 1 ? 'Active' : selectedStatus == -1 ? 'Deactive' : 'All'}} <v-icon right>filter_list</v-icon></v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="fn_changeStatus(null)">
                          <v-list-item-title>All</v-list-item-title>
                          <v-list-item-action>
                            <v-icon v-if="selectedStatus==null">radio_button_checked</v-icon>
                            <v-icon v-else>radio_button_unchecked</v-icon>
                          </v-list-item-action>
                        </v-list-item>

                        <v-list-item @click="fn_changeStatus(1)">
                          <v-list-item-title>Active</v-list-item-title>
                          <v-list-item-action>
                            <v-icon v-if="selectedStatus==1">radio_button_checked</v-icon>
                            <v-icon v-else>radio_button_unchecked</v-icon>
                          </v-list-item-action>
                        </v-list-item>

                        <v-list-item @click="fn_changeStatus(-1)">
                          <v-list-item-title>Deactive</v-list-item-title>
                          <v-list-item-action>
                            <v-icon v-if="selectedStatus==-1">radio_button_checked</v-icon>
                            <v-icon v-else>radio_button_unchecked</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <!-- <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on"><v-icon color="primary">filter_list</v-icon></v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="fn_changeStatus(null)">
                          <v-list-item-title>All</v-list-item-title>
                          <v-list-item-action>
                            <v-icon v-if="selectedStatus==null">radio_button_checked</v-icon>
                            <v-icon v-else>radio_button_unchecked</v-icon>
                          </v-list-item-action>
                        </v-list-item>

                        <v-list-item @click="fn_changeStatus(1)">
                          <v-list-item-title>Active</v-list-item-title>
                          <v-list-item-action>
                            <v-icon v-if="selectedStatus==1">radio_button_checked</v-icon>
                            <v-icon v-else>radio_button_unchecked</v-icon>
                          </v-list-item-action>
                        </v-list-item>

                        <v-list-item @click="fn_changeStatus(-1)">
                          <v-list-item-title>De-Active</v-list-item-title>
                          <v-list-item-action>
                            <v-icon v-if="selectedStatus==-1">radio_button_checked</v-icon>
                            <v-icon v-else>radio_button_unchecked</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-menu> -->
                </v-subheader>

                <v-divider v-if="businessData.count"></v-divider>

                <div v-for="(item, index) in businessData.Businesses" :key="index">
                    <v-list-item class="pr-0" @click="fn_viewDetails(item.BusinessID)">
                        <v-list-item-content>
                          <v-list-item-title>{{item.BusinessName}}</v-list-item-title>
                          <v-list-item-subtitle>Created On: {{item.CreatedOn | moment("DD/MM/YYYY h:mm a")}}</v-list-item-subtitle>
                          <v-list-item-subtitle>{{item.IsSupport == "Yes" ? "By Support User" : "By Customer"}}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                            <div :class="[item.Status=='Active' ? 'greenbackground' : 'orangebackground']" style="color: #fff;padding: 2px 8px;border-top-left-radius: 4px;border-bottom-left-radius: 4px;font-size: 0.875rem;">
                                {{item.Status=="Active" ? 'Active' : "Deactive"}}
                            </div>
                        </v-list-item-action>

                    </v-list-item>
                    <v-divider v-if="businessData.Businesses.length-1 != index"></v-divider>
                </div>

                <infinite-loading spinner="spiral" :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading>
            </v-list>

        </div>

        <snackbar-retry :value="snackbarRetry" message="Failed to load messages!" :action="fn_searchItem"></snackbar-retry>

        <v-snackbar v-model="phonenumbersnackbar" timeout="2000">Please copy phone number to paste!
          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="phonenumbersnackbar = false">Close</v-btn>
          </template>
        </v-snackbar>

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <dialog-period-selection ref="ref_dialogCustomPeriod" title="Select Period" :openDatePicker="fn_openDatePicker" :startDate="selectedStartDate" :endDate="selectedEndDate" :actionMethod="fn_updateCustomDates"></dialog-period-selection>
        <dialog-business-details ref="ref_dialogBusinessDetails" type="business" :showBusinessMetrics="true" :updateData="fn_reload"></dialog-business-details>


        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>
    </div>
</template>

<script>
import axios from 'axios';
import debounce from 'lodash/debounce';

import InfiniteLoading from 'vue-infinite-loading';
import snackbarRetry from '../../dialogs/snackbar_retry'

import dialogPeriodSelection from '../tickets/dialogs/dialog_period_selection'
import dialogDatepicker from '../tickets/dialogs/dialog_datepicker'
import dialogBusinessDetails from '../tickets/dialogs/dialog_viewbusinessdetailsfullscreen';

    export default{
        name:'items_business',
        data(){
            return{
                toolbarTitle:"Messages",
                businessData:{"Businesses":[], "count":0},
                offset:0,
                infiniteId: +new Date(),
                searchValue:'',
                snackbarRetry:false,
                loadingObj:{
                    loading:false,
                    message:"Please wait..."    
                },
                selectedStatus:null,
                dialog_datepicker:false,
                selectedDate:null,
                dateType:null,
                selectedStartDate:null,
                selectedEndDate:null,
                dateFrom:this.$root.minDateSelection,
                dateTo:this.$root.maxDateSelection,
                phonenumbersnackbar: false
            }
        },
        components: {
            InfiniteLoading,
            'snackbar-retry': snackbarRetry,
            'dialog-period-selection':dialogPeriodSelection,
            'dialog-datepicker':dialogDatepicker,
            'dialog-business-details': dialogBusinessDetails
        },
        methods:{

            fn_openDialogPeriod: function(){
                this.selectedStartDate = this.dateFrom;
                this.selectedEndDate = this.dateTo;
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = true;
            },

            fn_openDatePicker: function (type, date) {
                let self = this;
                this.dateType = type;
                let dateObj = date ? new Date(date) : new Date();
                this.$refs['ref_dialogDatePicker'].selectedDate = dateObj.getFullYear()+'-'+("0" + (dateObj.getMonth()+1)).slice(-2)+'-'+("0" + dateObj.getDate()).slice(-2);
                setTimeout(function () {
                    self.dialog_datepicker = true;
                })
            },

            fn_updateDate: function (value) {
                // if(this.dateType=="end" && new Date(value).getTime() < new Date(this.$root.CurrentBusiness.OpeningDate).getTime()){
                //     this.showToast("Not Applicable");
                //     return;
                // }

                if(this.dateType=="start"){
                    this.selectedStartDate = value;
                }
                else if(this.dateType=="end"){
                    this.selectedEndDate = value;
                }

                this.dialog_datepicker = false;

                // this.fn_getDashboardData();
            },

            fn_updateCustomDates: function(){
                this.dateFrom = this.selectedStartDate;
                this.dateTo = this.selectedEndDate;
                // this.fn_closePeriodDialog();
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = false;
                this.fn_searchItem();
            },

            fn_viewDetails: function(businessId){
                this.$refs['ref_dialogBusinessDetails'].fn_getToken(businessId);
                //this.$router.push({path:"/support/supportteam/business/item/"+businessId});
            },

            infiniteHandler($state) {

                let jsonData = { "Keywords":this.searchValue, "GSTRegistrationStatuses":null, "HSNApplicable":null, "State":null, "TaxMode": null, "IsSupport":null, "BusinessNumber":null, "Status":this.selectedStatus, "DateFrom":null, "DateTo":null}


                // if(localStorage.getItem('businesslistfilters')){
                //     let filterData = JSON.parse(localStorage.getItem('businesslistfilters'));
                //     if(filterData.Status !== undefined){
                //         jsonData.Status = filterData.Status === undefined ? null : filterData.Status;
                //         this.selectedStatus = filterData.Status;
                //     }
                // }

                if(this.dateFrom){
                  jsonData.DateFrom = this.$moment(this.dateFrom).startOf('day');
                  jsonData.DateFrom = this.fn_getLocalDateFormat(jsonData.DateFrom);
                }
                if(this.dateTo){
                  jsonData.DateTo = this.$moment(this.dateTo).endOf('day');
                  jsonData.DateTo = this.fn_getLocalDateFormat(jsonData.DateTo);
                }
                  

                console.log(JSON.stringify(jsonData));

                let self = this;
                this.$root.fn_getAuthToken(function(token){

                    if(token=='error'){
                        self.snackbarRetry = true;
                        $state.complete();
                        return;
                    }

                    var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                    axios.post(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/businesses/getlist?offset='+self.offset+'&limit=10', jsonData, headersObj, {timeout:30000})
                    .then(response => {
                        console.log(JSON.stringify(response));
                        self.businessData.count = response.data.ItemCount;

                        if (self.businessData.count > self.businessData.Businesses.length) {
                            for(let i=0; i<response.data.Businesses.length; i++){
                                self.businessData.Businesses.push(response.data.Businesses[i]);
                            }
                            $state.loaded();
                        } else {
                            $state.complete();
                        }

                        self.offset = self.businessData.Businesses.length;

                    }).catch(e => {
                        console.log(e);
                        self.$root.fn_showToastMsg("Something went wrong. Try again!");
                        self.snackbarRetry = true;
                        $state.complete();
                    })
                })
                
            },

            fn_searchItem:  function() {
              this.snackbarRetry = false;
              this.offset = 0;
              this.businessData.Businesses = [];
              this.businessData.count = 0;
              this.infiniteId += 1;
            },

            fn_searchItemDebounce: debounce(function() {
                this.snackbarRetry = false;
                this.offset = 0;
                this.businessData.Businesses = [];
                this.businessData.count = 0;
                this.infiniteId += 1;
            }, 1000),

            fn_changeStatus: function(status){
                this.selectedStatus = status;
                // let filterObj = {}
                // filterObj.Status = this.selectedStatus;
                // localStorage.setItem('businesslistfilters', JSON.stringify(filterObj));
                this.fn_searchItem();
            },

            fn_viewTicket: function(item){
                this.$router.push({path:"/support/supportteam/tickets/"+item.SupportTicketID, query:{"messageid":item.SupportTicketConversationID, "businessid":this.$route.query.businessid}});
            },

            fn_goBack: function(){
                this.$router.go(-1);
            },

            fn_getLatestData: function(type){
              var currentDate = new Date();
              var year = currentDate.getFullYear();
              var month = String(currentDate.getMonth() + 1).padStart(2, '0');
              var day = String(currentDate.getDate()).padStart(2, '0');
              var formattedStartDate = `${year}-${month}-${day}`;
              this.dateTo = formattedStartDate;


              var startDate = new Date();
              if(type == 'Yesterday')
                startDate.setDate(startDate.getDate() - 1);
              else if(type == 'Last 3 days')
                startDate.setDate(startDate.getDate() - 3);
              else if(type == 'Last 7 days')
                startDate.setDate(startDate.getDate() - 7);
              else if(type == 'Last 15 days')
                startDate.setDate(startDate.getDate() - 15);
              else if(type == 'Last 30 days')
                startDate.setDate(startDate.getDate() - 30);
              var endDateYear = startDate.getFullYear();
              var endDateMonth = String(startDate.getMonth() + 1).padStart(2, '0');
              var endDateDay = String(startDate.getDate()).padStart(2, '0');
              var formattedEndDate = `${endDateYear}-${endDateMonth}-${endDateDay}`;
              console.log('Start Date : ' + formattedStartDate);
              console.log('End Date : ' + formattedEndDate);
              this.dateFrom = formattedEndDate;
              this.fn_searchItem();

            },

            fn_clearDateFilter: function(){
              this.dateFrom = null;
              this.dateTo = null;
              this.fn_searchItem();
            },

            fn_clearFilter: function(){
                // let filterObj = {};
                // filterObj.Status = null;
                // localStorage.setItem('businesslistfilters', JSON.stringify(filterObj));
                this.selectedStatus = null;
                this.fn_searchItem();
            },

            fn_reload: function(){
              console.log('Reload data');
            },

            fn_pastePhoneNumber: function(){
              var that = this;
              navigator.clipboard.readText().then(function(clipboardText) {
                let number = clipboardText.replace(/\D/g, ''); // Remove non-numeric characters
                console.log(number);
                if(number != '' && number != null){
                  that.searchValue = number;
                  that.fn_searchItem();
                }
                else
                  that.phonenumbersnackbar = true;
              }).catch(function(err) {
                console.error('Failed to read clipboard contents: ', err);
              });
            }
        },
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
        },
        beforeRouteLeave (to, from , next) {

            var wrp = document.getElementsByTagName('html');
            for (var i = 0; i < wrp.length; i++) {
                wrp[i].style.height = "auto";
                wrp[i].style.overflowY = "auto";
            }

            if(this.dialog_datepicker){
                this.dialog_datepicker = false;
                next(false);
            }
            else if(this.$refs['ref_dialogCustomPeriod'].dialog_period_selection){
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = false;
                next(false);
            }
            else{
                next();
            }
        },
    }
</script>
<style>
.datepickerbtn{
  color: rgba(0,0,0,.6) !important;
  font-size: .875rem !important;
  text-transform: none;
}

.clearfilterbtn{
  color: rgba(0,0,0,.6) !important;
  border: 1px solid  rgba(0,0,0,.6);
  border-color: rgba(0,0,0,.6) !important;
  height: 30px !important;
  text-transform: none;
}
</style>