<template>
    <div>
        <v-toolbar flat extended fixed v-if="$root.platform()=='android'">

            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title class="ml-5">{{toolbarTitle}}</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-text-field slot="extension" nopadding hide-details elevation-10  flat solo placeholder="Search..." v-model.lazy="searchValue" @input="fn_searchItem" clearable></v-text-field>

        </v-toolbar>

        <div :class="{'hastoolbar hasExtension': $root.platform()=='android'}">

            <v-text-field class="desktop-searchbar" nopadding hide-details elevation-10  flat solo placeholder="Search..." v-model.lazy="searchValue" @input="fn_searchItem" clearable v-if="$root.platform()=='desktop'"></v-text-field>

            <v-list three-line subheader full-width>

                <v-divider></v-divider>

                <div v-for="(item, index) in messageData.Messages" :key="index">
                    <v-list-item :class="{'unread':!item.IsSupportRead && item.FromSupport && item.Status==1}">
                        <v-list-item-content @click="fn_viewTicket(item)">
                          <v-list-item-title>{{item.Message}}</v-list-item-title>
                          <v-list-item-subtitle>{{item.Business.BusinessName}}</v-list-item-subtitle>
                          <v-list-item-subtitle>Last Modified: {{item.ModifiedOn | moment("DD/MM/YYYY h:mm a")}}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action v-if="item.FromSupport">
                            <v-menu>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on"><v-icon>more_horiz</v-icon></v-btn>
                              </template>
                              <v-list nopadding>
                                <v-list-item @click="fn_getToken(item)" v-if="item.Status==1">
                                  <v-list-item-title>Mark as {{!item.IsSupportRead ? 'read' : 'unread'}}</v-list-item-title>
                                </v-list-item>
                                <!-- <v-divider v-if="item.Status == 2"></v-divider> -->
                                <!-- <v-list-item @click="fn_editDraftMessage(item)" v-if="item.Status == 2"> -->
                                <v-list-item @click="fn_editDraftMessage(item)" v-else>
                                  <v-list-item-title>Update Message</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>

                            <!-- <span class="primary-color">{{item.Status == 2 ? 'Draft' : ''}}</span> -->
                        </v-list-item-action>

                    </v-list-item>
                    <v-divider v-if="messageData.Messages.length-1 != index"></v-divider>
                </div>

                <infinite-loading spinner="spiral" :identifier="infiniteId" @infinite="infiniteHandler">
                    <div slot="no-results" font12 light-opacity-color class="pa-3">There are no messages!</div>
                    <div slot="no-more"></div>
                </infinite-loading>
            </v-list>

        </div>

        <snackbar-retry :value="snackbarRetry" message="Failed to load messages!" :action="fn_searchItem"></snackbar-retry>

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios';

import InfiniteLoading from 'vue-infinite-loading';
import snackbarRetry from '../../../dialogs/snackbar_retry'

    export default{
        name: 'message_items',
        data(){
            return{
                toolbarTitle:"Messages",
                messageData:{"Messages":[], "count":null},
                offset:0,
                infiniteId: +new Date(),
                searchValue:'',
                fromSupport:null,
                status:null,
                snackbarRetry:false,
                loading:false,
                loadingObj:{
                    loading:false,
                    message:"Please wait..."    
                }
            }
        },
        components: {
            InfiniteLoading,
            'snackbar-retry': snackbarRetry
        },
        methods:{

            fn_editDraftMessage: function(item){
                this.$router.push({path:"/support/supportteam/tickets/"+item.SupportTicketID+"/message/"+item.SupportTicketConversationID, query:{businessid:this.$route.query.businessid, "userbusinessid":item.Business.BusinessID, "ticketFolderId":item.TicketFolderID}});
            },

            infiniteHandler($state) {

                let jsonData = {"Keywords": this.searchValue, "FromSupport" : this.fromSupport, "IsSupportRead":null, "Status":this.status, "ConversationType":null, "FeedbackType":null, "TicketNumber":null, "DateFrom":null, "DateTo":null};

                console.log(JSON.stringify(jsonData));

                let self = this;
                this.$root.fn_getAuthToken(function(token){

                    if(token=='error'){
                        self.snackbarRetry = true;
                        $state.complete();
                        return;
                    }

                    var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                    axios.post(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/tickets/messages/getlist?offset='+self.offset+'&limit=10', jsonData, headersObj, {timeout:30000})
                    .then(response => {
                        console.log(JSON.stringify(response));
                        self.messageData.count = response.data.ItemCount;

                        if (self.messageData.count > self.messageData.Messages.length) {
                            for(let i=0; i<response.data.Messages.length; i++){
                                self.messageData.Messages.push(response.data.Messages[i]);
                            }
                            $state.loaded();
                        } else {
                            $state.complete();
                        }

                        self.offset = self.messageData.Messages.length;

                    }).catch(e => {
                        console.log(e);
                        self.$root.fn_showToastMsg("Something went wrong. Try again!");
                        self.snackbarRetry = true;
                        $state.complete();
                    })
                })
                
            },

            fn_searchItem:  function() {
              this.snackbarRetry = false;
              this.offset = 0;
              this.messageData.Messages = [];
              this.messageData.count = 0;
              this.infiniteId += 1;
            },

            fn_getToken: function(item){
                let self = this;
                // this.showLoading('Please wait...');
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){
                    if(token=='error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        return;
                    }
                    self.fn_toggleRead(token, item)
                });
            },

            fn_toggleRead: function(token, item){
                var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                let markAsReadUnread = !item.IsSupportRead ? 'MarkasRead' : 'MarkasUnread';

                axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/tickets/'+item.SupportTicketID+'/Messages/'+item.SupportTicketConversationID+'/'+markAsReadUnread, headersObj)
                .then(response => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    console.log(response);
                    console.log(JSON.stringify(response.data));
                    item.IsSupportRead = !item.IsSupportRead;
                }).catch(e => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    console.log(e);
                })
            },

            fn_viewTicket: function(item){
                this.$router.push({path:"/support/supportteam/tickets/"+item.SupportTicketID, query:{"messageid":item.SupportTicketConversationID, "businessid":this.$route.query.businessid}});
            },

            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        mounted: function(){

            this.fromSupport = JSON.parse(this.$route.query.fromSupport);
            this.status = Number(this.$route.query.status);

            if(!this.fromSupport){
                this.toolbarTitle = "User Messages";
            }
            else{
                this.toolbarTitle = this.status == 1 ? "Support Messages" : "Support Draft Messages";
            }

            window.js_globals.fn_onBackPress = this.fn_goBack;
        }
        // activated: function(){
        //     alert('in');
        //     this.fromSupport = JSON.parse(this.$route.query.fromSupport);
        //     this.status = Number(this.$route.query.status);

        //     if(!this.fromSupport){
        //         this.toolbarTitle = "User Messages";
        //     }
        //     else{
        //         this.toolbarTitle = this.status == 1 ? "Support Messages" : "Support Draft Messages";
        //     }

        //     window.js_globals.fn_onBackPress = this.fn_goBack;
        // },
        // beforeRouteEnter (to, from, next) {
        //     next(vm => {

        //         vm.fromView = from.name;

        //     })
        // },
    }
</script>