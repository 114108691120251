import * as firebase from 'firebase/app';
import "firebase/auth";
import "firebase/storage"


// var firebaseConfig = {
//   apiKey: "AIzaSyBqe1wRXiA9WS34YYQrUemK1B5Rw4hK8TE",
//   authDomain: "bizopsstaging.firebaseapp.com",
//   databaseURL: "https://bizopsstaging.firebaseio.com",
//   projectId: "bizopsstaging",
//   storageBucket: "bizopsstaging.appspot.com",
//   messagingSenderId: "22911752863",
//   appId: "1:22911752863:android:ec46bf57ddc3662d9c60aa",
// };

let firebaseConfig = null;

if(process.env.NODE_ENV === 'production'){
    // production configs
    firebaseConfig = {
        apiKey: "AIzaSyDgLFjui5BgZw5B_PfOPsyH-8UxF_Xwlqc",
        authDomain: "bizopsapp.firebaseapp.com",
        databaseURL: "https://bizopsapp.firebaseio.com",
        projectId: "bizopsapp",
        storageBucket: "bizopsapp.appspot.com",
        messagingSenderId: "81292557995",
        appId: "1:81292557995:android:252d55026b5aca0366c1ee",
    };
}
else if(process.env.NODE_ENV === 'staging'){
    // Uat configs
    firebaseConfig = {
        apiKey: "AIzaSyBqe1wRXiA9WS34YYQrUemK1B5Rw4hK8TE",
        authDomain: "bizopsstaging.firebaseapp.com",
        databaseURL: "https://bizopsstaging.firebaseio.com",
        projectId: "bizopsstaging",
        storageBucket: "bizopsstaging.appspot.com",
        messagingSenderId: "22911752863",
        appId: "1:22911752863:android:ec46bf57ddc3662d9c60aa",
    };
}
else if(process.env.NODE_ENV === 'development'){
    // Dev configs
    firebaseConfig = {
        apiKey: "AIzaSyCpj5j5bajxXczXScRE_IEGT7z4W8amFEU",
        authDomain: "bizopsdevelopment.firebaseapp.com",
        databaseURL: "https://bizopsdevelopment.firebaseio.com",
        projectId: "bizopsdevelopment",
        storageBucket: "bizopsdevelopment.appspot.com",
        messagingSenderId: "889089510735",
        appId: "1:889089510735:android:4830f61e16da24a74d093a",
    };
}
else if(process.env.NODE_ENV === 'uatprod'){
    // UatProd configs
    firebaseConfig = {
        apiKey: "AIzaSyATSLJp5tbQGdY87rnX7zMx2gY6hfhdiok",
        authDomain: "bizopsappuatprod.firebaseapp.com",
        databaseURL: "https://bizopsappuatprod.firebaseio.com",
        projectId: "bizopsappuatprod",
        storageBucket: "bizopsappuatprod.appspot.com",
        messagingSenderId: "84925644823",
        appId: "1:84925644823:android:58a08b938bb81d60f5dc52",
    };
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const storageFn = firebase.storage();
const storageObj = firebase.storage;


export const fireConfig = new Object({
	auth:auth,
	storageFn:storageFn,
	storageObj:storageObj
});