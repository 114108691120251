<template>
    <div full-height>
        <v-toolbar flat fixed>
            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
            <v-toolbar-title class="ml-5">New Ticket</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on"><v-icon color="primary">attachment</v-icon></v-btn>
              </template>
              <v-list nopadding>
                <v-list-item @click="fn_selectFile('file')">
                  <v-list-item-title>Attach File</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="fn_selectFile('db', 1)" :disabled="$root.platform()=='desktop'">
                  <v-list-item-title>Attach Database</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="fn_selectFile('db', 2)">
                  <v-list-item-title>Attach Database From Device</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
        </v-toolbar>

        <div class="hastoolbar hasfooter" :class="{'hasQuillToolbar':editMessage, 'desktop-view':$root.platform()=='desktop'}">
            <v-form class="form-element">

                <v-expansion-panels flat class="mb-3" v-if="attachments && attachments.length">
                  <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="expand_more" style="padding:8px 16px;min-height:48px;">Attachments ({{attachments.length}})</v-expansion-panel-header>
                    <v-divider></v-divider>
                    <v-expansion-panel-content>
                      <div v-for="(item, index) in attachments" :key="index">
                        <v-chip class="mr-2 mt-2 mb-2 pr-1" color="primary" label text-color="white">
                          <span style="overflow:hidden;text-overflow:ellipsis">{{item.fileName}}</span> 
                          <v-icon v-ripple class="ml-3" @click="fn_deleteAttachment(item.filePath, index)">close</v-icon>
                        </v-chip>  
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <input type="file" id="filePicker" style="display:none" @change="fn_validateFile"/>

                <v-layout align-center nomargin padding-top-ten row wrap white-background>
                    <v-flex xs12 sm12 md12 form-item>
                       <!-- <v-text-field  
                            v-model="Form.Title" 
                            label="Title" 
                            placeholder=" "
                            v-validate="'required'"
                            data-vv-name="title"  
                            :error-messages="errors.collect('title')"
                            required>
                        </v-text-field> -->
                        <v-textarea 
                          v-validate="'required|max:250'"
                          data-vv-name="title" 
                          :error-messages="errors.collect('title')" 
                          rows="1" 
                          v-model="Form.Title"
                          placeholder=" " 
                          label="Title" 
                          :counter="250">
                        </v-textarea>
                    </v-flex>

                    <v-flex xs12 sm12 md12 form-item>
                        <v-select :items="requestTypes" label="Request Type" placeholder="" v-model="requestTypeObj" return-object item-text="name" item-value="value"></v-select>
                    </v-flex>

                    <v-flex xs12 sm12 md12 form-item>
                        <v-select :items="ticketStatuses" label="Ticket Status" v-model="StatusObj" return-object item-text="name" item-value="status"></v-select>
                    </v-flex>

                    <v-flex xs12 sm12 md12 form-item class="pb-4" :class="{'blur':!editMessage}">
                        <label style="color: rgba(0, 0, 0, 0.6);font-size: 12px;">Message</label>
                        
                        <quill-editor class="mt-1"
                          ref="myTextEditor"
                          v-model="Form.Message"
                          :options="editorOption"
                          placeholder=""
                          @blur="onEditorBlur($event)"
                          @focus="onEditorFocus($event)"
                          @ready="onEditorReady($event)">
                          <div id="toolbar" slot="toolbar">
                              <!-- Add a bold button -->
                              <button class="ql-bold">Bold</button>
                              <button class="ql-italic">Italic</button>
                              <button class="ql-underline">ql-underline</button>
                              <button class="ql-strike">Italic</button>
                              <!-- Add ordered/bullet list -->
                              <button class="ql-list" value="ordered">ql-list</button>
                              <button class="ql-list" value="bullet">ql-list</button>
                              <button class="ql-link">ql-link</button>
                              
                          </div>
                      </quill-editor>

                        <v-divider dark-opacity-divider></v-divider>
                    </v-flex>

                </v-layout>

            </v-form>

            <v-footer nopadding style="position:fixed;bottom:0;width:100%">
                <v-layout row wrap>
                    <v-flex xs12 sm12 md12 height48>
                        <v-btn color="primary" block full-height noshadow no-round-borders @click="fn_checkValidations">Create Ticket</v-btn>
                    </v-flex>
                </v-layout>
            </v-footer>
        </div>  

        <dialog-progress ref="ref_dialogProgress" :status="buffer_size" :progressMessage="progressMessage" :abortRequest="fn_cancelUpload" :cancelInit="cancelInit"></dialog-progress>

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">{{loadingObj.message ? loadingObj.message : 'Please wait...'}}</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <dialog-permission ref="ref_dialogPermission"></dialog-permission>
        <dialog-confirmation ref="ref_dialogConfirmation" header="Attach Database" confirmationMessage="Desktop connection will break. Whould you like to continue?" :actionMethod="actionMethod" okBtnText="Yes" cancelBtnText="No"></dialog-confirmation>
    </div>
</template>

<script>
import dialogProgress from '../../dialogs/dialog_progress'

import dialogConfirmation from '../../dialogs/dialog_confirmation'

import axios from 'axios'
import {supportMixin} from './support_mixin'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import { quillEditor } from 'vue-quill-editor'

    export default{
        name:'form_ticket',
        mixins:[supportMixin],
        data(){
            return{
                Form:{Title:null, Message:"", Attachments:null, RequestType:3, TicketFolderID:null, TicketMessageFolderID:null, Status:null},
                requestTypes:[{"value":1, "name":"General"},{"value":2, "name":"Login Issue"},{"value":3, "name":"Registration Issue"},{"value":4, "name":"Data Issue"}],
                requestTypeObj:{"value":1, "name":"General"},
                ticketStatuses:[
                    {"name":"Open", "status":1},
                    {"name":"Waiting for Response", "status":2},
                    {"name":"Closed", "status":3},
                    // {"name":"Pending", "status":4},
                ],
                StatusObj:{"name":"Open", "status":1},
                attachments:[],
                editMessage:false,
                editorOption: {placeholder:"",modules: {toolbar: '#toolbar'}},
                buffer_size:0,
                progressMessage:null,
                loadingObj:{
                    loading:false,
                    message:"Please wait..."    
                }
            }
        },
        components:{
            quillEditor,
            'dialog-progress':dialogProgress,
            'dialog-confirmation':dialogConfirmation
        },
        methods: {

          fn_openDialogSampleMessage: function(){
            this.$refs['ref_dialogSampleMessage'].openDialogWeb();
          },

          onEditorBlur(editor) {
            this.editMessage = false;
            console.log('editor blur!', editor);
          },
          onEditorFocus(editor) {
              this.editMessage = true;
              console.log('editor focus!', editor)
          },
          onEditorReady(editor) {
              console.log('editor ready!', editor)
          },

            fn_checkValidations: function(){
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        if(!this.Form.Message){
                          this.$root.fn_showToastMsg("Please add message!");
                        }
                        else{
                           let self = this;
                            this.$root.fn_showLoading('Please wait...', this.loadingObj);
                            this.$root.fn_getAuthToken(function(token){
                              if(token=='error'){
                                self.$root.fn_hideLoading(self.loadingObj);
                                self.$root.fn_showToastMsg("Something went wrong. Try again!");
                                return;
                              }
                              self.fn_createTicket(token);
                            })
                        }
                    }
                    else{
                        this.$vuetify.goTo('.error--text', this.scrollOptions);
                    }
                });
            },

            fn_createTicket: function(token){

                var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                this.Form.Attachments = this.attachments.length ? JSON.stringify(this.attachments) : null;
                this.Form.RequestType = this.requestTypeObj.value;
                this.Form.Status = this.StatusObj.status;
                this.Form.FromDeviceType = this.$root.platform() == 'desktop' ? 3 : 1;

                console.log(JSON.stringify(this.Form));

                // axios.post(process.env.VUE_APP_BASE_API_URL + '/api/support/tickets/create?businessid='+this.$route.query.userbusinessid, this.Form, headersObj, {timeout:30000})
                axios.post(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/tickets/create?businessid='+this.$route.query.userbusinessid, this.Form, headersObj, {timeout:30000})
                .then(response => { 
                    this.$root.fn_hideLoading(this.loadingObj);
                    console.log(response);
                    this.$root.fn_showToastMsg("Ticket created!");
                    this.fn_goBack(-2);
                }).catch(e => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    console.log(e);
                })
                
            },

            // fn_init: function(){
            //   let self = this;
            //   this.fn_getSignature(function(response){
            //     self.Form.Message += response.data.SignatureText;
            //     self.editMessage = true;
            //   })
            // },
           
            fn_goBack: function(count){
              if(count && typeof(count)=="number"){
                this.$router.go(count);  
              }
              else{
                this.$router.go(-1);
              }
            },
        },
        
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
            window.js_globals.fn_setStatus = this.fn_setStatus;
            let self = this;
            this.fn_generateGuid(function(guid){
              self.Form.TicketFolderID = guid;
            });
            // this.fn_init();
        },
        beforeRouteLeave(to, from, next){
            if(this.$refs['ref_dialogProgress'].dialog_progress){
                this.$root.fn_showToastMsg("File uploading in progress!");
                next(false);
            }
            else if(this.$refs['ref_dialogPermission'].dialog_permission){
              this.$refs['ref_dialogPermission'].dialog_permission = false;
              next(false);
            }
            else if(this.$refs['ref_dialogConfirmation'].dialog_confimation){
              this.$refs['ref_dialogConfirmation'].closeDialogWeb();
              next(false);
            }
            else{
                next();
            }
        }
    }
</script>