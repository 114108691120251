<template>
    <div full-height>
        <v-toolbar flat fixed>
            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
            <v-toolbar-title class="ml-5">{{toolbarTitle}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on"><v-icon color="primary">attachment</v-icon></v-btn>
              </template>
              <v-list nopadding>
                <v-list-item @click="fn_selectFileViaDesktop('file')">
                  <v-list-item-title>Attach File</v-list-item-title>
                </v-list-item>
                <template v-if="$root.platform()!='iframe'">
                    <v-list-item @click="fn_checkStorage('db')">
                        <v-list-item-title>Attach Database</v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="fn_checkStorage('logs')">
                        <v-list-item-title>Attach Error Logs</v-list-item-title>
                    </v-list-item>
                </template>
              </v-list>
            </v-menu>
        </v-toolbar>

        <!-- <div class="hastoolbar hasfooter" :class="{'hasQuillToolbar':editMessage}"> -->
          <div class="hastoolbar hasfooter hasQuillToolbar">

              <input type="file" id="filePicker" style="display:none" @change="fn_validateFile"/>
              
            <v-form class="form-element">

                <v-expansion-panels flat v-if="attachments && attachments.length">
                  <v-expansion-panel>
                    <v-expansion-panel-header expand-icon="expand_more" style="padding:8px 16px;min-height:48px;">Attachments ({{attachments.length}})</v-expansion-panel-header>
                    <v-divider></v-divider>
                    <v-expansion-panel-content>
                      <div v-for="(item, index) in attachments" :key="index">
                        <v-chip class="mr-2 mt-2 mb-2 pr-1" color="primary" label text-color="white">
                          <span style="overflow:hidden;text-overflow:ellipsis">{{item.fileName}}</span> 
                          <v-icon v-ripple class="ml-3" @click="fn_deleteAttachment(item.filePath, index)">close</v-icon>
                        </v-chip>  
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <v-layout align-center nomargin padding-top-ten row wrap white-background style="border-top:12px #eee solid;">
                  <v-flex xs12 sm12 md12 mb-2 pb-3 v-if="isFeedback">
                      <!-- <div class="mb-2" style="color:rgba(0,0,0,0.6)">Are You Happy?<span redcolor>*</span></div> -->

                      <span class="mr-4">
                        <!-- Satisfied -->
                        <v-btn :disabled="loading" capitalize text :color="Form.FeedbackType===true ? 'primary' : ''" @click="Form.FeedbackType=true">
                          Satisfied
                          <v-icon right>{{Form.FeedbackType===true ? 'radio_button_checked' : 'radio_button_unchecked'}}</v-icon>
                        </v-btn>
                      </span>

                      <span>
                        <v-btn :disabled="loading" capitalize text :color="Form.FeedbackType===false ? 'primary' : ''" @click="Form.FeedbackType=false">
                          <!-- <v-icon>thumb_down_alt</v-icon> -->
                          Not Satisfied
                          <v-icon right>{{Form.FeedbackType===false ? 'radio_button_checked' : 'radio_button_unchecked'}}</v-icon>
                        </v-btn>
                      </span>
                  </v-flex>

                     <!-- <v-flex xs12 sm12 md12 form-item class="pb-4" :class="{'blur':!editMessage}"> -->
                      <v-flex xs12 sm12 md12 form-item class="pb-4">
                        <label style="color: rgba(0, 0, 0, 0.6);font-size: 12px;">Message</label>
                        
                        <quill-editor class="mt-1"
                            :disabled="loading"
                          ref="myTextEditor"
                          v-model="Form.Message"
                          :options="editorOption"
                          placeholder=""
                          @blur="onEditorBlur($event)"
                          @focus="onEditorFocus($event)"
                          @ready="onEditorReady($event)">
                          <div id="toolbar" slot="toolbar">
                              <!-- Add a bold button -->
                              <button class="ql-bold">Bold</button>
                              <button class="ql-italic">Italic</button>
                              <button class="ql-underline">ql-underline</button>
                              <button class="ql-strike">Italic</button>
                              <!-- Add ordered/bullet list -->
                              <button class="ql-list" value="ordered">ql-list</button>
                              <button class="ql-list" value="bullet">ql-list</button>
                              <button class="ql-link">ql-link</button>

                              
                          </div>
                      </quill-editor>

                        <v-divider dark-opacity-divider></v-divider>
                    </v-flex>
                </v-layout>

            </v-form>

            <v-footer style="position:fixed;bottom:0;width:100%;padding:0">
              <v-layout ma-0 row wrap>
                  <v-flex xs12 sm12 md12 style="height:48px;">
                      <v-btn :disabled="loading" style="height:100%" color="primary" block @click="fn_checkValidations">
                        {{isFeedback ? 'Post Feedback' : 'Post Message'}}
                      </v-btn>
                  </v-flex>
              </v-layout>
                <!-- <v-layout row wrap>
                    <v-flex xs12 sm12 md12 form-item>
                        <v-btn color="primary" block @click="fn_checkValidations">
                          {{isFeedback ? 'Post Feedback' : 'Post Message'}}
                        </v-btn>
                    </v-flex>
                </v-layout> -->
            </v-footer>
        </div>  

        <dialog-progress ref="ref_dialogProgress" :status="buffer_size" :progressMessage="progressMessage" :abortRequest="fn_cancelUpload" :cancelInit="cancelInit"></dialog-progress>

        <dialog-permission ref="ref_dialogPermission"></dialog-permission>
        <dialog-error-logs ref="ref_dialogErrorLogs" :logFileObj="errorLogFileObj" :uploadLogs="fn_getlogEncKey"></dialog-error-logs>

        <dialog-confirmation ref="ref_dialogConfirmation" header="Attach Database" confirmationMessage="Desktop connection will break. Would you like to continue?" :actionMethod="fn_getLocalKeyValue" okBtnText="Yes" cancelBtnText="No"></dialog-confirmation>

        <v-snackbar v-model="$root.snackbarMessageObj.value" right top>
          {{$root.snackbarMessageObj.message}}
          <template v-slot:action="{ attrs }">
            <v-btn icon color="white" v-bind="attrs" text @click.native="$root.snackbarMessageObj.value=false">
                <v-icon>close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
    </div>
</template>

<script>
import axios from 'axios'
import {userMixin} from './user_mixin'
import dialogProgress from '../../dialogs/dialog_progress'
import dialogErrorLogs from './dialogs/dialog_error_logs'

import dialogConfirmation from '../../dialogs/dialog_confirmation'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import { quillEditor } from 'vue-quill-editor'

    export default{
        mixins:[userMixin],
        name: 'user_message_form',
        data(){
            return{
              toolbarTitle:"Message",
              Form:{Message:null, Attachments:null, FeedbackType:null, TicketFolderID:null, TicketMessageFolderID:null},
              attachments:[],
              editMessage:false,
              editorOption: {placeholder:"",modules: {toolbar: '#toolbar'}},
              buffer_size:0,
              progressMessage:null,
              isFeedback:false,
              loading:false,
              errorLogFileObj:null
            }
        },
        components:{
            quillEditor,
            'dialog-progress':dialogProgress,
            'dialog-error-logs':dialogErrorLogs,
            'dialog-confirmation':dialogConfirmation
        },
        methods: {

          onEditorBlur(editor) {
            this.editMessage = false;
            console.log('editor blur!', editor);
          },
          onEditorFocus(editor) {
              this.editMessage = true;
              console.log('editor focus!', editor)
          },
          onEditorReady(editor) {
              console.log('editor ready!', editor)
          },

            fn_checkValidations: function(){
                this.$validator.validateAll().then((result) => {
                    if (result) {

                      if(this.isFeedback && this.Form.FeedbackType==null){
                        this.$root.fn_showToastMsg("Please provide feedback!");
                      }
                      else if(!this.Form.Message){
                        this.$root.fn_showToastMsg("Please add message!");
                      }
                      else{
                         let self = this;
                            this.loading = true;
                          this.showLoading('Please wait...');
                          this.$root.fn_getAuthToken(function(token){
                              if(token == 'error'){
                                  self.loading = false;
                                self.hideLoading();
                                return;
                              }
                              self.fn_postMessage(token);
                          })
                      }
                    }
                    else{
                        this.$vuetify.goTo('.error--text', this.scrollOptions);
                    }
                });
            },

            fn_postMessage: function(token){

              let postObj = {};
              postObj.Message = this.Form.Message;
              postObj.Attachments = this.attachments.length ? JSON.stringify(this.attachments) : null;
              postObj.TicketMessageFolderID = this.Form.TicketMessageFolderID;
              postObj.FromDeviceType = this.$root.platform() == 'desktop' ? 3 : 1;

              let toastMsg = null;
              let api = null;

              if(this.isFeedback){
                postObj.FeedbackType = this.Form.FeedbackType;
                toastMsg = "Feedback Added!";
                // api = process.env.VUE_APP_BASE_API_URL + "/api/business/"+this.$route.query.businessid+"/User/tickets/"+this.$route.params.ticketid+"/postfeedback"
                api = process.env.VUE_APP_BASE_API_URL + "/api/business/"+this.$route.query.businessid+"/Support/tickets/"+this.$route.params.ticketid+"/postfeedback"
              }
              else{
                toastMsg = "Message added!";
                // api = process.env.VUE_APP_BASE_API_URL + '/api/business/'+this.$route.query.businessid+'/User/tickets/'+this.$route.params.ticketid+'/postmessage'
                api = process.env.VUE_APP_BASE_API_URL + '/api/business/'+this.$route.query.businessid+'/Support/tickets/'+this.$route.params.ticketid+'/postmessage'
              }

              var headersObj = {
              headers: { 
                    'Authorization': 'bearer '+token,
                  }
              };

              axios.post(api, postObj, headersObj, {timeout:30000})
              .then(response => {
                  this.hideLoading();
                  console.log(response);
                  this.$root.fn_showToastMsg(toastMsg);
                  if(this.isFeedback){
                    localStorage.setItem("refresh_items", 1);
                  }
                  this.fn_goBack();
              }).catch(e => {
                  this.loading = false;
                  this.hideLoading();
                  this.$root.fn_showToastMsg("Something went wrong. Try again!");
                  console.log(e);
              })
                
            },
           
            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        
        mounted: function(){
          window.js_globals.fn_onBackPress = this.fn_goBack;
          window.js_globals.fn_setStatus = this.fn_setStatus;

          this.isFeedback = this.$route.query.isfeedback == 1 ? true : false;
          this.toolbarTitle = this.isFeedback ? "Submit FeedBack" : "Add Message";
          this.Form.TicketFolderID = this.$route.query.ticketFolderId;
          let self = this;
          this.fn_generateGuid(function(guid){
            self.Form.TicketMessageFolderID = guid;
          });
        },
        beforeRouteLeave(to, from, next){
            if(this.$refs['ref_dialogProgress'].dialog_progress){
                this.$root.fn_showToastMsg("File uploading in progress!");
                next(false);
            }
            else if(this.$refs['ref_dialogPermission'].dialog_permission){
              this.$refs['ref_dialogPermission'].dialog_permission = false;
              next(false);
            }
            else if(this.$refs['ref_dialogConfirmation'].dialog_confimation){
              this.$refs['ref_dialogConfirmation'].closeDialogWeb();
              next(false);
            }
            else{
                next();
            }
        }
    }
</script>