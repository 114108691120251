<template>
    <div>
        <v-dialog ref="datepicker" no-click-animation v-model="$parent.dialog_datepicker" :return-value.sync="selectedDate" persistent lazy full-width width="290px" transition="slide-y-transition">
            <!-- <v-date-picker v-model="selectedDate" :min="$root.minDateSelection" :max="$root.maxDateSelection" scrollable> -->
            <v-date-picker v-model="selectedDate" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$parent.dialog_datepicker = false">Cancel</v-btn>
                <v-btn text color="primary" @click="fn_saveDate(selectedDate)">OK</v-btn>
            </v-date-picker>
        </v-dialog>
    </div>
</template>

<script>

export default{
    props:['selected', 'updateDate'],
    data(){
        return{
            selectedDate: null
        }
    },
    methods:{
        fn_saveDate: function (value) {
            let dateObj = new Date(value);
            this.$refs.datepicker.save(value);
            this.updateDate(dateObj);
        }
    }
}
</script>