<template>
    <div full-height>
        <v-toolbar flat fixed>
            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
            <v-toolbar-title class="ml-5">{{$route.query.tagid != undefined ? 'Edit Tag' : 'Add Tag'}}</v-toolbar-title>
        </v-toolbar>

        <div class="hastoolbar hasfooter" :class="{'desktop-view':$root.platform()=='desktop'}">
            <v-form class="form-element">

                <v-layout align-center nomargin padding-top-ten row wrap white-background>

                    <v-flex xs12 sm12 md12 form-item>
                       <v-text-field  
                            v-model="Form.Title" 
                            label="Enter Tag Name" 
                            placeholder=" "
                            clearable
                            v-validate="'required'"
                            data-vv-name="tagName"  
                            data-vv-as="tag name"  
                            :error-messages="errors.collect('tagName')"
                            required>
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 sm12 md12 form-item>
                        <v-select
                            v-model="Form.Status"
                            label="Select Status" 
                            :items="statusList"
                            item-text="title"
                            item-value="value"
                            placeholder=" "
                            v-validate="'required'"
                            data-vv-name="tagStatus"  
                            data-vv-as="tag status"  
                            :error-messages="errors.collect('tagStatus')"
                            required>
                        </v-select>
                    </v-flex>

                </v-layout>

            </v-form>

            <!-- <v-footer style="position:fixed;bottom:0;width:100%">
                <v-layout row wrap>
                    <v-flex xs12 sm12 md12 form-item>
                        <v-btn color="primary" block @click="fn_checkValidations">Save</v-btn>
                    </v-flex>  
                </v-layout>
            </v-footer> -->

            <v-footer nopadding style="position:fixed;bottom:0;width:100%">
                <v-layout row wrap>
                    <v-flex xs12 sm12 md12 height48>
                        <v-btn color="primary" block full-height noshadow no-round-borders @click="fn_checkValidations">
                            Save
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-footer>
        </div>  

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <dialog-loading :loading="loading"></dialog-loading>
    </div>
</template>

<script>
import axios from 'axios';
import dialogLoading from '../../dialogs/dialog_loading.vue'

    export default{
        name:'form_tag',
        data(){
            return{
                Form:{"Title":null, "Status": 1},
                loadingObj:{
                  loading:false,
                  message:"Please wait..."
                },
                statusList: [
                    {title: 'Active', value: 1},
                    {title: 'In-Active', value: -1},
                    {title: 'Draft', value: 2},
                ],
                tagID: null,
                loading: false
            }
        },
        components: {
            'dialog-loading':dialogLoading
        },
        methods: {



            fn_checkValidations: function(){
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        let self = this;
                        this.$root.fn_showLoading('Please wait...', this.loadingObj);
                        this.$root.fn_getAuthToken(function(token){
                            if(token=='error'){
                              self.$root.fn_hideLoading(self.loadingObj);
                              self.$root.fn_showToastMsg("Something went wrong. Try again!");
                              return;
                            }
                            self.fn_save(token);
                        })
                    }
                });
            },

            fn_save: function(token){

                var headersObj = {
                headers: { 
                      'Authorization': 'bearer '+token,
                    }
                };

                let postObj = {};
                postObj.Title = this.Form.Title;
                postObj.Status = this.Form.Status;

                console.log(JSON.stringify(postObj));

                var postApi = '';
                if(this.$route.query.tagid){
                    postObj.TagID = this.Form.TagID;
                    postApi = '/api/supportteam/tags/update';
                }
                else
                    postApi = '/api/supportteam/tags/create';

                axios.post(process.env.VUE_APP_BASE_API_URL + postApi, postObj, headersObj, {timeout:30000})
                .then(response => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    console.log(response);
                    if(this.$route.query.tagid)
                        this.$root.fn_showToastMsg("Tag updated!");
                    else
                        this.$root.fn_showToastMsg("Tag added!");
                    this.fn_goBack();
                }).catch(e => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    console.log(JSON.stringify(e));
                })
                
            },

            fn_getToken: function(){
                let self = this;
                this.loading = true;
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){
                    if(token == 'error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        this.loading = false;
                        self.$root.fn_showToastMsg("Something went wrong. Try again!!");
                        return;
                    }

                    self.fn_getTagDetails(token);
                });
            },

            fn_getTagDetails: function(token){
                let self = this;
               
                var headersObj = {
                headers: { 
                        'Authorization': 'bearer '+token,
                    }
                };

                axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/tags/' + this.$route.query.tagid, headersObj, {timeout:30000})
                .then(response => {
                    console.log(response);
                    self.Form = response.data;
                    self.$root.fn_hideLoading(self.loadingObj);
                    this.loading = false;
                }).catch(e => {
                    console.log(e);
                    self.$root.fn_showToastMsg("Something went wrong. Try again!");
                    self.$root.fn_hideLoading(self.loadingObj);
                    self.snackbarRetry = true;
                })
                
            },
           
            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
            console.log(this.$route.query.tagid);
            if(this.$route.query.tagid)
                this.fn_getToken();
        }
    }
</script>