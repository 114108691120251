<template>
    <div full-height grey-back>
        <v-toolbar flat>
            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
            <v-toolbar-title class="ml-5">Business</v-toolbar-title>
        </v-toolbar>

        <div class="hastoolbar" full-height v-if="displayData">

            <v-list three-line full-width>
                <v-list-item style="padding-right:0">
                    <v-list-item-content>
                      <v-list-item-title>{{displayData.BusinessName}}</v-list-item-title>
                      <v-list-item-subtitle>Business ID: {{displayData.BusinessID}}</v-list-item-subtitle>
                      <v-list-item-subtitle>State: {{displayData.State}}</v-list-item-subtitle>
                      <v-list-item-subtitle v-if="displayData.BusinessProfileNumber">Mobile Number: <span class="label_mobile_number" style="display: none;">+{{displayData.BusinessProfileNumber}}</span> <span @click="fn_toggleMobileHideShow" class="label_hide_show primary-color link-item font-medium">View</span></v-list-item-subtitle>
                      <v-list-item-subtitle v-else-if="displayData.BusinessOwnerNumber">Mobile Number: <span class="label_mobile_number" style="display: none;">+{{displayData.BusinessOwnerNumber}}</span> <span @click="fn_toggleMobileHideShow" class="label_hide_show primary-color link-item font-medium">View</span></v-list-item-subtitle>
                      <!-- <v-list-item-subtitle class="label_mobile_number" v-else-if="displayData.BusinessOwnerNumber"> +{{displayData.BusinessOwnerNumber}}</v-list-item-subtitle> -->

                    </v-list-item-content>

                     <v-list-item-action>
                        <div style="background: #1976d2;color: #fff;padding: 2px 8px;border-top-left-radius: 4px;border-bottom-left-radius: 4px;font-size: 0.875rem;">
                            {{displayData.Status==1 ? 'Active' : "De-Active"}}
                        </div>
                    </v-list-item-action>
                </v-list-item>
            </v-list>

            <v-card flat margin-top-ten v-if="displayData.Address">
                <v-subheader>Address</v-subheader>
                <v-divider></v-divider>
                <v-card-text>
                    {{displayData.Address}}
                </v-card-text>
            </v-card>

            <v-list nopadding three-line full-width margin-top-ten>
                <v-subheader>GST Details</v-subheader>
                <v-divider></v-divider>
                <v-list-item style="padding-right:0">
                    <v-list-item-content>
                      <v-list-item-title>
                          {{displayData.GSTRegistrationStatus == 1 ? "Registered Business" : displayData.GSTRegistrationStatus == 2 ? "Composite Business" : "Unregistered Business"}}
                      </v-list-item-title>
                      <v-list-item-subtitle>GST Number: {{displayData.GSTNumber}}</v-list-item-subtitle>
                      <v-list-item-subtitle>{{displayData.HSNApplicable ? "HSN Applicable" : "HSN Not Applicable"}}</v-list-item-subtitle>
                      <v-list-item-subtitle>Tax Mode: {{displayData.TaxMode == 1 ? 'Exclusive' : displayData.TaxMode == 2 ? 'Inclusive' : 'None'}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <v-list three-line full-width margin-top-ten>
                <v-list-item style="padding-right:0">
                    <v-list-item-content>
                      <v-list-item-title>Created On</v-list-item-title>
                      <v-list-item-subtitle>{{displayData.CreatedOn | moment("DD/MM/YYYY h:mm a")}}</v-list-item-subtitle>
                      <v-list-item-subtitle>{{displayData.IsSupport ? "By Support User" : "By Customer"}}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>


            
        </div>

        <snackbar-retry :value="snackbarRetry" message="Failed to load ticket!" :action="fn_getToken"></snackbar-retry>

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import snackbarRetry from '../../dialogs/snackbar_retry'

    export default{
        name:'display_business',
        data(){
            return{
                displayData:null,
                snackbarRetry:false,
                loadingObj:{
                    loading:false,
                    message:"Please wait..."    
                }
                
            }
        },
        components:{
            'snackbar-retry': snackbarRetry,
        },
        methods:{

            fn_toggleMobileHideShow: function(){
                let displayPropertyVal = document.getElementsByClassName('label_mobile_number')[0].style.display;
                if(displayPropertyVal == 'none'){
                    document.getElementsByClassName('label_mobile_number')[0].style.display = 'inline-block';
                    document.getElementsByClassName('label_hide_show')[0].innerHTML = 'Hide';
                }
                else{
                    document.getElementsByClassName('label_mobile_number')[0].style.display = 'none';
                    document.getElementsByClassName('label_hide_show')[0].innerHTML = 'View';
                }
            },
            
            fn_getToken: function(){
                let self = this;
                this.snackbarRetry = false;

                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){
                    if(token == 'error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        self.snackbarRetry = true;
                        return;
                    }

                    self.fn_getBusiness(token);
                });
            },

            fn_getBusiness: function(token){
                var headersObj = {
                headers: { 
                      'Authorization': 'bearer '+token,
                    }
                };

                axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/businesses/'+this.$route.params.businessid, headersObj, {timeout:30000})
                .then(response => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    console.log(response);
                    console.log(JSON.stringify(response.data));
                    this.displayData = response.data;
                }).catch(e => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    this.snackbarRetry = true;
                    console.log(JSON.stringify(e));
                })
            },

            fn_goBack: function(){
                this.$router.go(-1);
            },
        },
        mounted: function(){
            this.fn_getToken();
            window.js_globals.fn_onBackPress = this.fn_goBack;
        }
    }
</script>