<template>
    <div>
        <v-dialog v-model="dialog_business" scrollable fullscreen persistent :overlay="false" max-width="500px" no-click-animation transition="slide-y-transition">
            <v-card text>
                <v-toolbar fixed flat class="main_toolbar">
                    <v-toolbar-side-icon @click="closeDialogWeb"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
                    <v-toolbar-title class="ml-5">Select Business</v-toolbar-title>
                </v-toolbar>

                <div class="hastoolbar">
                    <v-list nopadding>
                        <v-list-item v-for="(item, index) in businessList" :key="index" @click="newTicket(item.BusinessID)">
                            <v-list-item-title>{{item.BusinessName}}</v-list-item-title>
                            <v-list-item-action>
                              <v-icon>chevron_right</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </div>  
            </v-card>
        </v-dialog>
    </div>
</template>     

<script>

export default{
    props:['businessList', 'newTicket'],
    data(){ 
        return{
            dialog_business: false,
        }
    },
    methods: {
        openDialogWeb: function () {    
            this.dialog_business = true;
        },
        closeDialogWeb: function () {
            this.dialog_business = false;
        },
    }
}
</script>