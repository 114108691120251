<template>
    <div>
        <v-dialog v-model="dialog_restore_type" :hide-overlay="true" persistent max-width="290" transition="slide-y-transition">
            <v-card>
                <v-subheader>Restore Type</v-subheader>
                <v-divider></v-divider>
                <v-card-text nopadding>
                    <v-list nopadding>
                        <v-list-item @click="selectedType=1">
                            <v-list-item-title>Temporary Setup</v-list-item-title>
                            <v-list-item-action>
                                <v-icon>{{selectedType == 1 ? 'radio_button_checked' : 'radio_button_unchecked'}}</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                        <!-- <v-divider></v-divider>
                        <v-list-item @click="selectedType=2" :disabled="$parent.isTemporaryBusiness">
                            <v-list-item-title>Overwrite Business</v-list-item-title>
                            <v-list-item-action>
                              <v-icon>{{selectedType == 2 ? 'radio_button_checked' : 'radio_button_unchecked'}}</v-icon>
                            </v-list-item-action>
                        </v-list-item> -->
                    </v-list>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small color="blue darken-1" text @click="closeDialogWeb">Close</v-btn>
                    <v-btn small color="blue darken-1" text @click="restore(selectedType)">Done</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>     

<script>

export default{
    props:['restore'],
    data(){ 
        return{
            dialog_restore_type: false,
            selectedType:1
        }
    },
    methods: {
        openDialogWeb: function () {    
            this.dialog_restore_type = true;
        },
        closeDialogWeb: function () {
            this.dialog_restore_type = false;
        },
    }
}
</script>