<template>
    <div>
        <v-toolbar flat fixed dense>

            <v-toolbar-side-icon @click="fn_goBack" v-if="$root.platform()=='android'"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title :class="{'ml-5':$root.platform()=='android'}">App Feedbacks</v-toolbar-title>

            <v-spacer></v-spacer>

            <span @click="fn_clearFilter()" style="font-size: 13px;margin-right: 16px; cursor: pointer; border: 1px solid black; border-radius: 20px; padding: 4px;" v-if="selectedType">
                <span>Clear All Filters</span><v-icon class="ml-2" size="18">close</v-icon>
            </span>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text capitalize v-bind="attrs" color="primary" v-on="on">
                    {{ selectedType == 1 ? "Positive" : selectedType == 2 ? "Negative" : selectedType == 3 ? "Suggestions" : selectedType == 4 ? "Bugs" : "All"}}
                    <v-icon right color="primary">filter_list</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in feedbackTypes" :key="index" @click="fn_changeStatus(item.value)">
                  <v-list-item-title>{{item.name}}</v-list-item-title>
                  <v-list-item-action>
                    <v-icon v-if="item.value==selectedType">radio_button_checked</v-icon>
                    <v-icon v-else>radio_button_unchecked</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>

        </v-toolbar>

        <div class="hastoolbar" :class="{'hastoolbar':$root.platform()=='android'}">


            <v-list three-line nopadding subheader full-width>

                <v-subheader style="height:40px" full-width>
                    <span @click="fn_openDialogPeriod"><v-icon>date_range</v-icon>&nbsp;Period: {{dateFrom | moment("DD/MM/YYYY")}} To {{dateTo | moment("DD/MM/YYYY")}}</span>

                    <!-- <v-spacer></v-spacer>

                    <span @click="fn_clearFilter()" style="margin-right: 16px; cursor: pointer; border: 1px solid black; border-radius: 20px; padding: 4px;" v-if="selectedType">
                        <span>Clear All Filters</span><v-icon class="ml-2" size="18">close</v-icon>
                    </span> -->
                </v-subheader>

                <v-divider></v-divider>

                <div v-for="(item, index) in feedback.Items" :key="index">
                    <v-list-item @click="fn_viewFeedback(item.FeedbackID)">
                        <v-list-item-content>
                            <v-list-item-title v-html="item.FeedbackText" class="child-no-margin"></v-list-item-title>
                            <v-list-item-subtitle>
                                {{item.Business.BusinessName}}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                {{item.ModifiedOn | moment("DD MMM, YYYY h:mm a")}}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-tile-action>
                            <!-- <v-menu>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on" style="justify-content:flex-end"><v-icon>more_horiz</v-icon></v-btn>
                              </template>
                              <v-list nopadding>
                                <v-list-item @click="fn_getToken(item)">
                                  <v-list-item-title>Mark as {{!item.IsSupportRead ? 'read' : 'unread'}}</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu> -->
                          
                            <div right-align><v-icon :color="item.FeedbackType == 1 ? 'green' : item.FeedbackType == 2 ? 'warning' : item.FeedbackType == 3 ? 'primary' : 'red'">{{item.FeedbackType == 1 ? 'thumb_up_alt' : item.FeedbackType == 2 ? 'thumb_down_alt' : item.FeedbackType == 3 ? "comment" : "bug_report"}}</v-icon></div>
                        </v-list-tile-action>
                    </v-list-item>
                    <v-divider v-if="feedback.Items.length-1 != index"></v-divider>
                </div>

                <infinite-loading spinner="spiral" :identifier="infiniteId" @infinite="infiniteHandler">
                    <div slot="no-results" font12 light-opacity-color class="pa-3">There is no feedback!</div>
                    <div slot="no-more"></div>
                </infinite-loading>
            </v-list>

        </div>

        <snackbar-retry :value="snackbarRetry" message="Failed to load feedbacks!" :action="fn_searchItem"></snackbar-retry>

        <dialog-period-selection ref="ref_dialogCustomPeriod" title="Select Period" :openDatePicker="fn_openDatePicker" :startDate="selectedStartDate" :endDate="selectedEndDate" :actionMethod="fn_updateCustomDates"></dialog-period-selection>

        <dialog-datepicker ref="ref_dialogDatePicker" :selected="selectedDate" :updateDate="fn_updateDate"></dialog-datepicker>

    </div>
</template>

<script>
import axios from 'axios';

import InfiniteLoading from 'vue-infinite-loading';
import snackbarRetry from '../../../dialogs/snackbar_retry'

import dialogPeriodSelection from '../dialogs/dialog_period_selection'
import dialogDatepicker from '../dialogs/dialog_datepicker'

    export default{
        name:'items_feedback',
        data(){
            return{
                feedback:{"Items":[], "count":null},
                offset:0,
                infiniteId: +new Date(),
                selectedType:null,
                feedbackTypes:[{"name":"All", "value":null},{"name":"Positive", "value":1},{"name":"Negative", "value":2},{"name":"Suggestions", "value":3},{"name":"Bugs", "value":4}],
                snackbarRetry:false,
                loadingObj:{
                    loading:false,
                    message:"Please wait..."    
                },
                dialog_datepicker:false,
                selectedDate:null,
                dateType:null,
                selectedStartDate:null,
                selectedEndDate:null,
                dateFrom:this.$root.minDateSelection,
                dateTo:this.$root.maxDateSelection
            }
        },
        components: {
            InfiniteLoading,
            'snackbar-retry': snackbarRetry,
            'dialog-period-selection':dialogPeriodSelection,
            'dialog-datepicker':dialogDatepicker
        },
        methods:{

            fn_viewFeedback: function(feedbackId){
                this.$router.push({path:"/support/supportteam/tickets/appfeedback/items/"+feedbackId});
            },

            fn_openDialogPeriod: function(){
                this.selectedStartDate = this.dateFrom;
                this.selectedEndDate = this.dateTo;
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = true;
            },

            fn_openDatePicker: function (type, date) {
                let self = this;
                this.dateType = type;
                let dateObj = date ? new Date(date) : new Date();
                this.$refs['ref_dialogDatePicker'].selectedDate = dateObj.getFullYear()+'-'+("0" + (dateObj.getMonth()+1)).slice(-2)+'-'+("0" + dateObj.getDate()).slice(-2);
                setTimeout(function () {
                    self.dialog_datepicker = true;
                })
            },

            fn_updateDate: function (value) {
                // if(this.dateType=="end" && new Date(value).getTime() < new Date(this.$root.CurrentBusiness.OpeningDate).getTime()){
                //     this.showToast("Not Applicable");
                //     return;
                // }

                if(this.dateType=="start"){
                    this.selectedStartDate = value;
                }
                else if(this.dateType=="end"){
                    this.selectedEndDate = value;
                }

                this.dialog_datepicker = false;

                // this.fn_getDashboardData();
            },

            fn_updateCustomDates: function(){
                this.dateFrom = this.selectedStartDate;
                this.dateTo = this.selectedEndDate;
                // this.fn_closePeriodDialog();
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = false;
                this.fn_searchItem();
            },

            fn_newTicket: function(){
                this.$router.push({path:"/support/supportteam/tickets/new", query:{businessid:this.$route.query.businessid}});
            },

            fn_viewTicket: function(ticketId){
                this.$router.push({path:"/support/supportteam/tickets/"+ticketId, query:{businessid:this.$route.query.businessid}});
            },

            infiniteHandler($state) {

                let jsonData = {"FeedbackType": this.selectedType};

                if(localStorage.getItem('appfeedbackfilters')){
                    let filterData = JSON.parse(localStorage.getItem('appfeedbackfilters'));
                    if(filterData.FeedbackType){
                        jsonData.FeedbackType = filterData.FeedbackType === undefined ? null : filterData.FeedbackType;
                        this.selectedType = filterData.FeedbackType;
                    }
                }

                jsonData.DateFrom = this.$moment(this.dateFrom).startOf('day');
                jsonData.DateTo = this.$moment(this.dateTo).endOf('day');

                jsonData.DateFrom = this.fn_getLocalDateFormat(jsonData.DateFrom);
                jsonData.DateTo = this.fn_getLocalDateFormat(jsonData.DateTo);

                console.log(JSON.stringify(jsonData));

                let self = this;
                this.$root.fn_getAuthToken(function(token){

                    if(token=='error'){
                        self.snackbarRetry = true;
                        $state.complete();
                        return;
                    }

                    var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                    axios.post(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/appfeedback/getlist?offset='+self.offset+'&limit=10', jsonData, headersObj, {timeout:30000})
                    .then(response => {
                        console.log(response);
                        console.log(JSON.stringify(response));
                        self.feedback.count = response.data.ItemCount;

                        if (self.feedback.count > self.feedback.Items.length) {
                            for(let i=0; i<response.data.Feedbacks.length; i++){
                                self.feedback.Items.push(response.data.Feedbacks[i]);
                            }
                            $state.loaded();
                        } else {
                            $state.complete();
                        }

                        self.offset = self.feedback.Items.length;

                    }).catch(e => {
                        console.log(e);
                        self.$root.fn_showToastMsg("Something went wrong. Try again!");
                        self.snackbarRetry = true;
                        $state.complete();
                    })
                })
                
            },

            fn_getToken: function(item){
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                let self = this;
                this.$root.fn_getAuthToken(function(token){
                    if(token=='error'){
                        this.$root.fn_hideLoading(this.loadingObj);
                        self.$root.fn_showToastMsg("Something went wrong. Try again!");
                        return;
                    }

                    self.fn_markReadUnread(token, item);
                })
            },

            fn_markReadUnread: function(token, item){
                var headersObj = {
                headers: { 
                      'Authorization': 'bearer '+token,
                    }
                };

                let stat = item.IsSupportRead ? 'MarkasUnread' : 'MarkasRead';

                let api = process.env.VUE_APP_BASE_API_URL + '/api/supportteam/tickets/'+item.SupportTicketID+'/feedback/'+item.SupportTicketConversationID+'/'+stat;

                axios.get(api, headersObj, {timeout:30000})
                .then(response => {
                    console.log(response);
                    item.IsSupportRead = !item.IsSupportRead;
                    this.$root.fn_hideLoading(this.loadingObj);
                }).catch(e => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    this.snackbarRetry = true;
                    console.log(JSON.stringify(e));
                })
            },

            fn_changeStatus: function(value){
                this.selectedType = value;
                let filterObj = {}
                filterObj.FeedbackType = this.selectedType;
                localStorage.setItem('appfeedbackfilters', JSON.stringify(filterObj));
                this.fn_searchItem();
            },

            fn_searchItem:  function() {
              this.snackbarRetry = false;
              this.offset = 0;
              this.feedback.Items = [];
              this.feedback.count = 0;
              this.infiniteId += 1;
            },

            fn_goBack: function(){
                this.$router.go(-1);
            },

            fn_clearFilter: function(){
                let filterObj = {};
                filterObj.FeedbackType = null;
                localStorage.setItem('appfeedbackfilters', JSON.stringify(filterObj));
                this.selectedType = null;
                this.fn_searchItem();
            },
        },
        mounted: function(){
            /*global AndroidRoutingTest*/
            AndroidRoutingTest.onPageLoadCompleted('1', JSON.stringify({}));
            this.hideLoading();
            window.js_globals.fn_onBackPress = this.fn_goBack;
        },
        beforeRouteLeave (to, from , next) {
            if(this.dialog_datepicker){
                this.dialog_datepicker = false;
                next(false);
            }
            else if(this.$refs['ref_dialogCustomPeriod'].dialog_period_selection){
                this.$refs['ref_dialogCustomPeriod'].dialog_period_selection = false;
                next(false);
            }
            else{
                next();
            }
        },
    }
</script>