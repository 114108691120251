<template>
    <div>
        <v-toolbar flat fixed dense>

            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
                <v-toolbar-title class="ml-5">App Feedback</v-toolbar-title>
            </v-toolbar>

            <div class="hastoolbar" :class="{'hastoolbar':$root.platform()=='android'}" v-if="displayData">
                <v-card flat>
                    <v-card-text>
                        Feedback Type: <strong>{{ displayData.FeedbackType == 1 ? "Positive" : displayData.FeedbackType == 2 ? "Negative" : displayData.FeedbackType == 3 ? "Suggestions" : displayData.FeedbackType == 4 ? "Bugs" : "All"}} </strong>
                    </v-card-text>

                    <v-card-text>
                        Feedback:
                        <div style="color:rgba(0,0,0,0.87)">{{displayData.FeedbackText}}</div>
                        <div class="pt-1">{{ displayData.ModifiedOn | moment("DD MMM, YYYY h:mm a") }}</div>
                    </v-card-text>

                    <v-card-text>
                        Feedback By
                        <div class="mb-2" style="color:rgba(0,0,0,0.87)"><a @click="fn_openDialogBusinessDetails(displayData.BusinessID)">{{displayData.Business.BusinessName}}</a></div>
                        <div light-opacity-color>Business ID</div>
                        <div class="mb-2" style="color:rgba(0,0,0,0.87)">{{displayData.BusinessID}}</div>
                        <div light-opacity-color>State</div>
                        <div class="mb-2" style="color:rgba(0,0,0,0.87)">{{displayData.Business.State}}</div>
                        <div light-opacity-color>User Type</div>
                        <div style="color:rgba(0,0,0,0.87)">{{displayData.IsAccountantOnly ? 'Accountant Only' : displayData.UserType == 2 ? 'Accountant with Business Registered' : 'General User'}}</div>
                    </v-card-text>
                </v-card>
            </div>
        <snackbar-retry :value="snackbarRetry" message="Failed to load feedback!" :action="fn_getFeedback"></snackbar-retry>

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <dialog-business-details ref="ref_dialogBusinessDetails" type="ticket"></dialog-business-details>

    </div>
</template>

<script>
import axios from 'axios';
import snackbarRetry from '../../../dialogs/snackbar_retry';
import dialogBusinessDetails from '../dialogs/dialog_viewbusinessdetails';

    export default{
        data(){
            return{
                displayData:null,
                feedbackTypes:[{"name":"All", "value":null},{"name":"Positive", "value":1},{"name":"Negative", "value":2},{"name":"Suggestions", "value":3},{"name":"Bugs", "value":4}],
                snackbarRetry:false,
                loadingObj:{
                    loading:true,
                    message:"Please wait..."    
                },
            }
        },
        components: {
            'snackbar-retry': snackbarRetry,
            'dialog-business-details': dialogBusinessDetails
        },
        methods:{

            fn_getFeedback: function() {

                let self = this;
                this.loadingObj.loading = true;
                this.$root.fn_getAuthToken(function(token){

                    if(token=='error'){
                        self.snackbarRetry = true;
                        this.loadingObj.loading = false;
                        return;
                    }

                    var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                    axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/appfeedback/'+self.$route.params.feedbackId, headersObj, {timeout:30000})
                    .then(response => {
                        console.log(response);
                        self.displayData = response.data;
                        self.loadingObj.loading = false;
                    }).catch(e => {
                        console.log(e);
                        self.$root.fn_showToastMsg("Something went wrong. Try again!");
                        self.snackbarRetry = true;
                        self.loadingObj.loading = false;
                    })
                })
                
            },

            fn_goBack: function(){
                this.$router.go(-1);
            },

            fn_openDialogBusinessDetails: function(businessid){
                this.$refs['ref_dialogBusinessDetails'].fn_getToken(businessid);
            },
        },
        mounted: function(){
            this.fn_getFeedback();
            window.js_globals.fn_onBackPress = this.fn_goBack;
        }
    }
</script>