<template>
    <div>
        <v-toolbar flat fixed>
            <v-toolbar-side-icon @click="fn_goBack" v-if="$root.platform()=='android'"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
            <v-toolbar-title :class="{'ml-5':$root.platform()=='android'}">Support Users</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" icon @click="fn_newUser">New</v-btn>


            <span class="ml-3" @click="fn_clearFilter()" style="font-size: 13px;margin-right: 16px; cursor: pointer; border: 1px solid black; border-radius: 20px; padding: 4px;" v-if="selectedUserType">
                <span>Clear All Filters</span><v-icon class="ml-2" size="18">close</v-icon>
            </span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text capitalize color="primary" v-bind="attrs" v-on="on">
                    {{ selectedUserType == 1 ? "Active" : selectedUserType == 0 ? "De-active" : "All"}}
                    <v-icon right color="primary">filter_list</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in userTypes" :key="index" @click="fn_changeUserType(item.value)">
                  <v-list-item-title>{{item.name}}</v-list-item-title>
                  <v-list-item-action>
                    <v-icon v-if="item.value==selectedUserType">radio_button_checked</v-icon>
                    <v-icon v-else>radio_button_unchecked</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
        </v-toolbar>

        <div class="hastoolbar">

            <v-text-field class="desktop-searchbar" nopadding hide-details elevation-10  flat solo placeholder="Search..." v-model.lazy="searchValue" @input="fn_searchItemDebounce" clearable v-if="$root.platform()=='desktop'"></v-text-field>

            <v-divider v-if="$root.platform()=='desktop'"></v-divider>

            <v-list two-line subheader full-width>

                <div v-for="(item, index) in user.items" :key="index">
                    <v-list-item @click="fn_itemClick(item)" style="padding-right:0">
                        <v-list-item-content>
                          <v-list-item-title>{{item.Name}}</v-list-item-title>
                          <v-list-item-subtitle>{{item.PhoneNumber}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <div style="background: #1976d2;color: #fff;padding: 2px 8px;border-top-left-radius: 4px;border-bottom-left-radius: 4px;font-size: 0.875rem;">
                                {{item.Status}}
                            </div>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider v-if="user.items.length-1 != index"></v-divider>
                </div>

                <infinite-loading spinner="spiral" :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading>
            </v-list>


        </div>

        <snackbar-retry :value="snackbarRetry" message="Failed to load users!" :action="fn_searchItem"></snackbar-retry>

    </div>
</template>

<script>
import axios from 'axios';
import debounce from 'lodash/debounce';

import InfiniteLoading from 'vue-infinite-loading';
import snackbarRetry from '../../dialogs/snackbar_retry'

    export default{
        data(){
            return{
                user:{"items":[], "count":null},
                infiniteId: +new Date(),
                offset:0,
                userTypes:[
                    {"name":"All", "value":null},
                    {"name":"Active", "value":1},
                    {"name":"De-active", "value":0},
                ],
                selectedUserType:1,
                snackbarRetry:false,
                searchValue: ''
            }
        },
        components: {
            InfiniteLoading,
            'snackbar-retry': snackbarRetry
        },
        methods:{

            fn_newUser: function(){
                this.$router.push({path:"/support/supportteam/new"});
            },

            fn_itemClick: function(item){
                if(item.Status==0){
                    this.$root.fn_showToastMsg("User deactivated!");
                    return;
                }
                this.$router.push({path:"/support/supportteam/"+item.SupportUserID+"/edit"});
            },

            fn_changeUserType:  function(value) {
                this.selectedUserType = value;
                let filterObj = {}
                filterObj.Status = this.selectedUserType;
                localStorage.setItem('supportuserfilters', JSON.stringify(filterObj));
                this.fn_searchItem();
            },

            fn_searchItem: function(){
                this.snackbarRetry = false;
                this.offset = 0;
                this.user.items = [];
                this.user.count = 0;
                this.infiniteId += 1;
            },

            infiniteHandler($state) {

                let jsonData = {"Keywords": this.searchValue,"Status":this.selectedUserType,"IsDeveloper":null,"IsNotification":null,"IsFeedback":null,"DateFrom":null,"DateTo":null}


                if(localStorage.getItem('supportuserfilters')){
                    let filterData = JSON.parse(localStorage.getItem('supportuserfilters'));
                    if(filterData.Status !== undefined){
                        jsonData.Status = filterData.Status === undefined ? null : filterData.Status;
                        this.selectedUserType = filterData.Status;
                    }
                }

                console.log(JSON.stringify(jsonData));

                let self = this;
                this.$root.fn_getAuthToken(function(token){

                    if(token=='error'){
                        self.snackbarRetry = true;
                        $state.complete();
                        return;
                    }

                    var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                    axios.post(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/SupportUsers/getlist?offset='+self.offset+'&limit=10', jsonData, headersObj, {timeout:30000})
                    .then(response => {
                        console.log(response);
                        JSON.stringify(response);
                        self.user.count = response.data.ItemCount;

                        if (self.user.count > self.user.items.length) {
                            for(let i=0; i<response.data.Users.length; i++){
                                self.user.items.push(response.data.Users[i]);
                            }
                            $state.loaded();
                        } else {
                            $state.complete();
                        }

                        self.offset = self.user.items.length;

                    }).catch(e => {
                        console.log(e);
                        self.$root.fn_showToastMsg("Something went wrong. Try again!");
                        self.snackbarRetry = true;
                        $state.complete();
                    })
                })
                
            },


            fn_goBack: function(){
                this.$router.go(-1);
            },

            fn_searchItemDebounce: debounce(function() {
                this.snackbarRetry = false;
                this.offset = 0;
                this.user.items = [];
                this.user.count = 0;
                this.infiniteId += 1;
            }, 1000),

            fn_clearFilter: function(){
                let filterObj = {};
                filterObj.Keywords = null;
                filterObj.Status = null;
                localStorage.setItem('supportuserfilters', JSON.stringify(filterObj));
                this.selectedUserType = null;
                this.searchValue = '';
                this.fn_searchItem();
            },
        },
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
        }
    }
</script>