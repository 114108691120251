<template>
    <div>
        <v-toolbar flat fixed>

            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title class="ml-5">Reports</v-toolbar-title>

        </v-toolbar>

        <div class="hastoolbar hasfooter">
            <v-list nopadding full-width>
                <v-list-item @click="fn_report('ticket')">
                    <v-list-item-content>
                      <v-list-item-title>Support Ticket</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <!-- <v-list-item @click="fn_report('ticketprogress')">
                    <v-list-item-content>
                      <v-list-item-title>Ticket Progress</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_report('userprogress')">
                    <v-list-item-content>
                      <v-list-item-title>User Progress</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider> -->

                <v-list-item @click="fn_report('businessregister', 'daywise')">
                    <v-list-item-content>
                      <v-list-item-title>Business Register Day Wise</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_report('businessregister', 'monthwise')">
                    <v-list-item-content>
                      <v-list-item-title>Business Register Month Wise</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_report('appusage')">
                    <v-list-item-content>
                      <v-list-item-title>App Usage Report</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>
                
                <v-divider></v-divider>

                <v-list-item @click="fn_report('appusagedaywise')">
                    <v-list-item-content>
                      <v-list-item-title>App Usage Day Wise Report (Action On)</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_report('retention')">
                    <v-list-item-content>
                      <v-list-item-title>Retention Report</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_report('appusagedaywisetagwise')">
                    <v-list-item-content>
                      <v-list-item-title>App Usage Report by Tagging</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon color="primary">chevron_right</v-icon>
                    </v-list-item-action>
                </v-list-item>

            </v-list>
        </div>
    </div>
</template>

<script>
    export default{
        methods:{
            fn_report: function(reportId, type){
                let reportType = type ? type : 'general';
                this.$router.push({path:"/support/supportteam/reports/items/"+reportId, query:{"businessid":this.$route.query.businessid, reporttype:reportType}});
            },

            fn_goBack: function () {
                this.$router.go(-1);
            }
        },
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
        }
    }
</script>