<template>
    <div>
        <v-dialog v-model="dialog_attachments" scrollable fullscreen persistent :hide-overlay="true" max-width="500px" no-click-animation transition="slide-y-transition">
            <v-card>
                <v-toolbar fixed flat class="main_toolbar">
                    <v-toolbar-side-icon @click="closeDialogWeb"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
                    <v-toolbar-title class="ml-5">Attachments</v-toolbar-title>
                </v-toolbar>

                <div class="hastoolbar">
                    <v-card-text>
                        <div v-for="(item, index) in attachments" :key="index">
                          <v-chip class="mr-2 mt-2 mb-2" color="primary" label text-color="white">
                            <span style="overflow:hidden;text-overflow:ellipsis">{{item.fileName}}</span> 
                            <v-icon v-ripple class="ml-3" @click="downloadAttachment(item)">cloud_download</v-icon>
                          </v-chip>  
                        </div>
                    </v-card-text>
                </div>  
            </v-card>
        </v-dialog>
    </div>
</template>     

<script>

export default{
    props:['attachments', 'downloadAttachment'],
    data(){ 
        return{
            dialog_attachments: false,
        }
    },
    methods: {
        openDialogWeb: function () {    
            this.dialog_attachments = true;
        },
        closeDialogWeb: function () {
            this.dialog_attachments = false;
        },
    }
}
</script>