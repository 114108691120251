<template>
    <div>
        <v-toolbar flat fixed v-if="$root.platform()=='android'">

            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>

            <v-toolbar-title class="ml-5">Workflows</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small text capitalize color="primary" v-bind="attrs" v-on="on">{{ selectedStatus == 0 ? 'Initiated' : selectedStatus == 1  ? 'Completed' : selectedStatus == 2  ? 'Partially Completed' : selectedStatus == 3  ? 'Failed' : selectedStatus == 4  ? 'Completed Later' : selectedStatus == 5  ? 'Re-Initiated' : 'All'}} <v-icon right>filter_list</v-icon></v-btn>
                </template>
                <v-list>
                    <v-list-item @click="fn_changeStatus(-1)">
                        <v-list-item-title>All</v-list-item-title>
                        <v-list-item-action>
                        <v-icon v-if="selectedStatus==-1">radio_button_checked</v-icon>
                        <v-icon v-else>radio_button_unchecked</v-icon>
                        </v-list-item-action>
                    </v-list-item>

                    <v-list-item @click="fn_changeStatus(0)">
                        <v-list-item-title>Initiated</v-list-item-title>
                        <v-list-item-action>
                        <v-icon v-if="selectedStatus==0">radio_button_checked</v-icon>
                        <v-icon v-else>radio_button_unchecked</v-icon>
                        </v-list-item-action>
                    </v-list-item>

                    <v-list-item @click="fn_changeStatus(1)">
                        <v-list-item-title>Completed</v-list-item-title>
                        <v-list-item-action>
                        <v-icon v-if="selectedStatus==1">radio_button_checked</v-icon>
                        <v-icon v-else>radio_button_unchecked</v-icon>
                        </v-list-item-action>
                    </v-list-item>

                    <v-list-item @click="fn_changeStatus(2)">
                        <v-list-item-title>Partially Completed</v-list-item-title>
                        <v-list-item-action>
                        <v-icon v-if="selectedStatus==2">radio_button_checked</v-icon>
                        <v-icon v-else>radio_button_unchecked</v-icon>
                        </v-list-item-action>
                    </v-list-item>

                    <v-list-item @click="fn_changeStatus(3)">
                        <v-list-item-title>Failed</v-list-item-title>
                        <v-list-item-action>
                        <v-icon v-if="selectedStatus==3">radio_button_checked</v-icon>
                        <v-icon v-else>radio_button_unchecked</v-icon>
                        </v-list-item-action>
                    </v-list-item>

                    <v-list-item @click="fn_changeStatus(4)">
                        <v-list-item-title>Completed Later</v-list-item-title>
                        <v-list-item-action>
                        <v-icon v-if="selectedStatus==4">radio_button_checked</v-icon>
                        <v-icon v-else>radio_button_unchecked</v-icon>
                        </v-list-item-action>
                    </v-list-item>

                    <v-list-item @click="fn_changeStatus(5)">
                        <v-list-item-title>Re-Initiated</v-list-item-title>
                        <v-list-item-action>
                        <v-icon v-if="selectedStatus==5">radio_button_checked</v-icon>
                        <v-icon v-else>radio_button_unchecked</v-icon>
                        </v-list-item-action>
                    </v-list-item>
                    
                </v-list>
            </v-menu>

        </v-toolbar>

        <div :class="{'hastoolbar': $root.platform()=='android'}">

            <v-list two-line subheader full-width>

                <v-subheader style="position: sticky;top: 56px;z-index: 1;background: #ffffff;">
                    {{ data.count }} Items
                    <v-spacer></v-spacer>
                    <v-menu offset-y v-if="$root.platform()=='desktop'">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small text capitalize color="primary" v-bind="attrs" v-on="on">{{ selectedStatus == 0 ? 'Initiated' : selectedStatus == 1  ? 'Completed' : selectedStatus == 2  ? 'Partially Completed' : selectedStatus == 3  ? 'Failed' : selectedStatus == 4  ? 'Completed Later' : selectedStatus == 5  ? 'Re-Initiated' : 'All'}} <v-icon right>filter_list</v-icon></v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="fn_changeStatus(-1)">
                                <v-list-item-title>All</v-list-item-title>
                                <v-list-item-action>
                                <v-icon v-if="selectedStatus==-1">radio_button_checked</v-icon>
                                <v-icon v-else>radio_button_unchecked</v-icon>
                                </v-list-item-action>
                            </v-list-item>

                            <v-list-item @click="fn_changeStatus(0)">
                                <v-list-item-title>Initiated</v-list-item-title>
                                <v-list-item-action>
                                <v-icon v-if="selectedStatus==0">radio_button_checked</v-icon>
                                <v-icon v-else>radio_button_unchecked</v-icon>
                                </v-list-item-action>
                            </v-list-item>

                            <v-list-item @click="fn_changeStatus(1)">
                                <v-list-item-title>Completed</v-list-item-title>
                                <v-list-item-action>
                                <v-icon v-if="selectedStatus==1">radio_button_checked</v-icon>
                                <v-icon v-else>radio_button_unchecked</v-icon>
                                </v-list-item-action>
                            </v-list-item>

                            <v-list-item @click="fn_changeStatus(2)">
                                <v-list-item-title>Partially Completed</v-list-item-title>
                                <v-list-item-action>
                                <v-icon v-if="selectedStatus==2">radio_button_checked</v-icon>
                                <v-icon v-else>radio_button_unchecked</v-icon>
                                </v-list-item-action>
                            </v-list-item>

                            <v-list-item @click="fn_changeStatus(3)">
                                <v-list-item-title>Failed</v-list-item-title>
                                <v-list-item-action>
                                <v-icon v-if="selectedStatus==3">radio_button_checked</v-icon>
                                <v-icon v-else>radio_button_unchecked</v-icon>
                                </v-list-item-action>
                            </v-list-item>

                            <v-list-item @click="fn_changeStatus(4)">
                                <v-list-item-title>Completed Later</v-list-item-title>
                                <v-list-item-action>
                                <v-icon v-if="selectedStatus==4">radio_button_checked</v-icon>
                                <v-icon v-else>radio_button_unchecked</v-icon>
                                </v-list-item-action>
                            </v-list-item>

                            <v-list-item @click="fn_changeStatus(5)">
                                <v-list-item-title>Re-Initiated</v-list-item-title>
                                <v-list-item-action>
                                <v-icon v-if="selectedStatus==5">radio_button_checked</v-icon>
                                <v-icon v-else>radio_button_unchecked</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-subheader>

                <!-- <v-divider v-if="businessData.count"></v-divider> -->

                <div v-for="(item, index) in data.items" :key="index">
                    <v-list-item class="pr-0">

                        <v-list-item-action>
                            <v-icon :class="[item.Status == 0 || item.Status == 5 ? 'primary-color' : item.Status == 1 || item.Status == 4 ? 'greencolor' : 'redcolor']">{{ item.Status == 0 ? 'hourglass_empty' : item.Status == 5 ? 'sync' : item.Status == 1 || item.Status == 4 ? 'check_circle' : 'error'  }}</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title>{{item.WorkflowID}}</v-list-item-title>
                          <v-list-item-subtitle>{{item.InitiatedOn | moment("DD/MM/YYYY h:mm a")}}</v-list-item-subtitle>
                          <!-- <v-list-item-subtitle>
                            <span :class="[item.Status == 'Initiated' ? 'primary-color' : item.Status == 'Completed' ? 'greencolor' : 'redcolor']">{{ item.Status }}</span>
                          </v-list-item-subtitle> -->
                        </v-list-item-content>

                        <v-list-item-action v-if="item.Status == 0 || item.Status == 2 || item.Status == 3">
                            <v-btn small class="mr-4" color="primary" @click="fn_triggerWorkflow(item.WorkflowID, index)">Start Process</v-btn>
                        </v-list-item-action>

                    </v-list-item>
                    <v-divider v-if="data.items.length-1 != index"></v-divider>
                </div>

                <infinite-loading spinner="spiral" :identifier="infiniteId" @infinite="infiniteHandler">
                    <div slot="no-results" class="pa-4" style="font-size:0.875rem" grey-back light-opacity-color>No items found!</div>
                    <div slot="no-more"></div>
                </infinite-loading>
            </v-list>

        </div>

        <snackbar-retry :value="snackbarRetry" message="Failed to load messages!" :action="fn_searchItem"></snackbar-retry>

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios';

import InfiniteLoading from 'vue-infinite-loading';
import snackbarRetry from '../dialogs/snackbar_retry'

    export default{
        name:'items_business',
        data(){
            return{
                data:{"items":[], "count":0},
                offset:0,
                infiniteId: +new Date(),
                searchValue:'',
                snackbarRetry:false,
                loadingObj:{
                    loading:false,
                    message:"Please wait..."    
                },
                selectedStatus:3
            }
        },
        components: {
            InfiniteLoading,
            'snackbar-retry': snackbarRetry
        },
        methods:{

            // fn_getItems: function(){

            //     this.$root.fn_getAuthToken(token => {

            //         if(token=='error'){
            //             self.snackbarRetry = true;  
            //             return;
            //         }

            //         var headersObj = {
            //         headers: { 
            //               'Authorization': 'bearer '+token,
            //             }
            //         };

            //         axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/workflow/getlist?status='+this.selectedStatus, headersObj, {timeout:30000})
            //         .then(response => {
            //             console.log(JSON.stringify(response));
            //             this.data.items = response.data;
            //             this.data.count = this.data.items.length;
            //         }).catch(e => {
            //             console.log(e);
            //             this.$root.fn_showToastMsg("Something went wrong. Try again!");
            //             this.snackbarRetry = true;
            //         })
            //     })
            // },

            fn_triggerWorkflow: function(workflowid, index){

                this.$root.fn_getAuthToken(token => {

                    if(token=='error'){
                        this.snackbarRetry = true;  
                        return;
                    }

                    var headersObj = {
                    headers: { 
                        'Authorization': 'bearer '+token,
                        }
                    };

                    axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/workflow/'+workflowid+'/manualtrigger', headersObj, {timeout:30000})
                    .then(response => {
                        console.log(JSON.stringify(response));
                        this.data.items[index].Status = 5;
                        this.$root.fn_showToastMsg("Initiated");
                    }).catch(e => {
                        console.log(e);
                        this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    })
                })
            },

            infiniteHandler($state) {

                this.$root.fn_getAuthToken(token=>{

                    if(token=='error'){
                        this.snackbarRetry = true;
                        $state.complete();
                        return;
                    }

                    var headersObj = {
                    headers: { 
                          'Authorization': 'bearer '+token,
                        }
                    };

                    axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/workflow/getlist?status='+this.selectedStatus+'&offset='+this.offset+'&limit=10', headersObj, {timeout:30000})
                    .then(response => {
                        console.log(JSON.stringify(response));
                        this.data.count = response.data.ItemCount;

                        if (this.data.count > this.data.items.length) {
                            for(let i=0; i<response.data.Workflows.length; i++){
                                this.data.items.push(response.data.Workflows[i]);
                            }
                            $state.loaded();
                        } else {
                            $state.complete();
                        }

                        this.offset = this.data.items.length;

                    }).catch(e => {
                        console.log(e);
                        this.$root.fn_showToastMsg("Something went wrong. Try again!");
                        this.snackbarRetry = true;
                        $state.complete();
                    })
                })
                
            },

            fn_searchItem:  function() {
              this.snackbarRetry = false;
              this.offset = 0;
              this.data.items = [];
              this.data.count = 0;
              this.infiniteId += 1;
            },

            fn_changeStatus: function(status){
                this.selectedStatus = status;
                this.fn_searchItem();
                // this.fn_getItems();
            },

            // fn_viewTicket: function(item){
            //     this.$router.push({path:"/support/supportteam/tickets/"+item.SupportTicketID, query:{"messageid":item.SupportTicketConversationID, "businessid":this.$route.query.businessid}});
            // },

            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        mounted: function(){
            // this.fn_getItems();
            window.js_globals.fn_onBackPress = this.fn_goBack;
        }
    }
</script>