<template>
    <div full-height>
        <v-toolbar flat fixed>
            <v-btn icon @click="back"><v-icon>close</v-icon></v-btn>
            <v-toolbar-title color="primary">BizOps App Support</v-toolbar-title>
        </v-toolbar>

        <div class="hastoolbar grey-back">
            <!-- <v-progress-linear indeterminate color="primary" :height="4"></v-progress-linear> -->

            <div style="display: flex;align-items: center;justify-content: center;height: calc(100vh - 56px);">
                <v-layout ma-0 row wrap center-align>
                    <v-flex xs12 sm12 md12>
                        <v-progress-circular :size="36" :width="3" indeterminate color="primary"></v-progress-circular>
                    </v-flex>
                    <v-flex xs12 sm12 md12 pt-3>
                        Please wait...
                    </v-flex>
                </v-layout>
            </div>

            <!-- <div>Gettting Details...</div> -->
        </div>
    </div>
</template>

<script>

    export default{
        data(){
            return{
                loading: false
            }
        },
        methods:{
            back: function(){
                if(this.$root.platform() == 'iframe'){
                    window.parent.postMessage({
                        type: 'closeSupportApp'
                    }, "*");
                }
            }
        },
        mounted: function(){
            if(this.$root.platform() == 'iframe'){
                this.getUser(user=>{
                    if(!user){
                        window.parent.postMessage({
                            type: 'getToken'
                        }, "*");
                    }
                })
            }
        }
    }
</script>