<template>
    <div full-height>

        <div>

            <v-card noshadow align-center>
                <v-card-text>
                    <v-form class="form-element">
                        <v-layout row wrap style="max-width:400px;margin:auto">

                                <div class="mt-4" style="margin:auto">
                                    <v-img max-height="80" max-width="80" src="../../../assets/BizOpslogo_Updated.svg" class="mr-4"></v-img>
                                </div>

                                <p class="title grey-color mt-4" style="line-height:28px !important">
                                    We will send an OTP on the mentioned mobile number
                                </p>

                                <v-flex xs12 sm12 md12 nopadding>
                                   <v-text-field type="tel"  
                                        id="mobile_number_field"
                                        v-model="Form.mobileNumber" 
                                        flat
                                        clearable
                                        label="Mobile Number" 
                                        prefix="+91"
                                        hide-details
                                        placeholder=" "
                                        v-validate="'required|numeric|min:10|max:10'"
                                        :maxlength="10"
                                        data-vv-name="mobile" 
                                        data-vv-as="mobile number" 
                                        required>
                                    </v-text-field>
                                </v-flex>

                                <v-flex xs12 sm12 md12 margin-top-ten nopadding right-align>
                                   <v-btn color="primary" nomargin @click="fn_checkValidation">next</v-btn>
                                </v-flex>

                            </v-layout>
                    </v-form>
                </v-card-text>
            </v-card>
        </div>  

        <v-footer class="pa-3" height-auto bottom-fixed-footer>
            <v-flex xs12 sm12 md12 center-align>
                <div grey-color>BizOps App Support</div>
            </v-flex>
        </v-footer>

        <v-snackbar v-model="snackbarToast">{{toastMsg}}
            <v-btn text color="primary" @click="snackbarToast=false">Okay</v-btn>
        </v-snackbar>

        <v-dialog v-model="loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import axios from 'axios'

    export default{
        name:'login',
        data(){
            return{
                Form:{"mobileNumber":null},
                loading:false,
                snackbarToast:false,
                toastMsg:"Something went wrong!"
            }
        },
        methods: {

            fn_checkValidation: function(){
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        
                        if(!navigator.onLine){
                            this.snackbarToast = true;
                            this.toastMsg = "Internet not available!";
                            return;
                        }

                        this.fn_sendOtp();
                    }
                    else{
                        this.snackbarToast = true;
                        this.toastMsg = "Invalid mobile number!";
                    }
                });
            },

            fn_sendOtp: function(){

                let postObj = {};
                postObj.PhoneNumber = "91"+this.Form.mobileNumber;
                postObj.RequestKey = this.guidWeb();
                console.log(JSON.stringify(postObj));
                this.loading = true;
                axios.post(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/login/SendOTP', postObj, {timeout:30000})
                .then(response => { 
                    console.log(response);
                    this.loading = false;
                    this.snackbarToast = true;
                    this.toastMsg = "Otp Sent!";
                    this.$router.push({path:"/support/supportteam/verifyotp", query:{mobile_number:this.Form.mobileNumber, request_id:response.data.RequestID, request_key:postObj.RequestKey}});
                }).catch(e => {
                    this.loading = false;
                    this.snackbarToast = true;
                    this.toastMsg = "Something went wrong!";
                    console.log(JSON.stringify(e));
                })
            },

            fn_goBack: function(){
                console.log("Confirm Exit");
                this.exitApp();
            },
        },  

        mounted: function(){
            let formElement = document.getElementsByClassName("form-element")[0];
            formElement.addEventListener("submit", function(e){
                e.preventDefault();
            });
        }
    }
</script>