export const globalMixin =  {

    data(){
        return {
            // /*global AndroidRoutingTest*/
            isRestoreDb:false,
            temporaryBusinessId:null,
            snackbarRetry:false,
            permissionType:null,
            currentBusinessLogo:null,
            minDateSelection:"2022-04-01",
            maxDateSelection:"2025-03-31",
            iframeToken:null,
            fnCallBack:null,
            snackbarMessageObj:{"value":false, "messag":""},
        }
    },

    methods: {

        fn_ticketStatus: function(status){
            if(status==1)
                return "Open";
            else if(status==2)
                return "Waiting for Response";
            else
                return "Closed";
        },

        fn_requestType: function(type){
            if(type==1)
                return "General";
            else if(type==2)
                return "Login Issue";
            else if(type==3)
                return "Registration Issue";
            else if(type==4)
                return "Data Issue";
        },

        fn_statusBackgroundClass: function(status){
            if(status==1)
                return "redbackground";
            else if(status==2)
                return "primarybackground";
            else
                return "greenbackground";
        },

        apiBaseUrl: function(){
            // let baseUrl = null;
            // if(process.env.NODE_ENV === 'production'){
            //     baseUrl = 'https://bizopsappapi.azurewebsites.net'
            // }
            // else if(process.env.NODE_ENV === 'staging'){
            //     baseUrl = 'https://uatbizopsappapi.azurewebsites.net'
            // }
            // else{
            //     baseUrl = 'https://devbizopsappapi.azurewebsites.net'
            // }
            // return baseUrl;
            return process.env.VUE_APP_BASE_API_URL
        },

        platform: function(){
            /*global AndroidRoutingTest*/
            if ( window.location !== window.parent.location ) {
                return 'iframe'
            }
            else if(typeof(AndroidRoutingTest) == "undefined"){
                return 'desktop'
            }
            else{
                return 'android'
            }
        },

        fn_checkStoragePermission: function(callback){
            var self = this;
            this.getData("CheckStoragePermission", null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        callback();
                    }, 
                    onError: function(error){
                        console.log(error);
                        self.showToast("Please allow storage permission!");
                    }
                }
            );
        },

        fn_getAuthToken: function(callback){
            if(!navigator.onLine){
                this.showToast('Internet not available!');
                callback('error');
                return;
            }
            
            if(this.platform() == 'android'){
                this.fn_getTokenViaAndroid(callback);
            }
            else if(this.platform() == 'iframe'){
                // this.fnCallBack = callback;
                // window.parent.postMessage({
                //     type: 'getToken'
                // }, "*");
                this.getFirebaseToken(callback);
            }
            else{
                this.getFirebaseToken(callback);
            }
        },

        fn_getTokenViaAndroid: function(callback){
            let self = this;
            this.getData('GetToken', null, 
                {
                    onSuccess: function(response){
                        console.log(response);
                        callback(response);
                    }, 
                    onError: function(error){
                        console.log(error);
                        callback('error');
                        self.showToast("Something went wrong. Try again!.");
                        self.hideLoading();
                    }
                }
            );
        },

        fn_closeSupportAndReopenDB: function(){
            this.getData('CloseAndReopenDB', this.temporaryBusinessId, 
                {
                    onSuccess: function(response){
                        console.log(response);
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        },

        fn_showLoading(msg, loadingObj){
            if(this.platform()=='android'){
                this.showLoading(msg);
            }
            else{
                loadingObj.loading = true;
                loadingObj.message = msg;
            }
        },

        fn_hideLoading(loadingObj){
            if(this.platform()=='android'){
                this.hideLoading();
            }
            else{
                loadingObj.loading = false;
            }
        },

        fn_gotoPermissionSettings: function(){
            this.getData('ShowPermissionSettings', this.permissionType, {
                onSuccess: function(response){
                  console.log(response);
                }, 
                onError: function(error){
                  console.log(error);
                }
            });
        },

        // getting current business logo
        fn_getCurrentBusinessLogo: function(businessId){
            let self = this;
            this.getData('CurrentBusinessLogo', businessId, {
                onSuccess: function(response){
                  console.log(response);
                  self.currentBusinessLogo = response;
                },
                onError: function(error){
                  console.log(error);
                }
            });
        },

        fn_showToastMsg: function(message){
            if(this.$root.platform()=='android'){
                this.showToast(message);
            }
            else{
                this.snackbarMessageObj.value = true;
                if(!navigator.onLine){
                  this.snackbarMessageObj.message = "Internet not available!";  
                }
                else{
                  this.snackbarMessageObj.message = message;
                }
            }
        },

        fn_checkConnectionStatus: function(callback){
            this.getData('RTCConnectionStatus', null, 
            {
                onSuccess: function(response){
                    console.log(response);
                    callback ? callback(response) : '';
                }, 
                onError: function(error){
                    console.log(error);
                }
            });
        },

        fn_openExternalUrl: function(path){
            this.getData('OpenBrowser', path, 
                {   
                    onSuccess: function(response){
                        console.log(response);
                    }, 
                    onError: function(error){
                        console.log(error);
                    }
                }
            );
        }

    },
    mounted: function(){
        // window.js_globals.iframeTokenCallback = this.fnCallBack;
        if(this.platform()=='desktop' && this.$route.name!='defaultPage'){
            this.$router.replace({path:"/support/supportteam/default"});
        }
        
        let self = this;

        window.addEventListener("message", (event) => {
            console.log(event);
            if(event.data.type == "token"){
                console.log(self.getUser);
                self.signInWithCustomToken(event.data.authToken, function(){
                    // alert('signed in');
                    self.$router.replace({path:"/support/appsupport/tickets/items", query:{businessid:self.$route.query.businessid}})
                });
            }

            if(event.data.type == "business_logo"){
                console.log(event.data.businessLogo);
                self.currentBusinessLogo = event.data.businessLogo;
            }
            // alert('online');
        });

        addEventListener('click', event => {
            const anchor = event.target.closest("a");
            if (!anchor) {
                return
            }

            let href = anchor.getAttribute('href');
            this.fn_openExternalUrl(href);
        })
    }
}