import axios from 'axios';
import dialogPermission from '../../dialogs/dialog_permission';
var storageFn = require('../../../firebase.js').fireConfig.storageFn
var storageObj = require('../../../firebase.js').fireConfig.storageObj

export const supportMixin =  {
  components:{
    'dialog-permission': dialogPermission,
  },
  data(){
    return{
      cancelInit:false,
      uploadTask:null,
      actionMethod:null
    }
  },
	methods:{

    fn_generateGuid: function(callback){

      this.$root.fn_showLoading('Please wait...', this.loadingObj);
      let self = this;
      this.$root.fn_getAuthToken(function(token){
          if(token == 'error'){
            self.$root.fn_hideLoading(self.loadingObj);
            return;
          }

          var headersObj = {
          headers: { 
              'Authorization': 'bearer '+token,
            }
          };

        // axios.post(process.env.VUE_APP_BASE_API_URL + '/api/business/'+this.$route.query.businessid+'/User/tickets/create', this.Form, headersObj, {timeout:30000})
        axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/generateguid', headersObj, {timeout:30000})
        .then(response => {
            console.log(response);
            self.$root.fn_hideLoading(self.loadingObj);
            callback(response.data.GUID);
        }).catch(e => {
            // this.showToast("Something went wrong. Try again!");
            self.$root.fn_hideLoading(self.loadingObj);
            console.log(e);
            callback(null);
        })
      })
        
    },

    fn_openDialogProgress: function(){
      this.$refs['ref_dialogProgress'].openDialogWeb();
    },

    fn_closeDialogProgress: function(){
      this.$refs['ref_dialogProgress'].closeDialogWeb();
    },

    fn_setStatus: function(value){
      console.log(value);
      this.buffer_size = value;
    },

    fn_cancelUpload: function(){
        this.cancelInit = true;
        this.progressMessage = "Cancel Upload...";
        if(this.$root.platform()=='desktop'){
          this.fn_cancelDesktopUpload();
        }
        else{
          let self = this;
          this.getData('CancelUpload', null, 
              {
                  onSuccess: function(response){
                      console.log(response);
                      self.$root.fn_showToastMsg("Uploading cancelled!");
                      self.cancelInit = false;
                      self.fn_closeDialogProgress();
                  }, 
                  onError: function(error){
                      self.cancelInit = false;
                      self.fn_closeDialogProgress();
                      self.$root.fn_showToastMsg("Failed to cancel uploading!");
                      console.log(error);
                  }
              }
          );
        }
    },

	fn_checkStorage: function(type, dbType){
      if(this.attachments.length==5){
        this.$root.fn_showToastMsg("Max file limit reached!");
        return;
      }

      let self = this;
      this.$root.fn_checkStoragePermission(function(){
        if(!self.Form.TicketMessageFolderID){
          self.fn_generateGuid(function(guid){
            self.Form.TicketMessageFolderID = guid;
            self.fn_chooseFile(type, dbType);
          });
        }
        else{
          self.fn_chooseFile(type, dbType);
        }
      })

      // let self = this;
      // this.$root.fn_checkStoragePermission(function(){
      //   if(type=='file'){
      //     self.fn_selectFileAndroid();
      //   }
      //   else if(type=='db'){
      //     dbType == 1 ? self.fn_checkTemporaryBusiness() : self.fn_selectLocalDatabase();
      //   }
      // })
    },

    fn_chooseFile: function(type, dbType){
      if(type=='file'){
        this.fn_selectFileAndroid();
      }
      else if(type=='db'){
        // this.$refs['ref_dialogConfirmation'].openDialogWeb();
        if(dbType == 2){
          this.fn_selectLocalDatabase();
        }
        else{
          //this.actionMethod = this.fn_checkTemporaryBusiness;
          this.$root.fn_checkConnectionStatus(response => {
            if(JSON.parse(response)){
              this.actionMethod = this.fn_checkTemporaryBusiness;
              this.$refs['ref_dialogConfirmation'].openDialogWeb();
            }
            else{
              this.fn_checkTemporaryBusiness();
            }
          });
        }
        
        // dbType == 1 ? this.fn_checkTemporaryBusiness() : this.fn_selectLocalDatabase();
      }
    },

      fn_checkTemporaryBusiness : function(){
        this.$refs['ref_dialogConfirmation'].closeDialogWeb();
        let self = this;
        this.$root.fn_showLoading('Please wait...', this.loadingObj);
          this.getData('CheckTemporaryBusiness', this.$route.query.businessid, 
              {
                  onSuccess: function(response){
                      console.log(response);
                      if(JSON.parse(response)){
                        // self.fn_getLocalKeyValue();
                        self.fn_getServerKeyValue();
                      }
                      else{
                        self.fn_checkOverwriteBusiness();
                      }
                  }, 
                  onError: function(error){
                      console.log(error);
                      self.$root.fn_hideLoading(self.loadingObj);
                      self.$root.fn_showToastMsg("Something went wrong. Try again!");
                  }
              }
          );
      },

      fn_checkOverwriteBusiness : function(){
        let self = this;
          this.getData('CheckOverwriteBusiness', this.$route.query.businessid, 
              {
                  onSuccess: function(response){
                      console.log(response);
                      if(JSON.parse(response)){
                        self.fn_getOverwriteBusinessId();
                      }
                      else{
                        self.fn_getLocalKeyValue();
                      }
                  }, 
                  onError: function(error){
                      console.log(error);
                      self.$root.fn_hideLoading(self.loadingObj);
                      self.$root.fn_showToastMsg("Something went wrong. Try again!");
                  }
              }
          );
      },

      fn_getOverwriteBusinessId : function(){
          let self = this;
          this.getData('GetOverwriteBusinessId', null, 
              {
                  onSuccess: function(response){
                      console.log(response);
                      self.fn_getServerKeyValue(response);
                  }, 
                  onError: function(error){
                      console.log(error);
                      self.$root.fn_hideLoading(self.loadingObj);
                      self.$root.fn_showToastMsg("Something went wrong. Try again!");
                  }
              }
          );
      },

      fn_getServerKeyValue: function(businessId){
        if(!navigator.onLine){
            this.showToast('Internet not available.');
            this.$root.fn_hideLoading(this.loadingObj);
            return;
        }

        let self = this;
        this.$root.fn_getAuthToken(function(token){
          let filterObject = {};
          filterObject.businessId = businessId ? businessId : self.$route.query.businessid;
          filterObject.token = token;
          self.getServerData('GetServerKeyValue', filterObject, 
              {
                  onSuccess: function(response){
                      let keyValueObj = JSON.parse(response);
                      self.$root.fn_hideLoading(self.loadingObj);
                      self.fn_selectDatabase(keyValueObj, businessId)
                  }, 
                  onError: function(error){
                      console.log(error);
                      self.$root.fn_hideLoading(self.loadingObj);
                      self.$root.fn_showToastMsg("Something went wrong. Try again!");
                  }, 
              }
          );
        })
        
      },

      fn_getLocalKeyValue : function(){
        let self = this;
          this.getData('GetLocalKeyValue', this.$route.query.businessid, 
              {
                  onSuccess: function(response){
                      console.log(response);
                      let keyValueObj = JSON.parse(response);
                      self.$root.fn_hideLoading(self.loadingObj);
                      self.fn_selectDatabase(keyValueObj)
                  }, 
                  onError: function(error){
                      console.log(error);
                      self.$root.fn_hideLoading(self.loadingObj);
                      self.$root.fn_showToastMsg("Something went wrong. Try again!");
                  }
              }
          );
      },

      fn_selectFileAndroid: function(){
        var self = this;
        let postObj = {}
        postObj.isSupportUser = true;
        postObj.ticketFolderId = this.Form.TicketFolderID;
        postObj.messageFolderId = this.Form.TicketMessageFolderID;
        // postObj.businessId = this.$route.query.businessid;
        postObj.businessId = this.$route.query.userbusinessid;
        postObj.fileExtentions = ['JPG','JPEG','PNG','GIF','MP4', 'MPEG', 'AVI','MOV','FLV','PDF','DOC','DOCX','TEXT','XLSX','CSV','MP3','WMA','AAC','WAV','AES'];
        self.progressMessage = "Uploading...";
        setTimeout(function(){
          self.fn_openDialogProgress();
        },300)
        this.postData("SelectLocalFile", postObj, 
            {
                onSuccess: function(response){
                    console.log(response);
                    if(!self.cancelInit){
                      let fileObj = JSON.parse(response);
                      // self.Form.TicketFolderId = fileObj.ticketFolderId;
                      // self.Form.TicketMessageFolderID = fileObj.messageFolderId;
                      self.attachments.push(fileObj);
                    }
                    else{
                      self.$root.fn_showToastMsg("Uploading cancelled!");
                    }
                    self.cancelInit = false;
                    self.fn_closeDialogProgress();
                },
                onError: function(error){
                    console.log(error);
                    if(error == 'file not valid'){
                      self.$root.fn_showToastMsg("Invalid file!");
                    }
                    else if(typeof(error) == 'string' && error.toLowerCase() == "request cancel"){
                      console.log('request cancel');
                    }
                    else{
                      self.$root.fn_showToastMsg("Something went wrong. Try again!");
                    }
                    self.cancelInit = false
                    self.fn_closeDialogProgress();
                }
            }
        );
      },

      fn_selectDatabase: function(keyValueObj, businessId){
        let businessid = businessId ? businessId : this.$route.query.businessid;

        if(businessid && this.$route.query.userbusinessid && businessid != this.$route.query.userbusinessid){
          this.$root.fn_showToastMsg("Error: Database file mismatch!");
          return
        }

        var self = this;
        let postObj = {
          "ticketFolderId":this.Form.TicketFolderID,
          "messageFolderId":this.Form.TicketMessageFolderID,
          // "businessId":this.$route.query.businessid,
          "businessId":this.$route.query.userbusinessid,
          "KeyName":keyValueObj.KeyName,
          "KeyValue":keyValueObj.KeyValue
        }

        self.progressMessage = "Uploading...";
        self.fn_openDialogProgress();
        this.postData("UploadDbToFirebase", postObj, 
            {
                onSuccess: function(response){
                    console.log(response);
                    if(!self.cancelInit){
                      let fileObj = JSON.parse(response);
                      // self.Form.TicketFolderId = fileObj.ticketFolderId;
                      // self.Form.TicketMessageFolderID = fileObj.messageFolderId;
                      self.attachments.push(fileObj);
                    }
                    else{
                      self.$root.fn_showToastMsg("Uploading cancelled!");
                    }
                    self.cancelInit = false;
                    self.$root.isRestoreDb = true;
                    /* global AndroidRoutingTest */
                    AndroidRoutingTest.dbRestored('false');
                    self.fn_reopenDB();
                    self.fn_closeDialogProgress();
                },
                onError: function(error){
                    console.log(error);
                    self.$root.fn_showToastMsg("Something went wrong. Try again!");
                    self.cancelInit = false;
                    self.$root.isRestoreDb = true;
                    AndroidRoutingTest.dbRestored('false');
                    self.fn_reopenDB();
                    self.fn_closeDialogProgress();
                }
            }
        );
      },

      fn_selectLocalDatabase: function(){
        this.$refs['ref_dialogConfirmation'].closeDialogWeb();
        var self = this;
        this.postData("SelectLocalBackup", null, 
            {
                onSuccess: function(response){
                    console.log(response);
                    let fileExt = response.substr(response.lastIndexOf('.'));
                    if(fileExt=='.bizops' || fileExt=='.gz'){
                      self.fn_uploadLocalDatabase(response);
                    }
                    else{
                      self.$root.fn_showToastMsg("Please select database file!");
                    }
                },
                onError: function(error){
                    console.log(error);
                    // if(error == "Request Cancel"){
                    //   console.log('request cancel');
                    // }
                    if(typeof(error) == 'string' && error.toLowerCase() == "request cancel"){
                      console.log('request cancel');
                    }
                    else{
                      self.$root.fn_showToastMsg("Something went wrong. Try again!");
                    }
                }
            }
        );
      },

      fn_uploadLocalDatabase: function(filePath){
        var self = this;
        let postObj = {
          "filePath":filePath, 
          "ticketFolderId":this.Form.TicketFolderID, 
          "messageFolderId":this.Form.TicketMessageFolderID, 
          // "businessId":this.$route.query.businessid
          "businessId":this.$route.query.userbusinessid
        };
        self.progressMessage = "Uploading...";
        // self.fn_openDialogProgress();
        setTimeout(function(){
          self.fn_openDialogProgress();
        },300)
        this.postData("UploadLocalAttachDB", postObj, 
            {
                onSuccess: function(response){
                    console.log(response);
                    if(!self.cancelInit){
                      let fileObj = JSON.parse(response);
                      // self.Form.TicketFolderId = fileObj.ticketFolderId;
                      // self.Form.TicketMessageFolderID = fileObj.messageFolderId;
                      self.attachments.push(fileObj);
                    }
                    else{
                      self.$root.fn_showToastMsg("Uploading cancelled!");
                    }
                    self.cancelInit = false;
                    self.fn_closeDialogProgress();
                },
                onError: function(error){
                    console.log(error);
                    self.$root.fn_showToastMsg("Something went wrong. Try again!");
                    self.cancelInit = false;
                    self.fn_closeDialogProgress();
                }
            }
        );
      },

      fn_reopenDB: function(){
          this.getData('ReopenDataBase', this.$route.query.businessid, 
              {
                  onSuccess: function(response){
                      console.log(response);
                  }, 
                  onError: function(error){
                      console.log(error);
                  }
              }
          );
      },

        fn_deleteAttachment: function(filePath, index, type){
            let self = this;
            if(this.$root.platform()=='android'){
                if(type=='update'){
                    this.deletedFiles.push(filePath);
                    this.attachments.splice(index, 1);
                }
                else{
                    this.showLoading('Deleting File...');
                    this.postData("deleteFirebaseFile", filePath, 
                        {
                            onSuccess: function(response){
                                console.log(response);
                                self.attachments.splice(index, 1);
                                self.hideLoading();
                            },
                            onError: function(error){
                                console.log(error);
                                self.$root.fn_showToastMsg("Something went wrong. Try again!");
                                self.hideLoading();
                            }
                        }
                    );
                }
            }
            else{
                this.$root.fn_showLoading('Deleting File...', this.loadingObj);
                this.fn_deleteFile(filePath, index);
            }
        },

      fn_deleteFile: function(filePath, index){
        let self = this;
        var fileRef = storageFn.refFromURL(filePath);
        // Delete the file
        fileRef.delete().then(function() {
          self.attachments.splice(index, 1);
          self.$root.fn_hideLoading(self.loadingObj);
        }).catch(function(error) {
          console.log(error);
          self.$root.fn_hideLoading(self.loadingObj);
          self.$root.fn_showToastMsg("Something went wrong. Try again!");
        });
      },

      fn_cancelDesktopUpload: function(){
        if(this.uploadTask){
          this.uploadTask.cancel();
        }
        else{
          this.$root.fn_showToastMsg("Failed to cancel uploading!");
        }
      },

      fn_selectFile: function(type, dbType){
        if(this.$root.platform()=='desktop'){
          document.getElementById('filePicker').click();  
        }
        else{
          this.fn_checkStorage(type, dbType);
        }
      },

      fn_validateFile: function(event){
        if(this.attachments.length==5){
          this.$root.fn_showToastMsg("Max file limit reached!");
          return;
        }

        let extentions = ['JPG','JPEG','PNG','GIF','MP4', 'MPEG', 'AVI','MOV','FLV','PDF','DOC', 'DOCX', 'TEXT','XLSX','CSV','MP3','WMA','AAC','WAV','BIZOPS','GZ'];
        console.log(event.target.files[0]);
        let file = event.target.files[0];
        let fileExt = file.name.substr(file.name.lastIndexOf('.'));
        let extIndex = extentions.findIndex(ext => '.'+ext.toUpperCase()==fileExt.toUpperCase());
        if(extIndex == -1){
          this.$root.fn_showToastMsg("File not supported!");
          return;
        }

        // this.fileName = file.name;
        // file.name = file.name.substr(0, file.name.lastIndexOf('.')) + "@" + new Date().getTime() + fileExt;
        let fileName = file.name.substr(0, file.name.lastIndexOf('.')) + "@" + new Date().getTime() + fileExt;

        this.progressMessage = "Uploading...";
        this.fn_openDialogProgress();

        let self = this;
        // !this.Form.TicketFolderID ? this.Form.TicketFolderID = this.guidWeb() : '';
        // !this.Form.TicketMessageFolderID ? this.Form.TicketMessageFolderID = this.guidWeb() : '';

        let fileType = fileExt == '.bizops' || fileExt == '.gz' ? 2 : 1;
        this.fn_uploadViaDesktop(file, fileName, function(downloadUrl){
          let fileObj = {"fileExt":fileExt, "fileType":fileType, "fileName":fileName, "filePath":downloadUrl, "ticketFolderId":self.Form.TicketFolderId, "messageFolderId":self.Form.TicketMessageFolderID};
          if(!self.cancelInit){
            self.attachments.push(fileObj);
            self.fn_closeDialogProgress();
          }
          else{
            self.$root.fn_showToastMsg("Uploading cancelled!");
            self.fn_closeDialogProgress();
            self.cancelInit = false;
          }

          self.$root.fn_hideLoading(self.loadingObj);
          document.getElementById('filePicker').value = "";
        })
      },

      fn_uploadViaDesktop: function(file, fileName, callback){

        // let storageRef = firebase.storage().ref();
        let storageRef = storageFn.ref();
        let self = this;

        let path = 'tickets/'+ this.$route.query.userbusinessid + '/' + this.Form.TicketFolderID+'/'+this.Form.TicketMessageFolderID+'/' + fileName
        // 'tickets/'+ this.$route.query.businessid + '/' + this.Form.TicketFolderID+'/'+this.Form.TicketMessageFolderID+'/' + fileName
        // Upload file and metadata to the object 'images/mountains.jpg'
        this.uploadTask = storageRef.child(path).put(file);

        // Listen for state changes, errors, and completion of the upload.
        this.uploadTask.on(storageObj.TaskEvent.STATE_CHANGED, // or 'state_changed'
          function(snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            // setting up buffer size
            self.fn_setStatus(progress)
            switch (snapshot.state) {
              case storageObj.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
              case storageObj.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
          }, function(error) {

          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              console.log('storage/unauthorized');
              // User doesn't have permission to access the object
              break;

            case 'storage/canceled':
              callback('cancelled');
              // User canceled the upload
              break;

            case 'storage/unknown':
              console.log('storage/unknown');
              // Unknown error occurred, inspect error.serverResponse
              break;
          }
        }, function() {
          // Upload completed successfully, now we can get the download URL
          self.uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            console.log('File available at', downloadURL);
            callback(downloadURL);
          });
        });
      },

      fn_openDialogQuill: function(){
          this.$refs['ref_dialogQuill'].openDialogWeb();
      },

      fn_setMessage: function(message){
          this.Form.Message = message;
      },

      // fn_getSignature: function(callback){

      //   let self = this;
      //   this.$root.fn_showLoading('Please wait...', this.loadingObj);
      //   this.$root.fn_getAuthToken(function(token){

      //     if(token=='error'){
      //       self.$root.fn_hideLoading(self.loadingObj);
      //       self.$root.fn_showToastMsg("Something went wrong. Try again!");
      //       return;
      //     }

      //     var headersObj = {
      //     headers: { 
      //           'Authorization': 'bearer '+token,
      //         }
      //     };

      //     axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/SupportSignatures/getsignature', headersObj, {timeout:30000})
      //     .then(response => { 
      //         console.log(JSON.stringify(response.data));
      //         self.$root.fn_hideLoading(self.loadingObj);
      //         callback(response)
      //     }).catch(e => {
      //         self.$root.fn_showToastMsg("Something went wrong. Try again!");
      //         self.$root.fn_hideLoading(self.loadingObj);
      //         console.log(e);
      //     })
      //   })
      // },

      fn_openPermissionDialog: function(permissionType){
          this.$root.permissionType = permissionType;
          this.$refs['ref_dialogPermission'].dialog_permission = true;
      },
	},
  mounted: function(){
    window.js_globals.fn_openPermissionDialog = this.fn_openPermissionDialog;
  }
}