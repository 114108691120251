<template>
    <div full-height>
        <v-toolbar flat fixed>
            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
            <v-toolbar-title class="ml-5">Backup/Restore</v-toolbar-title>
        </v-toolbar>

        <div class="hastoolbar">

            <v-list nopadding>

                <!-- <v-list-item @click="fn_backupToDevice"> -->
                <v-list-item @click="fn_checkTemporaryBusiness">
                    <v-list-item-action>
                      <v-icon color="primary">cloud_upload</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Backup to Device</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="fn_selectDBFile">
                    <v-list-item-action>
                      <v-icon color="primary">cloud_download</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Restore from Device</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

            </v-list>

        </div> 

        <dialog-restore-types ref="ref_dialogRestoreTypes" :restore="fn_restore"></dialog-restore-types> 
    </div>
</template>

<script>

import dialogRestoreTypes from './dialogs/dialog_restore_type'

    export default{
        name:'backup_restore',
        data(){
            return{
                filePath:null
            }
        },
        components:{
            'dialog-restore-types':dialogRestoreTypes
        },
        methods:{

            fn_checkTemporaryBusiness : function(){
                let self = this;
                this.showLoading('Please wait...');
                  this.getData('CheckTemporaryBusiness', this.$route.query.businessid, 
                      {
                          onSuccess: function(response){
                              console.log(response);
                              if(JSON.parse(response)){
                                // self.fn_getLocalKeyValue();
                                self.fn_getServerKeyValue();
                              }
                              else{
                                self.fn_checkOverwriteBusiness();
                              }
                          }, 
                          onError: function(error){
                              console.log(error);
                              self.hideLoading();
                              self.$root.fn_showToastMsg("Something went wrong. Try again!");
                          }
                      }
                );
            },

            fn_checkOverwriteBusiness : function(){
                let self = this;
                  this.getData('CheckOverwriteBusiness', this.$route.query.businessid, 
                      {
                          onSuccess: function(response){
                              console.log(response);
                              if(JSON.parse(response)){
                                self.fn_getOverwriteBusinessId();
                              }
                              else{
                                self.fn_getLocalKeyValue();
                              }
                          }, 
                          onError: function(error){
                              console.log(error);
                              self.hideLoading();
                              self.$root.fn_showToastMsg("Something went wrong. Try again!");
                          }
                      }
                  );
              },

              fn_getOverwriteBusinessId : function(){
                  let self = this;
                  this.getData('GetOverwriteBusinessId', null, 
                      {
                          onSuccess: function(response){
                              console.log(response);
                              self.fn_getServerKeyValue(response);
                          }, 
                          onError: function(error){
                              console.log(error);
                              self.hideLoading();
                              self.$root.fn_showToastMsg("Something went wrong. Try again!");
                          }
                      }
                  );
              },

              fn_getServerKeyValue: function(businessId){
                if(!navigator.onLine){
                    this.$root.fn_showToastMsg('Internet not available.');
                    this.hideLoading();
                    return;
                }

                let self = this;
                this.$root.fn_getAuthToken(function(token){
                  let filterObject = {};
                  filterObject.businessId = businessId ? businessId : self.$route.query.businessid;
                  filterObject.token = token;
                  self.getServerData('GetServerKeyValue', filterObject, 
                      {
                          onSuccess: function(response){
                              let keyValueObj = JSON.parse(response);
                              self.hideLoading();
                              self.fn_backupToDevice(keyValueObj);
                          }, 
                          onError: function(error){
                              console.log(error);
                              self.hideLoading();
                              self.$root.fn_showToastMsg("Something went wrong. Try again!");
                          }, 
                      }
                  );
                })
                
              },

              fn_getLocalKeyValue : function(){
                let self = this;
                  this.getData('GetLocalKeyValue', null, 
                      {
                          onSuccess: function(response){
                              console.log(response);
                              let keyValueObj = JSON.parse(response);
                              self.hideLoading();
                              self.fn_backupToDevice(keyValueObj);
                          }, 
                          onError: function(error){
                              console.log(error);
                              self.hideLoading();
                              self.$root.fn_showToastMsg("Something went wrong. Try again!");
                          }
                      }
                  );
              },

            fn_backupToDevice: function(keyValueObj){
                var self = this;
                this.showLoading("Backing up data to device...");

                let postObj = {};
                postObj.businessId = this.$route.query.businessid;
                postObj.KeyName = keyValueObj.KeyName;
                postObj.KeyValue = keyValueObj.KeyValue;

                this.postData("BackupToDevice", postObj, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.hideLoading();
                            self.$root.fn_showToastMsg("Data backup complete!");
                            self.fn_reopenDB();
                        },
                        onError: function(error){
                            console.log(error);
                            self.fn_reopenDB();
                            self.hideLoading();
                            self.$root.fn_showToastMsg("Something went wrong. Try again!");
                        }
                    }
                );
            },

            // fn_backupToDevice: function(){
            //     var self = this;
            //     this.showLoading("Backing up data to device...");
            //     this.getData("BackupToDevice", this.$route.query.businessid, 
            //         {
            //             onSuccess: function(response){
            //                 console.log(response);
            //                 self.hideLoading();
            //                 self.$root.fn_showToastMsg("Backup Complete!");
            //                 self.fn_reopenDB();
            //             },
            //             onError: function(error){
            //                 console.log(error);
            //                 self.fn_reopenDB();
            //                 self.hideLoading();
            //                 self.$root.fn_showToastMsg("Something went wrong. Try again!");
            //             }
            //         }
            //     );
            // },

            fn_selectDBFile: function(){
                var self = this;
                this.postData("SelectLocalDBFile", null, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                            self.filePath = response;
                            let fileExt = response.substr(response.lastIndexOf('.'));
                            if(fileExt=='.bizops' || fileExt=='.gz'){
                                self.fn_openDialogRestoreType();
                            }
                            else{
                                self.$root.fn_showToastMsg("Please select database file!");
                            }
                        },
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
            },

            fn_openDialogRestoreType: function(){
                this.$refs['ref_dialogRestoreTypes'].openDialogWeb();
            },

            fn_restore: function(type){
                this.$refs['ref_dialogRestoreTypes'].dialog_restore_type = false;
                this.fn_restoreDatabase(type)
            },

            fn_restoreDatabase: function(type){
                let self = this;


                let postObj = {};
                // type == 1 for clone 2 for overwrite
                postObj.businessId = this.$route.query.businessid;
                postObj.filePath = this.filePath;
                postObj.overwriteBusiness = type == 1 ? false : true;

                console.log(JSON.stringify(postObj));
                this.showLoading('Restore in process...');
                this.postData("RestoreBusinessFromLocal", postObj, 
                  {
                      onSuccess: function(response){
                          console.log(response);
                          self.hideLoading();
                          self.$root.isRestoreDb = true;
                          /* global AndroidRoutingTest */
                          AndroidRoutingTest.dbRestored('true');
                          self.$root.fn_showToastMsg("Data restore complete!");
                          let businessId = type == 1 ? response : null;
                          self.fn_reopenDB(businessId);
                      },
                      onError: function(error){
                          console.log(error);
                          self.fn_reopenDB();
                          self.hideLoading();
                          self.$root.fn_showToastMsg("Something went wrong. Try again!");
                      }
                  }
                );
            },

            fn_reopenDB: function(businessid){
                let bid = null;
                if(businessid){
                    this.$root.temporaryBusinessId = businessid;
                    bid = businessid;
                }
                else{
                    this.$root.temporaryBusinessId = null;
                    bid = this.$route.query.businessid;
                }
                this.getData('ReopenDataBase', bid, 
                    {
                        onSuccess: function(response){
                            console.log(response);
                        }, 
                        onError: function(error){
                            console.log(error);
                        }
                    }
                );
            },

            fn_goBack: function(){
                this.$router.go(-1);
            },
        },
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
        },
        beforeRouteLeave(to, from, next){
            if(this.$refs['ref_dialogRestoreTypes'].dialog_restore_type){
                this.$refs['ref_dialogRestoreTypes'].dialog_restore_type = false;
                next(false);
            }
            else{
                next();
            }
        }
    }
</script>