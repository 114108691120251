<template>
    <div full-height>
        <v-toolbar flat fixed>
            <v-toolbar-side-icon @click="fn_goBack"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon>
            <v-toolbar-title class="ml-5">{{$route.params && $route.params.supportUserId ? 'Update' : 'Add'}} Support User</v-toolbar-title>
        </v-toolbar>

        <div class="hastoolbar hasfooter" :class="{'desktop-view':$root.platform()=='desktop'}">
            <v-form class="form-element">

                <v-layout align-center nomargin padding-top-ten row wrap white-background>
                    <v-flex xs12 sm12 md12 form-item>
                       <v-text-field  
                            v-model="Form.Name" 
                            label="Name" 
                            placeholder=" "
                            v-validate="'required'"
                            data-vv-name="name" 
                            :error-messages="errors.collect('title')"
                            required>
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 sm12 md12 form-item>
                       <v-text-field  
                            v-model="Form.PhoneNumber" 
                            label="Enter User Phone Number" 
                            type="tel"
                            placeholder=" "
                            clearable
                            prefix="+91"
                            v-validate="'required|numeric|min:10|max:10'"
                            :maxlength="10"
                            data-vv-name="phoneNumber"  
                            data-vv-as="phone number"  
                            :error-messages="errors.collect('phoneNumber')"
                            required>
                        </v-text-field>
                    </v-flex>

                    <v-flex xs12 sm12 md12 form-item>
                        <v-select :items="userStatuses" label="Status" v-model="Form.Status"></v-select>
                    </v-flex>

                    <v-flex xs12 sm12 md12 form-item>
                        <v-select :items="userTypes" label="Is Developer" v-model="isDeveloper"></v-select>
                    </v-flex>

                    <v-flex xs12 sm12 md12 form-item>
                        <v-select :items="notificationTypes" label="Ticket Notification" v-model="isNotification"></v-select>
                    </v-flex>

                    <v-flex xs12 sm12 md12 form-item>
                        <v-select :items="notificationTypes" label="Feedback Notification" v-model="isFeedback"></v-select>
                    </v-flex>

                </v-layout>

            </v-form>
<!-- 
            <v-footer style="position:fixed;bottom:0;width:100%">
                <v-layout row wrap>

                    <v-flex xs12 sm12 md12 form-item>
                        <v-btn color="primary" block @click="fn_checkValidations">
                            {{$route.params && $route.params.supportUserId ? 'Update' : 'Add User'}}
                        </v-btn>
                    </v-flex>  
                    
                </v-layout>
            </v-footer> -->

            <v-footer nopadding style="position:fixed;bottom:0;width:100%">
                <v-layout row wrap>
                    <v-flex xs12 sm12 md12 height48>
                        <v-btn color="primary" block full-height noshadow no-round-borders @click="fn_checkValidations">
                            {{$route.params && $route.params.supportUserId ? 'Update' : 'Save'}}
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-footer>
        </div>  

        <v-dialog v-model="loadingObj.loading" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios';

    export default{
        data(){
            return{
                Form:{"Name":null, "PhoneNumber":null, "Status":"Active"},
                isDeveloper:"No",
                isNotification:"No",
                isFeedback:"No",
                userStatuses:["Active", "De-active"],
                userTypes:["Yes", "No"],
                notificationTypes:["Yes", "No"],
                loadingObj:{
                  loading:false,
                  message:"Please wait..."    
                }
            }
        },
        methods: {

            fn_checkValidations: function(){
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        let self = this;
                        this.$root.fn_showLoading('Please wait...', this.loadingObj);
                        this.$root.fn_getAuthToken(function(token){
                            if(token=='error'){
                              self.$root.fn_hideLoading(self.loadingObj);
                              self.$root.fn_showToastMsg("Something went wrong. Try again!");
                              return;
                            }
                            self.fn_save(token);
                        })
                    }
                });
            },

            fn_save: function(token){

                var headersObj = {
                headers: { 
                      'Authorization': 'bearer '+token,
                    }
                };

                let api = null;
                let toastMsg = null

                if(this.$route.params.supportUserId){
                    api = process.env.VUE_APP_BASE_API_URL + '/api/supportteam/supportusers/'+this.$route.params.supportUserId+'/updateuser';
                    toastMsg = "Successfully updated!";
                }
                else{
                    api = process.env.VUE_APP_BASE_API_URL + '/api/supportteam/supportusers/adduser'
                    toastMsg = "Successfully Added!";
                }

                let postObj = {};
                postObj.Name = this.Form.Name;
                postObj.PhoneNumber = "91"+this.Form.PhoneNumber;
                postObj.Status = this.Form.Status == "Active" ? 1 : 0;
                postObj.IsDeveloper = this.isDeveloper == "Yes" ? true : false;
                postObj.IsNotification = this.isNotification == "Yes" ? true : false;
                postObj.IsFeedback = this.isFeedback == "Yes" ? true : false;

                console.log(JSON.stringify(postObj));

                axios.post(api, postObj, headersObj, {timeout:30000})
                .then(response => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    console.log(response);
                    if(response && response.data && response.data.Message == "User Already Exist!"){
                        this.$root.fn_showToastMsg("User already exist!");
                    }
                    else{
                        this.$root.fn_showToastMsg(toastMsg);
                        this.fn_goBack();
                    }
                    
                }).catch(e => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    console.log(JSON.stringify(e));
                })
                
            },

            fn_getUser: function(token){

                var headersObj = {
                headers: { 
                      'Authorization': 'bearer '+token,
                    }
                };
                
                axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/supportusers/'+this.$route.params.supportUserId, headersObj, {timeout:30000})
                .then(response => {
                    console.log(JSON.stringify(response));
                    this.Form.Name = response.data.Name;
                    this.Form.PhoneNumber = response.data.PhoneNumber.substr(2, response.data.PhoneNumber);
                    this.Form.Status = response.data.Status == 1 ? "Active" : "De-active";
                    this.isDeveloper = response.data.IsDeveloper ? "Yes" : "No";
                    this.isNotification = response.data.IsNotification ? "Yes" : "No";
                    this.isFeedback = response.data.IsFeedback ? "Yes" : "No";
                    this.$root.fn_hideLoading(this.loadingObj);
                }).catch(e => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    console.log(e);
                })
                
            },

            fn_getToken: function(){
                let self = this;
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){
                    if(token=='error'){
                      self.$root.fn_hideLoading(self.loadingObj);
                      self.$root.fn_showToastMsg("Something went wrong. Try again!");
                      return;
                    }
                    self.fn_getUser(token);
                })
            },
           
            fn_goBack: function(){
                this.$router.go(-1);
            }
        },
        
        mounted: function(){
            window.js_globals.fn_onBackPress = this.fn_goBack;
            if(this.$route.params.supportUserId){
                this.fn_getToken()
            }
        }
    }
</script>

<style>
.native_checkbox{
   height:20px;
   width:20px;
}
</style>