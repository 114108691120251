<template>
    <div>
        <v-dialog v-model="dialog_businessdetails" content-class="businessview_dialog" scrollable persistent max-width="50%" no-click-animation transition="slide-x-transition">
            <v-card>
                <v-toolbar flat class="main_toolbar">
                    <!-- <v-toolbar-side-icon @click="closeDialogWeb"><v-icon color="primary">arrow_back</v-icon></v-toolbar-side-icon> -->
                    <v-toolbar-side-icon @click="closeDialogWeb"><v-icon color="primary">close</v-icon></v-toolbar-side-icon>
                    <v-toolbar-title class="ml-5 halfscreen_dialog halfscreen_dialog_title">Business Details</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn v-if="displayData && displayData.Tags && displayData.Tags.length>0" class="mt-3 mb-3 mr-3 addtag_btn" @click="fn_showEditTags(displayData.BusinessID, displayData.TagIDs)" plain><v-icon>edit</v-icon> Update Tags</v-btn>
                    <v-btn v-else class="mt-3 mb-3 mr-3 addtag_btn" @click="fn_showAddTags(displayData.BusinessID)" plain><v-icon>add</v-icon> Add Tags</v-btn>
                </v-toolbar>

                <div class="hastoolbar" full-height grey-back v-if="displayData">
                    <v-list three-line full-width>
                        <v-list-item style="padding-right:0">
                            <v-list-item-content>
                            <v-list-item-title>{{displayData.BusinessName}}</v-list-item-title>
                            <v-list-item-subtitle>Business ID: {{displayData.BusinessID}}</v-list-item-subtitle>
                            <v-list-item-subtitle>State: {{displayData.State}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="displayData.BusinessProfileNumber">Mobile Number: <span class="label_mobile_number" style="display: none;">+{{displayData.BusinessProfileNumber}}</span> <span @click="fn_toggleMobileHideShow" class="label_hide_show primary-color link-item font-medium">View</span></v-list-item-subtitle>
                            <v-list-item-subtitle v-else-if="displayData.BusinessOwnerNumber">Mobile Number: <span class="label_mobile_number" style="display: none;">+{{displayData.BusinessOwnerNumber}}</span> <span @click="fn_toggleMobileHideShow" class="label_hide_show primary-color link-item font-medium">View</span></v-list-item-subtitle>
                            
                            <v-list-item-subtitle v-if="displayData.GSTRegistrationStatus">Registration Type: {{fn_getRegistrationType(displayData.GSTRegistrationStatus)}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="displayData.IsGSTINFilled != null">Is GST Filled: {{displayData.IsGSTINFilled == true ? 'Yes' : displayData.IsGSTINFilled == false ? 'No' : '' }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="displayData.IsAddressFilled != null">Is Address Filled: {{displayData.IsAddressFilled == true ? 'Yes' : displayData.IsAddressFilled == false ? 'No' : '' }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="displayData.IsPoweredByRemoved != null">Is Powered By Removed: {{displayData.IsPoweredByRemoved == true ? 'Yes' : displayData.IsPoweredByRemoved == false ? 'No' : '' }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="displayData.IsDriveSetup != null">Is Drive Setup: {{displayData.IsDriveSetup == true ? 'Yes' : displayData.IsDriveSetup == false ? 'No' : '' }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="displayData.Tags && displayData.Tags.length>0">
                                Tags: 
                                <span v-for="(tag, index2) in displayData.Tags" :key="index2">
                                    <span>{{tag}}<span v-if="displayData.Tags.length - 1 != index2">, </span></span>
                                </span>
                            </v-list-item-subtitle>
                            
                            <v-list-item-subtitle>View Daily Usage: <span @click="fn_showBusinessMetrics(displayData.BusinessID)" class="label_hide_show primary-color link-item font-medium">Show</span></v-list-item-subtitle>
                            <v-list-item-subtitle>View Detail Usage: <span @click="fn_showBusinessDetailMetrics(displayData.BusinessID)" class="label_hide_show primary-color link-item font-medium">Show</span></v-list-item-subtitle>
                            <!-- <v-list-item-subtitle class="label_mobile_number" v-else-if="displayData.BusinessOwnerNumber"> +{{displayData.BusinessOwnerNumber}}</v-list-item-subtitle> -->

                            </v-list-item-content>

                            <v-list-item-action>
                                <div style="background: #1976d2;color: #fff;padding: 2px 8px;border-top-left-radius: 4px;border-bottom-left-radius: 4px;font-size: 0.875rem;">
                                    {{displayData.Status==1 ? 'Active' : "De-Active"}}
                                </div>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>

                    <v-card flat margin-top-ten v-if="displayData.Address">
                        <v-subheader>Address</v-subheader>
                        <v-divider></v-divider>
                        <v-card-text>
                            {{displayData.Address}}
                        </v-card-text>
                    </v-card>

                    <v-list nopadding three-line full-width margin-top-ten>
                        <v-subheader>GST Details</v-subheader>
                        <v-divider></v-divider>
                        <v-list-item style="padding-right:0">
                            <v-list-item-content>
                            <v-list-item-title>
                                {{displayData.GSTRegistrationStatus == 1 ? "Registered Business" : displayData.GSTRegistrationStatus == 2 ? "Composite Business" : "Unregistered Business"}}
                            </v-list-item-title>
                            <v-list-item-subtitle>GST Number: {{displayData.GSTNumber}}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{displayData.HSNApplicable ? "HSN Applicable" : "HSN Not Applicable"}}</v-list-item-subtitle>
                            <v-list-item-subtitle>Tax Mode: {{displayData.TaxMode == 1 ? 'Exclusive' : displayData.TaxMode == 2 ? 'Inclusive' : 'None'}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>

                    <v-list three-line full-width margin-top-ten>
                        <v-list-item style="padding-right:0">
                            <v-list-item-content>
                            <v-list-item-title>Created On</v-list-item-title>
                            <v-list-item-subtitle>{{displayData.CreatedOn | moment("DD/MM/YYYY h:mm a")}}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{displayData.IsSupport ? "By Support User" : "By Customer"}}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>



                    </div>
            </v-card>
        </v-dialog>

        <dialog-business-metrics ref="ref_dialogBusinessMetrics"></dialog-business-metrics>
        <dialog-businessdetail-metrics ref="ref_dialogBusinessDetailMetrics"></dialog-businessdetail-metrics>
        <dialog-addtags ref="ref_dialogAddTags" :fn_updateTags="fn_updateReload"></dialog-addtags>

        <v-dialog v-model="loadingdialog" persistent width="300" no-click-animation>
            <v-card>
                <v-card-text class="pt-3">
                    <div class="pb-2">Please wait...</div>
                    <v-progress-linear indeterminate color="primary"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>     

<script>

import axios from 'axios';

import dialogBusinessMetrics from '../../tickets/dialogs/dialog_viewbusinessmetricsfullscreen';
import dialogBusinessDetailMetrics from '../../tickets/dialogs/dialog_viewbusinessdetailmetricsfullscreen';
import dialogAddTags from '../../tickets/dialogs/dialog_addtags';

export default{
    props:['attachments', 'downloadAttachment', 'type'],
    data(){ 
        return{
            dialog_businessdetails: false,
            loadingdialog: false,
            loadingObj:{
                loading:false,
                message:"Please wait..."    
            },
            displayData:null,
            RegistrationType:[
                {"name":"All", "status":null},
                {"name":"Registered", "status":1},
                {"name":"Composite", "status":2},
                {"name":"Unregistered", "status":3}
            ],
        }
    },
    components: {
        'dialog-business-metrics':dialogBusinessMetrics,
        'dialog-businessdetail-metrics': dialogBusinessDetailMetrics,
        'dialog-addtags': dialogAddTags
    },
    methods: {
        openDialogWeb: function () {    
            this.dialog_businessdetails = true;
        },
        closeDialogWeb: function () {
            this.dialog_businessdetails = false;
            var wrp = document.getElementsByTagName('html');
            for (var i = 0; i < wrp.length; i++) {
                wrp[i].style.height = "auto";
                wrp[i].style.overflowY = "auto";
            }
        },
        fn_getRegistrationType: function(value){
            for(var i in this.RegistrationType){
                if(this.RegistrationType[i].status == value)
                    return this.RegistrationType[i].name
            }
        },

        fn_showBusinessMetrics: function(businessId){
            this.$refs['ref_dialogBusinessMetrics'].fn_getToken(businessId);
        },

        fn_showBusinessDetailMetrics: function(businessId){
            this.$refs['ref_dialogBusinessDetailMetrics'].fn_getToken(businessId);
        },

        fn_toggleMobileHideShow: function(){
                let displayPropertyVal = document.getElementsByClassName('label_mobile_number')[0].style.display;
                if(displayPropertyVal == 'none'){
                    document.getElementsByClassName('label_mobile_number')[0].style.display = 'inline-block';
                    document.getElementsByClassName('label_hide_show')[0].innerHTML = 'Hide';
                }
                else{
                    document.getElementsByClassName('label_mobile_number')[0].style.display = 'none';
                    document.getElementsByClassName('label_hide_show')[0].innerHTML = 'View';
                }
            },

        fn_getToken: function(businessid){
                let self = this;
                this.loadingdialog = true;
                this.$root.fn_showLoading('Please wait...', this.loadingObj);
                this.$root.fn_getAuthToken(function(token){
                    if(token == 'error'){
                        self.$root.fn_hideLoading(self.loadingObj);
                        self.loadingdialog = false;
                        return;
                    }

                    self.fn_getBusiness(token, businessid);
                });
            },

            fn_getBusiness: function(token, businessid){
                var headersObj = {
                headers: { 
                      'Authorization': 'bearer '+token,
                    }
                };

                axios.get(process.env.VUE_APP_BASE_API_URL + '/api/supportteam/businesses/'+businessid, headersObj, {timeout:30000})
                .then(response => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.loadingdialog = false;
                    this.dialog_businessdetails = true;

                    var wrp = document.getElementsByTagName('html');
                    for (var i = 0; i < wrp.length; i++) {
                        wrp[i].style.height = "100vh";
                        wrp[i].style.overflowY = "hidden";
                    }

                    console.log(response);
                    console.log(JSON.stringify(response.data));
                    this.displayData = response.data;
                    setTimeout(function(){
                        document.getElementsByClassName('label_mobile_number')[0].style.display = 'none';
                        document.getElementsByClassName('label_hide_show')[0].innerHTML = 'View';
                    }, 100)
                }).catch(e => {
                    this.$root.fn_hideLoading(this.loadingObj);
                    this.$root.fn_showToastMsg("Something went wrong. Try again!");
                    this.snackbarRetry = true;
                    console.log(JSON.stringify(e));
                })
            },

            fn_showAddTags: function(businessId){
                this.$refs['ref_dialogAddTags'].fn_getToken(businessId);
            },

            fn_showEditTags: function(businessId, tags){
                this.$refs['ref_dialogAddTags'].fn_getToken(businessId, tags);
            },

            fn_updateReload: function(updatedtags, updatedtagids){
                console.log(updatedtags);
                this.displayData.Tags = updatedtags;
                this.displayData.TagIDs = updatedtagids;
                //this.fn_getToken(this.displayData.BusinessID)
            }

    }
}
</script>

<style>
.businessview_dialog{
    position: absolute;
    right: 0px;
    left: auto;
    max-width: 50% !important;
    max-height: 100% !important;
    margin: 0px;
    height: 100%;
}

.halfscreen_dialog{
    max-width: 43% !important;
    min-width: 43% !important;
    width: 43% !important;
    display: inline !important;
}

.halfscreen_dialog_title{
    max-width: 43% !important;
    min-width: 43% !important;
    width: 43% !important;
    display: inline !important;
}

.addtag_btn{
    right: 50%;
}
</style>