import Vue from 'vue' 

var actionQueue = [];

/*global AndroidRoutingTest*/
/*global SampleDeviceApi*/

Vue.prototype.excuteAction = function(action, params, callbackEvents){

  if((typeof SampleDeviceApi !== "undefined" && SampleDeviceApi !== null) || 
      (typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null)){
  
    var callbackID = this.guidWeb();
    pushAction(callbackID, action, callbackEvents);
  
    if(typeof SampleDeviceApi !== "undefined" && SampleDeviceApi !== null)
      SampleDeviceApi.execute(callbackID, action, JSON.stringify(params));

    else if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null)
      AndroidRoutingTest.execute(callbackID, action, JSON.stringify(params));
  }
  else{
    callbackEvents.onError('Device api is not defined');
  }
}

Vue.prototype.getData = function(action, itemId, callbackEvents){
  if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
    var callbackID = this.guidWeb();
    pushAction(callbackID, action, callbackEvents);
    AndroidRoutingTest.getData(callbackID, action, itemId);
  }
  else{
    callbackEvents.onError('Device api is not defined');
  }
}

Vue.prototype.getFilterData = function(action, itemId, filterObject, callbackEvents){
  if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
    var callbackID = this.guidWeb();
    pushAction(callbackID, action, callbackEvents);
    AndroidRoutingTest.getFilterData(callbackID, action, itemId, JSON.stringify(filterObject));
  }
  else{
    callbackEvents.onError('Device api is not defined');
  }
}

Vue.prototype.postFilterData = function(action, itemId, filterObject, callbackEvents){
  if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
    var callbackID = this.guidWeb();
    pushAction(callbackID, action, callbackEvents);
    AndroidRoutingTest.postFilterData(callbackID, action, itemId, JSON.stringify(filterObject));
  }
  else{
    callbackEvents.onError('Device api is not defined');
  }
}

Vue.prototype.postData = function(action, dataModel, callbackEvents){
  if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
    var callbackID = this.guidWeb();
    pushAction(callbackID, action, callbackEvents);
    console.log(JSON.stringify(dataModel));

    let postObj = typeof(dataModel) == 'string' ? dataModel : JSON.stringify(dataModel);
    AndroidRoutingTest.postData(callbackID, action, postObj);
  }
  else{
    if(window.webkit && window.webkit.messageHandlers){
      window.webkit.messageHandlers.postProductCategory.postMessage(JSON.stringify(dataModel));
    }
    else{
      console.log('messageHandlers not found');
    }
    callbackEvents.onError('Device api is not defined');
  }
}

Vue.prototype.deleteData = function(action, itemId, callbackEvents){
  if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
    var callbackID = this.guidWeb();
    pushAction(callbackID, action, callbackEvents);
    AndroidRoutingTest.deleteData(callbackID, action, itemId);
  }
  else{
    callbackEvents.onError('Device api is not defined');
  }
}

Vue.prototype.cancelData = function(action, itemId, callbackEvents){
  if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
    var callbackID = this.guidWeb();
    pushAction(callbackID, action, callbackEvents);
    AndroidRoutingTest.cancelData(callbackID, action, itemId);
  }
  else{
    callbackEvents.onError('Device api is not defined');
  }
}

Vue.prototype.openDialog = function(viewObject, dialogPath, tagId, paramObject, ref, callbackEvents){
  if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
    var callbackID = this.guidWeb();
    pushAction(callbackID, dialogPath, callbackEvents);
    AndroidRoutingTest.openDialog(callbackID, JSON.stringify(viewObject), dialogPath, tagId, JSON.stringify(paramObject), ref);
  }
  else{
    callbackEvents.onError(false);
  }
}

Vue.prototype.showDialog = function(tagId, ref, callbackEvents){
  if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
    var callbackID = this.guidWeb();
    pushAction(callbackID, null, callbackEvents);
    AndroidRoutingTest.showDialog(callbackID, tagId, ref);
  }
  else{
    callbackEvents.onError('Device api is not defined');
  }
}

Vue.prototype.closeDialog = function(tagId, callbackEvents){
  if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
    var callbackID = this.guidWeb();
    pushAction(callbackID, null, callbackEvents);
    AndroidRoutingTest.closeDialog(callbackID, tagId);
  }
  else{
    callbackEvents.onError('Device api is not defined');
  }
}

Vue.prototype.setBottomMenu = function(tagId, menuObject, callbackEvents){
  if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
    var callbackID = this.guidWeb();
    pushAction(callbackID, null, callbackEvents);
    AndroidRoutingTest.setBottomMenu(callbackID, tagId, JSON.stringify(menuObject));
  }
  else{
    callbackEvents.onError('Device api is not defined');
  }
}

Vue.prototype.showToast = function(message){
  if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
    AndroidRoutingTest.showToast(message);
  }
}

Vue.prototype.showLoading = function(message){
  if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
    AndroidRoutingTest.showLoading(message);
  }
}

Vue.prototype.hideLoading = function(){
  if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
    AndroidRoutingTest.hideLoading();
  }
}

Vue.prototype.exitApp = function(){
  if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
    AndroidRoutingTest.exitApp();
  }
}

Vue.prototype.openDownloadedFile = function(){
  if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
    AndroidRoutingTest.openDownloadedFile();
  }
},

Vue.prototype.postServerData = function(action, dataObj, callbackEvents){
  if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
    var callbackID = this.guidWeb();
    pushAction(callbackID, action, callbackEvents);
    AndroidRoutingTest.postServerData(callbackID, action, JSON.stringify(dataObj));
  }
  else{
    callbackEvents.onError('Device api is not defined');
  }
}

Vue.prototype.getServerData = function(action, filterObject, callbackEvents){
  if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null){
    var callbackID = this.guidWeb();
    pushAction(callbackID, action, callbackEvents);
    AndroidRoutingTest.getServerData(callbackID, action, JSON.stringify(filterObject));
  }
  else{
    callbackEvents.onError('Device api is not defined');
  }
}

function pushAction(callbackID, action, callbackEvents) {
  var actionObj = {};
  actionObj.action = action;
  actionObj.actionId = callbackID;
  actionObj.callbackEvents = callbackEvents
  actionQueue.push(actionObj);
}

function callNativeToJavascript(callbackID, status){
  if(Number(status)){
    successCallback(callbackID);
  }
  else{
    errorCallback(callbackID);
  }
}

function fn_itemMultipleSelection(callbackID, tagId, ref, selectedItemsId) {
  actionQueue.map(action =>{
    if(action.actionId == callbackID){
      action.callbackEvents.onChangeMultiple(tagId, ref, selectedItemsId);
    }
  }) 
}

function fn_itemSelection(callbackID, tagId, ref, selectedValueId, selectedValueName) {
  actionQueue.map(action =>{
    if(action.actionId == callbackID){
      action.callbackEvents.onChange(tagId, ref, selectedValueId, selectedValueName, false);
    }
  }) 
}

function fn_itemRemoveSelection(callbackID, tagId, ref, selectedValueId, selectedValueName) {
    actionQueue.map(action =>{
      if(action.actionId == callbackID){
        action.callbackEvents.onChange(tagId, ref, selectedValueId, selectedValueName, true);
      }
    }) 
}

function fn_menuItemClick(callbackID, tagId, menuId, menuAction, itemId) {
    actionQueue.map(action =>{
      if(action.actionId == callbackID){
        action.callbackEvents.onMenuItemClick(tagId, menuId, menuAction, itemId);
      }
    }) 
}

function fn_itemClick(callbackID, tagId, itemId, itemName) {
    actionQueue.map(action =>{
      if(action.actionId == callbackID){
        action.callbackEvents.onItemClick(tagId, itemId, itemName);
      }
    }) 
}

window.js_globals = new Object();
window.js_globals['callNativeToJavascript'] = callNativeToJavascript;

window.js_globals['fn_itemMultipleSelection'] = fn_itemMultipleSelection;
window.js_globals['fn_itemSelection'] = fn_itemSelection;
window.js_globals['fn_itemRemoveSelection'] = fn_itemRemoveSelection;
window.js_globals['fn_menuItemClick'] = fn_menuItemClick;
window.js_globals['fn_itemClick'] = fn_itemClick;

function successCallback(callbackID){
  var response;
  if(typeof SampleDeviceApi !== "undefined" && SampleDeviceApi !== null)
    response = SampleDeviceApi.successResponseCallback(callbackID);
  else if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null)
    response = AndroidRoutingTest.successResponseCallback(callbackID);

  actionQueue.map(action =>{
    if(action.actionId == callbackID){
      action.callbackEvents.onSuccess(response);
    }
  }) 
}

function errorCallback(callbackID){
  var response;
  if(typeof SampleDeviceApi !== "undefined" && SampleDeviceApi !== null)
    response = SampleDeviceApi.errorResponseCallback(callbackID);
  else if(typeof AndroidRoutingTest !== "undefined" && AndroidRoutingTest !== null)
    response = AndroidRoutingTest.errorResponseCallback(callbackID);

  actionQueue.map(action =>{
    if(action.actionId == callbackID){
      action.callbackEvents.onError(response);
    }
  })
}