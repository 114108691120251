<template>
    <div>
        <v-dialog v-model="dialog_permission" persistent max-width="290">
          <v-card>
            <v-card-text class="pt-4">
                <div v-if="$root.permissionType=='contact'">
                    Allow Bizops App to access your contacts.
                </div>
                <div v-if="$root.permissionType=='storage'">
                    Allow Bizops App to access your device's photos, media and files.
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small color="blue darken-1" text @click.native="closeDialogWeb">close</v-btn>
                <v-btn small color="blue darken-1" text @click.native="fn_permissionSettings">Ok</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>     

<script>

export default{
    props:['type'],
    data(){
        return{
            dialog_permission:false
        }
    },
    methods:{
        openDialogWeb: function () {
            this.dialog_permission = true;
        },
        closeDialogWeb: function () {
            this.dialog_permission = false;
        },
        fn_permissionSettings: function(){
            this.dialog_permission = false;
            this.$root.fn_gotoPermissionSettings()
        }
    }
}
</script>